import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getTopAuctionData } from "../../features/auctionList";
import {
  CardAction,
  CardContainer,
  CardDescription,
  CardFooter,
  CardProduct,
  CardTitle,
  EmptyImg,
  ImageWrapper,
  Label,
  ProgressBarContainer,
  TicketDetail,
  TicketDetails,
  Value,
} from "./StyledTopAuction";
import Button from "../../components/UI/Buttons/Button";
import {
  AppButtonType,
  ButtonSizeVariant,
  ButtonType,
  ButtonVariant,
} from "../../Utility/InterFacesAndEnum";
import { useNavigate } from "react-router-dom";
import { transformDate } from "../../Utility/Utility";
import AuctionBuyBtn from "../AuctionBuyButton/AuctionBuyButton";
import ProgressBarView from "../ProgressBar/ProgressBar";

const TopAuction:FC<any> = ({topAuction }) => {
  const navigate = useNavigate();
  // const { topAuction } = useSelector((state: RootState) => state.auction);
  // const isLoading = useSelector((state: RootState) => state.loader.isLoading);

  
  // let totalUsers:any = topAuction?.totalUsers!==null ? topAuction?.totalUsers:100;
  // let engagedUsers:any = topAuction?.noOfUsersJoined!==null ? topAuction?.noOfUsersJoined:0;

  let usersParticipatedPercentage =
    topAuction?.usersParticipatedPercentage !== null
      ? topAuction?.usersParticipatedPercentage
      : 0;

  const redirectToMoreDetail = (auctionId: number) => {
    navigate(`/auction/detail/${auctionId}`, { state: { id: auctionId } });
    // navigate(RouterPath.coinDetails, { state: { id: id } });
  };
  return (
    <CardContainer>
      <ImageWrapper>
        {
         topAuction?.thumbnail !== null||topAuction?.thumbnail !== undefined? <img
          src={
            topAuction?.thumbnail === null ? "" : topAuction?.thumbnail
            // liveAuctionList[1]?.auctionImageUrls[0]
          }
          key={topAuction?.thumbnail}
          
        />:<EmptyImg/>
        }
      </ImageWrapper>
      <div style={{ width: "100%" }}>
        <CardFooter>
          <CardDescription>
            <CardTitle>{topAuction?.auctionTitle}</CardTitle>
            <CardProduct>{topAuction?.productType}</CardProduct>
            <ProgressBarContainer>
              <ProgressBarView
                total={100}
                completed={usersParticipatedPercentage}
              />
            </ProgressBarContainer>
          </CardDescription>
        </CardFooter>
        <TicketDetails>
          <TicketDetail>
            <Label>Entry Ticket:</Label>
            <Value>&#x24; {topAuction?.bronzeTicketPrice}</Value>
          </TicketDetail>
          <TicketDetail>
            <Label>Draw Date:</Label>
            <Value>
              {transformDate(new Date(topAuction?.auctionEndDate)).toString()}
            </Value>
          </TicketDetail>
        </TicketDetails>
        <CardAction>
          <Button
            disabled={false}
            appBtnType={AppButtonType.primaryBordered}
            fullWidth={true}
            variant={ButtonVariant.contained}
            type={ButtonType.default}
            size={ButtonSizeVariant.small}
            clicked={() => {
              redirectToMoreDetail(topAuction?.auctionId);
            }}
          >
            More Detail
          </Button>
          <AuctionBuyBtn auctionObj={topAuction} />
        </CardAction>
      </div>
    </CardContainer>
  );
};

export default TopAuction;
