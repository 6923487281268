import { FC } from "react";
import logo from "../../assets/headerLogo.svg";
// import { BiPhoneCall } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import {BsWhatsapp} from "react-icons/bs";
import {
  Container,
  Contactus,
  Title,
  ContactBox,
  ImgContainer,
  ContactDetails,
  DetailSection,
  DetailIcon,
  AnchorTag,
} from "./StyleContactUs";

import { Helmet } from 'react-helmet';

const ContactUs = () => {
  return (
    <Container>
      <Helmet>
        <title>KingsRings - Contact Us</title>
      </Helmet>
      <Contactus>
        <Title>Contact Us</Title>
        <ContactBox>
          <ImgContainer>
            <img src={logo} />
          </ImgContainer>
          <ContactDetails>
            <DetailSection>
              <DetailIcon>
                <HiOutlineMail />
              </DetailIcon>
              <AnchorTag href="mailTo:info@kingsrings.games">
                info@kingsrings.games
              </AnchorTag>
            </DetailSection>
            <DetailSection>
              <DetailIcon>
                <BsWhatsapp />
              </DetailIcon>
              248-2630368
            </DetailSection>
          </ContactDetails>
        </ContactBox>
      </Contactus>
    </Container>
  );
};

export default ContactUs;
