import './App.css';
import AppRoutes, { RouterPath } from './routes';
import AppWrapper from './containers/AppWrapper/AppWrapper';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetailes } from './features/loadMoneySlice'
import { getUserProfileDetail } from './features/userProfileSlice';
import { getLocalStorage } from './localStorage/GetLocalStorage';
import * as localStorageActionType from "./localStorage/ActionTypes";
import AppProtectedRoutes from './routes/routes';
import { useNavigate } from 'react-router-dom';
import { RootState } from './app/store';

function App() {
  // const navigate=useNavigate();
  const accessToken = getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN)
  // const isAccessInvalid = localStorage.getItem("IS_ACCESS_INVALID")
  // const isAuthorized = useSelector((state:RootState)=>state.userProfile.isAuthorized);
  // console.log(isAuthorized)

  // useEffect(()=>{
  //   if(isAccessInvalid ){
  //     navigate(RouterPath.signIn)
  //   }
  //   localStorage.removeItem("IS_ACCESS_INVALID")
  // },[isAccessInvalid])

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getUserDetailes());
    dispatch(getUserProfileDetail());
  },[accessToken])
  return (
    <AppWrapper>
      <div className="App">
        {/* <AppRoutes /> */}
        <AppProtectedRoutes/>
    </div>
    </AppWrapper>
  );
}

export default App;
