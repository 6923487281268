import {FC,useEffect} from 'react';
import loadingImgUrl from '../../../../assets/loader.gif';
import * as styled from './StyledSuccess';

import {useNavigate, useLocation} from 'react-router-dom'
import {RouterPath} from '../../../../routes/routes';

import {useSelector, useDispatch} from 'react-redux';
import { RootState } from '../../../../app/store';
import {paymentComplete} from '../../../../features/purchaseAuctionSlice';

const Success:FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigation = useNavigate();

    let isSuccess = useSelector((state:RootState) => state.purchaseAuction.isPurchased)

    useEffect(() => {
        let searchUrl = location.search;
        let searchItems = searchUrl.split("&PayerID=");
        let token = searchItems[0].slice(7);
        if (token !== undefined && token !== null && token.length > 0) {
            dispatch(paymentComplete({payerId:token}));
        }
    },[]);

    useEffect(() => {
        if (isSuccess) {
            navigation(RouterPath.auctionPaymentSuccess);
        }
    },[isSuccess])

    return <styled.Wrapper>
        <styled.SectionWrapper>
            <styled.Title>
                Payment is Processing...
            </styled.Title>
            <styled.Subtitle>
                Please do not press back button or reload <br />
                page will be automaticaly refresh once process is complete
            </styled.Subtitle>
            <styled.ImageSection>
            <img src={loadingImgUrl} alt={"loader"} />
            </styled.ImageSection>
        </styled.SectionWrapper>
    </styled.Wrapper>
};

export default Success;