import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as endPoint from '../networkUtilities/endpoints';

import { getLocalStorage } from '../localStorage/GetLocalStorage';
import * as localStorageActionType from '../localStorage/ActionTypes';
import { toggleLoader } from './loaderSlice';

enum NotificationType {
    success = "success",
    error = "error",
    warning = 'warning'
}

interface NotificationProps {
    isVisible: boolean,
    status: NotificationType,
    message: string
}


const notificatoinState: NotificationProps = {
    isVisible: false,
    status: NotificationType.success,
    message: "Kings Ring"
}
// 

export const notifyMe = createAsyncThunk(
    'notify me',
    async(payload:any,{dispatch})=>{
        dispatch(toggleLoader({
            isLoading:true
        }))
        // console.log(payload)
        await fetch(endPoint.notiFyMe,{
            method:"POST",
            body:JSON.stringify(payload),
            headers:{
                Authorization: `Bearer ${getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN)}`,
                "Content-type": "application/json; charset=UTF-8",
            }
        })
        .then((response)=>{
            return response.json();
        })
        .then((data)=>{
            dispatch(toggleLoader({
                isLoading:false
            }))
            // console.log(data);
            if(data.statusCode===200){
                dispatch(toggleNotificationVisibility({
                    isVisible:true,
                    status:NotificationType.success,
                    message:data.errorMsg
                }))
            }else{
                dispatch(toggleNotificationVisibility({
                    isVisible:true,
                    status:NotificationType.error,
                    message:data.errorMsg
                }))
            }
        })
        .catch((err)=>{
            dispatch(toggleLoader({isLoading:false}))
            console.log(err);
            return err;
        })
    }
)

export const notifyMeLottery = createAsyncThunk(
    'notify me',
    async(payload:any,{dispatch})=>{
        dispatch(toggleLoader({
            isLoading:true
        }))
        // console.log(payload)
        await fetch(endPoint.lotteryNotiFyMe,{
            method:"POST",
            body:JSON.stringify(payload),
            headers:{
                Authorization: `Bearer ${getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN)}`,
                "Content-type": "application/json; charset=UTF-8",
            }
        })
        .then((response)=>{
            return response.json();
        })
        .then((data)=>{
            dispatch(toggleLoader({
                isLoading:false
            }))
            // console.log(data);
            if(data.statusCode===200){
                dispatch(toggleNotificationVisibility({
                    isVisible:true,
                    status:NotificationType.success,
                    message:data.errorMsg
                }))
            }else{
                dispatch(toggleNotificationVisibility({
                    isVisible:true,
                    status:NotificationType.error,
                    message:data.errorMsg
                }))
            }
        })
        .catch((err)=>{
            dispatch(toggleLoader({isLoading:false}))
            console.log(err);
            return err;
        })
    }
)

export const notifyMeAuction = createAsyncThunk(
    'notify me',
    async(payload:any,{dispatch})=>{
        dispatch(toggleLoader({
            isLoading:true
        }))
        // console.log(payload)
        await fetch(endPoint.auctionNotiFyMe,{
            method:"POST",
            body:JSON.stringify(payload),
            headers:{
                Authorization: `Bearer ${getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN)}`,
                "Content-type": "application/json; charset=UTF-8",
            }
        })
        .then((response)=>{
            return response.json();
        })
        .then((data)=>{
            dispatch(toggleLoader({
                isLoading:false
            }))
            // console.log(data);
            if(data.statusCode===200){
                dispatch(toggleNotificationVisibility({
                    isVisible:true,
                    status:NotificationType.success,
                    message:data.errorMsg
                }))
            }else{
                dispatch(toggleNotificationVisibility({
                    isVisible:true,
                    status:NotificationType.error,
                    message:data.errorMsg
                }))
            }
        })
        .catch((err)=>{
            dispatch(toggleLoader({isLoading:false}))
            console.log(err);
            return err;
        })
    }
)




const notificationSlice = createSlice({
    name: "Notification",
    initialState: notificatoinState,
    reducers:{
        toggleNotificationVisibility: (state, action:PayloadAction<NotificationProps>) => {
            return{
                ...state,
                isVisible: action.payload.isVisible,
                status: action.payload.status,
                message: action.payload.message
            }
        }
    }
});

export const {toggleNotificationVisibility} = notificationSlice.actions;
export default notificationSlice.reducer;