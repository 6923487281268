import React, { FC } from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { getLocalStorage } from '../localStorage/GetLocalStorage';
import * as localStorageActionType from "../localStorage/ActionTypes";
import { RouterPath } from '../routes';
interface ProtectedRouteProp{
  redirectionPath:string
}

  const ProtectedRoute:FC<ProtectedRouteProp> = ({redirectionPath}) => {
  const isLoggedIn = getLocalStorage(localStorageActionType.GET_IS_LOGGED_IN)
    
    if (!isLoggedIn) {
      return <Navigate to={redirectionPath} replace />;
    }
  
    return <Outlet />;
  };

export default ProtectedRoute;