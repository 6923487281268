import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as endpoint from "../networkUtilities/endpoints";
import { toggleNotificationVisibility } from "./notificationSlice";
import { NotificationType } from "../Utility/InterFacesAndEnum";

import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { toggleLoader } from "./loaderSlice";

interface SubscriptionInterface {
  subscription: any;
  isSubscribed: boolean;
  links: any;
  isLoading: boolean;
}

interface ActivateAndSuspendPayload {
  reason: string;
}

const subScriptionInitialState: SubscriptionInterface = {
  subscription: [],
  isSubscribed: false,
  links: [],
  isLoading: false,
};

// function to get subscription details
export const getSubscriptionDetailData = createAsyncThunk(
  "get subscription detail",
  async (payload: void, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));

    await fetch(endpoint.getSubscriptionDetail, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));

        console.log(data);
        if (data.statusCode === 200) {
          // dispatch(toggleNotificationVisibility({
          //     isVisible:false,
          //     status:NotificationType.success,
          //     message:data.errorMsg
          // }))
          dispatch(
            setSubscriptionStatus({
              status: true,
            })
          );
          dispatch(
            setSubscriptionDetail({
              data: data.result,
            })
          );
        }
        if (data.statusCode === 500) {
          dispatch(
            setSubscriptionStatus({
              status: false,
            })
          );
          // dispatch(toggleNotificationVisibility({
          //     isVisible: true,
          //     status: NotificationType.error,
          //     message: data.errorMsg
          // }))
        }
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));

        console.log(err);
        return err;
      });
  }
);

// function to buy goldmembership
export const buyGoldMembership = createAsyncThunk(
  "buy goldmembership",
  async (payload: void, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));

    dispatch(
      toggleLoading({
        isLoading: true,
      })
    );
    fetch(endpoint.buyGoldMembershipCheckout, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));

        console.log("gold", data);
        if (data.statusCode === 200) {
          dispatch(
            toggleLoading({
              isLoading: false,
            })
          );
          data.links.filter((obj: any) => {
            if (obj.rel === "approve") {
              console.log(obj);
              dispatch(
                setSubscriptionLinks({
                  links: obj,
                })
              );
            }
          });
        } else if ((data.statusCode = 500)) {
          dispatch(
            toggleLoading({
              isLoading: false,
            })
          );
          data.links.filter((obj: any) => {
            if (obj.rel === "approve") {
              console.log(obj);
              dispatch(
                setSubscriptionLinks({
                  links: obj,
                })
              );
            }
          });
          // dispatch(toggleNotificationVisibility({
          //     isVisible:true,
          //     status:NotificationType.error,
          //     message:data.errorMsg
          // }))
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));

        console.log(err);
        return err;
      });
  }
);

export const suspendGoldmembership = createAsyncThunk(
  "suspend goldmembership of user",
  async (payload: any, { dispatch }) => {
    console.log(payload);
    dispatch(toggleLoader({ isLoading: true }));

    fetch(`${endpoint.suspendGoldMembership}${payload.id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(payload.payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log("gold",data);
        dispatch(toggleLoader({ isLoading: false }));

        if (data.statusCode === 200) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.success,
              message: data.errorMsg,
            })
          );
          dispatch(getSubscriptionDetailData());
        } else if (data.statusCode === 500) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));

        console.log(err);
        return err;
      });
  }
);

export const reActivateMembership = createAsyncThunk(
  "reactivate user gold membership",
  async (payload: any, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));

    fetch(`${endpoint.reActivateGoldMembership}${payload.id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(payload.payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log("gold",data);
        dispatch(toggleLoader({ isLoading: false }));

        if (data.statusCode === 200) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.success,
              message: data.errorMsg,
            })
          );
          dispatch(getSubscriptionDetailData());
        } else if (data.statusCode === 500) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));

        console.log(err);
        return err;
      });
  }
);

export const subscriptionComplete = createAsyncThunk(
  "load money complete",
  async (payload: any, { dispatch }) => {
    console.log(payload.subscription_id);
    dispatch(toggleLoader({ isLoading: true }));

    // const token = getLocalStorage(localStorageActionType.GET_PAYMENT_TOKEN);
    await fetch(
      `${endpoint.buyGoldMembershipComplete}${payload.subscription_id}`,
      {
        method: "PUT",
        // body:payload,
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));

        if (data.statusCode === 200) {
          console.log(data);
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.success,
              message: data.errorMsg,
            })
          );
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
        dispatch(getSubscriptionDetailData);
        // dispatch(getUserDetailes())
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));

        console.log(err);
      });
  }
);

const subscriptionSlice = createSlice({
  name: "subscription slice",
  initialState: subScriptionInitialState,
  reducers: {
    setSubscriptionDetail: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        subscription: action.payload.data,
      };
    },
    setSubscriptionLinks: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        links: action.payload.links,
      };
    },
    setSubscriptionStatus: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isSubscribed: action.payload.status,
      };
    },
    toggleLoading: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    },
  },
});

export default subscriptionSlice.reducer;
export const {
  setSubscriptionDetail,
  setSubscriptionStatus,
  setSubscriptionLinks,
  toggleLoading,
} = subscriptionSlice.actions;
