import { FC, useMemo, useRef } from "react";
import {
  Wrapper,
  Container,
  TitleWrapper,
  Title,
  AddBtn,
  EmptyImgSection,
  Image,
  ImageWrapper,
  IndImgWrapper,
  DeleteIcon,
} from "./StyledImageUploader";
import { RootState } from "../../app/Store";
import { deleteImage, uploadImage } from "../../features/imageUploaderSlice";
import { useSelector, useDispatch } from "react-redux";
import { toggleNotificationVisibility } from "../../features/notificationSlice";
import { NotificationType } from "../../Utility/InterFacesAndEnum";

const ImageUploader: FC = () => {
  const dispatch = useDispatch();

  const inputRef = useRef<HTMLInputElement>(null);

  const images = useSelector((state: RootState) => state.images.images);

  // const uniqueImages = [...new Set(images)];

  let imagesView = useMemo(() => {
    if (images.length === 0) {
      return (
        <EmptyImgSection>
          <div>No Images Found</div>
        </EmptyImgSection>
      );
    } else {
    }
  }, [images]);

  // let imageList = null;

  // if (images.length > 0) {
  //     imageList = images.map((imgUrl:string) =>{
  //         return <Image src={imgUrl} alt={"Image"} />
  //     });
  // }
  const deleteImg = (url: string) => {
    dispatch(
      deleteImage({
        imgUrl: url,
      })
    );
  };
  let imageList: any = <></>;
  // console.log("Images :", images)

  if (images !== undefined && images !== null && images.length > 0) {
    imageList =
      images !== null &&
      images.map((imgUrl: string) => {
        return (
          <IndImgWrapper>
            <DeleteIcon
              onClick={() => {
                deleteImg(imgUrl);
              }}
            >
              D
            </DeleteIcon>
            <Image src={imgUrl} alt={"Image"} />
          </IndImgWrapper>
        );
      });
  } else {
    imageList = (
      <EmptyImgSection>
        <div>No Images Found</div>
      </EmptyImgSection>
    );
  }

  const triggerImageUploader = () => {
    if (images.length === 20) {
      dispatch(
        toggleNotificationVisibility({
          isVisible: true,
          status: NotificationType.success,
          message: "Sorry, You can upload maximum 20 images.",
        })
      );
      return;
    }
    inputRef.current?.click();
  };


  const validateImage = (file: File) => {
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const maxSize = 5 * 1024 * 1024; // 5MB
    const maxFilenameLength = 100; // Maximum filename length

  if (file.name.length > maxFilenameLength) {
    return `Filename exceeds the maximum length of ${maxFilenameLength} characters.`;
  }

    if (validImageTypes.indexOf(file.type) === -1) {
      return "Invalid file type. Please select a JPEG, PNG, or JPG image.";
    }

    if (file.size > maxSize) {
      return "File size exceeds the limit of 5MB.";
    }

    return null; // Image is valid
  };

  const createUploadFileObj = (event: any) => {
    // uploadFile(event.target.files[0]);
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const validationError = validateImage(selectedFile);
      if (validationError) {
        dispatch(
          toggleNotificationVisibility({
            isVisible: true,
            status: NotificationType.error,
            message: validationError,
          })
        );
      } else {
        uploadFile(selectedFile);
      }
    }
    event.target.value = null;
    
  };

  const uploadFile = (files: any) => {
    const formData = new FormData();
    formData.append("file", files);
    formData.append("documentName", files.name);
    dispatch(uploadImage(formData));
  };

  return (
    <Wrapper>
      <Container>
        <input
          type={"file"}
          ref={inputRef}
          style={{ display: "none" }}
          onChange={(event) => {
            createUploadFileObj(event);
          }}
          accept="image/jpeg, image/png, image/jpg"
        />
        <TitleWrapper>
          <Title>Images</Title>
          <AddBtn onClick={triggerImageUploader}>Add images</AddBtn>
        </TitleWrapper>
        {images.length === 0 ? (
          imagesView
        ) : (
          <ImageWrapper>{imageList}</ImageWrapper>
        )}
      </Container>
    </Wrapper>
  );
};

export default ImageUploader;
