import React, { useEffect } from "react";
import { RootState } from "../../app/store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNotificationVisibility } from "../../features/notificationSlice";
import { NotificatoinWrapper } from "./StyledNotification";
import { NotificationType } from "../../Utility/InterFacesAndEnum";

const Notification: React.FC = () => {
  const dispatch = useDispatch();

  let isVisible = useSelector(
    (state: RootState) => state.notifcation.isVisible
  );
  let notificationStatus = useSelector(
    (state: RootState) => state.notifcation.status
  );
  let message = useSelector((state: RootState) => state.notifcation.message);
  let isLoading = useSelector((state:RootState)=>state.loader.isLoading);

  let timeoutId:any = null; 

  

  const hideNotification = () => {
    // Clear the previous timer if it exists
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timer to hide the notification after 5 seconds
    timeoutId = setTimeout(() => {
      dispatch(toggleNotificationVisibility({
          isVisible: false,
          status: NotificationType.success,
          message: "Kings Ring"
      }))
  }, 6000)
  };

  // Call the function to start the timer when the component is rendered
  hideNotification();

  return (
    <NotificatoinWrapper
      visibility={isVisible}
      status={
        notificationStatus === "success" || "error" ? "#FFFFFF" : "#000000"
      }
      bgColor={
        notificationStatus === "success"
          ? "green"
          : notificationStatus === "error"
          ? "red"
          : "yellow"
      }
    >
      {message}
    </NotificatoinWrapper>
  );
};

export default Notification;
