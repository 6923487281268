export const SET_ACCESS_REFRESH_TOKEN = "SET_ACCESS_REFRESH_TOKEN";
export const SET_PUBLIC_USER_ID = "SET_PUBLIC_USER_ID";
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_TWO_FA_STATUS = 'SET_TWO_FA_STATUS';
export const SET_SELECTED_LOTTERY_OBJ = "SET_SELECTED_LOTTERY_OBJ";
export const SET_SELECTED_AUCTION_OBJ = "SET_SELECTED_AUCTION_OBJ";
export const SET_TICKET_DETAIL_OBJ = "SET_TICKET_DETAIL_OBJ";
export const SET_IS_LOGGED_IN = "SET_IS_LOGGED_IN";
export const SET_PAYMENT_TOKEN = 'SET_PAYMENT_TOKEN';
export const SET_ACCESS_INVALID = 'SET_ACCESS_INVALID';
// action types for get

export const GET_IS_LOGGED_IN = "GET_IS_LOGGED_IN";

export const GET_ACCESS_TOKEN = "GET_ACCESS_TOKEN";
export const GET_REFRESH_TOKEN = "GET_REFRESH_TOKEN";
export const GET_PUBLIC_USER_ID = "GET_PUBLIC_USER_ID";
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_TWO_FA_STATUS = 'GET_TWO_FA_STATUS';
export const GET_SELECTED_LOTTERY_OBJ = "GET_SELECTED_LOTTERY_OBJ";
export const GET_SELECTED_AUCTION_OBJ = "GET_SELECTED_AUCTION_OBJ";
export const GET_TICKET_DETAIL_OBJ = "GET_TICKET_DETAIL_OBJ";
export const GET_PAYMENT_TOKEN = 'GET_PAYMENT_TOKEN';
export const GET_ACCESS_INVALID = 'GET_ACCESS_INVALID';



// actiontypes form remove
export const CLEAR_LOGIN_USER_DETAIL = "CLEAR_LOGIN_USER_DETAIL";