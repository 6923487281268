import { FC } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

interface SelectProps {
  fullWidth: boolean;
  required: boolean;
  value: string;
  label: string;
  id: string;
  name: string;
  error: boolean;
  dropdownValues: string[];
  handleInputChange(value: string, id: string): void;
}

const AutocompleteInput: FC<SelectProps> = (props) => {
  const {
    fullWidth,
    required,
    value,
    label,
    id,
    name,
    error,
    dropdownValues,
    handleInputChange,
  } = props;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string | null) => {
    if (newValue !== null) {
      handleInputChange(newValue, id);
    }
  };

  return (
    <Autocomplete
      fullWidth={fullWidth}
      id={id}
      value={value}
      options={dropdownValues}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          name={name}
          error={error}
        />
      )}
    />
  );
};

export default AutocompleteInput;
