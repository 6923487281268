import styled from "styled-components";
import { FooterOption } from "../LiveLottery/StyledLiveLottery";
import * as UIConstants from "../../UIConstants";
import bgImg from "../../assets/mainLotteryBgImg.jpg";

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  // display: flex;
  // flex-flow: row nowrap;
  // align-items: flex-start;
  // justify-content: flex-start;
  ${UIConstants.mobileView} {
    flex-flow: column wrap;
    align-items: center;
  }
`;

export const CoinsSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  ${UIConstants.mobileView} {
    display: none;
  }
`;

export const Coin = styled.img`
  height: 120px;
  width: 120px;
  margin-bottom: 8px;
  mix-blend-mode: multiply;
`;

export const CoinLabel = styled.div`
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #200e32;
  margin-bottom: 16px;
`;

export const MainLotterySection = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  ${UIConstants.mobileView} {
    align-items: center;
    justify-content: center;
  }
`;
export const MainLotteryImgSkeleton = styled.div`
  margin-top: 30px;
`;
export const MainLotteryImg = styled.img`
  // margin-left: 30px;

  width: 400px;
  object-fit: contain;
  mix-blend-mode: normal;
  /* border: 1px solid black; */
  ${UIConstants.mobileView} {
    height: 200px;
    height: 350px;
    // margin-left: 0px;
  }
  filter: drop-shadow(0 0 10px #ff6f00);
  animation: glow 2s infinite alternate;
  @keyframes glow {
    0% {
      filter: drop-shadow(0 0 5px #ff6f00);
    }
    100% {
      filter: drop-shadow(0 0 25px #ff6f00);
    }
  }
  margin: 30px 0px 30px 0px;

  @media (min-width: 1400px) {
    margin: 35px 0px 25px 0px;
  }
  @media (min-width: 1600px) {
    margin: 55px 0px 40px 0px;
    width: 450px;
  }

  @media (min-width: 1800px) {
    margin: 70px 0px 45px 0px;
    width: 480px;
  }
  @media (min-width: 2000px) {
    margin: 120px 0px 60px 0px;
    width: 550px;
  }
  @media (min-width: 2200px) {
  }
  // margin-bottom:40px;
  /* border: 1px solid black; */
`;

export const RewardDetail = styled.div`
  font-weight: 700;
  font-size: 72px;
  line-height: 87px;
  color: #200e32;
  text-align: center;
  ${UIConstants.mobileView} {
    font-size: 40px;
    line-height: 51px;
  }
  margin-bottom: 15px;

  @media (min-width: 1400px) {
    margin-bottom: 25px;
  }
  @media (min-width: 1600px) {
    margin-bottom: 30px;
    font-size: 80px;
  }
  @media (min-width: 1800px) {
    argin-bottom: 30px;
    font-size: 80px;
  }
  @media (min-width: 2000px) {
    argin-bottom: 30px;
    font-size: 90px;
  }
  @media (min-width: 2200px) {
  }
  /* border: 1px solid black; */
`;
export const RewardDetailSkeleton = styled.div`
  font-weight: 700;
  font-size: 72px;
  line-height: 87px;
  color: #200e32;
  margin: 30px 0px 20px 0;
  text-align: center;
  ${UIConstants.mobileView} {
    font-size: 40px;
    line-height: 51px;
  }
`;
export const LotteryDrawWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  // flex-flow: row nowrap;
  flex-wrap: wrap;
  color: #200e32;
  align-items: center;
  justify-content: center;
  ${UIConstants.mobileView} {
    flex-flow: column;
  }
  margin-bottom: 10px;

  @media (min-width: 1400px) {
    margin-bottom: 20px;
  }
  @media (min-width: 1600px) {
    div {
      font-size: 20px;
    }
  }
  @media (min-width: 1800px) {
    div {
      font-size: 20px;
    }
  }
  @media (min-width: 2000px) {
    div {
      font-size: 23px;
    }
  }
  @media (min-width: 2200px) {
  }
  /* border: 1px solid black; */
`;

export const DrawLabel = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #200e32;
  // margin-right: 5px;
`;

export const AuctionBtns = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
  ${UIConstants.mobileView} {
    gap: 10px;
  }
  Button {
    min-width: 220px;
    width: auto;
    height: 51px;
    ${UIConstants.mobileView} {
      min-width: 180px;
    }
  }
  margin-bottom: 10px;
  @media (min-width: 1400px) {
    margin-bottom: 30px;
  }
  @media (min-width: 1600px) {
    Button {
      min-width: 280px;
      width: auto;
      height: 61px;
      font-size: 16px;
      ${UIConstants.mobileView} {
        min-width: 180px;
      }
    }
  }
  @media (min-width: 1800px) {
  }
  @media (min-width: 2000px) {
    Button {
      min-width: 300px;
      width: auto;
      height: 65px;
      font-size: 18px;
      ${UIConstants.mobileView} {
        min-width: 180px;
      }
    }
  }
  @media (min-width: 2200px) {
  }
`;

export const FooterSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  ${FooterOption} {
    margin-bottom: 15px;
  }
  ${UIConstants.mobileView} {
    padding-left: 10px;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 12px;
  }
  margin-bottom: 10px;

  @media (min-width: 1400px) {
    margin-bottom: 30px;
  }
  @media (min-width: 1600px) {
    span {
      font-size: 18px;
    }
  }
  @media (min-width: 1800px) {
    span {
      font-size: 20px;
    }
  }
  @media (min-width: 2000px) {
    span {
      font-size: 23px;
    }
  }
  @media (min-width: 2200px) {
  }
  /* border: 1px solid black; */
`;
