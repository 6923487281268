import styled from 'styled-components';
import * as UIConstant from "../../UIConstants";

export const Wrapper = styled.div`
width: 100%;
box-sizing: border-box;
`;

export const ContainerWrapper = styled.div`
/* border:1px solid gray; */
width: 100%;
box-sizing: border-box;
background-color: #ffffff;
border-radius: 20px;


`

interface isMember {
    isMember: boolean
}

export const Container = styled.div<isMember>`

display: flex;
// flex-flow: row nowrap;
align-items: flex-start;
justify-content: flex-start;
padding:${((prop) => prop.isMember ? "10px 30px 30px 30px" : "40px 30px 30px 30px")};
gap: 10px;
${UIConstant.customMobileView}{
flex-wrap:wrap;

}
`;
export const GoldImg = styled.div`
img{
    width:30px;
object-fit: contain;
position: relative;
top: 10px;
right: 10px;
}
display: flex;
justify-content: flex-end;
width: 100%;
align-items: center;
`;

export const ImgSection = styled.div`
display: flex;
flex-basis: 30%;
${UIConstant.customMobileView}{
flex-basis: 100%;

}
`;

export const LotteryDetail = styled.div`
display: flex;
flex-flow: column;
flex-basis: 70%;
align-items: flex-start;
justify-content: flex-start;
${UIConstant.customMobileView}{
flex-basis: 100%;

}
`;

export const CoinImg = styled.img`
width: 130px;
object-fit: contain;
border-radius:10px;
`;

export const RewardTitle = styled.div`
font-size: 16px;
font-weight: 400;
color: #ADB5BD;
margin-bottom: 8px;
`;

export const RewardDetail = styled.div`
font-size: 38px;
    font-weight: 700;
    color: #003459;
    margin-bottom: 8px;
`;
export const RewardName = styled.div`
font-size: 30px;
    font-weight: 700;
    color: #003459;
    margin-bottom: 8px;
`;

export const CounterWrapper = styled.div`
display: flex;
flex-flow: row nowrap;
margin-bottom: 30px;
color: #200E32;
`;

export const CounterTitle = styled.div`
font-size: 16px;
font-weight: 300;
font-size: 16px;
margin-right: 10px;
`;

export const TicketDetails = styled.div`
width: 100%;
box-sizing: border-box;
display: flex;
flex-flow: row nowrap;
align-items: center;
justify-content: flex-start;
margin-bottom: 15px;
`;

export const IconImg = styled.img`
width:20px;
object-fit: contain;
`;

export const Detail = styled.div`
font-weight: 400;
font-size: 16px;
color: #200E32;
`;

export const Actions = styled.div`
width: 100%;
box-sizing: border-box;
text-align: left;
margin-top: 25px;


Button{
    height: 41px;
    width: 150px;
};
${UIConstant.customMobileView}{
    display:flex;
justify-content:flex-end;
aalign-item:flex-end;
}
`;