import {FC,useEffect} from 'react';
import loadingImgUrl from '../../../../assets/loader.gif';
import * as styled from './StyledSuccess';

import {useNavigate, useLocation} from 'react-router-dom'
import {RouterPath} from '../../../../routes/routes';

import {useSelector, useDispatch} from 'react-redux';
import { RootState } from '../../../../app/store';
import {paymentComplete} from '../../../../features/purchaseAuctionSlice';
import { createAuctionRequestComplete } from '../../../../features/auctionList';

const CreateAuctionPaymentSuccess:FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigation = useNavigate();

    let {isPaymentComplete,isPaymentIncomplete} = useSelector((state:RootState) => state.auction);

    useEffect(() => {
        let searchUrl = location.search;
        let searchItems = searchUrl.split("&PayerID=");
        let token = searchItems[0].slice(7);
        if (token !== undefined && token !== null && token.length > 0) {
            dispatch(createAuctionRequestComplete({payerId:token}));
            console.log(token)
        }
    },[]);

    useEffect(() => {
        if (isPaymentComplete) {
            navigation(RouterPath.createAuctionPaymentComplete);
        }else if(isPaymentIncomplete){
            navigation(RouterPath.createAuctionPaymentFail);

        }
    },[isPaymentComplete,isPaymentIncomplete])

    return <styled.Wrapper>
        <styled.SectionWrapper>
            <styled.Title>
                Payment is Processing...
            </styled.Title>
            <styled.Subtitle>
                Please do not press back button or reload <br />
                page will be automaticaly refresh once process is complete
            </styled.Subtitle>
            <styled.ImageSection>
            <img src={loadingImgUrl} alt={"loader"} />
            </styled.ImageSection>
        </styled.SectionWrapper>
    </styled.Wrapper>
};

export default CreateAuctionPaymentSuccess;