import styled from 'styled-components';

export const Wrapper = styled.div`
width: 100%;
box-sizing: border-box;
`;

export const CompletedColor = styled.div`
color: red
`;

export const PercentageLabel=styled.div`
text-align:right;
color:black;
`