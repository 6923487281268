import styled from "styled-components";

import * as UIConstants from "../../UIConstants";

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const Section = styled.div`
  width: 100%;
  padding: 10px 0px 10px 0px;
`;
export const ContainerWrapper = styled.div`
  background-color: white;
  width: 100%;
  box-sizing: border-box;
`;
export const Container = styled.div`
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 30px;
  padding: 30px 30px 5px 30px;
  ${UIConstants.mobileView} {
    flex-flow: column;
    gap: 15px;
    padding: 15px;
  }
  /* border: 1px solid black; */
`;

export const ImageSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 49%;
  /* border: 1px solid black; */
  img {
    height: 400px;

    /* width: 100%; */
    /* object-fit: contain; */
  }
  ${UIConstants.mobileView} {
    flex-basis: 100%;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const DetailSection = styled.div`
  display: flex;
  flex-basis: 49%;
  flex-flow: column;
  text-align: left;
  /* border: 1px solid black; */
  ${UIConstants.mobileView} {
    flex-basis: 100%;
    width: 100%;
    box-sizing: border-box;
  }
`;
export const DetailSection2 = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-flow: column;
  margin: 0 10px 0 10px;

  ${UIConstants.mobileView} {
    flex-basis: 100%;
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 10px 0;
    padding-bottom: 10px;
    border-bottom: 2px solid #adb5bd;
  }
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-flow: column;
  padding-left: 30px;

  border-left: 2px solid #adb5bd;

  ${UIConstants.mobileView} {
    flex-basis: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-left: 0px;

    border-left: none;
  }
`;

export const TitleWrapper = styled.div`
  padding: 5px 10px 5px 20px;
  /* border: 1px solid black; */
`;
export const Title = styled.div`
  font-weight: 400;
  font-size: 41px;
  line-height: 50px;
  color: #000000;
  margin-bottom: 8px;
  ${UIConstants.mobileView} {
    font-size: 28px;
    line-height: 27px;
  }
`;

export const ProductType = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 24px;
`;

export const Description = styled.div`
  max-height: 500px;
  overflow: auto;

  margin-bottom: 30px;
  ${UIConstants.mobileView} {
    margin-bottom: 16px;
  }
`;

export const Text = styled.div`
  font-family: Arial, sans-serif; /* Choose your preferred font */
  word-wrap: break-word; /* Allow long words to break and wrap */
  white-space: pre-wrap;
  padding-right: 5px;
  font-size: 18px;
  line-height: 1.5;
  color: #000;
  text-align: justify;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
`;

export const ProgressBarDetail = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
`;

export const EngagedUrsers = styled.div`
  display: flex;
  flex-basis: 50%;
  text-align: left;
  color: #adb5bd;
  align-items: center;
  justify-content: flex-start;
`;

export const TotalUsers = styled.div`
  display: flex;
  flex-basis: 50%;
  text-align: right;
  color: #adb5bd;
  align-items: center;
  justify-content: flex-end;
`;

export const ActionDetail = styled.div`
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  margin: 50px 0 30px 0;
  Button {
    width: 270px;
  }

  ${UIConstants.mobileView} {
    margin: 30px 0;
    Button {
      width: 100%;
    }
  }
`;

export const TicketDetail = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #000000;
  margin-right: 10px;
`;

export const Value = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #000000;
`;

export const Line = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-bottom: 2px solid #adb5bd;
  margin: 24px 0;
`;

export const SellerSectionTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  display: inline;
  border-bottom: 1px solid #adb5bd;
  width: fit-content;
  padding: 0 10px 3px 10px;
`;

export const SellerDetailWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  margin: 15px 0;
`;

export const ProfileImg = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  flex-basis: 10%;
`;

export const SellerDetail = styled.div`
  display: flex;
  flex-basis: 90%;
  margin-left: 15px;
`;

export const SellerName = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 15px;
  color: #adb5bd;
  margin-right: 10px;
`;

export const ItemDetails = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
`;

export const ItemDetailLabel = styled.div`
  width: 100px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #adb5bd;
`;

export const SimilarProductContainer = styled.div`
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  margin: 50px 0;
  ${UIConstants.customMobileView} {
  }
`;

export const BreadCrumb = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #200e32;
  background-color: #ffffff;
  padding: 0 30px;
  margin-top: 2px;
`;

export const BreadCrumbItem = styled.div`
  color: #200e32;
  padding: 0 4px;
  cursor: pointer;
`;
