import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import * as endpoint from '../networkUtilities/endpoints';
import * as localStorageActionType from '../localStorage/ActionTypes';
import { getLocalStorage } from '../localStorage/GetLocalStorage';
import { toggleNotificationVisibility } from './notificationSlice';
import { NotificationType } from '../Utility/InterFacesAndEnum';
import { toggleLoader } from './loaderSlice';
import { getUserDetailes } from './loadMoneySlice';

interface PurchaseLotteryInitialState {
    isPurchased: boolean,
    isPaymentInitiated: boolean,
    isWalletPaymentInitiated: boolean,
    message: string,
    totalNoOfTickets: number,
    paypalResponse: any,
    checkout: any
}

const purchaseInitialState: PurchaseLotteryInitialState = {
    isPurchased: false,
    isPaymentInitiated: false,
    isWalletPaymentInitiated: false,
    message: "",
    totalNoOfTickets: 0,
    paypalResponse: {},
    checkout: []
}

interface PurchaseLottery {
    lotteryGameId: number,
    userId: number,
    paymentId: number,
    purchaseDate: Date,
    ticketType: string,
    noOfTickets: number
}

interface TogglePurchaseState {
    isPurchased: boolean,
    message: string,
    tickets: number,

}

interface getCheckout {
    checkoutList: []
}

export const purchaseLottery = createAsyncThunk(
    'purchase ticket',
    async (payload: PurchaseLottery, { dispatch }) => {
        // payload["ticketType"] = "B";
        dispatch(toggleLoader({ isLoading: true }));

        console.log("payload", payload)
        await fetch(endpoint.purchaseLottery, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                Authorization: `Bearer ${getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN)}`,
                "Content-type": "application/json; charset=UTF-8",
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                dispatch(toggleLoader({ isLoading: false }));

                console.log(data, "data from pyament")
                if (data.status !== 404) {
                    dispatch(storePaymentResponse({
                        data: data
                    }));
                    data.links.filter((obj: any) => {
                        if (obj.rel === 'payer-action') {
                            console.log(obj)
                            dispatch(setLotteryCheckout({
                                checkoutList: obj
                            }))
                        }
                    })
                } else {
                    dispatch(toggleNotificationVisibility({
                        isVisible: true,
                        status: NotificationType.error,
                        message: `Something went wrong, ${data.error}`
                    }))
                }

            }).catch((err) => {
                dispatch(toggleLoader({ isLoading: false }));
                return err;
            })
    }
);

export const purchaseLotteryWallet = createAsyncThunk(
    'purchase ticket',
    async (payload: PurchaseLottery, { dispatch }) => {
        // payload["ticketType"] = "B";
        dispatch(toggleLoader({ isLoading: true }));

        console.log("payload", payload)
        await fetch(endpoint.lotteryPurchaseThroughWallet, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                Authorization: `Bearer ${getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN)}`,
                "Content-type": "application/json; charset=UTF-8",
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                dispatch(toggleLoader({ isLoading: false }));
                if (data.statusCode === 500) {
                    dispatch(toggleNotificationVisibility({
                        isVisible: true,
                        status: NotificationType.error,
                        message: data.errorMsg
                    }))
                }
                if (data.statusCode === 200) {
                    dispatch(toggleNotificationVisibility({
                        isVisible: true,
                        status: NotificationType.success,
                        message: data.errorMsg
                    }))
                    dispatch(storeWalletPaymentResponse({
                        isWalletPaymentInitiated: true
                    }))
                    dispatch(togglePurchaseState({
                        isPurchased: true,
                        message: "Perchased successfully",
                        tickets: data.result.length
                    }))
                    dispatch(getUserDetailes())
                } else {
                    dispatch(toggleNotificationVisibility({
                        isVisible: true,
                        status: NotificationType.error,
                        message: data.errorMsg
                    }))
                }
                console.log(data, "data from pyament")

            }).catch((err) => {
                dispatch(toggleLoader({ isLoading: false }));
                return err;
            })
    }
);
// ?token=6VN41912HE320334F&PayerID=Z25MADH6SXGWE
export const paymentComplete = createAsyncThunk(
    'payment complete',
    async (payload: any, { dispatch }) => {
        dispatch(toggleLoader({ isLoading: true }));

        await fetch(`${endpoint.lotteryPaymentComplete}${payload.payerId}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN)}`,
                "Content-type": "application/json; charset=UTF-8",
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                dispatch(toggleLoader({ isLoading: false }));

                if (response.statusCode === 200) {
                    dispatch(togglePurchaseState({
                        isPurchased: true,
                        message: "Perchased successfully",
                        tickets: response.result.length
                    }))
                    dispatch(toggleNotificationVisibility({
                        isVisible: true,
                        status: NotificationType.success,
                        message: response.errorMsg
                    }))
                } else {
                    dispatch(togglePurchaseState({
                        isPurchased: false,
                        message: "Something Went Wrong",
                        tickets: response.result.length
                    }))
                    dispatch(toggleNotificationVisibility({
                        isVisible: true,
                        status: NotificationType.error,
                        message: response.errorMsg
                    }))
                }

            })
            .catch((error) => {
                dispatch(toggleLoader({ isLoading: false }));

                console.log(error)
            })
            .finally(() => {
                dispatch(toggleLoader({ isLoading: false }));

                console.log("finaly")
            })
    }
);

const purchaseLotterySlice = createSlice({
    name: "Purchase Lottery",
    initialState: purchaseInitialState,
    reducers: {
        togglePurchaseState: (state, action: PayloadAction<TogglePurchaseState>) => {
            return {
                ...state,
                isPurchased: action.payload.isPurchased,
                message: action.payload.message,
                totalNoOfTickets: action.payload.tickets
            }
        },
        storePaymentResponse: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                paypalResponse: action.payload.data,
                isPaymentInitiated: true
            }
        },
        storeWalletPaymentResponse: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                isWalletPaymentInitiated: action.payload.isWalletPaymentInitiated
            }
        },
        setLotteryCheckout: (state, action: PayloadAction<getCheckout>) => {
            return {
                ...state,
                checkout: action.payload.checkoutList
            }
        },
        resetLotteryPurchaseState: (state, action: PayloadAction<void>) => {
            return {
                ...state,
                isPurchased: false,
                isPaymentInitiated: false,
                isWalletPaymentInitiated: false,
                message: "",
                totalNoOfTickets: 0,
                paypalResponse: {}
            }
        }
    }
});

export default purchaseLotterySlice.reducer;
export const { togglePurchaseState, storePaymentResponse, resetLotteryPurchaseState, storeWalletPaymentResponse, setLotteryCheckout } = purchaseLotterySlice.actions;