export const emptyEmailId = "email id should not be empty";
export const invalidEmailId = "Invalid email id";

export const emptyPassword = "password should not be empty";

export const emptyFirstName = "first name should not be empty";
export const emptyLastName = "last name should not be empty";

export const emptyMobileNumber = "mobile number should not be empty";
export const invalidMobileNumber = "Invalid mobile number i.e contact number length should not be less than 13 with its country code";

export const tcDisagree = 'you need to agree to terms and conditions';

export const emptyAddress = "address should not be empty";
export const emptyCountry = "country should not be empty";
export const emptyPincode = "pincode/zip code should not be empty";
export const emptyState = "state should not empty";