import styled from "styled-components";
import * as UIConstants from "../../UIConstants";

export const Auctions = styled.div`
  width: 300px;
  ${UIConstants.customMobileView} {
    width: 100%;
  }
`;

export const EmptySection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: gray;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
`;
