import { subscriptionAmount } from "./../networkUtilities/endpoints";
import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { toggleLoader } from "./loaderSlice";
import * as endpoints from "../networkUtilities/endpoints";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { toggleNotificationVisibility } from "./notificationSlice";
import { NotificationType } from "../Utility/InterFacesAndEnum";

interface InnitialStateProp {
  safetyDepositAmount: any;
  subscriptionAmount: any;
}

const initialState: InnitialStateProp = {
  safetyDepositAmount: {},
  subscriptionAmount: {},
};

interface setSafetyDepositAmount {
  safetyDepositAmount: any;
}

interface setSubscriptionAmount {
  subscriptionAmount: any;
}
export const getSafetyDepositAmount = createAsyncThunk(
  "get setting data",
  async (payload, { dispatch }) => {
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(endpoints.safetyDeposit, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data?.statusCode === 200) {
          dispatch(
            setSafetyDepositAmount({
              safetyDepositAmount: data?.result,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
);

export const getSubscriptiontAmount = createAsyncThunk(
  "get setting data",
  async (payload, { dispatch }) => {
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(endpoints.subscriptionAmount, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data?.statusCode === 200) {
          dispatch(
            setSubscriptionAmount({
              subscriptionAmount: data?.result,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
);
export const addVisitorsCount = createAsyncThunk(
  "add visitors count ",
  async (payload, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(endpoints.visitorsCount, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        dispatch(toggleLoader({ isLoading: false }));
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(toggleLoader({ isLoading: false }));
      });
  }
);

export const downloadApp = createAsyncThunk(
  "download apk file",
  async (payload, { dispatch }) => {
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(endpoints.downloadApk, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then(async (response) => {
        dispatch(toggleLoader({ isLoading: false }));

        // Check if the response status is OK (200)
        if (response.status === 200) {
          console.log(response);
          // Get the filename from the 'Content-Disposition' header
          const contentDisposition = response.headers.get(
            "Content-Disposition"
          );
          const match = contentDisposition?.match(/filename="(.+)"/);
          const fileName = match ? match[1] : "downloaded-file.apk";

          // Convert the response to a Blob
          const blob = await response.blob();

          // Create a URL for the Blob
          const url = window.URL.createObjectURL(blob);

          // Create an anchor element to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();

          // Clean up the URL and remove the anchor element
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.success,
              message: "File downloaded successfully.",
            })
          );
        } else {
          console.error("Failed to download the file.");
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: "Failed to download the file.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

const settingSlice = createSlice({
  name: "Settings",
  initialState: initialState,
  reducers: {
    setSafetyDepositAmount: (
      state,
      action: PayloadAction<setSafetyDepositAmount>
    ) => {
      return {
        ...state,
        safetyDepositAmount: action.payload.safetyDepositAmount,
      };
    },
    setSubscriptionAmount: (
      state,
      action: PayloadAction<setSubscriptionAmount>
    ) => {
      return {
        ...state,
        subscriptionAmount: action.payload.subscriptionAmount,
      };
    },
  },
});

export const { setSafetyDepositAmount, setSubscriptionAmount } =
  settingSlice.actions;
export default settingSlice.reducer;
