// const baseUrl = "https://41539805-3c3c-4956-a915-281ef555f98c.mock.pstmn.io";
const baseUrl = "https://kingsrings.games/kingsrings";
// const baseUrl ="http://192.169.179.206:9092"

// export const OAUTH2_REDIRECT_URI = "http://localhost:3000/oauth2/redirect";
// export const OAUTH2_REDIRECT_URI ="https://lotterydesktop.vercel.app/oauth2/redirect";
export const OAUTH2_REDIRECT_URI = `https://kingsrings.games/oauth2/redirect`;

export const GOOGLE_AUTH_URL =
  baseUrl + "/oauth2/authorize/google?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL =
  baseUrl + "/oauth2/authorize/facebook?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL =
  baseUrl + "/oauth2/authorize/github?redirect_uri=" + OAUTH2_REDIRECT_URI;

export const register = `${baseUrl}/auth/signup`; /* Metod: POST */
export const login = `${baseUrl}/auth/login`; /** Method: POST */
export const socialLogin = `${baseUrl}/auth/sociallogin`; /**Method post */
export const verifySignin2FA = `${baseUrl}/auth/verify`;
export const updatePassword = `${baseUrl}/api/v1/user/changepassword`; /** /{publicUserId}; Method: POST */
export const forgotPassword = `${baseUrl}/auth/forgotpwd`; /** Method: POST */
export const resetPassword = `${baseUrl}/api/v1/user/resetpwd`; /** /{{publicUserId}} Method: POST */
export const getLotteryList = `${baseUrl}/api/v1/lotterygame/list?lotteryType=C`;
export const getUpcomingLottery = `${baseUrl}/api/v1/lotterygame/list?lotteryType=U`;
export const viewProfile = `${baseUrl}/api/v1/user/list/`; /** {{publicUserId}} */
export const updateProfileImgae = `${baseUrl}/api/v1/user/uploadphoto`;
export const updateProfile = `${baseUrl}/api/v1/user/update/`; /** {{publicUserId}} */
export const updateAddress = `${baseUrl}/api/v1/user/updateaddress/`; /** {{publicUserId}} */
export const toggleTwoFA = `${baseUrl}/api/v1/user/enabledisable2fa/`; /**  */
export const verifyTwoFACode = `${baseUrl}/api/v1/user/verifyenable2faotp`; /** {{publicUserId}} */
// export const purchaseLottery = `${baseUrl}/api/v1/purchaselotteryticket/create`; /** Fixme */
// export const purchaseLottery = `${baseUrl}/api/v1/payment/checkout`;
export const purchaseLottery = `${baseUrl}/api/v1/payment/lottery/checkout`;
export const lotteryPaymentComplete = `${baseUrl}/api/v1/payment/lottery/complete/`;
export const getOrdersList = `${baseUrl}/api/v1/purchaselotteryticket/list`; /** ?userId=3 */
export const getAuctionOrdersList = `${baseUrl}/api/v1/purchaseauctionticket/list`; /** ?userId=3 */
export const createAuctionRequest = `${baseUrl}/api/v1/auction/create`; /** create auction */
export const getAuctionList = `${baseUrl}/api/v1/auction/list`; /** ?auctionStatus=U */
export const getAuctionDetailById = `${baseUrl}/api/v1/auction/list/`; /** 6 */
export const purchaseAuction = `${baseUrl}/api/v1/purchaseauctionticket/create`;
export const auctionPaymentCheckout = `${baseUrl}/api/v1/payment/auction/checkout`;
export const auctionPaymentComplete = `${baseUrl}/api/v1/payment/auction/complete/`;
export const uploadImage = `${baseUrl}/api/v1/file/upload`;
export const getAuctionWinnerList = `${baseUrl}/api/v1/auction/winnerslist`;
export const getPromotions = `${baseUrl}/api/v1/promotion/list`; /** ?promotionPage=LOTTERY */
export const generatePaypalToken = `https://api-m.sandbox.paypal.com/v1/oauth2/token`;
export const createCheckoutNew = `https://api-m.sandbox.paypal.com/v2/checkout/orders`;
export const createNewCapture =
  "https://api-m.paypal.com/v2/checkout/orders";
export const createPaypalCheckout = `${baseUrl}/api/v1/payment/checkout`;
export const createPaymentComplete = `${baseUrl}/api/v1/payment/complete/`; /* PayerID=Z25MADH6SXGWE */
export const getSubscriptionDetail = `${baseUrl}/api/v1/payment/getsubscription`; /** to get gold membership details  */
export const buyGoldMembership = `${baseUrl}/api/v1/payment/createsubscription`; /**  */
export const buyGoldMembershipCheckout = `${baseUrl}/api/v1/payment/createsubscription`;
export const buyGoldMembershipComplete = `${baseUrl}/api/v1/payment/completesubscription/`;
export const suspendGoldMembership = `${baseUrl}/api/v1/payment/suspendsubscription/`;
export const reActivateGoldMembership = `${baseUrl}/api/v1/payment/activatesubscription/`;
export const getLotteryWinnerList = `${baseUrl}/api/v1/lotterygame/winnerslist`;
export const notiFyMe = `${baseUrl}/api/v1/notification/notifyme`;
export const lotteryNotiFyMe = `${baseUrl}/api/v1/notification/lottery/notifyme`;
export const auctionNotiFyMe = `${baseUrl}/api/v1/notification/auction/notifyme`;

export const loadMoneyCheck = `${baseUrl}/api/v1/payment/loadmoney/checkout`;
export const loadMoneyComplete = `${baseUrl}/api/v1/payment/loadmoney/complete/`;
//coin
export const getCoinList = `${baseUrl}/api/v1/coins/list`;
export const getCoinById = `${baseUrl}/api/v1/coins/list/`;
export const buyCoinCheckout = `${baseUrl}/api/v1/payment/coin/checkout`;
export const buyCoinComplete = `${baseUrl}/api/v1/payment/coin/complete/`;
export const purchaseCoinList = `${baseUrl}/api/v1/purchasecoins/listbyuser`;
export const getTopLottery = `${baseUrl}/api/v1/lotterygame/topgame`;
export const purchaseListDownload = `${baseUrl}/api/v1/purchaselotteryticket/download`;
export const purchaseAuctionListDownload = `${baseUrl}/api/v1/purchaseauctionticket/download`;
export const lotteryPurchaseThroughWallet = `${baseUrl}/api/v1/payment/lottery/paythroughwallet`;
export const auctionPurchaseThroughWallet = `${baseUrl}/api/v1/payment/auction/paythroughwallet`;
export const coinPurchaseThroughWallet = `${baseUrl}/api/v1/payment/coin/paythroughwallet`;
export const getSettingList = `${baseUrl}/api/v1/setting/list`;

export const safetyDeposit = `${baseUrl}/api/v1/setting/auctionsecuritydeposit`;
export const subscriptionAmount = `${baseUrl}/api/v1/setting/subscriptionamount`;
export const visitorsCount = `${baseUrl}/api/v1/user/visitor`;
export const auctionByCountry = `${baseUrl}/api/v1/auction/list/bycountry`;
export const getTopAuction = `${baseUrl}/api/v1/auction/topauction`;
export const createAuctionCheckout = `${baseUrl}/api/v1/payment/auction/securitydeposit/checkout`;
export const createAuctionPaymentComplete = `${baseUrl}/api/v1/payment/auction/securitydeposit/complete/`;
export const createAuctionPayThroughWallet = `${baseUrl}/api/v1/payment/auction/securitydeposit/paythroughwallet`;

export const coinThumbnailbaseUrl = `${baseUrl}/api/v1/file/download/C/`;

export const downloadApk = `${baseUrl}/apk/android/KingsringsAPK_v1.1.2.apk`;
