import { FC, Fragment, useEffect, useState } from "react";
import Promotion from "../Promotions/Promotion";
import {
  ButtonSizeVariant,
  ButtonType,
  ButtonVariant,
  AppButtonType,
} from "../../Utility/InterFacesAndEnum";
import Button from "../../components/UI/Buttons/Button";
import AuctionItem from "../../components/AuctionCards/AuctionCards";
import {
  getAuctionList,
  getCountryWiseLiveAuctions,
  getCountryWiseUpcommingAuctions,
  getLiveAuctionList,
  getTopAuctionData,
  getUpcommingAuctionList,
  purchaseAuction,
  setCountrywiseLiveAuctionList,
  setCountrywiseUpcommingAuctionList,
  setDetailState,
  setSearchAuction,
  toggleAuctionPurchase,
} from "../../features/auctionList";
import { transformDate } from "../../Utility/Utility";
import { useSelector, useDispatch } from "react-redux";
import AuctionBuyBtn from "../../components/AuctionBuyButton/AuctionBuyButton";

import { useNavigate } from "react-router-dom";
import { RouterPath } from "../../routes/routes";
import {
  Wrapper,
  BannerSection,
  BannerContainer,
  CardContainer,
  CardFooter,
  CardDescription,
  CardTitle,
  CardProduct,
  CardAction,
  AuctionList,
  AuctionListItem,
  ListTitle,
  ActionSection,
  FilterViewSection,
  FormView,
  BannerCardContainer,
  DispayAuction,
  UpcommingAuction,
  LiveAuction,
  AuctionTitle,
  Category,
  ErrorMsg,
  ProgressBarContainer,
  TicketDetails,
  EntryTicket,
  StartDate,
  TicketDetail,
  Label,
  Value,
  BannerWrapper,
  StaticWrapper,
  ImageWrapper,
  AuctionAction,
  DefaultBannerImg,
  BannerContainerSkeleton,
  TopAuctionSkeleton,
  AuctionContainer,
} from "./StyledAuctionList";
import { RootState } from "../../app/store";

import FormBuilder from "../FormBuilder/FormBuilder";
import {
  updateFormInputState,
  updateFormSelectState,
} from "../../Utility/Utility";
import {
  FormElementType,
  customValidationType,
  InputVariant,
  InputTypes,
  FormElement,
} from "../../Utility/InterFacesAndEnum";
import { countryNames } from "../../assets/DropdownValues/DropdownValues";
import AuctionCarousel from "../../components/AuctionCarousel/AuctionCarousel";
import ProgressBarView from "../../components/ProgressBar/ProgressBar";
import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import * as localStorageActionType from "../../localStorage/ActionTypes";
import { getUserProfileDetail } from "../../features/userProfileSlice";
import { getPromotionData } from "../../features/promotion";
import useDimension from "../../CustomHooks/useDimention";
import Sliders from "../../components/Swipper/Swipper";
import TopAuction from "../../components/TopAuction/TopAuction";

import { Helmet } from "react-helmet";


interface FormState {
  form: FormElement[];
  isValidForm: boolean;
}

const FileterForm: FormState = {
  form: [
    {
      elementType: FormElementType.autocomplete,
      value: "",
      id: "country",
      isRequired: true,
      fullWidth: true,
      isCustomValidationRequred: false,
      inputVariant: InputVariant.outlined,
      inputType: InputTypes.text,
      customValidationType: customValidationType.null,
      isValidInput: false,
      isTouched: false,
      errorMessage: "",
      label: "Country",
      radioGroupValues: [],
      dropdownValues: [...countryNames],
      slectedDate: null,
      isPasswordHidden: true,
    },
  ],
  isValidForm: true,
};

const Auction: FC = () => {
  const dispatch = useDispatch();
  const dimention = useDimension();
  const navigate = useNavigate();
  const isLoggedIn = getLocalStorage(localStorageActionType.GET_IS_LOGGED_IN);
  const [fileterState, setFilterState] = useState(FileterForm);
  const [show, setShow] = useState(false);
  const [showFiterData, setShowFilterData] = useState(false);
  const searchValue= useSelector((state:RootState)=>state.auction.searchValue);

  // const auctionList = useSelector(
  //   (state: RootState) => state.auction.auctionList
  // );
  const {
    topAuction,
    upcommingAuctionList,
    liveAuctionList,
    isAuctionPurchased,
    countrywiseLiveAuctionList,
    countrywiseUpcommingAuctionList,
  } = useSelector((state: RootState) => state.auction);
console.log(topAuction)
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);

  const {
    staticLeftMobile,
    staticLeftWeb,
    staticRightMobile,
    staticRightWeb,
    auctionSlider,
    staticLeftUrl,
    staticRightUrl,
  } = useSelector((state: RootState) => state.promotion);

  useEffect(() => {
    dispatch(getAuctionList("auctionStatus=U"));
    dispatch(getUpcommingAuctionList("auctionStatus=U"));
    dispatch(getLiveAuctionList("auctionStatus=C"));
    dispatch(setDetailState({ detailState: false }));
    dispatch(getUserProfileDetail());
    dispatch(getPromotionData("AUCTION"));
    dispatch(getTopAuctionData());

    
    return () => {
      dispatch(
        toggleAuctionPurchase({
          isPurchased: false,
        })
      );
    };
  }, []);


  useEffect(() => {
    if (isAuctionPurchased === true) {
      navigate(RouterPath.lotteryPaymentSuccess);
    }

    return () => {
      dispatch(
        toggleAuctionPurchase({
          isPurchased: false,
        })
      );
    };
  }, [isAuctionPurchased]);

 

  const handleCountryDropdownValueChange = (value: string, name: string) => {
    let updatedArray = updateFormSelectState(value, name, fileterState.form);
    console.log(name,value)
    setFilterState({
      ...fileterState,
      form: updatedArray,
    });
    console.log(value.length);
    if (value === "All" || value.length === 0) {
      setShow(false);
      setShowFilterData(false);
    } else {
      setShow(true);
    }
  };
  // console.log(fileterState)
  const filterView = (
    <FormBuilder
      formElements={fileterState.form}
      onChangeDate={() => {}}
      onInputChange={() => {}}
      onSelectValueChange={handleCountryDropdownValueChange}
    />
  );

  const redirectToView = (path: string) => {
    navigate(path);
  };

  const handleSearchData = () => {
    // console.log([fileterState.form[0].value])
    dispatch(
      setCountrywiseLiveAuctionList({
        data: [],
      })
    );
    dispatch(
      setCountrywiseUpcommingAuctionList({
        data: [],
      })
    );
    dispatch(
      getCountryWiseLiveAuctions({
        auctionStatus: "C",
        countries: [fileterState.form[0].value],
      })
    );
    dispatch(
      getCountryWiseUpcommingAuctions({
        auctionStatus: "U",
        countries: [fileterState.form[0].value],
      })
    );
    setShowFilterData(true);

    const updatedForm = [...fileterState.form];
    updatedForm[0].value = ""; // Assuming country dropdown is the first element in the form array
    
    // Update the component state with the modified form
    setFilterState({
      ...fileterState,
      form: updatedForm,
    });
  };

  return (
    <Fragment>
      <Helmet>
        <title>KingsRings - Auctions</title>
      </Helmet>
      <BannerWrapper>
        {/* <Promotion pageName={"AUCTION"} /> */}
        <Sliders sliderList={auctionSlider} />
      </BannerWrapper>
      <StaticWrapper>
        {isLoading ? (
          <>
            <BannerSection>
              <BannerContainerSkeleton />
              <TopAuctionSkeleton />
              <BannerContainerSkeleton />
            </BannerSection>
          </>
        ) : (
          <BannerSection>
            <BannerContainer>
              {Object.keys(staticLeftMobile).length !== 0 ? (
                staticLeftUrl !== "" ? (
                  <a
                    href={`http://${staticLeftUrl}`}
                    target="_blank" // You can use "_self" for the same tab
                    rel="noopener noreferrer"
                  >
                    <img
                      src={
                        dimention.width <= 1021
                          ? staticLeftMobile
                          : staticLeftWeb
                      }
                    />
                  </a>
                ) : (
                  <img
                    src={
                      dimention.width <= 1021 ? staticLeftMobile : staticLeftWeb
                    }
                  />
                )
              ) : (
                // <img src={"https://picsum.photos/450/520"} />
                <DefaultBannerImg />
              )}
            </BannerContainer>
            <TopAuction topAuction={topAuction} />
            <BannerContainer>
              {Object.keys(staticRightWeb).length !== 0 ? (
                staticRightUrl !== "" ? (
                  <a
                    href={`http://${staticRightUrl}`}
                    target="_blank" // You can use "_self" for the same tab
                    rel="noopener noreferrer"
                  >
                    <img
                      src={
                        dimention.width <= 1021
                          ? staticRightMobile
                          : staticRightWeb
                      }
                    />
                  </a>
                ) : (
                  <img
                    src={
                      dimention.width <= 1021
                        ? staticRightMobile
                        : staticRightWeb
                    }
                  />
                )
              ) : (
                // <img src={"https://picsum.photos/450/520"} />
                <DefaultBannerImg />
              )}
            </BannerContainer>
          </BannerSection>
        )}
      </StaticWrapper>

      <AuctionContainer>
      <Wrapper>
        <ListTitle>
          showing {showFiterData? countrywiseLiveAuctionList.length+countrywiseUpcommingAuctionList.length: upcommingAuctionList?.length + liveAuctionList?.length}{" "}
          auctions
        </ListTitle>
        <ActionSection>
          <FilterViewSection>
           <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"10px"}}>
           <FormView>{filterView}</FormView>
            {show && (
              <Button
                disabled={false}
                appBtnType={AppButtonType.primaryBordered}
                fullWidth={false}
                variant={ButtonVariant.contained}
                type={ButtonType.default}
                size={ButtonSizeVariant.small}
                clicked={handleSearchData}
              >
                Search
              </Button>
            )}
           </div>
            <Button
              disabled={false}
              appBtnType={AppButtonType.primaryBtn}
              fullWidth={false}
              variant={ButtonVariant.contained}
              type={ButtonType.default}
              size={ButtonSizeVariant.small}
              clicked={() => {
                redirectToView(
                  isLoggedIn ? RouterPath.createAuction : RouterPath.signIn
                );
              }}
            >
              + List your product in auction
            </Button>
          </FilterViewSection>
        </ActionSection>
        <hr />
        {showFiterData ? (
          <DispayAuction>
            <LiveAuction>
            <AuctionAction>
                <AuctionTitle>Live Auctions</AuctionTitle>
                <div>
                  {liveAuctionList.length > 4 && (
                    <Button
                      disabled={false}
                      appBtnType={AppButtonType.primaryBordered}
                      fullWidth={true}
                      variant={ButtonVariant.contained}
                      type={ButtonType.default}
                      size={ButtonSizeVariant.small}
                      clicked={() => {
                        redirectToView(RouterPath.allAuction);
                      }}
                    >
                      See All
                    </Button>
                  )}
                </div>
              </AuctionAction>
              
              {countrywiseLiveAuctionList.length > 0 ? (
                <>
                  <AuctionCarousel
                    auctionList={countrywiseLiveAuctionList}
                    isLive={true}
                  />
                </>
              ) : (
                <ErrorMsg>No Live Auctions.</ErrorMsg>
              )}
            </LiveAuction>
            <hr />
            <UpcommingAuction>
              <AuctionTitle>Upcomming Auctions</AuctionTitle>
              {countrywiseUpcommingAuctionList.length > 0 ? (
                <>
                  <AuctionCarousel
                    auctionList={countrywiseUpcommingAuctionList}
                    isLive={false}
                  />
                </>
              ) : (
                <ErrorMsg>No Upcomming Auctions.</ErrorMsg>
              )}
            </UpcommingAuction>
          </DispayAuction>
        ) : (
          <DispayAuction>
            <LiveAuction>
              <AuctionAction>
                <AuctionTitle>Live Auctions</AuctionTitle>
                <div>
                  {liveAuctionList.length > 4 && (
                    <Button
                      disabled={false}
                      appBtnType={AppButtonType.primaryBordered}
                      fullWidth={true}
                      variant={ButtonVariant.contained}
                      type={ButtonType.default}
                      size={ButtonSizeVariant.small}
                      clicked={() => {
                        redirectToView(RouterPath.allAuction);
                      }}
                    >
                      See All
                    </Button>
                  )}
                </div>
              </AuctionAction>
              {liveAuctionList.length > 0 ? (
                <>
                  <AuctionCarousel
                    auctionList={liveAuctionList}
                    isLive={true}
                  />
                </>
              ) : (
                <ErrorMsg>No Live Auctions.</ErrorMsg>
              )}
            </LiveAuction>
            <hr />
            <UpcommingAuction>
              <AuctionTitle>Upcomming Auctions</AuctionTitle>
              {upcommingAuctionList.length > 0 ? (
                <>
                  <AuctionCarousel
                    auctionList={upcommingAuctionList}
                    isLive={false}
                  />
                </>
              ) : (
                <ErrorMsg>No Upcomming Auctions.</ErrorMsg>
              )}
            </UpcommingAuction>
          </DispayAuction>
        )}
      </Wrapper>
      </AuctionContainer>
    </Fragment>
  );
};

export default Auction;
