import { useEffect, FC, useState } from "react";
import {
  StyledWrapper,
  StyledFormContainer,
  Image,
  StyledFormHeader,
  StyledImg,
} from "./styleSignin";
import Loader from "../../../components/Loader/Loader";
import SigninForm from "../../Forms/Login/Login";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../app/store";
import { createLogin, toggleLogin } from "../../../features/loginSlice";
import { validateLogin } from "../../../Utility/formValidation";
import { getUserProfileDetail } from "../../../features/userProfileSlice";
import { useLocation, useNavigate } from "react-router-dom";

import { FeatierArrowLeft } from "../../../icons";

import { NotificationType } from "../../../Utility/InterFacesAndEnum";
import { toggleNotificationVisibility } from "../../../features/notificationSlice";

import { RouterPath } from "../../../routes/routes";
import LoginImage from "../../../assets/loginImage.png";
import AuthWrapper from "../AuthWrapper/AuthWrapper";
import LoginImg from "../../../assets/loginCupImg.png";
import { Helmet } from "react-helmet";

interface SigninAccount {
  emailId: string;
  password: string;
}
interface LocationState {
  from: string;
  error: string;
  // Add other properties as needed
}

const Login: FC = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  // console.log(location)

  let loadingState = useSelector((state: RootState) => state.login.isLoading);
  let isLoggedIn = useSelector((state: RootState) => state.login.isLoggedin);
  let isAuthenticated = useSelector(
    (state: RootState) => state.login.isAuthenticated
  );

  useEffect(() => {
    if (location.state !== null) {
      const { from, error }: LocationState = state as LocationState;
      console.log(location.state);
      
      dispatch(
        toggleNotificationVisibility({
          isVisible: true,
          status: NotificationType.error,
          message: error,
        })
      );
    }
  }, []);

  const signinHandler = (obj: SigninAccount): void => {
    let statusObj = validateLogin(obj);
    if (statusObj.status === true) {
      dispatch(createLogin(obj));
    } else {
      dispatch(
        toggleNotificationVisibility({
          isVisible: true,
          status: NotificationType.error,
          message: statusObj.message,
        })
      );
    }
  };

  const redirectToForgotPassword = (): void => {
    navigate(RouterPath.forgotPassword);
  };

  const redirectToRegister = (): void => {
    navigate(RouterPath.signUp);
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (isAuthenticated) {
        dispatch(getUserProfileDetail());
        navigate(RouterPath.root);

        // if we use -1 it will navigate to last page.
        // navigate(-1);
      } else if (isAuthenticated === false) {
        navigate(RouterPath.twoFA);
      }
    }
    return () => {
      dispatch(
        toggleLogin({
          isLoggedin: false,
          isAuthenticated: false,
        })
      );
    };
  }, [isLoggedIn]);

  return (
    <StyledWrapper>
      <Helmet>
        <title>KingsRings - SingIn</title>
      </Helmet>
      <AuthWrapper imgUrl={LoginImg} altText={"loginImage"}>
        <StyledFormContainer>
          <StyledFormHeader>Login account</StyledFormHeader>
          <Loader isLoading={loadingState} />
          <StyledImg>
            <Image src={LoginImage} />
          </StyledImg>
          <SigninForm
            onClickSignin={signinHandler}
            onClickForgotPassword={redirectToForgotPassword}
            onClickCreateAccount={redirectToRegister}
          />
        </StyledFormContainer>
      </AuthWrapper>
    </StyledWrapper>
  );
};

export default Login;
