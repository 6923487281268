import { FC } from "react";
import {
  ButtonSizeVariant,
  ButtonType,
  ButtonVariant,
  AppButtonType,
} from "../../Utility/InterFacesAndEnum";
import Button from "../UI/Buttons/Button";
import { transformDate } from "../../Utility/Utility";

import LotteryCoin from "../../assets/lotteryCoin.png";
import EntryTicketIcon from "../../assets/ticket -icon.png";
import goldIcon from "../../assets/gold-icon.svg";
import UserIcon from "../../assets/User-icon.svg";
import LotteyIcon from "../../assets/order-lottery.svg";
import WinnerIcn from "../../assets/Winner-icon.svg";
import CountdownTimer from "../CountdownTimer/CountdownTimer";

import {
  Wrapper,
  Container,
  CoinImgSection,
  LotteryDetailSection,
  PriceTitle,
  LotteryPrice,
  EndSection,
  EndSectinTitle,
  EndSectionTime,
  AuctionBtnSection,
  FooterSection,
  FooterOption,
  FooterTitle,
  FooterValue,
  LotterCoinImg,
  FooterImg,
  LotteryNumber,
  WinnerValue,
  GoldImg,
} from "./StyledLiveLottery";
import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import * as localStorageActionType from "../../localStorage/ActionTypes";
import { HeaderImg } from "../Header/StyledHeader";

interface LiveLotteryPrice {
  isGoldMember: boolean;
  lotteryNumber: any;
  id: number;
  lotteryType: string;
  lotteryPrice: string | number;
  lotteryEndTime: string;
  entryPrice: number;
  usersCound: number;
  drawDate: string;
  onSelectBuy(obj: any): void;
  onSelectGoldMembership(obj: any): void;
  rewardImages: any;
  isMemberLottery: boolean;
}

const LiveLottery: FC<LiveLotteryPrice> = (props) => {
  const {
    isGoldMember,
    lotteryNumber,
    lotteryPrice,
    lotteryEndTime,
    entryPrice,
    usersCound,
    lotteryType,
    id,
    drawDate,
    onSelectBuy,
    onSelectGoldMembership,
    rewardImages,
    isMemberLottery,
  } = props;
  let userDetails = getLocalStorage(localStorageActionType.GET_USER_DETAILS);
  // console.log(Object.keys(userDetails).length!==0 ? JSON.parse(userDetails) : "userDetails not found");
  // let isGoldMember =
  //   Object.keys(userDetails).length !== 0
  //     ? JSON.parse(userDetails).isGoldMember
  //     : false;
  // console.log(lotteryEndTime)
  const redirectToLogin = () => {};

  return (
    <Wrapper isMember={isMemberLottery}>
      <GoldImg>
        {isMemberLottery ? <HeaderImg src={goldIcon} /> : <></>}
      </GoldImg>
      <Container>
        <CoinImgSection>
          {rewardImages !== null && lotteryType === "G" ? (
            <LotterCoinImg src={rewardImages[0]} />
          ) : (
            // <LotterCoinImg src={"https://picsum.photos/450/520"} />
            <LotterCoinImg src={LotteryCoin} />
          )}
        </CoinImgSection>
        <LotteryDetailSection>
          <PriceTitle>Lottery Reward</PriceTitle>
          <LotteryPrice>
            {lotteryType === "M" && <span>&#x24;</span>}
            {lotteryType === "M" ? lotteryPrice.toLocaleString() : lotteryPrice}
          </LotteryPrice>
          <EndSection>
            {/* <EndSectinTitle>Lottery Number:</EndSectinTitle> */}
            {/* <LotteryNumber>{lotteryNumber}</LotteryNumber> */}
            <EndSectinTitle>Hurry Lottery Ends On:</EndSectinTitle>
            <EndSectionTime>
              <CountdownTimer
                timestamp={new Date(lotteryEndTime)}
                isDay={false}
              />
            </EndSectionTime>
          </EndSection>
          <AuctionBtnSection>
            <Button
              disabled={false}
              appBtnType={AppButtonType.primaryBtn}
              fullWidth={false}
              variant={ButtonVariant.contained}
              type={ButtonType.default}
              size={ButtonSizeVariant.small}
              clicked={() => {
                onSelectBuy(id);
              }}
            >
              BUY TICKETS
            </Button>
            {!isGoldMember && (
              <Button
                disabled={false}
                appBtnType={AppButtonType.secondary}
                fullWidth={false}
                variant={ButtonVariant.contained}
                type={ButtonType.default}
                size={ButtonSizeVariant.small}
                clicked={onSelectGoldMembership}
              >
                BUY GOLD MEMBERSHIP
              </Button>
            )}
          </AuctionBtnSection>
        </LotteryDetailSection>
      </Container>
      <FooterSection>
        <FooterOption>
          <FooterImg src={EntryTicketIcon} />
          <FooterTitle>Entry Tickets :</FooterTitle>
          <FooterValue>${entryPrice}</FooterValue>
        </FooterOption>
        <FooterOption>
          <FooterImg src={LotteyIcon} />
          <FooterTitle>Lottery Number : </FooterTitle>
          <FooterValue>{lotteryNumber}</FooterValue>
        </FooterOption>
        <FooterOption>
          <FooterImg src={WinnerIcn} />
          <FooterTitle>Winner will be announced on :</FooterTitle>
          <WinnerValue>{transformDate(new Date(lotteryEndTime))}</WinnerValue>
        </FooterOption>
        {/* <FooterOption>
          <FooterImg src={WinnerIcn} />
          <FooterTitle>{isMemberLottery?"Gold Member":"All Members"}</FooterTitle>
        </FooterOption> */}
      </FooterSection>
    </Wrapper>
  );
};

export default LiveLottery;
