import styled from "styled-components";
import * as UIConstants from "../../../UIConstants";

export const Wrapper = styled.div`
width:100%;
box-sizing: border-box;
padding :10px;

`
export const Section = styled.div`
background-color: #ffffff;
padding:15px;
border-radius: 10px;
`

export const AuctionContainer = styled.div`
width: 100%;
box-sizing: border-box;
 display: flex;
 flex-flow: row wrap;
align-items: center;
justify-content: center;
gap: 30px;
${UIConstants.mobileView}{
justify-content: center;

}
`;
// export const Auctions = styled.div`

// width: 300px;

// // flex-basis: 48.5%;
// img{
//     width:300px;
// }

// ${UIConstants.customMobileView}{
//     width:100%;
//     img{
//     width:100%;
//     height: 300px;
// }

// }

// `;
export const SectionTitle = styled.div`
font-size: 24px;
font-weight: 600;
color: #000000;
text-align: left;
margin: 0px 0 24px 0;
${UIConstants.mobileView}{
// margin: 25px 0;
font-size: 18px
}
`;

export const FilterViewSection = styled.div`
width: 100%;
box-sizing: border-box;
display: flex;
flex-flow: row ;
flex-wrap:wrap;
align-items: center;
justify-content:center;
column-gap: 15px;
margin-bottom:20px;
`;
export const FormView = styled.div`
width: 80%;
${UIConstants.customMobileView}{
width: 200px;

}
`;

export const BreadCrumb = styled.div`
width: 100%;
box-sizing: border-box;
height: 67px;
display: flex;
align-items: center;
justify-content: flex-start;
font-weight: 400;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;
text-transform: capitalize;
color: #200E32;
background-color: #ffffff;
padding:0 30px;
margin-top: 2px;
`;

export const BreadCrumbItem = styled.div`
    color: #200E32;
    padding: 0 4px;
    cursor: pointer;
    &:hover{
        color:blue;
    }
`;

export const TotalView = styled.div`
margin-left:10px;
color: black;
font-size: 20px;
font-weight: bold;
margin-bottom:20px;
opacity: 0.7;
`
