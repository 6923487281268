import React from 'react'
import {Container, DetailContainer, DetailSection, DetailSection2, ImageSection } from "./StyledAuctionDetail";
import { Skeleton } from '@mui/material';
const AuctionDetailSkeleton = () => {
    
  return (
    <>
    <Container>
        <ImageSection>
            <Skeleton style={{borderRadius:"5px"}} width={400} height={500}/>
        </ImageSection>
        <DetailSection>
        <Skeleton style={{borderRadius:"5px"}} width={400} height={70}/>
        <Skeleton style={{borderRadius:"5px"}} width={300} height={50}/>
        <Skeleton style={{borderRadius:"5px"}} width={600} height={300}/>
        </DetailSection>
    </Container>
    <Container>
    <DetailSection2>
    <Skeleton style={{borderRadius:"5px", margin:"0px 0 5px 0"}} width={600} height={20}/>
        <Skeleton style={{borderRadius:"5px", margin:"6px 0 6px 0"}} width={200} height={20}/>
        <Skeleton style={{borderRadius:"5px", margin:"6px 0 6px 0"}} width={300} height={90}/>
        <Skeleton style={{borderRadius:"5px", margin:"6px 0 6px 0"}} width={200} height={20}/>
        <Skeleton style={{borderRadius:"5px", margin:"6px 0 6px 0"}} width={200} height={20}/>
        {/* <Skeleton style={{borderRadius:"5px", margin:"25px 0 25px 0"}} width={600} height={5}/> */}

    </DetailSection2>
    <DetailContainer>
    <Skeleton style={{borderRadius:"5px", margin:"0px 0 6px 0"}} width={200} height={20}/>
        <Skeleton style={{borderRadius:"5px", margin:"6px 0 6px 0"}} width={150} height={30}/>
        <Skeleton style={{borderRadius:"5px", margin:"6px 0 6px 0"}} width={200} height={20}/>
        <Skeleton style={{borderRadius:"5px", margin:"6px 0 6px 0"}} width={200} height={20}/>
        <Skeleton style={{borderRadius:"5px", margin:"6px 0 6px 0"}} width={200} height={20}/>
        <Skeleton style={{borderRadius:"5px", margin:"6px 0 6px 0"}} width={200} height={20}/>
        <Skeleton style={{borderRadius:"5px", margin:"6px 0 6px 0"}} width={200} height={20}/>

    </DetailContainer>
    </Container>
    </>
  )
}

export default AuctionDetailSkeleton