import React from "react";
// import Backdrop from "../Backdrop/Backdrop";
import loaderImgSrc from '../../assets/loader2.gif'
import { LoadingImg, Wrapper } from "./styledLoader";
import LoaderImg from "./LoaderImg/LoaderImg";
// import RollerSpinner from "./RollerSpinner";

interface LoaderProps {
  isLoading: boolean;
}

const Loader: React.FC<LoaderProps> = ({ isLoading }) => {
  return <>{isLoading && <Wrapper>
    {/* <LoadingImg src={loaderImgSrc} /> */}
    <LoaderImg/>
</Wrapper>}</>
};

export default Loader;
