import styled from 'styled-components';
import * as UIConstants from '../../../UIConstants';


export const StyledWrapper = styled.div`
flex-flow: column;
display: flex;
align-items: center;
justify-content: flex-start;
height: 100vh;
${UIConstants.customMobileView}{
    padding:15px;
    height: 95vh;
}
`;

export const StyledFormContainer = styled.div`
border: 1px solid var(--color-borderColor);
box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.12);
border-radius: 20px;
width: 100%;
box-sizing: border-box;
padding: 30px;
max-width:420px;
// min-height: 630px;
background-color: #ffffff;
@media screen and (max-width: 500px){
    max-width: 100%;
    // box-shadow: unset;
    border: none;
    min-height: unset;
}`;

export const StyledFormHeader = styled.div`
display: flex;
align-items: flex-start;
justify-content: flex-start;
color: #000000;
margin-bottom: 19px;
`;
export const StyledIconContainer = styled.span`
margin-right: 15px;
font-size: 16px;
cursor: pointer;
`;

export const StyledDescription = styled.div`
margin-top:10px;
width: 100%;
box-sizing: border-box;
margin-bottom: 50px;
text-align: left;
font-weight: 400;
font-size: 14px;
color: #828282
`;