import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtilities/endpoints";
import { toggleNotificationVisibility } from "./notificationSlice";
import { NotificationType } from "../Utility/InterFacesAndEnum";

import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { toggleLoader } from "./loaderSlice";

interface LotteryObj {
  lotteryGameId: number;
  lotteryGameNo: number;
  lotteryType: "G" | "M";
  rewardType: string;
  rewardAmount: number;
  rewardGiftName: string;
  rewardGiftDesc: string | null;
  rewardImages: null | string[];
  lotteryGameStartDate: string;
  lotteryGameEndDate: string;
  isActive: boolean;
  isMemberLottery: boolean;
  bronzeTicketPrice: number;
  silverTicketPrice: number;
  goldTicketPrice: number;
  platinumTicketPrice: number;
  bronzeSubTickets: number;
  silverSubTickets: number;
  goldSubTickets: number;
  platinumSubTickets: number;
  lotteryGameStatus: string;
  noOfUsersJoined: number;
}

interface lotteryState {
  lotteries: LotteryObj[];
  upcomingLotteries: LotteryObj[];
  winnerList: any;
  topLottery: any;
  isTopGame: boolean;
}

const initalState: lotteryState = {
  lotteries: [],
  upcomingLotteries: [],
  winnerList: [],
  topLottery: {},
  isTopGame: false,
};

interface getLottery {
  lotteriesList: LotteryObj[];
}

interface UpcomingLotteries {
  upcomingLottery: LotteryObj[];
}
interface TopLottery {
  topLottery: any;
  isTopGame: boolean;
}

export const getLotteries = createAsyncThunk(
  "get lotteries",
  async (reques, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(endpoints.getLotteryList, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));
        if (data.statusCode === 504) {
          dispatch(
            setLotteryResponse({
              lotteriesList: [],
            })
          );
        } else if (data.statusCode === 200) {
          dispatch(
            setLotteryResponse({
              lotteriesList: data.result,
            })
          );
          // dispatch(toggleNotificationVisibility({
          //     isVisible: false,
          //     status: NotificationType.success,
          //     message: data.errorMsg
          // }));
        }
      })
      .catch((error) => {
        dispatch(toggleLoader({ isLoading: false }));
        dispatch(
          toggleNotificationVisibility({
            isVisible: true,
            status: NotificationType.error,
            message: error.errorMsg,
          })
        );
      });
  }
);

export const getUpcomingLotteries = createAsyncThunk(
  "get upcoming lotteries",
  async (payload, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(endpoints.getUpcomingLottery, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));
        if (data.statusCode === 504) {
          dispatch(
            setUpcomingLottery({
              upcomingLottery: [],
            })
          );
        } else if (data.statusCode === 200) {
          dispatch(
            setUpcomingLottery({
              upcomingLottery: data.result,
            })
          );
          // dispatch(toggleNotificationVisibility({
          //     isVisible: false,
          //     status: NotificationType.success,
          //     message: data.errorMsg
          // }));
        }
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        return err;
      });
  }
);

export const getTopLotteryGame = createAsyncThunk(
  "get Top lottery",
  async (payload, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(endpoints.getTopLottery, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));

        // console.log(data)
        if (data.statusCode === 200) {
          dispatch(
            setTopLottery({
              topLottery: data.result,
              isTopGame: true,
            })
          );
          // dispatch(toggleNotificationVisibility({
          //     isVisible: false,
          //     status: NotificationType.success,
          //     message: data.errorMsg
          // }));
        } else if (data.statusCode === 504) {
          dispatch(
            setTopLottery({
              topLottery: {},
              isTopGame: false,
            })
          );
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: "Top Lottery Game Not found.",
            })
          );
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        return err;
      });
  }
);

export const getLotteryWinnerList = createAsyncThunk(
  "lottery winner details",
  async (payload: void, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(endpoints.getLotteryWinnerList, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        // console.log(response,"[getLotteryWinnerList]")
        dispatch(toggleLoader({ isLoading: false }));
        dispatch(
          setWinnerList({
            data: response.result,
          })
        );
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        return err;
      });
  }
);

const LotterySlice = createSlice({
  name: "lottery Slice",
  initialState: initalState,
  reducers: {
    setLotteryResponse: (state, action: PayloadAction<getLottery>) => {
      return {
        ...state,
        lotteries: action.payload.lotteriesList,
      };
    },
    setUpcomingLottery: (state, action: PayloadAction<UpcomingLotteries>) => {
      return {
        ...state,
        upcomingLotteries: action.payload.upcomingLottery,
      };
    },
    setTopLottery: (state, action: PayloadAction<TopLottery>) => {
      return {
        ...state,
        topLottery: action.payload.topLottery,
        isTopGame: action.payload.isTopGame,
      };
    },
    setWinnerList: (state, action: PayloadAction<{ data: any }>) => {
      return {
        ...state,
        winnerList: action.payload.data,
      };
    },
  },
});

export const {
  setLotteryResponse,
  setUpcomingLottery,
  setTopLottery,
  setWinnerList,
} = LotterySlice.actions;
export default LotterySlice.reducer;
