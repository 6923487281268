import * as localStorageActionType from '../localStorage/ActionTypes';
import {getLocalStorage} from '../localStorage/GetLocalStorage';

import {Routes, Route} from 'react-router-dom';


import Profile from '../containers/Profile/Profile';
import Registration from '../containers/Auth/Signup/SignUp';
import Signin from '../containers/Auth/Siginin/Signin';
import TwoFA from "../containers/Auth/TwoFA/TwoFA";
import PasswordUpdate from '../containers/Auth/UpdatePassword/UpdatePassword';
import ResetPassword from '../containers/Auth/ResetPassword/ResetPassword';
import ResendPwdResetLink from '../containers/Auth/ResendMail/ResendMail';
import ForgotPassword from '../containers/Auth/ForgotPassword/ForgotPassword';
import Home from '../containers/Home/home';
import Lottery from '../containers/Lotteries/Lotteries';
import LotteryPayment from "../containers/LotteryPayment/LotteryPayment";
import UserProfile from '../containers/UserProfile/UserProfile';
import PaymentSuccess from '../containers/LotteryPaymentSuccess/PaymentSuccess';
import Auction from '../containers/Auction/AuctionList';
import AuctionDetail from '../containers/AuctionDetail/AuctionDetail';
import CreateAuction from '../containers/CreateAuction/CreateAuction';
import {AppRoutePath} from '../features/navigationSlice';
import Purchases from '../containers/Purchases/Purchases';
import AuctionPaymentSuccess from '../containers/AuctionPaymentSuccess/PaymentSuccess';
import PaypalSuccess from '../containers/PaymentStatus/Success/Success';
import PaypalFail from '../containers/PaymentStatus/Fail/Fail';
import AuctionPaypalSuccess from '../containers/AuctionPayment/PaymentStatus/Success/Success';
import AuctionPaypalFail from '../containers/AuctionPayment/PaymentStatus/Fail/Fail';
import MembershipSuccess from '../containers/BuyMembershipStatus/Success/Success';
import TextAreaComponent from '../containers/TAndC/TandC';
import PrivacyPolicy from '../containers/PrivacyPolicy/PrivacyPolicy';
import ContactUs from '../containers/ContactUs/ContactUs';
import CoinDetails from '../components/GoldCoins/MoreDetails';
import PageNotFound from '../components/PageNotFound/PageNotFound';
import LoadMoney from '../containers/LoadMoney/LoadMoney';
import Success from '../containers/PaymentStatus/Success/Success';
import GoogleLogin from '../containers/Forms/Login/GoogleLogin';
import ScrollToTop from './ScrollToTop';
import MembershipFail from '../containers/BuyMembershipStatus/Fail/Fail';
import LotteryPaymentFail from '../containers/LotteryPaymentSuccess/LotteryPaymentFalure';
// import CoinPaymentSuccess from '../containers/CoinPaymentStatus/Success';
// import CoinPaymentFailure from '../containers/CoinPaymentStatus/Fail';
import AuctionPayment from '../containers/AuctionPayment/AuctionPayment';
import CoinPayment from '../containers/CoinPayment/CoinPayment';
import ProtectedRoute from './ProtectedRoutes';
import SeeAllAuction from '../containers/Auction/SeeAllAuction/SeeAllAuction';
import CreateAuctionPaymentSuccess from '../containers/CreateAuction/PymentStatus/Success/Success';
import CreateAuctionPaymentFail from '../containers/CreateAuction/PymentStatus/Fail/Fail';
import CreateAuctionPaymentComplete from '../containers/CreateAuction/PymentStatus/Complete/PaymentComplete';
import LoadmoneyPaymentSuccess from '../containers/LoadMoney/PaymentStatus/Success/Success';
import LoadMoneyPaymentFailure from '../containers/LoadMoney/PaymentStatus/Fail/LoadMoneyPaymentFailure';
import LoadMoneyPaymentComplete from '../containers/LoadMoney/PaymentStatus/Complete/LoadMoneyPaymentComplete';
import CoinPaymentComplete from '../containers/CoinPayment/PaymentStatus/Complete/CoinPaymentComplete';
import CoinPaymentSuccess from '../containers/CoinPayment/PaymentStatus/Success/CoinPaymentSuccess';
import CoinPaypalFail from '../containers/CoinPayment/PaymentStatus/Fail/CoinPaymentFailure';

export const RouterPath = {
  ...AppRoutePath
}

const AppProtectedRoutes = () => {
  // const rootComponent = getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN) ? <Home /> : <Signin />;
  const rootComponent = getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN) ? <Home /> : <Home />;
    return (
        <>
          <ScrollToTop/>
          <Routes>
            {/* public routes */}

            <Route path={RouterPath.signUp} element={<Registration />} />
            <Route path={RouterPath.signIn} element={<Signin />} />
            <Route path={RouterPath.twoFA} element={<TwoFA />} />
            <Route path={RouterPath.lotteries} element={<Lottery />} />
            <Route path={RouterPath.auctionList} element={<Auction />} />
            <Route path={RouterPath.allAuction} element={<SeeAllAuction />} />
            <Route path={RouterPath.auctionDetail} element={<AuctionDetail />} />
            <Route path={RouterPath.termsAndCondition} element={<TextAreaComponent />} />
            <Route path={RouterPath.privacyPolicy} element={<PrivacyPolicy />} />
            <Route path={RouterPath.contactUs} element={<ContactUs />}/>
            <Route path={RouterPath.coinDetails} element={<CoinDetails />} />
            <Route path={RouterPath.auth2Redirect} element={<GoogleLogin/>}/>
            <Route path={RouterPath.forgotPassword} element={<ForgotPassword />} />
            <Route path={RouterPath.updatePassword} element={<PasswordUpdate />} />
            <Route path={RouterPath.resetPassword} element={<ResetPassword />} />
            <Route path={RouterPath.resendResetLink} element={<ResendPwdResetLink />} />
            <Route path={RouterPath.notFound} element={<PageNotFound/>} />
            <Route path={RouterPath.tempRoot} element={rootComponent} />
            <Route path={RouterPath.root} element={rootComponent} />

              <Route  element={<ProtectedRoute redirectionPath={RouterPath.signIn}/>}>
                  <Route path={RouterPath.profile} element={<UserProfile activeState={"PROFILE"} />} />
                  <Route path={RouterPath.address} element={<UserProfile activeState={"ADDRESS"} />} />
                  <Route path={RouterPath.changePassword} element={<UserProfile activeState={"CHANGE PASSWORD"} />} />
                  <Route path={RouterPath.settings} element={<UserProfile activeState={"SETTINGS"} />} />
                  <Route path={RouterPath.membership} element={<UserProfile activeState={"MEMBERSHIP"} />} />
                  <Route path={RouterPath.lotteryPaymentView} element={<LotteryPayment />} />
                  <Route path={RouterPath.lotteryPaymentSuccess} element={<PaymentSuccess />} />
                  <Route path={RouterPath.lotteryPaymentFail} element={<LotteryPaymentFail />} />
                  <Route path={RouterPath.userProfile} element={<UserProfile activeState={"PROFILE"} />} />
                  <Route path={RouterPath.createAuction} element={<CreateAuction />} />
                  <Route path={RouterPath.createAuctionPaymentSuccess} element={<CreateAuctionPaymentSuccess/>} />
                  <Route path={RouterPath.createAuctionPaymentFail} element={<CreateAuctionPaymentFail />} />
                  <Route path={RouterPath.createAuctionPaymentComplete} element={<CreateAuctionPaymentComplete/>} />
                  <Route path={RouterPath.orders} element={<Purchases />} />
                  <Route path={RouterPath.auctionPayment} element={<AuctionPayment />} />
                  <Route path={RouterPath.auctionPaymentSuccess} element={<AuctionPaymentSuccess />} />
                  <Route path={RouterPath.paypalSuccess} element={<PaypalSuccess />} />
                  <Route path={RouterPath.paypalFail} element={<PaypalFail />} />
                  <Route path={RouterPath.auctionPaypalSuccess} element={<AuctionPaypalSuccess />} />
                  <Route path={RouterPath.auctionPaypalFail} element={<AuctionPaypalFail />} />
                  <Route path={RouterPath.membershipSuccess} element={<MembershipSuccess />} />
                  <Route path={RouterPath.membershipFail} element={<MembershipFail/>} />
                  <Route path={RouterPath.coinPayment} element={<CoinPayment/>}/>
                  <Route path={RouterPath.coinPaymentSuccess} element={<CoinPaymentSuccess />} />
                  <Route path={RouterPath.coinPaymentFail} element={<CoinPaypalFail />} />
                  <Route path={RouterPath.coinPaymentComplete} element={<CoinPaymentComplete />} />
                  <Route path={RouterPath.loadMoney} element={<LoadMoney/>}/>
                  <Route path={RouterPath.loadMoneyPaymentSucess} element={<LoadmoneyPaymentSuccess/>}/>
                  <Route path={RouterPath.loadMoneyPaymentFail} element={<LoadMoneyPaymentFailure/>}/>
                  <Route path={RouterPath.loadMoneyPaymentComplete} element={<LoadMoneyPaymentComplete/>}/>
                  <Route path={RouterPath.successPayment} element={<Success/>}/>
              </Route>
          </Routes>
        </>
    )
}

export default AppProtectedRoutes