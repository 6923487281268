import { Wrapper, Container, Title, List, B, P } from "./StyledTandC";
import { FC } from "react";
import { Helmet } from "react-helmet";

const TandC = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>KingsRings - Terms and Conditions</title>
      </Helmet>
      <Container>
        <Title>Terms &amp; Conditions</Title>
        <List>
          <li>
            This website is owned, operated, and maintained by KingsRings Ltd
            company.
          </li>
          <li>The use of the site is for those aged 18+.</li>
          <li>
            Entering the website and using it or the information contained
            therein constitute the visitor's agreement to the terms of use
            detailed below.
          </li>
          <li>
            The terms of use below are binding on anyone who chooses to visit
            the website{" "}
            <a href="https://www.kingsrings.games/">
              https://www.kingsrings.games/
            </a>{" "}
            mobile app. in any way.
          </li>
          <li>
            KingsRings may and reserves the exclusive right to update and/or
            change and/or cancel and/or suspend and/or delete and/or add any
            part of the terms of use, without prior notice. The changes to the
            terms of use will enter into force upon their publication on the
            website.
          </li>
          <li>
            Visiting and/or using the website after changes to the terms of use
            come into force will be considered as consent to the changes to the
            terms of use.
          </li>
          <li>
            KingsRings shall be entitled to revoke the permission and rights
            mentioned above, in whole or in part.
          </li>
          <li>
            KingsRings has the right to set additional restrictions on any
            information, materials, elements and services or to limit visitors'
            access to the site, without notice and without any liability being
            imposed on it.
          </li>
          <li>
            By using the services you agree to the terms and conditions of this
            Agreement. If you do not agree to the terms in this Agreement, we
            suggest that you do not use our services.
          </li>
          <li>
            Since the global activity, the communications with this KingsRings
            is only in English.
          </li>
          <li>
            A response to inquiries in general will be given up to 30 business
            day in most cases, depending on the load of inquiries.
          </li>
          <li>
            In order to participate in the lottery, the participant must confirm
            that their involvement complies with the laws of their country of
            origin, and that they are eligible to take part as a result.
          </li>
          <li>
            KingsRings will apply a transaction fee for payments made on winning
            entries, which will be based on the payment gateway used and will
            consist of a flat rate of 1% plus a $1 charge.
          </li>
          <li>
            The lottery participant will be responsible for any other bank
            charges and additional costs incurred due to their payment
            transactions. These charges will be debited to the participant
            accordingly.
          </li>
          <li>
            The products available for sale on KingsRings site have been
            carefully chosen, and we make no guarantee regarding availability or
            quantity. Some items may be unique and only available as a single
            unit.
          </li>
          <li>
            The system has been launched after undergoing functional testing and
            is currently operating as a beta version, therefore there may be
            occasional malfunctions. By accepting these terms, you agree to use
            the system at your own risk and understand that there will be no
            complaints or claims regarding any bugs or malfunctions that occur
            during use. We kindly ask for your cooperation in reporting any
            malfunctions that you may encounter while using the system.
          </li>
          <li>
            To ensure smooth verification of identity in the event of winning
            the lottery, we recommend that users diligently fill in their
            personal details.
          </li>
        </List>
       <Title>Currency and Exchange Rate Disclosure:</Title>
       <List>
        <li>All prices displayed on this website are in United States Dollars (USD), unless otherwise stated. If you are purchasing from a country other than the United States, please be aware that currency conversion rates may apply. The final price you pay may vary based on the exchange rate at the time of purchase, as determined by your financial institution or payment provider.</li>
        <li>You have the option to check the current exchange rate for your currency before completing your purchase. Please consult with your bank or financial institution for the most up-to-date exchange rates. We do not guarantee the accuracy or reliability of exchange rate information provided by third-party sources.</li>
        <li>By proceeding with your purchase, you acknowledge and agree to the currency conversion terms outlined above.</li>
       </List>
        <Title>Purchase History, Media, and Actions:</Title>
        <List>
          <li>
            By using our services, you acknowledge and agree that the company
            reserves the right to delete or remove your purchase history, media
            files, and actions from time to time due to technical limitations or
            other reasons. While we strive to provide a seamless user
            experience, there may be instances where the retention of certain
            data becomes impractical or infeasible.
          </li>
          <B>Technical Limitations:</B>
          <li>
            The company understands that the storage and maintenance of user
            data are essential for a satisfactory user experience. However,
            certain technical limitations may arise that require the periodic
            deletion of user data. These limitations can include storage
            constraints, system maintenance, updates, or improvements to our
            services.
          </li>
          <B>User Responsibilities:</B>
          <li>
            As a user, it is your responsibility to ensure that you have
            appropriate backup copies of any purchase history, media files, or
            other data that you consider important or valuable. The company
            shall not be held liable for any loss or inconvenience resulting
            from the deletion of such data.
          </li>
          <B>Notification of Deletions:</B>
          <li>
            While we strive to inform users of any significant data deletions in
            advance, there may be circumstances where such notifications are not
            possible or practical. The company shall not be held responsible for
            any loss of data or inconvenience resulting from the deletion of
            user information, purchase history, media, or actions.
          </li>
          <B>Data Privacy and Protection:</B>
          <li>
            The company is committed to protecting your data and adheres to
            applicable data privacy laws and regulations. We employ reasonable
            security measures to safeguard your information. However, we cannot
            guarantee the absolute security of data transmission or storage. You
            understand and accept that any data provided or stored within our
            services is done at your own risk.
          </li>
          <B>Modification of Terms:</B>
          <li>
            The company reserves the right to modify, amend, or update these
            terms and conditions at any time without prior notice. It is your
            responsibility to review the terms periodically and stay informed of
            any changes.
          </li>
          <B>Acceptance of Terms:</B>
          <li>
            By using our services, you signify your acceptance of these terms
            and conditions. If you do not agree with any of the terms outlined
            above, please refrain from using our services.
          </li>
        </List>

        <Title>Limitation of use</Title>
        <List>
          <li>
            By using our services, you acknowledge and agree that the company
            reserves the right to delete or remove your purchase history, media
            files, and actions from time to time due to technical limitations or
            other reasons. While we strive to provide a seamless user
            experience, there may be instances where the retention of certain
            data becomes impractical or infeasible.
          </li>
          <B>Technical Limitations:</B>
          <li>
            The company understands that the storage and maintenance of user
            data are essential for a satisfactory user experience. However,
            certain technical limitations may arise that require the periodic
            deletion of user data. These limitations can include storage
            constraints, system maintenance, updates, or improvements to our
            services.
          </li>
          <B>User Responsibilities:</B>
          <li>
            As a user, it is your responsibility to ensure that you have
            appropriate backup copies of any purchase history, media files, or
            other data that you consider important or valuable. The company
            shall not be held liable for any loss or inconvenience resulting
            from the deletion of such data.
          </li>
          <B>Notification of Deletions:</B>
          <li>
            While we strive to inform users of any significant data deletions in
            advance, there may be circumstances where such notifications are not
            possible or practical. The company shall not be held responsible for
            any loss of data or inconvenience resulting from the deletion of
            user information, purchase history, media, or actions.
          </li>
          <B>Data Privacy and Protection:</B>
          <li>
            The company is committed to protecting your data and adheres to
            applicable data privacy laws and regulations. We employ reasonable
            security measures to safeguard your information. However, we cannot
            guarantee the absolute security of data transmission or storage. You
            understand and accept that any data provided or stored within our
            services is done at your own risk.
          </li>
          <B>Modification of Terms:</B>
          <li>
            The company reserves the right to modify, amend, or update these
            terms and conditions at any time without prior notice. It is your
            responsibility to review the terms periodically and stay informed of
            any changes.
          </li>
          <B>Acceptance of Terms:</B>
          <li>
            By using our services, you signify your acceptance of these terms
            and conditions. If you do not agree with any of the terms outlined
            above, please refrain from using our services.
          </li>
        </List>

        <Title>Limitation of Liability</Title>
        <List>
          <li>
            KingsRings App is currently run a beta version and as a result there
            may be disruptions and malfunctions on some of the site's pages. In
            any case of a fault or problem, please notified of this via the
            "Contact Us" form on the website, and we will do our best to deal
            with it as quickly as possible.
          </li>
          <li>
            KingsRings and/or anyone on its behalf will not be held responsible
            for any loss and/or damage that occurred due to the visit to the
            website and/or the use of the website and/or the lack of ability to
            use the website, including damage caused due to the use of software
            applications downloaded through the website or activated as a result
            of using the website, including Internet applications .
          </li>
          <li>
            KingsRings shall be entitled to change and/or edit the content of
            the site as well as to prevent access to the site and/or to suspend
            and/or terminate one or more of the services provided within the
            site at any time and without prior notice.
          </li>
        </List>
        <Title>
          {" "}
          Limitation of Kings Rings System and Operation Limitations{" "}
        </Title>
        <List>
          <li>
            KingsRings Allows purchase by standard payment (credit card or a
            like) and crypto currency.
          </li>
          <li>
            Due to the high fee that may appear in the transactions of the
            cryptocurrencies, we recommend to load to the system a sum of at
            least 200$ or more, note that the money charged to the system is a
            purchase and can be used by you throughout the site for various
            uses. Please note that money back transfers will not be possible due
            to the high transfer fees.
          </li>
          <li>
            Different Digital wallets have a minimum transfer of funds. In order
            for the money you transfer, to be able to allow you to participate
            in the lotteries, we recommend that you pay from the account
            balances in KingsRings. The charge itself will constitute a
            purchase.
          </li>
          <li>
            For use of the lottery system, you need to buy ticket : regular,
            high chance, extra chance, Or become a member. As a member you will
            participate all lottery games automatically and you also participate
            lottery games for members only.{" "}
          </li>
          <li>
            Kings Rings Auctions system Created to offer highly valuable assets
            in Auction-Lottery Model. For use of the auction system, the balance
            of your account in the KingsRings system should be an amount higher
            than Nx100$ (for each asset- publish request, you will be required
            to pay Nx100$ ), this amount is intended to prevent misuse of the
            site's features. Also, this amount will be used to verify that the
            property does belong to the seller and will later be used to carry
            out the legal actions required to transfer the property. In case
            that the seller decide to cancel / leave / change the property or to
            do any substantial change in the details of the property for which a
            "Publish Request" has been paid for, the system will ask for a new
            payment request. In light of the above, receiving a refund will not
            be possible, as the money is transferred to a third party for the
            purpose of verifying the legal details.
          </li>
          <li>
            Due to the confidentiality of cryptocurrency transfers, please note
            that all details are correct and wallet address is correct before
            making the purchase. The King Ring system will not be responsible
            for wrong Transfers \ wallet address data.
          </li>
          <li>
            Due to the high fees in transactions, we recommend loading the
            system a sum of over 200$ and making purchases from the King Rings
            account. (In case of crypto transfer Note that some digital wallets
            have a minimum transfer value.) In order for your purchases to reach
            the Kings Rings system, and allow you to participate in lotteries,
            please follow the recommendations. Further to the above, loading
            credit into the KingsRings system will be considered a purchase for
            everything and will allow the user to purchase various lottery
            tickets throughout the site /mobile app.{" "}
            <b style={{ color: "#000000", fontWeight: "bold" }}>
              THERE IS NO OPTION TO CANCEL OR GET REFUND FOR THE FUNDS LOADED TO
              THE KINGS RINGS PLATFORM.
            </b>
          </li>
        </List>
        <Title>Return, refund, and cancellation policy</Title>
        <List>
          <li>
            Kings Rings puts significant effort into its platform. However, due
            to regulatory constraints, we regret to inform you that we are
            unable to offer refunds for funds loaded onto Kings Rings, lottery
            tickets, lottery auction tickets, or published asset services for
            the lottery auction. This limitation directly stems from our
            business model and the dynamics of the lottery market.
          </li>
          <li>
            In case of buying some of the Special Items collections there is
            option to cancel/return as follow
          </li>
          <B>Shipment Policy:</B>
          <P>Our standard shipment policy is as follows:</P>
          <br />
          <P>
            We offer free shipping on orders as our supplier offer. Please note
            that we are not responsible for any shipping delays caused by the
            carrier.{" "}
          </P>

          <B>Return Policy:</B>
          <P>
            We want you to be completely satisfied with your purchase. If for
            any reason you are not, we offer a 30-day return policy from the
            date of delivery. To initiate a return, please contact our customer
            service team. The following conditions apply to all returns:
          </P>
          <ul>
            <li>
              The item must be in its original packaging and in unused
              condition.
            </li>
            <li>Returns must be initiated within 30 days of delivery.</li>
            <li>
              The customer is responsible for return shipping costs unless the
              item is defective, or the wrong item was shipped.
            </li>
          </ul>

          <B>Refund Policy:</B>
          <P>
            If you are approved for a return, we will issue a refund to your
            original payment method within 14-21 business days of receiving the
            returned item. Please note that shipping costs are non-refundable
          </P>
          <B>Cancellation Policy:</B>
          <P>
            If you need to cancel an order, please contact our customer service
            team as soon as possible, usually for cancellation there is 12.6%
            cancellation fee. We will do our best to cancel the order before it
            ships, but we cannot guarantee that we will be able to do so. If the
            order has already shipped, you will need to follow our return policy
            to initiate a return.
          </P>
          <br />
          <P>
            For any additional information please contact customer service:
            info@kingsrings.games.
          </P>
        </List>
        <Title>Using lottery system</Title>
        <List>
          <li>
            To use the lottery games you must purchase a ticket. KingsRings
            offers different ways of payment : Debit Card / Crypto and PayPal.
            In case of Bank Transaction is required please send specific email.
          </li>
          <li>
            Each ticket will entitle the buyer to participate in the lottery and
            a chance to win the grand prize.
          </li>
          <li>
            In order to increase the chances of winning the lottery, there are
            cards with an increased chance of: Silver, Gold, Bronze… and
            Membership, that will allow a chance of up to 20 times.
          </li>
          <li>
            Due to the high fees in transactions, we recommend loading the
            system a sum of over 200$ and making purchases from the King Rings
            account. (In case of crypto transfer Note that some digital wallets
            have a minimum transfer value.) In order for your purchases to reach
            the Kings Rings system, and allow you to participate in lotteries,
            please follow the recommendations. Further to the above, loading
            credit into the KingsRings system will be considered a purchase for
            everything and will allow the user to purchase various lottery
            tickets throughout the site /mobile app. THERE IS NO OPTION TO
            CANCEL OR GET REFUND FOR THE FUNDS LOADED TO THE KINGS RINGS
            PLATFORM.
          </li>
          <li>
            In case of a win, the system will locate you and inform you about
            it. At no stage will you be required to spend money to realize the
            winnings, please beware of false messages by foreign parties.
          </li>
        </List>
        <Title>Using auction system</Title>
        <List>
          <li>
            The auction platform is designed to enable the chances of winning a
            significant asset by purchasing a participation card that can range
            from Kx10$ to any amount you choose. The higher the value of the
            card, the greater the chances of winning.
          </li>
          <li>
            KingsRings Auction Platform is mainly designed & created for high
            value properties.
          </li>
          <li>
            In this way the seller will be able to obtain the requested amount
            while the buyer will be able to obtain the property with a minimum
            participation amount of Kx10$.
          </li>
          <li>
            A user wishing to participate in the auction system as a buyer will
            purchase a ticket for any user's auction or for the "Home Auction".
            If the Auction has a minimum number of participants (which varies
            from Auction to Auction), the Auction will take place and one of the
            participants who purchased a ticket will certainly win the asset.
          </li>
          <li>
            "Home Auction" is the KingsRings offers, and they are fully Trusted.
          </li>
          <li>
            A user wishing to sell a property through the Auction system will
            fill in his details, the details of the requested property and the
            date of execution of the auction. Note that in order to advertise an
            asset you will need to pay a “Publish Request”, this amount is
            intended to prevent misuse of the site's features. Also, this amount
            will be used to verify that the property does belong to the seller
            and will later be used to carry out the legal actions required to
            transfer the property.
          </li>
          <li>
            Please be aware that this amount CAN NOT BE RETURN !! it is used to
            verify the seller and asset details and payments for the future
            contracts. After your payment, the system will begin legal checks to
            verify the ownership of the property to the advertiser or someone on
            his behalf. In case all the details are correct the asset will be
            publish.
          </li>
          <li>
            In case of incorrect details or if it turns out that the advertiser
            is not the owner of the property and has no authority to advertise
            the property. The system will reject the "property publication
            request" and the payment will not be refunded. The money transferred
            to KingsRings system is directed to 3rd party for legal inspections
            of the property.{" "}
          </li>
          <li>
            If substantial changes are made to an already published property
            (such as location), the system will request a new payment request.
            For tiny changes there is no payment request but it will ask for
            “publish request” again.
          </li>
          <li>
            Due to the fluctuations in the cryptocurrencies, it is not possible
            to make credits and the amounts will be used by you for purchases on
            the site.
          </li>
          <li>
            If the Auction is executed, KingsRings system will contact the
            seller to verify the details. After the verification of the
            information by the legal department, KingsRings will complete the
            purchase process, pay the seller and transfer the property to the
            winner.{" "}
          </li>
          <li>
            The KingsRings system publishes auctions called "Home Auction" where
            you can enjoy maximum security.{" "}
          </li>
          <B>Withdrawal by Seller:</B>
          <li>
            <b>Seller's Right to Withdraw: </b> The seller retains the right to
            withdraw their listed asset from the auction at any time before the
            conclusion of the auction, regardless of the reason for such
            withdrawal.
          </li>
          <li>
            <b>Withdrawal Fees: </b>In the event that the seller decides to
            withdraw their asset, the seller shall forfeit the initial publish
            fees paid to list the asset on the platform. These fees are
            non-refundable.
          </li>
          <li>
            <b>Cancellation and Refunds: </b> In the event that the seller
            decides to withdraw their asset, the auction will be canceled, and
            all participants in the auction will receive a full refund of any
            funds they have paid to participate. The refunded amount will be
            credited to the participants' wallets on the KingsRings platform.
          </li>
          <li>
            <b>No Liability: </b>KingsRings and its affiliates shall not be held
            liable or responsible for any loss, inconvenience, or damage
            incurred by participants due to the seller's decision to withdraw
            their asset, including but not limited to any financial losses or
            missed opportunities.
          </li>
          <li>
            <b>Notification: </b>KingsRings will make reasonable efforts to
            notify all participants in the auction promptly if the auction is
            canceled due to the seller's withdrawal.
          </li>
          <li>
            <b>Auction Execution and Seller Non-Compliance :</b>In the event
            that an auction has already been executed, and the seller is
            unwilling to complete the sale, the winning user will receive the
            equivalent monetary value of the offered asset
          </li>
        </List>
        <Title>Benefits of being a member</Title>
        <List>
          <li>
            As a member you will enjoy participating in all lotteries
            automatically.
          </li>
          <li>Your chances of winning increase by at least 5 times</li>
          <li>
            As a member you can enjoy the participation of additional lotteries,
            in which participation is for members only, thus you can win prizes
            of various sizes from computers, trips around the world to the grand
            prize.
          </li>
          <li>
            KingsRings Ltd Promoting social activities as a member you can
            contribute to us helping others.
          </li>
        </List>
        <P>
          Kings Rings strives for excellence in all aspects, yet occasionally an
          oversight may occur. If you come across any issue or inconvenience, we
          kindly request that you reach out to our customer service for prompt
          assistance.
        </P>
      </Container>
    </Wrapper>
  );
};

export default TandC;
