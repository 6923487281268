const calculatePasswordStrength = (password: string): number => {
    let strength = 0;
    if (password.length >= 8) {
      strength++;
    }
    if (/[a-z]/.test(password)) {
      strength++;
    }
    if (/[A-Z]/.test(password)) {
      strength++;
    }
    if (/\d/.test(password)) {
      strength++;
    }
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
      strength++;
    }
    return strength;
  };
  
  export default calculatePasswordStrength;
  