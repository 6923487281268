import styled from 'styled-components';
import * as UIConstants from '../../../UIConstants';

export const StyledWrapper = styled.div`
flex-flow: column;
display: flex;
align-items: center;
justify-content: flex-start;
height: 110vh;
// margin-bottom:100px;
${UIConstants.mobileView}{
    height:130vh;
   margin-bottom:0px;

}
${UIConstants.customMobileView}{
    padding:15px;
    height: 110vh;
}
`;

export const StyledFormHeader = styled.div`
padding-top:20px;
margin-bottom: 30px;
color: #000000;
font-size: 16px;
`;

export const StyledImg = styled.span`
display:flex;
align-items:center;
justify-content:center;
`;

export const StyledFormContainer = styled.div`
max-width: 700px;
width: 100%;
box-sizing: border-box;
border: 1px solid var(--color-borderColor);
padding: 30px;
box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.12);
border-radius: 20px;
max-width:420px;
background-color: #ffffff;
@media screen and (max-width: 500px){
    max-width: 100%;
    // box-shadow: unset;
    border: none;
    min-height: unset;
}`;



export const Image = styled.img`
max-width: 220px;
max-width: 220px;
@media screen and (max-width: 500px){
    max-width: 100%;
    max-height: 100%
}
`;