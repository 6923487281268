import React, { useEffect, useState } from "react";
import {
  Container,
  Wrapper,
  Title,
  SectionWrapper,
  UserSection,
  WalletSection,
  Details,
  Img,
  BalanceSection,
  AddMoneySection,
  InputSection,
  WalletIcon,
  Amount,
  DetailSection,
  DetailIcon,
  UserButtonSection,
  AddMoneyAmount,
  AddMoneyInvoice,
  AddMoneySpan,
  BtnSection,
  Balance,
  Content,
  Heading,
  Remark,
  StyledNavLink,
} from "./LoadMoneyStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetailes,
  loadMoneyCheckout,
  loadMoneyComplete,
} from "../../features/loadMoneySlice";
import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import * as localStorageActionType from "../../localStorage/ActionTypes";
import { RootState } from "../../app/store";
import {
  HiOutlineMail,
  HiOutlineDeviceMobile,
  HiOutlineLocationMarker,
} from "react-icons/hi";
import { BiWalletAlt } from "react-icons/bi";
import ButtonComponent from "../../components/UI/Buttons/Button";
import {
  AppButtonType,
  ButtonSizeVariant,
  ButtonType,
  ButtonVariant,
} from "../../Utility/InterFacesAndEnum";
import { NavLink, useNavigate } from "react-router-dom";
import { RouterPath } from "../../routes/routes";
import { TextField } from "@mui/material";
import ProfileAvtarUrl from "../../assets/profileavtar.webp";
import useDimension from "../../CustomHooks/useDimention";
import { getUserProfileDetail } from "../../features/userProfileSlice";
import { Helmet} from "react-helmet";

// import Input from "../../components/UI/Input/Input";

const LoadMoney = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [money, setMoney] = useState("");
  const [isValid, setIsvalid] = useState(false);
  const [showAddBtn, setShowAddbtn] = useState(true);
  const [showAddInput, setShowAddInput] = useState(false);
  const [showProceed, setShowProceed] = useState(false);
  const dimention = useDimension();
  const publicUserId = getLocalStorage(
    localStorageActionType.GET_PUBLIC_USER_ID
  );
  const { checkout, isLoading, userData } = useSelector(
    (state: RootState) => state.loadmoney
  );

  let userObj = getLocalStorage(localStorageActionType.GET_USER_DETAILS);
  let obj = JSON.parse(userObj);
  // console.log(obj);
  // console.log(checkout);
  useEffect(() => {
     dispatch(getUserProfileDetail())
    dispatch(getUserDetailes());
    // console.log("user Data", userData)
  }, []);

  const handleFormSubmision = () => {
    navigate(RouterPath.profile);
  };

  const handleAddBtn = () => {
    setShowAddbtn(false);
    setShowAddInput(true);
    // navigate(RouterPath.loadMoneyPaymentSucess)
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setMoney(event.target.value);
    setIsvalid(false);
  };

  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (!money.trim()) {
      setIsvalid(true);
      return;
    }
    dispatch(
      loadMoneyCheckout({
        publicUserId: publicUserId,
        amount: money,
      })
    );
    // alert(money);
    // setMoney("");
    setShowAddInput(false);
    setShowProceed(true);
  };

  const addMoneyButton = (
   <BtnSection>
     <ButtonComponent
      appBtnType={AppButtonType.primaryBtn}
      disabled={false}
      fullWidth={true}
      size={ButtonSizeVariant.small}
      variant={ButtonVariant.contained}
      type={ButtonType.submit}
      clicked={handleAddBtn}
    >
      Add Money
    </ButtonComponent>
   </BtnSection>
  );

  const addMoneyInput = (
    <form onSubmit={handleSubmit}>
      <InputSection>
        <TextField
          fullWidth={true}
          id="outlined-basic"
          label="Enter Amount"
          variant="outlined"
          value={money}
          onChange={(e) => handleInputChange(e)}
          // required={true}
          error={isValid}
          helperText={isValid && "Please enter some amount to add."}
        />
        <div style={{ width: "200px" }}>
          <ButtonComponent
            appBtnType={AppButtonType.primaryBtn}
            disabled={false}
            fullWidth={true}
            size={ButtonSizeVariant.small}
            variant={ButtonVariant.contained}
            type={ButtonType.submit}
            clicked={() => {}}
          >
            Add
          </ButtonComponent>
        </div>
      </InputSection>
    </form>
  );

  const proceedToPayment=()=>{
    window.open(checkout?.href, "_self")
  }

  // console.log(checkout)
  const proceedBtn = (
    <div >
      {isLoading ? (
        <BtnSection>
          <ButtonComponent
          appBtnType={AppButtonType.primaryBtn}
          disabled={false}
          fullWidth={true}
          size={ButtonSizeVariant.small}
          variant={ButtonVariant.contained}
          type={ButtonType.submit}
          clicked={() => {}}
        >
          Loading...
        </ButtonComponent>
        </BtnSection>
      ) : (
        // <a href={checkout.href} style={{ width: "100%" }}>
          <ButtonComponent
            appBtnType={AppButtonType.primaryBtn}
            disabled={false}
            fullWidth={true}
            size={ButtonSizeVariant.small}
            variant={ButtonVariant.contained}
            type={ButtonType.submit}
            clicked={proceedToPayment}
          >
            Proceed to payment
          </ButtonComponent>
        // </a>
      )}
    </div>
  );

  return (
    <Container>
      <Helmet>
        <title>KingsRings - LoadMoney</title>
      </Helmet>
      <Title>Wallet</Title>
      <Wrapper>
        <SectionWrapper>
          <UserSection>
            <Details>
              <Img>
                <img
                  src={obj.imageUrl ? obj.imageUrl : ProfileAvtarUrl}
                  alt="userImg"
                  width={"200x"}
                  height={"250px"}
                />
              </Img>
            </Details>
            <Details>
              <h2>
                {obj.firstName} {obj.lastName}
              </h2>

              <DetailSection>
                <DetailIcon>
                  <HiOutlineMail />
                </DetailIcon>{" "}
                {obj.emailId}
              </DetailSection>

              <DetailSection>
                <DetailIcon>
                  <HiOutlineDeviceMobile />
                </DetailIcon>{" "}
                {obj.mobileNo}
              </DetailSection>

              <DetailSection>
                <DetailIcon>
                  <HiOutlineLocationMarker />
                </DetailIcon>{" "}
                {obj.country}
              </DetailSection>
              <UserButtonSection>
                <ButtonComponent
                  appBtnType={AppButtonType.primaryBtn}
                  disabled={false}
                  fullWidth={true}
                  size={ButtonSizeVariant.small}
                  variant={ButtonVariant.contained}
                  type={ButtonType.submit}
                  clicked={handleFormSubmision}
                >
                  Update Profile
                </ButtonComponent>
              </UserButtonSection>
            </Details>
          </UserSection>
          <WalletSection>
            <Details>
              <Heading>Avilable Balance</Heading>
              <BalanceSection>
                <WalletIcon />
                <Balance>&#x24;{userData?.amount}</Balance>
              </BalanceSection>

              <AddMoneySection>
                
                {showProceed && !isLoading && (
                  <>
                    <AddMoneyAmount>
                      <Content>
                      <div>Add amount of ${money} in wallet.</div>
                        {dimention.width < 567 ? "":<Amount>{money}</Amount>}
                      
                      </Content>
                    </AddMoneyAmount>
                    
                  </>
                )}
                {showAddBtn && addMoneyButton}
                {showAddInput && addMoneyInput}
                {showProceed && proceedBtn}
              </AddMoneySection>
              
                  <Remark>For cryptocurency payment please contact <StyledNavLink to={RouterPath.contactUs}>customer care service.</StyledNavLink></Remark>
                
            </Details>
          </WalletSection>
        </SectionWrapper>
      </Wrapper>
    </Container>
  );
};

export default LoadMoney;
