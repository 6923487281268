import React, { useEffect, useState } from "react";
import { LoaderWrapper, Wrapper } from "./StyleGoogleLogin";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoginwithGoogleUserdata,
  toggleLogin,
} from "../../../features/loginSlice";
import { toggleNotificationVisibility } from "../../../features/notificationSlice";
import { NotificationType } from "../../../Utility/InterFacesAndEnum";
import { RootState } from "../../../app/store";
import { useLocation, useNavigate } from "react-router-dom";
import { RouterPath } from "../../../routes/routes";
import Loader from "../../../components/Loader/Loader";
import { setLocalStorage } from "../../../localStorage/SetLocalStorage";
import * as localStorageActionType from "../../../localStorage/ActionTypes";

const GoogleLogin: React.FC = () => {
  const { isLoading } = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const getUrlParameter = (name: string) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    const results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const token = getUrlParameter("accessToken");
  const authenticated: any = getUrlParameter("authenticated");
  const roleName = getUrlParameter("roleName");
  const publicUserId = getUrlParameter("publicUserId");
  const error = getUrlParameter("error");
  // console.log(token,authenticated,roleName,publicUserId,error)

  useEffect(() => {
    if (token) {
      let tokenObj = {
        accessToken: token,
        refreshToken: token,
      };
      setLocalStorage(
        localStorageActionType.SET_ACCESS_REFRESH_TOKEN,
        tokenObj
      );
      localStorage.removeItem("IS_ACCESS_INVALID");

      dispatch(
        toggleLogin({
          isLoggedin: true,
          isAuthenticated: authenticated,
        })
      );

      dispatch(
        toggleNotificationVisibility({
          isVisible: true,
          status: NotificationType.success,
          message: "Login Successfull.",
        })
      );
      if (authenticated) {
        setLocalStorage(localStorageActionType.SET_IS_LOGGED_IN, true);
        setLocalStorage(
          localStorageActionType.SET_PUBLIC_USER_ID,
          publicUserId
        );
      }
      navigate(RouterPath.root, { state: { from: location } });
    } else {
      navigate(RouterPath.signIn, { state: { from: location, error: error } });
    }
  }, []);

  return (
    <Wrapper>
      <LoaderWrapper>
        <Loader isLoading={true} />
      </LoaderWrapper>
    </Wrapper>
  );
};

export default GoogleLogin;
