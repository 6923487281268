import { FC, useEffect } from "react";
import loadingImgUrl from "../../../../assets/loader.gif";
import * as styled from "./StyledSuccess";

import { useNavigate, useLocation } from "react-router-dom";
import { RouterPath } from "../../../../routes/routes";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import { setLocalStorage } from "../../../../localStorage/SetLocalStorage";
import * as localStorageActionType from "../../../../localStorage/ActionTypes";
import { loadMoneyComplete } from "../../../../features/loadMoneySlice";
import { buyCoinPaymentComplete } from "../../../../features/coinSlice";

const CoinPaymentSuccess: FC = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  let isSuccess = useSelector((state: RootState) => state.coin.isPurchase);

  useEffect(() => {
    const currentUrl = window.location.href;
    // console.log("current url :",currentUrl)
    const url = new URL(currentUrl);
    const token = url.searchParams.get("token");
    setLocalStorage(localStorageActionType.SET_PAYMENT_TOKEN, token);
    const PayerID = url.searchParams.get("PayerID");

    dispatch(buyCoinPaymentComplete(token));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      navigation(RouterPath.coinPaymentComplete);
    }
  }, [isSuccess]);

  return (
    <styled.Wrapper>
      <styled.SectionWrapper>
        <styled.Title>Payment is Processing...</styled.Title>
        <styled.Subtitle>
          Please do not press back button or reload <br />
          page will be automaticaly refresh once process is complete
        </styled.Subtitle>
        <styled.ImageSection>
          <img src={loadingImgUrl} alt={"loader"} />
        </styled.ImageSection>
      </styled.SectionWrapper>
    </styled.Wrapper>
  );
};

export default CoinPaymentSuccess;
