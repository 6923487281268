import React, { useState } from "react";
import FormBuilder from "../../FormBuilder/FormBuilder";
import Button from "../../../components/UI/Buttons/Button";
import { updateFormInputState, validateForm } from "../../../Utility/Utility";
import {
  FormElementType,
  customValidationType,
  InputVariant,
  InputTypes,
  FormElement,
  ButtonSizeVariant,
  ButtonVariant,
  ButtonType,
  AppButtonType,
} from "../../../Utility/InterFacesAndEnum";
import {
  LoginAccountContainer,
  LoginAccountOption,
  TermsConditionContainer,
  EmptyCheckbox,
  SelectedCheckbox,
  TermsText,
} from "./StyledReg";

import zxcvbn from "zxcvbn";
import calculatePasswordStrength from "../../../components/PasswordStrength/CalculatePasswordStrength";
import PasswordStrengthMeter from "../../../components/PasswordStrength/PasswordStrengthMeter";

import { useNavigate } from "react-router-dom";
import { RouterPath } from "../../../routes/routes";
import { GoogleButton } from "../Login/StyledLogin";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

interface RegFormState {
  form: FormElement[];
  isValidForm: boolean;
}

interface RegistrationProps {
  onRegister(obj: CreateAccount): void;
  onSelectLogin(): void;
}

interface CreateAccount {
  emailId: string;
  password: string;
  firstName: string;
  lastName: string;
  using2FA: boolean;
  agreeTC: boolean;
  mobileNo: string;
}

const PasswordInput = () => {
  const [password, setPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPassword(value);
    setPasswordStrength(calculatePasswordStrength(value));
  };

  return (
    <div>
      <label htmlFor="password">Password</label>
      <input
        type="password"
        id="password"
        value={password}
        onChange={handlePasswordChange}
      />
      <PasswordStrengthMeter strength={passwordStrength} />
    </div>
  );
};

const registrationFormInitalState: RegFormState = {
  form: [
    {
      elementType: FormElementType.input,
      value: "",
      id: "firstName",
      isRequired: true,
      fullWidth: true,
      isCustomValidationRequred: true,
      inputVariant: InputVariant.outlined,
      inputType: InputTypes.text,
      customValidationType: customValidationType.characterValidation,
      isValidInput: false,
      isTouched: false,
      errorMessage: "",
      label: "First Name",
      radioGroupValues: [],
      dropdownValues: [],
      slectedDate: null,
      isPasswordHidden: true,
    },
    {
      elementType: FormElementType.input,
      value: "",
      id: "lastName",
      isRequired: true,
      fullWidth: true,
      isCustomValidationRequred: true,
      inputVariant: InputVariant.outlined,
      inputType: InputTypes.text,
      customValidationType: customValidationType.characterValidation,
      isValidInput: false,
      isTouched: false,
      errorMessage: "",
      label: "Last Name",
      radioGroupValues: [],
      dropdownValues: [],
      slectedDate: null,
      isPasswordHidden: true,
    },
    {
      elementType: FormElementType.input,
      value: "",
      id: "mobileNumber",
      isRequired: true,
      fullWidth: true,
      isCustomValidationRequred: true,
      inputVariant: InputVariant.outlined,
      inputType: InputTypes.text,
      customValidationType: customValidationType.mobileValidation,
      isValidInput: false,
      isTouched: false,
      errorMessage: "",
      label: "Mobile Number",
      radioGroupValues: [],
      dropdownValues: [],
      slectedDate: null,
      isPasswordHidden: true,
    },
    {
      elementType: FormElementType.input,
      value: "",
      id: "email",
      isRequired: true,
      fullWidth: true,
      isCustomValidationRequred: true,
      inputVariant: InputVariant.outlined,
      inputType: InputTypes.text,
      customValidationType: customValidationType.emailValidation,
      isValidInput: false,
      isTouched: false,
      errorMessage: "",
      label: "Email Id",
      radioGroupValues: [],
      dropdownValues: [],
      slectedDate: null,
      isPasswordHidden: true,
    },
    {
      elementType: FormElementType.password,
      value: "",
      id: "password",
      isRequired: true,
      fullWidth: true,
      isCustomValidationRequred: true,
      inputVariant: InputVariant.outlined,
      inputType: InputTypes.password,
      customValidationType: customValidationType.passwordValidation,
      isValidInput: false,
      isTouched: false,
      errorMessage: "",
      label: "Password",
      radioGroupValues: [],
      dropdownValues: [],
      slectedDate: null,
      isPasswordHidden: true,
    },
  ],
  isValidForm: false,
};

const RegistrationForm: React.FC<RegistrationProps> = ({
  onRegister,
  onSelectLogin,
}) => {
  const navigate = useNavigate();

  const [values, setValues] = useState<RegFormState>(
    registrationFormInitalState
  );

  // const [passwordStrength, setPasswordStrength] = useState<number>(0);
  // <PasswordStrengthMeter strength={passwordStrength} />

  // const calculatePasswordStrength = (password: string): number => {
  //   // Use the zxcvbn library to calculate the password strength
  //   // The result is an object with various properties, including a 'score' property
  //   // The 'score' property is a number between 0 and 4, where 0 means the password is weak, and 4 means it's strong
  //   // You can customize the strength levels based on your needs
  //   const { score } = zxcvbn(password);
  //   return score;
  // };

  const [isAgreed, setAgreeState] = useState<boolean>(false);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const { id, value } = event.target;

    if (id === "password") {
      const strength = calculatePasswordStrength(value);
      // setPasswordStrength(strength);
    }

    const updatedStateArray = updateFormInputState(event, values.form);
    setValues({
      ...values,
      form: updatedStateArray,
    });
  };

  const handleFormSubmision = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    let isValidForm = validateForm(values.form);
    let createRegMethod: CreateAccount = {
      emailId: "",
      password: "",
      firstName: "",
      lastName: "",
      mobileNo: "",
      using2FA: false,
      agreeTC: false,
    };

    for (let element of values.form) {
      createRegMethod["emailId"] =
        element.id === "email" ? element.value : createRegMethod.emailId;
      createRegMethod["password"] =
        element.id === "password" ? element.value : createRegMethod.password;
      createRegMethod["firstName"] =
        element.id === "firstName" ? element.value : createRegMethod.firstName;
      createRegMethod["lastName"] =
        element.id === "lastName" ? element.value : createRegMethod.lastName;
      createRegMethod["mobileNo"] =
        element.id === "mobileNumber"
          ? element.value
          : createRegMethod.mobileNo;
      createRegMethod["agreeTC"] = isAgreed;
    }
    onRegister(createRegMethod);
  };

  const toggleAgreeState = () => {
    setAgreeState(!isAgreed);
  };
  

  return (
    <form
      name={"Customer Registration"}
      html-for={"customer resgistraion"}
      autoComplete="off"
    >
      <FormBuilder
        formElements={values.form}
        onInputChange={handleInputChange}
        onChangeDate={() => {}}
        onSelectValueChange={() => {}}
      />
      <TermsConditionContainer>
        {isAgreed ? (
          <SelectedCheckbox onClick={toggleAgreeState} />
        ) : (
          <EmptyCheckbox onClick={toggleAgreeState} />
        )}
        <TermsText>
          Agree to{" "}
          <span
            onClick={() => {
              navigate(RouterPath.termsAndCondition);
            }}
          >
            terms and conditions
          </span>
        </TermsText>
      </TermsConditionContainer>
      <Button
        appBtnType={AppButtonType.primaryBtn}
        disabled={false}
        fullWidth={true}
        size={ButtonSizeVariant.medium}
        variant={ButtonVariant.contained}
        type={ButtonType.submit}
        clicked={handleFormSubmision}
      >
        Create
      </Button>
      <LoginAccountContainer>
        Need Help?
        <LoginAccountOption onClick={() => navigate(RouterPath.contactUs)}>
          Contact Us
        </LoginAccountOption>
        <br />
        Already have an account?
        <LoginAccountOption onClick={onSelectLogin}>Login</LoginAccountOption>
      </LoginAccountContainer>
      
    </form>
  );
};

export default RegistrationForm;
