import { FC, useMemo, useState } from "react";
import { transformDate } from "../../Utility/Utility";
import * as Style from "./StyledLotteryCollapse";
import styled from "styled-components";

interface OrderObj {
  purchaseId: number | null | string;
  lotteryGameNo: number | string | null;
  auctionNo: number | string | null;
  auctionTitle: number | string | null;
  userId: number | null | string;
  firstName: number | null | string;
  lastName: number | null | string;
  purchaseDate: any;
  paymentId: number | null | string;
  ticketType: number | null | string;
  noOfTickets: number | null | string;
  ticketAmount: number | null | string;
  ticketId: number | null | string;
  ticketNo: number | null | string;
  paymentType: number | null | string;
  ticketPrice: number | null | string;
  rewardType: number | null | string;
  rewardAmount: number | null | string;
  rewardGiftName: number | null | string;
  winnerStatus: number | null | string;
  winnerAnnounceDate: any;
  lotteryGameStatus: number | null | string;
  isExpanded: boolean;
  ticketUrl: string | null;
}

interface LotteryCollapseProps {
  data: OrderObj[];
  onToggle(id: any): void;
}

const LotteryCollapse: FC<LotteryCollapseProps> = ({ data, onToggle }): any => {
  // console.log(data)

  let CollapseView = data.map((lotteryObj: any, index) => {
    // console.log(lotteryObj);
    let totalTickets =
      lotteryObj?.noOfTickets === null ? 1 : lotteryObj?.noOfTickets;
    let color =
      lotteryObj.winnerStatus === null
        ? "green"
        : lotteryObj.winnerStatus === "Winner"
        ? "#D1B000"
        : lotteryObj.winnerStatus === "Better Luck next Time"
        ? "#CD7F32"
        : lotteryObj.winnerStatus === "Auction Cancelled" ||
          lotteryObj.winnerStatus === "Lottery Cancelled"
        ? "red"
        : "#003459";
    return (
      <Style.Wrapper
        onClick={() => {
          onToggle(lotteryObj.ticketNo);
        }}
      >
        <Style.CollapseBtn>
          <Style.ChevronDown />
          <Style.Value width={"30%"} textAlign={"center"}>
            {lotteryObj.lotteryGameNo
              ? lotteryObj.lotteryGameNo
              : lotteryObj.auctionNo}
          </Style.Value>
          <Style.Value width={"20%"} textAlign={"center"}>
            {lotteryObj.ticketNo}
          </Style.Value>
          <Style.Value width={"15%"} textAlign={"center"}>
            {lotteryObj.rewardType
              ? lotteryObj.rewardType === "M"
                ? lotteryObj.rewardAmount
                : lotteryObj.rewardGiftName
              : lotteryObj.auctionTitle}
          </Style.Value>
          <Style.Value width={"25%"} textAlign={"center"} color={color}>
            {lotteryObj.winnerStatus === null
              ? "Yet to Announce"
              : lotteryObj.winnerStatus}
          </Style.Value>
          <Style.Value width={"30%"} textAlign={"right"}>
            {transformDate(
              lotteryObj.purchaseDate === null
                ? new Date()
                : lotteryObj.purchaseDate
            )}
          </Style.Value>
        </Style.CollapseBtn>
        {lotteryObj.isExpanded && (
          <Style.CollapseBody>
            <Style.TicketDetails>
              <Style.TicketDetail>
                <Style.TicketDetailTitle>Ticket Amount</Style.TicketDetailTitle>
                <Style.TicketDetailValue>
                  ${lotteryObj?.ticketPrice}
                </Style.TicketDetailValue>
              </Style.TicketDetail>
              {/* <Style.TicketDetail>
                <Style.TicketDetailTitle>Total Tickets</Style.TicketDetailTitle>
                <Style.TicketDetailValue>
                  {totalTickets}
                </Style.TicketDetailValue>
              </Style.TicketDetail>
              <Style.TicketDetail>
                <Style.TicketDetailTitle>Total Amount</Style.TicketDetailTitle>
                <Style.TicketDetailValue>
                  ${lotteryObj?.ticketPrice * totalTickets}
                </Style.TicketDetailValue>
              </Style.TicketDetail> */}
              <Style.TicketDetail>
                <Style.TicketDetailTitle>Ticket Type</Style.TicketDetailTitle>
                <Style.TicketDetailValue>
                  {lotteryObj.ticketType === "B"
                    ? "Bronze Ticket"
                    : lotteryObj.ticketType === "G"
                    ? "Gold Ticket"
                    : lotteryObj.ticketType === "P"
                    ? "Platinum Ticket"
                    : "Silver Ticket"}
                </Style.TicketDetailValue>
              </Style.TicketDetail>
              <Style.TicketDetail>
                <Style.TicketDetailTitle>Payment Type</Style.TicketDetailTitle>
                <Style.TicketDetailValue>
                  {lotteryObj.paymentType}
                </Style.TicketDetailValue>
              </Style.TicketDetail>
              <Style.TicketDetail>
                <Style.TicketDetailTitle>
                  {lotteryObj?.auctionStatus === "CL"? "Refund Status":"Winner Announce Date"}
                </Style.TicketDetailTitle>
                <Style.TicketDetailValue>
                  {lotteryObj?.auctionStatus === "CL"? lotteryObj?.refundStatus :
                  transformDate(
                    lotteryObj.winnerAnnounceDate === null
                      ? new Date()
                      : lotteryObj.winnerAnnounceDate
                  )}
                </Style.TicketDetailValue>
              </Style.TicketDetail>
            </Style.TicketDetails>
            <Style.TicketImage>
              {lotteryObj.ticketUrl === null ? (
                <Style.NullImageContainer>
                  <div style={{ textAlign: "center" }}>
                    <h1>Ticket Number</h1>
                    <h2>{lotteryObj.ticketNo}</h2>
                  </div>
                </Style.NullImageContainer>
              ) : (
                <Style.ImageContainer>
                  <a href={lotteryObj.ticketUrl}>
                    <img
                      src={lotteryObj.ticketUrl}
                      width="450px"
                      height=""
                      alt="ticket"
                    />
                  </a>
                </Style.ImageContainer>
              )}
            </Style.TicketImage>
          </Style.CollapseBody>
        )}
      </Style.Wrapper>
    );
  });

  return (
    <>
      {data.length > 0 ? (
        <Style.Values>{CollapseView}</Style.Values>
      ) : (
        <Style.EmptyView>
          <h3>No Data Found.</h3>
        </Style.EmptyView>
      )}
    </>
  );
};

export default LotteryCollapse;
