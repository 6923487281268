import {FC, useEffect} from 'react';

import * as Styled from '../Success/StyledSuccess';
import { useDispatch } from 'react-redux';
import { setPaymementStatus } from '../../../../features/auctionList';
const CreateAuctionPaymentFail:FC = () => {
    const dispatch= useDispatch();
    useEffect(()=>{
        dispatch(setPaymementStatus({
            isPaymentComplete:false,
            isPaymentIncomplete:false
        }))
    },[])
    return <Styled.Wrapper>
        <Styled.SectionWrapper>
            <Styled.Title>
                Payment Failed!
            </Styled.Title>
            <Styled.Subtitle>
                Payment is failed due to following reason:
               <Styled.ImageSection>
               <ol>
                    <li>
                    Transaction cannot be authorized
                    </li>
                    <li>
                    Incorrect OTP or Password
                    </li>
                    <li>
                    Signature validation failed
                    </li>
                    <li>
                    Insufficient balance in the account
                    </li>
                </ol>
               </Styled.ImageSection>
            </Styled.Subtitle>
        </Styled.SectionWrapper>
    </Styled.Wrapper>
};

export default CreateAuctionPaymentFail