import React from 'react'
import { Wrapper, Container, Messege1,Messege2, Section, SectionButtons, Title} from './StylePageNotFound'
import { useNavigate } from 'react-router-dom'
import { RouterPath } from '../../routes/routes' 
import Button from "../UI/Buttons/Button";
import {
    ButtonSizeVariant,
    ButtonType,
    ButtonVariant,
    AppButtonType,
  } from "../../Utility/InterFacesAndEnum";


const PageNotFound = () => {
    const navigate = useNavigate();

  return (
    <Wrapper>

    <Container>
        <Section >

        <Title>404</Title>
        <Messege1>Oops!!</Messege1>
        <Messege2>We could not find the page.</Messege2>
        <SectionButtons>
        <Button
          
          disabled={false}
          appBtnType={AppButtonType.primaryBordered}
          fullWidth={false}
          variant={ButtonVariant.contained}
          type={ButtonType.default}
          size={ButtonSizeVariant.small}
          clicked={() => {
            navigate("/");
          }}
        >
          Home
        </Button>
        <Button
          
          disabled={false}
          appBtnType={AppButtonType.primaryBtn}
          fullWidth={false}
          variant={ButtonVariant.contained}
          type={ButtonType.default}
          size={ButtonSizeVariant.small}
          clicked={() => {
            navigate(RouterPath.contactUs);
          }}
        >
          ContactUs
        </Button>
            {/* <Button onClick={()=>navigate("/")}>Home</Button>
            <Button onClick={()=>navigate(RouterPath.contactUs)}>Contact Us</Button> */}
        </SectionButtons>
        </Section>
    </Container>
    </Wrapper>
  )
}

export default PageNotFound