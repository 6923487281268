import React, { Fragment } from "react";
import { Wrapper, FooterWapper } from "./StyledAppWrapper";
import Navigation from "../../components/Navigation/Navigation";
import Notification from "../../components/Notification/Notification";
import Footer from "../../containers/Footer/Footer";
import { RouterPath } from "../../routes/routes";
import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

let footerExcludeUrl = [
  RouterPath.resendResetLink,
  RouterPath.resetPassword,
  RouterPath.signIn,
  RouterPath.signUp,
  RouterPath.twoFA,
  RouterPath.forgotPassword,
  RouterPath.paypalSuccess,
  RouterPath.paypalFail,
  RouterPath.auctionPaypalSuccess,
  RouterPath.auctionPaypalFail,
  RouterPath.loadMoneyPaymentSucess,
  RouterPath.coinPaymentSuccess


];

let headerExcludeUrl = [
  RouterPath.paypalSuccess,
  RouterPath.paypalFail,
  RouterPath.auctionPaypalSuccess,
  RouterPath.auctionPaypalFail,
  RouterPath.loadMoneyPaymentSucess,
  RouterPath.coinPaymentSuccess
];

const AppWrapper: React.FC = ({ children }) => {
  let params = useLocation();
  let isLoading = useSelector((state: RootState) => state.loader.isLoading);
  let FooterView = <Fragment></Fragment>;

  let header = <Fragment></Fragment>;

  if (headerExcludeUrl.indexOf(params.pathname) === -1) {
    header = <Navigation />;
  }

  if (footerExcludeUrl.indexOf(params.pathname) === -1) {
    FooterView = (
      <FooterWapper>
        <Footer />
      </FooterWapper>
    );
  }

  return (
    <Wrapper>
      <Loader isLoading={isLoading} />
      <Notification />
      {header}
      {children}
      {FooterView}
    </Wrapper>
  );
};

export default AppWrapper;
