import { FC, Fragment, useEffect, useState } from "react";
import LiveLottery from "../../components/LiveLottery/LiveLottery";
import Promotion from "../Promotions/Promotion";
import UpcomingLottery from "../../components/UpcomingLottery/UpcomingLottery";
import { getLocalStorage } from "../../localStorage/GetLocalStorage";

import {
  LotteryContainer,
  BannerImg,
  UpcomingLotteryContainer,
  Lottery,
  SectionTitle,
  Wrapper,
} from "./StyledLotteries";
import BannerImgUrl from "../../assets/BANNER-LOTTERY-SECTION.jpg";
import { useSelector, useDispatch } from "react-redux";
import {
  getLotteries,
  getUpcomingLotteries,
} from "../../features/lotteriesSlice";
import { RootState } from "../../app/store";

import * as localStorageActionType from "../../localStorage/ActionTypes";
import { setLocalStorage } from "../../localStorage/SetLocalStorage";

import BuyLotteryModal from "../../components/Modals/BuyTicketModal/BuyTicketModal";
import { useNavigate } from "react-router-dom";
import { RouterPath } from "../../routes/routes";
import {
  notifyMe,
  toggleNotificationVisibility,
} from "../../features/notificationSlice";
import { NotificationType } from "../../Utility/InterFacesAndEnum";
import { getUserProfileDetail } from "../../features/userProfileSlice";
import { getPromotionData } from "../../features/promotion";
import Sliders from "../../components/Swipper/Swipper";

import { Helmet } from "react-helmet";


interface SelectedTicket {
  label: string;
  amount: number;
  selected: boolean;
  ticketType: string;
}

const Lotteries: FC = () => {
  const dispatch = useDispatch();
  const [isBuyTicketModalVisible, setModalVisibility] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<SelectedTicket[] | []>(
    []
  );

  //** Demo date  */
  let targetDate = new Date("2023-12-31T23:59:59");

  const navigate = useNavigate();
  const isLoggedIn = getLocalStorage(localStorageActionType.GET_IS_LOGGED_IN);
  const isGoldMember = useSelector(
    (state: RootState) => state.userProfile.userDetail?.isGoldMember
  );
  const { lotterySlider } = useSelector((state: RootState) => state.promotion);

  // console.log(isGoldMember)

  const publicUserId = getLocalStorage(
    localStorageActionType.GET_PUBLIC_USER_ID
  );
  let userDetails = getLocalStorage(localStorageActionType.GET_USER_DETAILS);
  // console.log(Object.keys(userDetails).length!==0 ? JSON.parse(userDetails) : "userDetails not found");
  // let isGoldMember =
  //   Object.keys(userDetails).length !== 0
  //     ? JSON.parse(userDetails).isGoldMember
  //     : false;
  let lotteriesList = useSelector(
    (state: RootState) => state.lotteries.lotteries
  );
  const sortedLiveLotteries = [...lotteriesList].sort((a, b) => {
    const dateA: any = new Date(a.lotteryGameEndDate);
    const dateB: any = new Date(b.lotteryGameEndDate);
    return dateA - dateB;
  });

  let upcomingLotteries = useSelector(
    (state: RootState) => state.lotteries.upcomingLotteries
  );
  const sortedUpcommingLotteries = [...upcomingLotteries].sort((a, b) => {
    const dateA: any = new Date(a.lotteryGameStartDate);
    const dateB: any = new Date(b.lotteryGameStartDate);
    return dateA - dateB;
  });

  useEffect(() => {
    dispatch(getLotteries());
    dispatch(getUpcomingLotteries());
    dispatch(getUserProfileDetail());
    dispatch(getPromotionData("LOTTERY"));
  }, []);

  const onSelectBuyTicket = (id: number) => {
    let selectedTicketObj = lotteriesList.filter((lotteryObj) => {
      return id === lotteryObj.lotteryGameId;
    })[0];

    let ticketsArray = [
      {
        label: "Bronze Ticket",
        amount: selectedTicketObj.bronzeTicketPrice,
        selected: false,
        ticketType: "bronze",
      },
      {
        label: "Silver Ticket",
        amount: selectedTicketObj.silverTicketPrice,
        selected: false,
        ticketType: "silver",
      },
      {
        label: "Gold Ticket",
        amount: selectedTicketObj.goldTicketPrice,
        selected: true,
        ticketType: "gold",
      },
      {
        label: "Platinum Ticket",
        amount: selectedTicketObj.platinumTicketPrice,
        selected: false,
        ticketType: "Platinum",
      },
    ];

    let updatedObj = {
      ...selectedTicketObj,
      lotteryGameEndDate: new Date(selectedTicketObj.lotteryGameEndDate)
        .getTime()
        .toString(),
    };

    setSelectedTicket(ticketsArray);
    setLocalStorage(
      localStorageActionType.SET_SELECTED_LOTTERY_OBJ,
      updatedObj
    );
    if (isLoggedIn) {
      setModalVisibility(true);
    } else {
      navigate(RouterPath.signIn);
    }
  };

  const onSelectGoldMembership = (ticketObj: any) => {
    if (isLoggedIn) {
      navigate(RouterPath.membership);
    } else {
      navigate(RouterPath.signIn);
    }
  };

  let buyModal =
    selectedTicket.length > 0 ? (
      <BuyLotteryModal
        ticket={selectedTicket}
        label={"Buy tickets"}
        isVisible={isBuyTicketModalVisible}
        toggleModal={() => {
          setModalVisibility(!isBuyTicketModalVisible);
        }}
      />
    ) : (
      <div></div>
    );

  const onSelectyNotifyMe = (id: number) => {
    isLoggedIn
      ? // alert(id)
        upcomingLotteries.map((obj) => {
          if (obj.lotteryGameId === id) {
            dispatch(
              notifyMe({
                publicUserId: publicUserId,
                notifyType: "L",
                lotteryGameId: id,
              })
            );
          }
        })
      : navigate(RouterPath.signIn);
  };

  return (
    <Fragment>
      <Helmet>
        <title>KingsRings - Lotteries</title>
      </Helmet>
      {buyModal}
      <Wrapper>
        {/* <Promotion pageName={"LOTTERY"} /> */}
        <Sliders sliderList={lotterySlider} />
        {!isGoldMember && (
          <BannerImg
            src={BannerImgUrl}
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(isLoggedIn ? RouterPath.membership : RouterPath.signIn)
            }
          />
        )}
      </Wrapper>
      <LotteryContainer>
        {lotteriesList.length > 0 ? (
          <SectionTitle>Live lotteries</SectionTitle>
        ) : (
          <SectionTitle>No Live Lotteries</SectionTitle>
        )}
        {isGoldMember
          ? sortedLiveLotteries.map((lotteryObj) => {
              return (
                <LiveLottery
                  isGoldMember={isGoldMember}
                  lotteryNumber={lotteryObj.lotteryGameNo}
                  lotteryType={lotteryObj.rewardType}
                  id={lotteryObj.lotteryGameId}
                  lotteryPrice={
                    lotteryObj.rewardType === "G"
                      ? lotteryObj.rewardGiftName
                      : lotteryObj.rewardAmount
                  }
                  onSelectBuy={onSelectBuyTicket}
                  onSelectGoldMembership={onSelectGoldMembership}
                  lotteryEndTime={lotteryObj.lotteryGameEndDate}
                  //** Demo date */
                  // lotteryEndTime={targetDate}
                  entryPrice={lotteryObj.bronzeTicketPrice}
                  usersCound={lotteryObj.noOfUsersJoined}
                  drawDate={lotteryObj.lotteryGameEndDate}
                  rewardImages={lotteryObj?.rewardImages}
                  isMemberLottery={lotteryObj.isMemberLottery}
                />
              );
            })
          : sortedLiveLotteries.map((lotteryObj) => {
              return (
                !lotteryObj.isMemberLottery && (
                  <LiveLottery
                    isGoldMember={isGoldMember}
                    lotteryNumber={lotteryObj.lotteryGameNo}
                    lotteryType={lotteryObj.rewardType}
                    id={lotteryObj.lotteryGameId}
                    lotteryPrice={
                      lotteryObj.rewardType === "G"
                        ? lotteryObj.rewardGiftName
                        : lotteryObj.rewardAmount
                    }
                    onSelectBuy={onSelectBuyTicket}
                    onSelectGoldMembership={onSelectGoldMembership}
                    lotteryEndTime={lotteryObj.lotteryGameEndDate}
                    //** Demo date */
                    // lotteryEndTime={targetDate}
                    entryPrice={lotteryObj.bronzeTicketPrice}
                    usersCound={lotteryObj.noOfUsersJoined}
                    drawDate={lotteryObj.lotteryGameEndDate}
                    rewardImages={lotteryObj?.rewardImages}
                    isMemberLottery={lotteryObj.isMemberLottery}
                  />
                )
              );
            })}
        <br />
        {upcomingLotteries.length > 0 ? (
          <SectionTitle>Upcoming lotteries</SectionTitle>
        ) : (
          <SectionTitle>No Upcomming Lotteries</SectionTitle>
        )}
        <UpcomingLotteryContainer>
          {isGoldMember
            ? sortedUpcommingLotteries.map((lotteryObj) => {
                return (
                  <Lottery>
                    <UpcomingLottery
                      lotteryId={lotteryObj.lotteryGameId}
                      reward={
                        lotteryObj.rewardType === "M"
                          ? lotteryObj.rewardAmount
                          : lotteryObj.rewardGiftName
                      }
                      gameType={lotteryObj.rewardType}
                      gameStartsOn={lotteryObj.lotteryGameStartDate}
                      entryTicket={lotteryObj?.bronzeTicketPrice}
                      onClickNotifyMe={() =>
                        onSelectyNotifyMe(lotteryObj.lotteryGameId)
                      }
                      rewardImages={lotteryObj?.rewardImages}
                      isMemberLottery={lotteryObj.isMemberLottery}
                    />{" "}
                  </Lottery>
                );
              })
            : sortedUpcommingLotteries.map((lotteryObj) => {
                return (
                  !lotteryObj.isMemberLottery && (
                    <Lottery>
                      <UpcomingLottery
                        lotteryId={lotteryObj.lotteryGameId}
                        reward={
                          lotteryObj.rewardType === "M"
                            ? lotteryObj.rewardAmount
                            : lotteryObj.rewardGiftName
                        }
                        gameType={lotteryObj.rewardType}
                        gameStartsOn={lotteryObj.lotteryGameStartDate}
                        //** Demo date */
                        // gameStartsOn={targetDate}
                        entryTicket={lotteryObj.bronzeTicketPrice}
                        onClickNotifyMe={() =>
                          onSelectyNotifyMe(lotteryObj.lotteryGameId)
                        }
                        rewardImages={lotteryObj?.rewardImages}
                        isMemberLottery={lotteryObj.isMemberLottery}
                      />{" "}
                    </Lottery>
                  )
                );
              })}
        </UpcomingLotteryContainer>
      </LotteryContainer>
    </Fragment>
  );
};

export default Lotteries;
