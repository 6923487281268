import {FC, useEffect, useState} from 'react';
import RegistratoinForm from '../../Forms/Registration/Registration';
import AuthWrapper from '../AuthWrapper/AuthWrapper';
import Loader from '../../../components/Loader/Loader';
import {useDispatch, useSelector} from 'react-redux';
import {createSignup, toggleRegister} from '../../../features/registrationSlice';
import { RootState } from '../../../app/store';
import {FeatierArrowLeft} from '../../../icons';
import {useNavigate} from 'react-router-dom';
import {RouterPath} from '../../../routes/routes';
import { Helmet } from 'react-helmet';

import {NotificationType} from '../../../Utility/InterFacesAndEnum';
import {toggleNotificationVisibility} from '../../../features/notificationSlice';

import {validateSignup} from '../../../Utility/formValidation';

import {StyledWrapper, StyledFormContainer, StyledFormHeader, StyledIconContainer} from './StylesSignup';

import RegImgUrl from '../../../assets/regImage.png';

interface CreateAccount {
    emailId: string,
    password: string,
    firstName:string,
    lastName:string,
    using2FA: boolean,
    agreeTC:boolean,
    mobileNo:string
}
const SignUp:FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isLoading = useSelector((state: RootState) => state.register.isLoading);
    const isSignedUp = useSelector((state: RootState) => state.register.isAccountCreated);

    // const [passwordStrength, setPasswordStrength] = useState(0);

    // const calculatePasswordStrength = (password: string): number => {
    //     let score = 0;
    //     if (!password) {
    //       return score;
    //     }
      
    //     // Award points for length
    //     score += Math.min(10, password.length);
      
    //     // Award points for uppercase letters
    //     const upperCaseRegex = /[A-Z]/g;
    //     const upperCaseMatches = password.match(upperCaseRegex) || [];
    //     score += Math.min(5, upperCaseMatches.length * 5);
      
    //     // Award points for lowercase letters
    //     const lowerCaseRegex = /[a-z]/g;
    //     const lowerCaseMatches = password.match(lowerCaseRegex) || [];
    //     score += Math.min(5, lowerCaseMatches.length * 5);
      
    //     // Award points for digits
    //     const digitRegex = /\d/g;
    //     const digitMatches = password.match(digitRegex) || [];
    //     score += Math.min(5, digitMatches.length * 5);
      
    //     // Award points for special characters
    //     const specialCharRegex = /[^\w\s]/g;
    //     const specialCharMatches = password.match(specialCharRegex) || [];
    //     score += Math.min(5, specialCharMatches.length * 5);
      
    //     return score;
    //   };
      

    const customerRegistrationHandler = (obj:CreateAccount):void => {
        let validatedObj = validateSignup(obj);
        console.log(validatedObj,"validateSignup")
        if (validatedObj.status === true) {
            dispatch(createSignup(validatedObj.requestObj))
        } else {
            dispatch(toggleNotificationVisibility({
                isVisible: true,
                status: NotificationType.error,
                message: validatedObj.message
            }));
        }
        
    };

    const routeTologin = () => {
        navigate(RouterPath.signIn);
    }

    useEffect(() => {
        if (isSignedUp === true) {
            navigate(RouterPath.signIn);
        }
        return () => {
            dispatch(toggleRegister({
                isRegistered: false
            }));
        }
    },[isSignedUp])

    return <StyledWrapper>
        <Helmet>
            <title>KingsRings - SignUp</title>
        </Helmet>
        <Loader isLoading={isLoading} />
        <AuthWrapper imgUrl={RegImgUrl} altText={"registration image"} >
            <StyledFormContainer>
                <StyledFormHeader>
                    <StyledIconContainer onClick={routeTologin}>
                        <FeatierArrowLeft />
                    </StyledIconContainer>
                    Create an account
                </StyledFormHeader>
                <RegistratoinForm onRegister={customerRegistrationHandler} onSelectLogin={routeTologin} /> 
            </StyledFormContainer>
            </AuthWrapper>
        </StyledWrapper>
};

export default SignUp