import { FC, useEffect, useMemo, useState } from "react";

import Collapse from "../../../components/LotteryCollapse/LotteryCollapse";

import { downloadPurchaseList, getCoinOrders, getLotteryOrders } from "../../../features/ordersSlice";
import { useSelector, useDispatch } from "react-redux";
import { setOrders } from "../../../features/ordersSlice";
import { RootState } from "../../../app/store";
import Button from "../../../components/UI/Buttons/Button";


import * as Style from "./StyledCoinOrder";
import { transformDate } from "../../../Utility/Utility";
import { getLocalStorage } from "../../../localStorage/GetLocalStorage";
import * as localStorageActionType from "../../../localStorage/ActionTypes";
import { DownloadListBtn, TabMenuContainer } from "../Lottery/StyledLotteries";
import { AppButtonType, ButtonSizeVariant, ButtonType, ButtonVariant } from "../../../Utility/InterFacesAndEnum";


const lotteryTabMenu = [
  {
    label: "Additional",
    isActive: true,
    id: 1,
    filterParam: "",
  },
//   {
//     label: "Executed Auction",
//     isActive: false,
//     id: 2,
//     filterParam: "",
//   },
];
interface PaymentIdProp {
  paymentIds: [];
}
const CoinPurchaseList: FC = () => {
  const dispatch = useDispatch();

  const [tabMenu, setTabMenu] = useState(lotteryTabMenu);

  const [liveLottery, setLiveLottery] = useState([]);
  const [executedLottery, setExecutedLottery] = useState([]);
  const [activeTabMenuId, setMenuId] = useState(1);
  const [paymentId, setPaymentId] = useState<PaymentIdProp>();
  const publicUserId = getLocalStorage(
    localStorageActionType.GET_PUBLIC_USER_ID
  );

  const lotteryList = useSelector(
    (state: RootState) => state.orders.lotteryOrders
  );
  const CoinList = useSelector(
    (state: RootState) => state.orders.coinOrders
  );

  // console.log(CoinList)

  useEffect(() => {
    dispatch(getCoinOrders());
  }, []);

  useEffect(() => {
    // console.log(1)
    let arr: any = [];
    if (activeTabMenuId === 1) {
      CoinList.map((coin: any) => {
        // console.log(lottery.paymentId);
        arr.push(coin.paymentId);
      });
    } 
    setPaymentId({
      paymentIds: arr,
    });
  }, [CoinList, activeTabMenuId]);
  console.log(paymentId)
  const downloadListHandler = () => {
    dispatch(downloadPurchaseList({
      publicUserId: publicUserId,
      paymentIds: paymentId?.paymentIds,
    }))
  };

  const toggleActiveState = (id: number) => {
    setMenuId(id);
    setTabMenu((tabMenu) => {
      return tabMenu.map((menuObj) => {
        return {
          ...menuObj,
          isActive: menuObj.id === id,
        };
      });
    });
  };

  let tabMenuView = useMemo(() => {
    return tabMenu.map((menuObj, index) => {
      return (
        <Style.TabMenuItem
          onClick={() => {
            toggleActiveState(menuObj.id);
          }}
          key={`lotteryMenuItem_${menuObj.id}_${index}`}
          isActive={menuObj.isActive}
        >
          {menuObj.label}
        </Style.TabMenuItem>
      );
    });
  }, [tabMenu]);


  let tableValue = CoinList.map((coinObj:any, index) => {
    // console.log(coinObj);
    let color =
      coinObj?.coinDeliveryStatus === null
        ? "#FFB332"
        : coinObj?.coinDeliveryStatus === "RECIEVED"
        ? " #003459"
        :coinObj?.coinDeliveryStatus === "SHIPPING"? "#CD7F32" : "#D1B000";
    return (
      
        <Style.CoinTable key={index}>
          <Style.Value width={"30%"} textAlign={"center"}>
            {coinObj?.coinCode}
          </Style.Value>
          <Style.Value width={"30%"} textAlign={"center"}>
            {coinObj?.coinTitle}
          </Style.Value>
          <Style.Value width={"20%"} textAlign={"center"}>
            {coinObj?.purchaseSlNo}
          </Style.Value>
          <Style.Value width={"15%"} textAlign={"center"}>
            ${coinObj?.coinPrice}
          </Style.Value>
          <Style.Value width={"25%"} textAlign={"center"} color={color}>
            {coinObj.winnerStatus === null
              ? "Yet to Announce"
              : coinObj?.coinDeliveryStatus            }
          </Style.Value>
          <Style.Value width={"30%"} textAlign={"right"}>
            {transformDate(
              coinObj?.purchaseDate === null
                ? new Date()
                : coinObj?.purchaseDate
            )}
          </Style.Value>
        </Style.CoinTable>
    )
  })

  return (
    <Style.Wrapper>
      <TabMenuContainer>
        <Style.TabMenuWrapper>{tabMenuView}</Style.TabMenuWrapper>
        {/* <DownloadListBtn>
          <Button
            disabled={false}
            appBtnType={AppButtonType.primaryBtn}
            fullWidth={false}
            variant={ButtonVariant.text}
            type={ButtonType.default}
            size={ButtonSizeVariant.small}
            clicked={downloadListHandler}
          >
            Export
          </Button>
        </DownloadListBtn> */}
      </TabMenuContainer>
      <Style.TableOverflowX>
        <Style.Table>
          <Style.CollapseHeader>
            <Style.Label width={"30%"} textAlign={"center"}>
              Code
            </Style.Label>
            <Style.Label width={"30%"} textAlign={"center"}>
              Title
            </Style.Label>
            <Style.Label width={"20%"} textAlign={"center"}>
            PurchaseSlNo
            </Style.Label>
            <Style.Label width={"15%"} textAlign={"center"}>
              Price
            </Style.Label>
            <Style.Label width={"25%"} textAlign={"center"}>
              Status
            </Style.Label>
            <Style.Label width={"30%"} textAlign={"right"}>
              Purchased Date
            </Style.Label>
          </Style.CollapseHeader>
          <Style.Values>
          {tableValue}

          </Style.Values>
        </Style.Table>
      </Style.TableOverflowX>
    </Style.Wrapper>
  );
};

export default CoinPurchaseList;
