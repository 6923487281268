import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 50px;
  border-radius: 4px;
  @media (max-width: 400px) {
    padding: 20px;
  }
`;

export const Contactus = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  /* box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.2), 0 20px 20px 0 rgba(0, 0, 0, 0.19); */
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const ContactBox = styled.div`
  padding-top: 20px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 70px;
`;

export const ContactDetails = styled.div``;

export const Title = styled.h1`
  font-size: 50px;
  text-align: center;
  color: rgb(100, 70, 70);
  @media (max-width: 640px) {
    font-size: 40px;
  }
  @media (max-width: 500px) {
    font-size: 30px;
  }
`;

export const ImgContainer = styled.div`
  img {
    height: 200px;
    @media (max-width: 500px) {
      height: 150px;
    }
  }
`;

export const AnchorTag = styled.a`
  color: rgb(100, 70, 70);
  text-decoration: none;
  &:hover {
    color: rgb(85, 85, 194);
  }
`;

export const DetailSection = styled.div`
  display: flex;
  color: rgb(100, 70, 70);
  opacity: 0.9;

  font-size: 30px;
  font-weight: 500;
  @media (max-width: 640px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 15px;
  }
`;
export const DetailIcon = styled.span`
  margin-right: 5px;
  margin-top: 6px;
  font-size: 30px;
  @media (max-width: 640px) {
    font-size: 20px;
    margin-top: 5px;
  }
  @media (max-width: 500px) {
    font-size: 15px;
    margin-top: 3px;
  }
`;

export const Icon = styled.div`
  color: rgb(43, 41, 41);
`;
