import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';
import * as endpoints from '../networkUtilities/endpoints';

import * as localStorageActiontype from '../localStorage/ActionTypes';
import {getLocalStorage} from '../localStorage/GetLocalStorage';
import { getUserDetailes } from './loadMoneySlice';
import { getUserProfileDetail } from './userProfileSlice';
import { toggleLoader } from './loaderSlice';


interface UploaderState{
    images: any,
    imageNames:any
}

interface ImageObj {
    documentName: string
fileDownloadUri: string
fileId: string
fileName: string
fileSize: number
fileType: string
}

interface SetImages {
    data: string[],
    name:string[]
}

const imageUploaderInitialState: UploaderState = {
    images:[],
    imageNames:[]
}

export const uploadImage = createAsyncThunk(
    'upload image',
    async (payload:any, {dispatch}) => {
        dispatch(toggleLoader({isLoading:true}));
        await fetch(endpoints.uploadImage,{
            method: "POST",
            headers:{
                Authorization: `Bearer ${getLocalStorage(localStorageActiontype.GET_ACCESS_TOKEN)}`,
            },
            body:payload
        })
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            dispatch(toggleLoader({isLoading:false}));
            let image:ImageObj = response
            // if (response.statusCode === 200) {
                let responseUrl:string[] = [];
                let responseNames : string[]=[];
                
                    let imageString = image.fileDownloadUri;
                    let imageName = image.fileName;
                    responseUrl.push(imageString)
                    responseNames.push(imageName);
                
                dispatch(setImages({
                    data:responseUrl,
                    name:responseNames
                }))
                dispatch(getUserDetailes());
                dispatch(getUserProfileDetail());
            // }
        }).catch((err)=>{
            dispatch(toggleLoader({isLoading:true}));
            return err;
        })
    }
)

const ImageUploaderSlice = createSlice({
    name: 'Image uploader',
    initialState: imageUploaderInitialState,
    reducers:{
        setImages:(state, action:PayloadAction<SetImages>) => {
            return {
                ...state,
                images: state.images.concat(action.payload.data),
                imageNames:state.imageNames.concat(action.payload.name)

            }
        },
        deleteImage:(state,action:PayloadAction<{imgUrl:string}>) => {
            const parts = action.payload.imgUrl.split("/");
            const fileName = parts[parts.length - 1];
            // console.log(action.payload.imgUrl)
            // console.log(fileName)
            let filteredImages:any = [];
            let filteredImageNames:any=[];
            if (state.images !== null && state.images.length > 0) {
                filteredImages = (state.images).filter((img:any) => {
                    return img !== action.payload.imgUrl
                })
            }
            if (state.imageNames !== null && state.imageNames.length > 0) {
                filteredImageNames = (state.imageNames).filter((img:any) => {
                    // const parts = action.payload.imgUrl.split("/");
                    // const fileName = parts[parts.length - 1];
                    return img !== fileName;
                })
            }
            return{
                ...state,
                images:filteredImages,
                imageNames:filteredImageNames
            }
        },
    }
});

export const {setImages,deleteImage} = ImageUploaderSlice.actions;
export default ImageUploaderSlice.reducer;