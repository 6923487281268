import {
  FormElement,
  customValidationType,
  FormElementType,
} from "./InterFacesAndEnum";

const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/);
// const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/);
const characterRegex = new RegExp(/^[a-zA-Z. ]*$/);
const numberRegex = new RegExp(/^\+?[0-9]*$/);
const passwordRegex = new RegExp(
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
);

export const validateEmail = (email: string): boolean => {
  return emailRegex.test(email);
};

export const validatePassword = (password: string): boolean => {
  return passwordRegex.test(password);
};

export const characterValidation = (value: string): string => {
  let updatedString = value
    .split("")
    .map((character) => {
      if (characterRegex.test(character)) {
        return character;
      } else {
        return "";
      }
    })
    .join("");
  return updatedString;
};

export const numberValidation = (value: string): string => {
  let updatedString = value
    .split("")
    .map((character) => {
      if (numberRegex.test(character)) {
        return character;
      } else {
        return "";
      }
    })
    .join("");
  return updatedString;
};

interface validationProp {
  value: string;
  errorMessage: string;
}

export const mobileNumberValidation = (value: string): validationProp => {
  let updatedNumbers = numberValidation(value);
  let message = "";

  if (updatedNumbers.length < 11) {
    message =
      "Invalid mobile number i.e contact number length should not be less than 13 with its country code.";
  }

  return {
    value: updatedNumbers.slice(0, 13),
    errorMessage: message,
  };
};

export const emailValidation = (value: string): validationProp => {
  let updatedEmail = value;
  let message = "";

  if (!emailRegex.test(updatedEmail)) {
    message = `Invalid Email,ie email should be like this : "example123@gmail.com".`;
  }
  if (updatedEmail.length > 100) {
    message = "Email should be less than 100 character";
  }
  return {
    value: updatedEmail.slice(0, 100),
    errorMessage: message,
  };
};

export const nameValidation = (value: string): validationProp => {
  let updatedName = value;
  let message = "";

  if (!characterRegex.test(updatedName)) {
    message = `Invalid Name,ie name should be in alphabets.`;
  }
  if (updatedName.length > 50) {
    message = "Name should be less than 50 character";
  }
  return {
    value: updatedName.slice(0, 50),
    errorMessage: message,
  };
};

export const titleValidation = (value: string): validationProp => {
  let updatedTitle = value;
  let message = "";

  if (updatedTitle.length > 100) {
    message = "Title should be less than 100 character";
  }

  return {
    value: updatedTitle.slice(0, 100),
    errorMessage: message,
  };
};

export const descriptionValidation = (value: string): validationProp => {
  let updatedDescription = value;
  let message = "";

  if (updatedDescription.length > 5000) {
    message = "Description should be less than 5000 character";
  }

  return {
    value: updatedDescription.slice(0, 5000),
    errorMessage: message,
  };
};

export const priceValidation = (value: string): validationProp => {
  let updatedPrice = value;
  let message = "";

  if (updatedPrice.length > 15) {
    message = "Price length should be less than 15.";
  }

  return {
    value: updatedPrice.slice(0, 15),
    errorMessage: message,
  };
};

export const addressValidation = (value: string): validationProp => {
  let updatedAddress = value;
  let message = "";

  if (updatedAddress.length > 100) {
    message = "Address should be less than 100 characters.";
  }

  return {
    value: updatedAddress.slice(0, 100),
    errorMessage: message,
  };
};

export const cityStateValidation = (value: string): validationProp => {
  let updatedCityStateValue = value;
  let message = "";

  if (updatedCityStateValue.length > 40) {
    message = "Should be less than 40 characters.";
  }

  return {
    value: updatedCityStateValue.slice(0, 40),
    errorMessage: message,
  };
};

export const zipValidation = (value: string): validationProp => {
  let updatedZip = value;
  let message = "";

  if (updatedZip.length > 10) {
    message = "Zip code length should be less than 10.";
  }

  return {
    value: updatedZip.slice(0, 10),
    errorMessage: message,
  };
};

interface password {
  value: string;
  isValidInput: boolean;
  errorMessage: string;
}

// export const passwordValidation = (value: string): password => {
//     let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
//     let message = "";
//     let updatedValue = value;

//     if (!passwordRegex.test(updatedValue)) {
//         message = "Password must contain at least 8 characters, including one lowercase letter, one uppercase letter, and one number"
//     }

//     return {
//         value: updatedValue,
//         isValidInput: false,
//         errorMessage: message,
//     };
// }

interface ValidatedFormObj {
  value: string;
  isValidInput: boolean;
  errorMessage: string;
}

// method to return updated form input value state
export const updateFormInputState = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  values: FormElement[]
): FormElement[] => {
  const { name, value } = event.target;
  let updatedArry: FormElement[] = values.map(
    (formObj: FormElement): FormElement => {
      if (formObj.id === name) {
        let obj = Object.assign(formObj);
        let validatedObj: ValidatedFormObj = validateFormElement(
          obj.customValidationType,
          value,
          obj.isRequired,
          formObj.isCustomValidationRequred
        );

        if (
          formObj.id === "auctionTitle" ||
          formObj.id === "rewardGiftName" ||
          formObj.id === "coinTitle" ||
          formObj.id === "emailSubject"
        ) {
          const enteredTitle = value;
          if (enteredTitle.length > 100) {
            // console.log("sorry")
          } else {
            obj["value"] = validatedObj.value;
            obj["isTouched"] = true;
            obj["errorMessage"] = validatedObj.errorMessage;
            obj["isValidInput"] = validatedObj.isValidInput;
          }
        }

        if (formObj.id === "auctionDesc" || formObj.id === "coinDescription") {
          const enteredDescription = value;
          if (enteredDescription.length > 1000) {
            // console.log("sorry")
          } else {
            obj["value"] = validatedObj.value;
            obj["isTouched"] = true;
            obj["errorMessage"] = validatedObj.errorMessage;
            obj["isValidInput"] = validatedObj.isValidInput;
          }
        }

        if (
          formObj.id === "auctionPrice" ||
          formObj.id === "auctionProposedPrice" ||
          formObj.id === "rewardAmount" ||
          formObj.id === "coinPrice" ||
          formObj.id === "bronzeTicketPrice" ||
          formObj.id === "silverTicketPrice" ||
          formObj.id === "goldTicketPrice" ||
          formObj.id === "platinumTicketPrice"
        ) {
          const enteredPrice = value;
          if (enteredPrice.length > 15) {
            // console.log("sorry")
          } else {
            obj["value"] = validatedObj.value;
            obj["isTouched"] = true;
            obj["errorMessage"] = validatedObj.errorMessage;
            obj["isValidInput"] = validatedObj.isValidInput;
          }
        }

        if (formObj.id === "address") {
          const enteredAddress = value;
          if (enteredAddress.length > 100) {
            // console.log("sorry")
          } else {
            obj["value"] = validatedObj.value;
            obj["isTouched"] = true;
            obj["errorMessage"] = validatedObj.errorMessage;
            obj["isValidInput"] = validatedObj.isValidInput;
          }
        }

        if (formObj.id === "city" || formObj.id === "state") {
          const enteredValue = value;
          if (enteredValue.length > 20) {
            // console.log("sorry")
          } else {
            obj["value"] = validatedObj.value;
            obj["isTouched"] = true;
            obj["errorMessage"] = validatedObj.errorMessage;
            obj["isValidInput"] = validatedObj.isValidInput;
          }
        }

        if (formObj.id === "pincode") {
          const enteredValue = value;
          if (enteredValue.length > 10) {
            // console.log("sorry")
          } else {
            obj["value"] = validatedObj.value;
            obj["isTouched"] = true;
            obj["errorMessage"] = validatedObj.errorMessage;
            obj["isValidInput"] = validatedObj.isValidInput;
          }
        }

        if (formObj.id === "userName") {
          const enteredValue = value;
          if (enteredValue.length > 50) {
            // console.log("sorry")
          } else {
            obj["value"] = validatedObj.value;
            obj["isTouched"] = true;
            obj["errorMessage"] = validatedObj.errorMessage;
            obj["isValidInput"] = validatedObj.isValidInput;
          }
        }

        if (formObj.id === "mobileNumber" || formObj.id === "userMobile") {
          const enterNumer = value.trim();
          if (enterNumer.length > 13) {
            // not accept number digits which is greter than 13
          } else {
            if (enterNumer.length > 0 && enterNumer.charAt(0) !== "+") {
              obj["value"] = "+" + enterNumer;
              obj["isTouched"] = true;
              obj["errorMessage"] = validatedObj.errorMessage;
              obj["isValidInput"] = validatedObj.isValidInput;
            } else {
              obj["value"] = enterNumer;
              obj["isTouched"] = true;
              obj["errorMessage"] = validatedObj.errorMessage;
              obj["isValidInput"] = validatedObj.isValidInput;
            }
          }
        } else {
          obj["value"] = validatedObj.value;
          obj["isTouched"] = true;
          obj["errorMessage"] = validatedObj.errorMessage;
          obj["isValidInput"] = validatedObj.isValidInput;
        }

        return obj;
      } else {
        return formObj;
      }
    }
  );
  return updatedArry;
};

export const updateFormSelectState = (
  selectedValue: string,
  name: string,
  formValues: FormElement[]
): FormElement[] => {
  let updatedArray = formValues.map((formObj) => {
    if (formObj.id === name) {
      let obj = Object.assign(formObj);
      let validatedObj: ValidatedFormObj = validateFormElement(
        obj.customValidationType,
        selectedValue,
        obj.isRequired,
        formObj.isCustomValidationRequred
      );
      obj["value"] = validatedObj.value;
      obj["isTouched"] = true;
      obj["errorMessage"] = validatedObj.errorMessage;
      obj["isValidInput"] = validatedObj.isValidInput;
      return obj;
    } else {
      return formObj;
    }
  });
  return updatedArray;
};

export const updateFormTimeState = (
  date: Date | null,
  name: string,
  formValues: FormElement[]
): FormElement[] => {
  let updatedArray = formValues.map((formObj) => {
    if (formObj.id === name) {
      let obj = Object.assign(formObj);
      obj["value"] = obj.value;
      obj["isTouched"] = true;
      obj["errorMessage"] = "";
      obj["selectedTime"] = date;
      obj["isValidInput"] = "";
      return obj;
    } else {
      return formObj;
    }
  });
  return updatedArray;
};

export const updateFormDate = (
  date: Date | null,
  name: string,
  formValues: FormElement[]
): FormElement[] => {
  let updatedArray = formValues.map((formObj) => {
    if (formObj.id === name) {
      let obj = Object.assign(formObj);
      // console.log(date?date[_d],"date")
      obj["value"] = date;
      obj["isTouched"] = true;
      obj["errorMessage"] = "";
      obj["slectedDate"] = date;
      obj["isValidInput"] = "";
      return obj;
    } else {
      return formObj;
    }
  });
  return updatedArray;
};

// method will validate the input with empty and custom validation
const validateFormElement = (
  validationType: customValidationType,
  value: string,
  isRequired: boolean,
  customValidationRequired: boolean
): ValidatedFormObj => {
  let updatedValue = value;
  let validatedObj = {
    value: updatedValue,
    isValidInput: false,
    errorMessage: "",
  };
  if (isRequired && updatedValue.length === 0) {
    return {
      value: updatedValue,
      isValidInput: false,
      errorMessage: "Value should not be empty",
    };
  }

  if (customValidationRequired && updatedValue.length > 0) {
    switch (validationType) {
      // case customValidationType.emailValidation:
      //   return (validatedObj = {
      //     value: updatedValue,
      //     isValidInput: validateEmail(updatedValue),
      //     errorMessage: validateEmail(updatedValue) ? "" : "Invalid email id",
      //   });
      case customValidationType.characterValidation:
        return (validatedObj = {
          value: characterValidation(updatedValue),
          isValidInput: true,
          errorMessage: "",
        });
      case customValidationType.mobileValidation:
        let mobileValidateObj = mobileNumberValidation(updatedValue);
        // console.log(mobileValidateObj, "mobile");
        return {
          value: mobileValidateObj.value,
          isValidInput: mobileValidateObj.errorMessage.length > 0,
          errorMessage: mobileValidateObj.errorMessage,
        };
      case customValidationType.passwordValidation:
        // Define a regex pattern to check for password strength
        // let passwordValidateObj = validatePassword(updatedValue);
        // console.log(passwordValidateObj, "password")

        // Password is valid
        // return {
        //     value: updatedValue,
        //     isValidInput: true,
        //     errorMessage: ""
        // };
        return (validatedObj = {
          value: updatedValue,
          isValidInput: validatePassword(updatedValue),
          errorMessage: validatePassword(updatedValue)
            ? ""
            : "Password must contain at least 8 characters, including one lowercase letter, one uppercase letter, and one number",
        });

      case customValidationType.emailValidation:
        let emailValidateObj = emailValidation(updatedValue);
        return {
          value: emailValidateObj.value,
          isValidInput: emailValidateObj.errorMessage.length > 0,
          errorMessage: emailValidateObj.errorMessage,
        };

      case customValidationType.titleValidation:
        let titleVadidationObj = titleValidation(updatedValue);
        return {
          value: titleVadidationObj.value,
          isValidInput: titleVadidationObj.errorMessage.length > 0,
          errorMessage: titleVadidationObj.errorMessage,
        };
      case customValidationType.descriptionValidation:
        let descriptionVadidationObj = descriptionValidation(updatedValue);
        return {
          value: descriptionVadidationObj.value,
          isValidInput: descriptionVadidationObj.errorMessage.length > 0,
          errorMessage: descriptionVadidationObj.errorMessage,
        };
      case customValidationType.priceValidation:
        let priceVadidationObj = priceValidation(updatedValue);
        return {
          value: priceVadidationObj.value,
          isValidInput: priceVadidationObj.errorMessage.length > 0,
          errorMessage: priceVadidationObj.errorMessage,
        };
      case customValidationType.adressValidation:
        let addressVadidationObj = addressValidation(updatedValue);
        return {
          value: addressVadidationObj.value,
          isValidInput: addressVadidationObj.errorMessage.length > 0,
          errorMessage: addressVadidationObj.errorMessage,
        };
      case customValidationType.cityStateValidation:
        let cityStateVadidationObj = cityStateValidation(updatedValue);
        return {
          value: cityStateVadidationObj.value,
          isValidInput: cityStateVadidationObj.errorMessage.length > 0,
          errorMessage: cityStateVadidationObj.errorMessage,
        };
      case customValidationType.zipValidation:
        let zipVadidationObj = zipValidation(updatedValue);
        return {
          value: zipVadidationObj.value,
          isValidInput: zipVadidationObj.errorMessage.length > 0,
          errorMessage: zipVadidationObj.errorMessage,
        };
      case customValidationType.nameValidation:
        let nameVadidationObj = nameValidation(updatedValue);
        return {
          value: nameVadidationObj.value,
          isValidInput: nameVadidationObj.errorMessage.length > 0,
          errorMessage: nameVadidationObj.errorMessage,
        };

      default:
        return validatedObj;
    }
  }

  return validatedObj;
};

// method to validate complete form
export const validateForm = (formElements: FormElement[]): boolean => {
  let isValidForm = true;
  for (let element of formElements) {
    if (
      element.isRequired ||
      (element.isCustomValidationRequred && element.errorMessage.length > 0)
    ) {
      isValidForm = false;
      break;
    }
  }
  return isValidForm;
};

// method to add padding zero to the incoming digit
// incoming value 8 it will return 08
export const addPaddingZero = (digit: number): string =>
  digit > 9 ? `${digit}` : `0${digit}`;

export const monthNames = (monthNumber: number): string => {
  switch (monthNumber) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
    default:
      return "NA";
  }
};

// export const transformDate = (dateObj: Date): string => {
//   let date = new Date(dateObj);
//   let day = date.getDate().toString().padStart(2, "0");
//   let hour = date.getHours().toString().padStart(2, "0");
//   let minutes = date.getMinutes().toString().padStart(2, "0");
//   // console.log("date",date)
//   return `${day} ${monthNames(date.getMonth())}
//   ${date.getFullYear()}, ${hour}:${minutes}`;
// };

export const transformDate = (dateObj: Date): string => {
  let date = new Date(dateObj);

  let day = date.getDate().toString().padStart(2, "0");
  let hour = date.getHours();
  let minutes = date.getMinutes().toString().padStart(2, "0");

  // Determine whether it's AM or PM
  let period = hour < 12 ? "AM" : "PM";

  // Convert to 12-hour clock
  hour = hour % 12 === 0 ? 12 : hour % 12;

  // Return the formatted string
  return `${day} ${monthNames(
    date.getMonth()
  )} ${date.getFullYear()}, ${hour}:${minutes} ${period}`;
};
