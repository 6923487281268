// import { FC, useState, useEffect, Fragment } from "react";

// import { addPaddingZero } from "../../Utility/Utility";
// import { Timer } from "./StyledCountdownTimer";
// import { useSelector } from "react-redux";
// import { RootState } from "../../app/store";

// interface CountdownTimerProps {
//   timestamp: Date;
//   isDay: Boolean;
// }

// const CountdownTimer: FC<CountdownTimerProps> = ({ timestamp, isDay }) => {
//   const [endDate, setEndDate] = useState(timestamp);
//   const [days, setDay] = useState(0);
//   const [hours, setHours] = useState(0);
//   const [minutes, setMinutes] = useState(0);
//   const [seconds, setSeconds] = useState(0);
//   const isLoading = useSelector((state: RootState) => state.loader.isLoading);

//   useEffect(() => {
//     setEndDate(timestamp);
//     let timerInterval = setInterval(() => {
//       let endDateTime = endDate.getTime();
//       let nowTime = new Date().getTime();
//       var distance = endDateTime - nowTime;

//       var remainingDays = Math.floor(distance / (1000 * 60 * 60 * 24));
//       var remaininghours = Math.floor(
//         (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
//       );
//       var remainingMinutes = Math.floor(
//         (distance % (1000 * 60 * 60)) / (1000 * 60)
//       );
//       var remainigSeconds = Math.floor((distance % (1000 * 60)) / 1000);

//       setDay(remainingDays);
//       setHours(remaininghours);
//       setMinutes(remainingMinutes);
//       setSeconds(remainigSeconds);
//     }, 1000);
//     return () => {
//       clearInterval(timerInterval);
//     };
//   });

//   return (
//     <Fragment>
//       {!isLoading && (
//         <Timer>
//           {isDay && `${addPaddingZero(days)} :`} {addPaddingZero(hours)} :{" "}
//           {addPaddingZero(minutes)} : {addPaddingZero(seconds)}
//         </Timer>
//       )}
//     </Fragment>
//   );
// };

// export default CountdownTimer;


import React, { FC, useState, useEffect, Fragment } from "react";

import { addPaddingZero } from "../../Utility/Utility";
import { Timer } from "./StyledCountdownTimer";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

interface CountdownTimerProps {
  timestamp: Date;
  isDay: boolean;
}

const CountdownTimer: FC<CountdownTimerProps> = ({ timestamp, isDay }) => {
  const [endDate, setEndDate] = useState(timestamp);
  const [days, setDay] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [timerFinished, setTimerFinished] = useState(false);
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  const zero:string="00";

  useEffect(() => {
    setEndDate(timestamp);
    setTimerFinished(false); // Reset timer status
  }, [timestamp]);

  useEffect(() => {
    let timerInterval = setInterval(() => {
      let endDateTime = endDate.getTime();
      let nowTime = new Date().getTime();
      let distance = endDateTime - nowTime;

      if (distance <= 0) {
        clearInterval(timerInterval);
        setTimerFinished(true); // Update timer status to indicate it's finished
      } else {
        let remainingDays = Math.floor(distance / (1000 * 60 * 60 * 24));
        let remainingHours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let remainingMinutes = Math.floor(
          (distance % (1000 * 60 * 60)) / (1000 * 60)
        );
        let remainingSeconds = Math.floor((distance % (1000 * 60)) / 1000);

        setDay(remainingDays);
        setHours(remainingHours);
        setMinutes(remainingMinutes);
        setSeconds(remainingSeconds);
      }
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [endDate]);

  return (
    <Fragment>
      {!isLoading && (
        <Timer>
          {timerFinished ? (
            <span>{isDay&& `${zero} :`} {zero} : {zero} : {zero}</span>
          ) : (
            <span>
              {isDay && `${addPaddingZero(days)} :`} {addPaddingZero(hours)} :{" "}
              {addPaddingZero(minutes)} : {addPaddingZero(seconds)}
            </span>
          )}
        </Timer>
      )}
    </Fragment>
  );
};

export default CountdownTimer;
