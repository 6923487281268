import { FC, useEffect } from "react";
import { Wrapper, Table, EmptyView, TableWrapper } from "./StyledLotteryWinners";
import { transformDate } from "../../Utility/Utility";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import { getLotteryWinnerList } from "../../features/lotteriesSlice";
let tableValues = [
  {
    name: "Kira***",
    reward: "1000",
    date: new Date(),
  },
  {
    name: "Kira***",
    reward: "1000",
    date: new Date(),
  },
  {
    name: "Kira***",
    reward: "1000",
    date: new Date(),
  },
  {
    name: "Kira***",
    reward: "1000",
    date: new Date(),
  },
  {
    name: "Kira***",
    reward: "1000",
    date: new Date(),
  },
  {
    name: "Kira***",
    reward: "1000",
    date: new Date(),
  },
  {
    name: "Kira***",
    reward: "1000",
    date: new Date(),
  },
  {
    name: "Kira***",
    reward: "1000",
    date: new Date(),
  },
  {
    name: "Kira***",
    reward: "1000",
    date: new Date(),
  },
  {
    name: "Kira***",
    reward: "1000",
    date: new Date(),
  },
  {
    name: "Kira***",
    reward: "1000",
    date: new Date(),
  },
];

const LotteryWinners: FC = () => {

  const dispatch = useDispatch();

  const winnerList = useSelector(
    (state: RootState) => state.lotteries.winnerList
  );
  const sortedLiveLotteries = winnerList!==undefined&&winnerList.length>0 ? [...winnerList].sort((a, b) => {
    const dateA:any = new Date(a.lotteryGameEndDate);
    const dateB:any = new Date(b.lotteryGameEndDate);
    return dateA - dateB;
  }) : [];
  let reverseList = [...sortedLiveLotteries].reverse();
  useEffect(() => {
    dispatch(getLotteryWinnerList());
  }, []);

  let view =
    winnerList !== undefined && winnerList.length > 0
      ? reverseList.map((obj: any) => {
          return (
            <>
              <tr><td>{obj?.lotteryGameNo}</td>
                <td>{obj.winnerTicketNo}</td>
                {/* <td>$ {obj.rewardAmount}</td> */}
                <td>{transformDate(obj.lotteryGameEndDate)}</td>
              </tr>
            </>
          );
        })
      : null;

  return (
    <Wrapper>
      {winnerList!==undefined&&winnerList.length >0 ? (
        <TableWrapper>
          <Table>
        <thead>
          <tr><td>Lottery Number</td>
            <td>Ticket number</td>
            {/* <td>Reward</td> */}
            <td>Date</td>
          </tr>
        </thead>
        <tbody>{view}</tbody>
      </Table>
        </TableWrapper>
      ):(
        <EmptyView>No Winner Records Found</EmptyView>
      ) }
    </Wrapper>
  );
};

export default LotteryWinners;
