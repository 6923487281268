import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtilities/endpoints";
import { toggleNotificationVisibility } from "./notificationSlice";
import { NotificationType } from "../Utility/InterFacesAndEnum";

import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { toggleLoader } from "./loaderSlice";
import { error } from "console";
import { getUserDetailes } from "./loadMoneySlice";

interface AuctionItem {
  auctionDesc: string;
  auctionEndDate: string;
  auctionImageUrls: string[];
  auctionId: number;
  auctionPrice: number;
  auctionStatus: string;
  auctionTitle: string;
  bronzeTicketPrice: number;
  imageUrls: string[] | null;
  noOfUsersJoined: null | number;
  productCategory: string;
  productType: string;
  userName: string;
}

interface AuctionDetailObj {
  auctionId: number;
  auctionTitle: string;
  auctionDesc: string;
  auctionType: string;
  productType: string;
  productCategory: string;
  auctionPrice: number;
  auctionProposedPrice: number;
  auctionImageUrls: string[] | null;
  auctionStartDate: string;
  auctionEndDate: string;
  isActive: boolean;
  isMemberAuction: boolean;
  userName: string;
  userMobile: string;
  userEmailId: string;
  address: string;
  city: string;
  state: string;
  country: string;
  pincode: number;
  auctionStatus: string;
  winnerTicketNo: null | number | string;
  winnerUserId: null | number | string;
}

interface CreateAuctionRequest {
  auctionTitle: string;
  auctionDesc: string;
  auctionProposedPrice: string;
  auctionStartDate: string;
  auctionEndDate: string;
  productType: string;
  productCategory: string;
  userName: string;
  userMobile: string;
  userEmailId: string;
  address: string;
  city: string;
  state: string;
  country: string;
  pinCode: string;
  publicUserId: string;
}

interface AuctionState {
  auctionList: [] | AuctionItem[];
  upcommingAuctionList: [] | AuctionItem[];
  countrywiseUpcommingAuctionList: [] | AuctionItem[];
  liveAuctionList: [] | AuctionItem[];
  countrywiseLiveAuctionList: [] | AuctionItem[];
  isAuctionCreated: boolean;
  auctionDetail: any;
  isAuctionPurchased: boolean;
  auctionWinnerList: any;
  detailState: boolean;
  topAuction: any;
  checkout: any;
  isWalletPaymentInitiated: boolean;
  isPaymentComplete: boolean;
  isPaymentIncomplete: boolean;
  searchValue: string;
}

interface ToggleCreateAuctionProps {
  isCreated: boolean;
}

interface SetAuctionDetailProps {
  data: AuctionItem;
}
interface SearchProps {
  value: string;
}
interface getCheckout {
  checkoutList: [];
}

const auctionInitialState: AuctionState = {
  auctionList: [],
  upcommingAuctionList: [],
  liveAuctionList: [],
  countrywiseLiveAuctionList: [],
  countrywiseUpcommingAuctionList: [],
  isAuctionCreated: false,
  auctionDetail: { auctionImageUrls: "" },
  isAuctionPurchased: false,
  auctionWinnerList: [],
  detailState: false,
  topAuction: {},
  checkout: [],
  isWalletPaymentInitiated: false,
  isPaymentComplete: false,
  isPaymentIncomplete: false,
  searchValue: "",
};

interface SetAuctionList {
  data: AuctionItem[];
}

interface SetUpcoomingAuctionList {
  data: AuctionItem[];
}
interface SetLiveAuctionList {
  data: AuctionItem[];
}

interface AuctionPurchaseState {
  isPurchased: boolean;
}

interface SetWinnerList {
  data: any;
}

interface SetTopAuction {
  topAuction: any;
}
interface PaymentStatus {
  isPaymentComplete: boolean;
  isPaymentIncomplete: boolean;
}
export const createAuctionRequest = createAsyncThunk(
  "create user auction request",
  async (payload: CreateAuctionRequest, { dispatch }) => {
    let publicUserId = getLocalStorage(
      localStorageActionType.GET_PUBLIC_USER_ID
    );
    payload["publicUserId"] = publicUserId;
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(endpoints.createAuctionRequest, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        if (data.statusCode === 200) {
          dispatch(
            toggleCreateAuctionState({
              isCreated: true,
            })
          );
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
        if (data.statusCode === 900) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return err;
      });
  }
);

export const createAuctionRequestCheckout = createAsyncThunk(
  "create user auction request",
  async (payload: CreateAuctionRequest, { dispatch }) => {
    let publicUserId = getLocalStorage(
      localStorageActionType.GET_PUBLIC_USER_ID
    );
    payload["publicUserId"] = publicUserId;
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(endpoints.createAuctionCheckout, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );

        data.links.filter((obj: any) => {
          if (obj.rel === "payer-action") {
            console.log(obj);
            dispatch(
              setAuctionRequestCheckout({
                checkoutList: obj,
              })
            );
          }
        });
      })
      .catch((err) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return err;
      });
  }
);

export const createAuctionRequestWallet = createAsyncThunk(
  "create user auction request",
  async (payload: CreateAuctionRequest, { dispatch }) => {
    let publicUserId = getLocalStorage(
      localStorageActionType.GET_PUBLIC_USER_ID
    );
    payload["publicUserId"] = publicUserId;
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(endpoints.createAuctionPayThroughWallet, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );

        if (data.statusCode === 500) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
        if (data.statusCode === 200) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.success,
              message: data.errorMsg,
            })
          );
          dispatch(
            storeWalletCreateAuctionPaymentResponse({
              isWalletPaymentInitiated: true,
            })
          );

          dispatch(getUserDetailes());
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return err;
      });
  }
);

export const createAuctionRequestComplete = createAsyncThunk(
  "payment complete",
  async (payload: any, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));

    await fetch(`${endpoints.createAuctionPaymentComplete}${payload.payerId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        dispatch(toggleLoader({ isLoading: false }));

        if (response.statusCode === 200) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.success,
              message: response.errorMsg,
            })
          );
          dispatch(
            setPaymementStatus({
              isPaymentComplete: true,
              isPaymentIncomplete: false,
            })
          );
        } else if (response?.statusCode === 500) {
          dispatch(
            setPaymementStatus({
              isPaymentComplete: false,
              isPaymentIncomplete: true,
            })
          );
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: response.statusMsg,
            })
          );
        } else {
          dispatch(
            setPaymementStatus({
              isPaymentComplete: false,
              isPaymentIncomplete: true,
            })
          );
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: response.errorMsg,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(toggleLoader({ isLoading: false }));

        console.log(error);
      })
      .finally(() => {
        dispatch(toggleLoader({ isLoading: false }));

        console.log("finaly");
      });
  }
);

export const getAuctionList = createAsyncThunk(
  "get auction list",
  async (payload: string, { dispatch }) => {
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(`${endpoints.getAuctionList}?${payload}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        if (response.statusCode === 200) {
          // dispatch(
          //   toggleNotificationVisibility({
          //     isVisible: false,
          //     status: NotificationType.success,
          //     message: response.errorMsg,
          //   })
          // );

          dispatch(
            setAuctionList({
              data: response.result,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return err;
      });
  }
);

// get upcomming auctions

export const getUpcommingAuctionList = createAsyncThunk(
  "get auction list",
  async (payload: string, { dispatch }) => {
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(`${endpoints.getAuctionList}?${payload}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        if (response.statusCode === 200) {
          // dispatch(
          //   toggleNotificationVisibility({
          //     isVisible: false,
          //     status: NotificationType.success,
          //     message: response.errorMsg,
          //   })
          // );

          dispatch(
            setUpcommingAuctionList({
              data: response.result,
            })
          );
        }
      })
      .then((err) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return err;
      });
  }
);

// get live auctions

export const getLiveAuctionList = createAsyncThunk(
  "get auction list",
  async (payload: string, { dispatch }) => {
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(`${endpoints.getAuctionList}?${payload}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        if (response.statusCode === 200) {
          // dispatch(
          //   toggleNotificationVisibility({
          //     isVisible: false,
          //     status: NotificationType.success,
          //     message: response.errorMsg,
          //   })
          // );

          dispatch(
            setLiveAuctionList({
              data: response.result,
            })
          );
        }
      })
      .then((err) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return err;
      });
  }
);

export const getAuctionDetail = createAsyncThunk(
  "get single auction detail",
  async (payload: string, { dispatch }) => {
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(`${endpoints.getAuctionDetailById}${payload}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        if (response.statusCode === 200) {
          // dispatch(
          //   toggleNotificationVisibility({
          //     isVisible: false,
          //     status: NotificationType.success,
          //     message: response.errorMsg,
          //   })
          // );
          dispatch(
            setAuctionDetail({
              data: response.result,
            })
          );
          dispatch(
            setDetailState({
              detailState: true,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return err;
      });
  }
);

export const getCountryWiseUpcommingAuctions = createAsyncThunk(
  "countrywise auctions",
  async (payload: any, { dispatch }) => {
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(endpoints.auctionByCountry, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return response.json();
      })
      .then((data) => {
        if (data?.statusCode === 200) {
          dispatch(
            setCountrywiseUpcommingAuctionList({
              data: data.result,
            })
          );
          console.log(data);
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: "No Upcomming Auction Found.",
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
      });
  }
);

export const getCountryWiseLiveAuctions = createAsyncThunk(
  "countrywise auctions",
  async (payload: any, { dispatch }) => {
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(endpoints.auctionByCountry, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return response.json();
      })
      .then((data) => {
        if (data?.statusCode === 200) {
          dispatch(
            setCountrywiseLiveAuctionList({
              data: data.result,
            })
          );
          console.log(data);
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: "No Live Auction Found.",
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
      });
  }
);

export const getTopAuctionData = createAsyncThunk(
  "get top auction",
  async (payload, { dispatch }) => {
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(endpoints.getTopAuction, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data)
        if (data.statusCode === 200) {
          let auctionObj = { ...data.result };
          auctionObj["thumbnail"] =
            data.result?.auctionImageUrls === null
              ? null
              : data.result?.auctionImageUrls[0];
          // console.log(auctionObj)
          dispatch(
            setTopAuction({
              topAuction: auctionObj,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          toggleNotificationVisibility({
            isVisible: true,
            status: NotificationType.error,
            message: "Error while getting top auction.",
          })
        );
        return error;
      });
  }
);

export const purchaseAuction = createAsyncThunk(
  "Purchase Auction",
  async (payload: any, { dispatch }) => {
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(endpoints.purchaseAuction, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log(response);
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        if (response.statusCode === 200) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.success,
              message: response.errorMsg,
            })
          );
          dispatch(
            toggleAuctionPurchase({
              isPurchased: true,
            })
          );
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: response.errorMsg,
            })
          );
          dispatch(
            toggleAuctionPurchase({
              isPurchased: true,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return err;
      });
  }
);

export const getAuctionWinnerList = createAsyncThunk(
  "get auction winner list",
  async (payload: string, { dispatch }) => {
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(endpoints.getAuctionWinnerList, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        if (response.statusCode === 200) {
          dispatch(
            setWinnerList({
              data: response.result,
            })
          );
        } else if (response.statusCode === 504) {
          dispatch(
            setWinnerList({
              data: [],
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return err;
      });
  }
);

export const purchaseAuctionCheckout = createAsyncThunk(
  "auction payment checkout",
  async (payload: any, { dispatch }) => {
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(endpoints.auctionPaymentCheckout, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );

        console.log(data);
      })
      .catch((err) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return err;
      })
      .finally(() => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        console.log("finaly");
      });
  }
);

const auctionSlice = createSlice({
  name: "auction slice",
  initialState: auctionInitialState,
  reducers: {
    setAuctionList: (state, action: PayloadAction<SetAuctionList>) => {
      return {
        ...state,
        auctionList: action.payload.data,
      };
    },
    setUpcommingAuctionList: (
      state,
      action: PayloadAction<SetUpcoomingAuctionList>
    ) => {
      return {
        ...state,
        upcommingAuctionList: action.payload.data,
      };
    },
    setLiveAuctionList: (
      state,
      action: PayloadAction<SetUpcoomingAuctionList>
    ) => {
      return {
        ...state,
        liveAuctionList: action.payload.data,
      };
    },

    setCountrywiseUpcommingAuctionList: (
      state,
      action: PayloadAction<SetUpcoomingAuctionList>
    ) => {
      return {
        ...state,
        countrywiseUpcommingAuctionList: action.payload.data,
      };
    },
    setCountrywiseLiveAuctionList: (
      state,
      action: PayloadAction<SetUpcoomingAuctionList>
    ) => {
      return {
        ...state,
        countrywiseLiveAuctionList: action.payload.data,
      };
    },

    toggleCreateAuctionState: (
      state,
      action: PayloadAction<ToggleCreateAuctionProps>
    ) => {
      return {
        ...state,
        isAuctionCreated: action.payload.isCreated,
      };
    },
    setAuctionDetail: (state, action: PayloadAction<SetAuctionDetailProps>) => {
      return {
        ...state,
        auctionDetail: action.payload.data,
      };
    },
    setSearchAuction: (state, action: PayloadAction<SearchProps>) => {
      return {
        ...state,
        searchValue: action.payload.value,
      };
    },

    toggleAuctionPurchase: (
      state,
      action: PayloadAction<AuctionPurchaseState>
    ) => {
      return {
        ...state,
        isAuctionPurchased: action.payload.isPurchased,
      };
    },
    setWinnerList: (state, action: PayloadAction<SetWinnerList>) => {
      return {
        ...state,
        auctionWinnerList: action.payload.data,
      };
    },
    setDetailState: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        detailState: action.payload.detailState,
      };
    },
    setTopAuction: (state, action: PayloadAction<SetTopAuction>) => {
      return {
        ...state,
        topAuction: action.payload.topAuction,
      };
    },
    setAuctionRequestCheckout: (state, action: PayloadAction<getCheckout>) => {
      return {
        ...state,
        checkout: action.payload.checkoutList,
      };
    },
    storeWalletCreateAuctionPaymentResponse: (
      state,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        isWalletPaymentInitiated: action.payload.isWalletPaymentInitiated,
      };
    },
    setPaymementStatus: (state, action: PayloadAction<PaymentStatus>) => {
      return {
        ...state,
        isPaymentComplete: action.payload.isPaymentComplete,
        isPaymentIncomplete: action.payload.isPaymentIncomplete,
      };
    },
  },
});

export const {
  setAuctionList,
  setUpcommingAuctionList,
  setLiveAuctionList,
  toggleCreateAuctionState,
  setAuctionDetail,
  toggleAuctionPurchase,
  setWinnerList,
  setDetailState,
  setTopAuction,
  setCountrywiseLiveAuctionList,
  setCountrywiseUpcommingAuctionList,
  setAuctionRequestCheckout,
  storeWalletCreateAuctionPaymentResponse,
  setPaymementStatus,
  setSearchAuction,
} = auctionSlice.actions;
export default auctionSlice.reducer;
