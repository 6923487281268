import { FC, useEffect } from "react";
import {
  Wrapper,
  Table,
  EmptyView,
  Reward,
  TableWrapper,
} from "./StyledAuctionWinners";
import { transformDate } from "../../Utility/Utility";

import { RootState } from "../../app/store";
import { getAuctionWinnerList } from "../../features/auctionList";
import { useSelector, useDispatch } from "react-redux";

let auctionWinnersList = [
  {
    imgUrl: "https://picsum.photos/100/100",
    name: "Sudhindra",
    auctionType: "House",
    date: new Date(),
    reward: "Maserati Levante GT Hybrid car won",
  },
  {
    imgUrl: "https://picsum.photos/100/100",
    name: "Sudhindra",
    auctionType: "House",
    date: new Date(),
    reward: "Maserati Levante GT Hybrid car won",
  },
  {
    imgUrl: "https://picsum.photos/100/100",
    name: "Sudhindra",
    auctionType: "House",
    date: new Date(),
    reward: "Maserati Levante GT Hybrid car won",
  },
  {
    imgUrl: "https://picsum.photos/100/100",
    name: "Sudhindra",
    auctionType: "House",
    date: new Date(),
    reward: "Maserati Levante GT Hybrid car won",
  },
  {
    imgUrl: "https://picsum.photos/100/100",
    name: "Sudhindra",
    auctionType: "House",
    date: new Date(),
    reward: "Maserati Levante GT Hybrid car won",
  },
];

const AuctionWinners = () => {
  const dispatch = useDispatch();

  const winnerList = useSelector(
    (state: RootState) => state.auction.auctionWinnerList
  );
  const sortedLiveLotteries = [...winnerList].sort((a, b) => {
    const dateA:any = new Date(a.auctionDate);
    const dateB:any = new Date(b.auctionDate);
    return dateA - dateB;
  });
  let reverseList = [...sortedLiveLotteries].reverse();
  // console.log(winnerList);
  useEffect(() => {
    if (winnerList.length === 0) {
      dispatch(getAuctionWinnerList(""));
    }
  }, []);


  const view =
    winnerList.length > 0 &&
    reverseList.map((bodyObj: any) => {
      return (
        <tr>
          <td>{bodyObj?.auctionNo}</td>
          {/* <td>{bodyObj.productCategory}</td> */}
          {/* <td>{bodyObj.productType}</td> */}
          <td>{bodyObj?.winnerTicketNo}</td>
          {/* <td>{bodyObj?.auctionTitle}</td> */}
          
          <td>{transformDate(new Date(bodyObj.auctionDate))}</td>
        </tr>
      );
    });

  return (
    <Wrapper>
      {winnerList.length > 0 ? (
        <TableWrapper>

          <Table>
            <thead>
              <tr>
                <td>Auction Number</td>
                {/* <td>Auction Category</td> */}
                {/* <td>Auction Type</td> */}
                <td>Ticket Number</td>
                {/* <td>Reward</td> */}
                <td>Date</td>
              </tr>
            </thead>
            <tbody>{view}</tbody>
          </Table>
        </TableWrapper>
      ) : (
        <EmptyView>No Winner Records Found</EmptyView>
      )}
    </Wrapper>
  );
};

export default AuctionWinners;
