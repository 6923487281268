import styled from "styled-components";

import * as UIConstants from "../../UIConstants";

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  img {
    width: 100%;
    object-fit: contain;
  }

  ${UIConstants.mobileView} {
    img {
      width: 100%;
      object-fit: contain;
    }
    padding: 0px;
  }
`;

export const LotteryContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5;
  padding: 0 70px 50px 70px;
  ${UIConstants.mobileView} {
    padding: 15px;
  }
`;

export const BannerImg = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const UpcomingLotteryContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: center;
  gap: 30px;
`;

export const Lottery = styled.div`
  flex-basis: 48.5%;
  ${UIConstants.customMobileView} {
    flex-basis: 100%;
  }
`;

export const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  text-align: left;
  margin: 0px 0 24px 0;
  ${UIConstants.mobileView} {
    // margin: 25px 0;
    font-size: 18px;
  }
`;
