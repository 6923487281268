import styled from 'styled-components';
import * as UIConstants from '../../UIConstants';
import bgImg from '../../assets/mainLotteryBgImg.jpg';

export const MainPageWrapper = styled.div`
width: 100%;
box-sizing: border-box;
background-color: #ffffff;
margin-top: 2px;
padding: 50px 80px;
border-bottom: 1px solid #D4D8E2;
background-image: url(${bgImg});
background-size: contain;
background-position: center;
${UIConstants.mobileView}{
    padding: 10px;
}
`;

export const Section = styled.div`
padding: 50px 50px 0px 50px;
/* border: 1px solid black; */
display: flex;
flex-basis: 50%;
flex-flow: column;
Button{
  margin: 20px 0 10px 0;
}
${UIConstants.mobileView}{
    margin:15px;
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(255, 200, 57, 0.5);
}
`;

export const SectionTwo = styled.div`
width: 100%;
box-sizing: border-box;
display: flex;
flex-flow: row nowrap;
align-items: stretch;
/* border: 1px solid black; */
justify-content: space-between;
background-color: #ffffff;
border-bottom: 1px solid #D4D8E2;
${Section}:first-child{
    border-right: 1px solid #D4D8E2;

${UIConstants.mobileView}{
    margin:15px;
    border-right: none;
    border-radius: 10px;
    overflow: hidden;
    padding: 0;
};

};
${UIConstants.mobileView}{
    flex-flow: column;
};
`;

export const Title = styled.div`
font-weight: 600;
font-size: 24px;
line-height: 27px;
text-transform: capitalize;
color: #200E32;
text-align: left;
${UIConstants.mobileView}{
    border-bottom: none;
    padding: 20px 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: unset;
    background: rgba(255, 200, 57, 0.4);
};
`;

export const CoinDiv=styled.div`
/* height: 500px; */
overflow: auto;
${UIConstants.mobileView}{
  padding:0 20px 0 20px;
}
`

export const AuctionSection = styled.div`
width: 100%;
box-sizing: border-box;
background-color: #ffffff;
margin-top: 2px;
padding: 20px 20px 70px 20px;
border-bottom: 1px solid #D4D8E2;
${UIConstants.mobileView}{
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(255, 200, 57, 0.5);
    /* padding: 15px; */
}
`;

export const TitelWrapper = styled.div`
width: 100%;
box-sizing:border-box;
padding: 0 20px 0 40px;
display: flex;
flex-flow:row nowrap;
align-items: center;
justify-content: space-between;
${UIConstants.mobileView}{
    flex-flow: column;
    /* padding: 0; */
    padding: 0 0 20px 16px;
}
${Title}{
    margin-top: 30px;
${UIConstants.mobileView}{
    width: 100%;
    margin: 0;
}
}
`;

export const SectionWrapper = styled.div`
width: 100%;
box-sizing: border-box;
background-color: #ffffff;
${UIConstants.mobileView}{
    padding: 15px;
}
`;

export const MoreAuction = styled.div`
font-weight: 400;
font-size: 18px;
line-height: 22px;
text-align: right;
color: #200E32;
cursor:pointer;
`;

export const CardContainer = styled.div`
padding : 20px 20px 20px 20px ;
display : flex;
flex-wrap:wrap;
justify-content:center;
gap: 10px;
${UIConstants.mobileView}{
padding : 20px 0px 20px 0px ;

}

`;

export const Card = styled.div`
flex: 1;
padding: 20px;
background-color: #fff;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

export const AuctionWinnerSection = styled.div`
width: 100%;
box-sizing: border-box;
background-color: #ffffff;
margin-top: 2px;
padding: 40px 50px 70px 50px;
border-bottom: 1px solid #D4D8E2;
${UIConstants.mobileView}{
    /* display: none; */
    padding:15px;
    /* padding: 0; */
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(255, 200, 57, 0.5);
}
`;

export const MoreLotteries = styled.div`
font-weight: 400;
font-size: 18px;
line-height: 22px;
text-align: right;
color: #200E32;
cursor:pointer;
padding-top: 40px;
`;

export const Container = styled.div`
  margin-top:2px;
  // display: flex;
  // flex-wrap: wrap;
`;

export const CoinSection = styled.div`
  
`;

export const CoinImages = styled.div`
  // display: flex;
  text-align:center;
  img{
    height: 600px;
  }
`;

export const BigCoinImage = styled.img`
background: #f0f0f0;
 margin-top: 28px;
width: 80%;
height: 80%;
// object-fit: fill;
`;

export const DetailsSection = styled.div`
  width: 50%;
  
`;

export const CoinHeading = styled.h2`
  font-size: 24px;
  font-weight: bold;
`;

export const CoinSubheading = styled.h3`
  font-size: 18px;
  color: #888;
  text-align: left;
`;

export const CoinDetailsText = styled.p`
  font-size: 18px;
  line-height: 1.5;
  color: #000;
   text-align: justify;

`;
export const Description = styled.div`
  max-height: 400px;
  overflow: auto;

  margin-bottom: 30px;
  ${UIConstants.mobileView} {
    margin-bottom: 16px;
  }
`;
export const Text = styled.div`
font-family: Arial, sans-serif; /* Choose your preferred font */
    word-wrap: break-word; /* Allow long words to break and wrap */
    white-space: pre-wrap;
    padding-right:5px;
    font-size: 18px;
    line-height: 1.5;
    color: #000;
    text-align: justify;
`

export const BuyButton = styled.button`
margin: 20px 0px 20px 0px;
padding: 11px;
width:50%;
height:10%;
border: none;
border-radius: 5px;
font-size: 12px;
cursor: pointer;
color: #ffffff;
  background: linear-gradient(76.64deg, #003459 5.43%, #326F9B 27.36%, #4B93C7 46.41%, #326F9B 68.72%, #003459 90.4%);

// background: linear-gradient(76.64deg, #3A57E8 5.43%, #647CF5 27.36%, #8395EF 46.41%, #4564FF 68.72%, #001994 90.4%);

  padding: "16px";
  &:hover: {
    backgroundColor: #3A57E8;
    
}
${UIConstants.mobileView}{
    margin : 20px;
}
`;

export const SimilarCoinsSection = styled.div`

padding : 0px 20px 0px 20px;
padding-top:10px;
padding-bottom:50px;
display: flex;
// flex-basis: 50%;
// flex-flow: column;
${UIConstants.mobileView}{
    margin:15px;
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(255, 200, 57, 0.5);
}


`;

export const Heading = styled.h2`
  padding-left: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  text align: left;
`;

export const SmallCoinImage = styled.img`
  width: 100px;
  height: 100px;
`;