import { FC, useEffect, useState } from "react";
import goldIcon from "../../assets/gold-icon.svg";
import {
  Wrapper,
  Container,
  CoinImg,
  LotteryDetail,
  RewardTitle,
  Reward,
  StartsWrapper,
  Label,
  Value,
  NotifySection,
  EmptyLottery,
  AuctionBtnSection,
  LotteryContainer,
  GoldImg,
} from "./StyledLiveLotteries";
import LotteryCoin from "../../assets/lotteryCoin.png";
import { transformDate } from "../../Utility/Utility";

import { RootState } from "../../app/store";
import { useSelector, useDispatch } from "react-redux";
import { getLotteries } from "../../features/lotteriesSlice";
// import { Button } from "@mui/material";
import {
  notifyMe,
  toggleNotificationVisibility,
} from "../../features/notificationSlice";
import {
  AppButtonType,
  ButtonSizeVariant,
  ButtonType,
  ButtonVariant,
  NotificationType,
} from "../../Utility/InterFacesAndEnum";

import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import * as localStorageActionType from "../../localStorage/ActionTypes";
import { useNavigate } from "react-router-dom";
import { RouterPath } from "../../routes/routes";
import { setLocalStorage } from "../../localStorage/SetLocalStorage";
import BuyLotteryModal from "../../components/Modals/BuyTicketModal/BuyTicketModal";
import Button from "../UI/Buttons/Button";
import useDimension from "../../CustomHooks/useDimention";

interface SelectedTicket {
  label: string;
  amount: number;
  selected: boolean;
  ticketType: string;
}

const LiveLotteries: FC = () => {
  const dispatch = useDispatch();
  const dimention = useDimension();
  const [isBuyTicketModalVisible, setModalVisibility] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<SelectedTicket[] | []>(
    []
  );
  let userDetails = getLocalStorage(localStorageActionType.GET_USER_DETAILS);
  // console.log(Object.keys(userDetails).length!==0 ? JSON.parse(userDetails) : "userDetails not found");
  let isGoldMember =
    Object.keys(userDetails).length !== 0
      ? JSON.parse(userDetails).isGoldMember
      : false;

  const liveLotteries = useSelector(
    (state: RootState) => state.lotteries.lotteries
  );
  // console.log("live:",liveLotteries)

  const sortedLiveLotteries = [...liveLotteries].sort((a, b) => {
    const dateA: any = new Date(a.lotteryGameEndDate);
    const dateB: any = new Date(b.lotteryGameEndDate);
    return dateA - dateB;
  });
  // console.log(liveLotteries)
  // console.log(sortedLiveLotteries)

  const isLoggedIn = getLocalStorage(localStorageActionType.GET_IS_LOGGED_IN);
  const publicUserId = getLocalStorage(
    localStorageActionType.GET_PUBLIC_USER_ID
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (LiveLotteries.length === 0) {
      dispatch(getLotteries());
    }
  }, []);

  const handleNotify = (id: number) => {
    isLoggedIn
      ? liveLotteries.map((obj) => {
          if (obj.lotteryGameId === id) {
            // console.log("object",obj);
            dispatch(
              notifyMe({
                publicUserId: publicUserId,
                notifyType: "L",
                lotteryGameId: id,
              })
            );
          }
        })
      : navigate(RouterPath.signIn);
    // alert(id)
  };
  // console.log("upcomming lottery :",upcomingLotteries)
  // let lotteries = upcomingLotteries.map((lotteryObj) => {

  const onSelectBuyTicket = (id: number) => {
    let selectedTicketObj = liveLotteries.filter((lotteryObj) => {
      return id === lotteryObj.lotteryGameId;
    })[0];

    let ticketsArray = [
      {
        label: "Bronze Ticket",
        amount: selectedTicketObj.bronzeTicketPrice,
        selected: false,
        ticketType: "bronze",
      },
      {
        label: "Silver Ticket",
        amount: selectedTicketObj.silverTicketPrice,
        selected: false,
        ticketType: "silver",
      },
      {
        label: "Gold Ticket",
        amount: selectedTicketObj.goldTicketPrice,
        selected: true,
        ticketType: "gold",
      },
      {
        label: "Platinum Ticket",
        amount: selectedTicketObj.platinumTicketPrice,
        selected: false,
        ticketType: "Platinum",
      },
    ];

    let updatedObj = {
      ...selectedTicketObj,
      lotteryGameEndDate: new Date(selectedTicketObj.lotteryGameEndDate)
        .getTime()
        .toString(),
    };

    setSelectedTicket(ticketsArray);
    setLocalStorage(
      localStorageActionType.SET_SELECTED_LOTTERY_OBJ,
      updatedObj
    );
    if (isLoggedIn) {
      setModalVisibility(true);
    } else {
      navigate(RouterPath.signIn);
    }
  };

  const onSelectGoldMembership = (ticketObj: any) => {
    if (isLoggedIn) {
      navigate(RouterPath.membership);
    } else {
      navigate(RouterPath.signIn);
    }
  };

  let buyModal =
    selectedTicket.length > 0 ? (
      <BuyLotteryModal
        ticket={selectedTicket}
        label={"Buy tickets"}
        isVisible={isBuyTicketModalVisible}
        toggleModal={() => {
          setModalVisibility(!isBuyTicketModalVisible);
        }}
      />
    ) : (
      <div></div>
    );

  let memberLotteries = sortedLiveLotteries.map((lotteryObj) => {
    return (
      <>
      <GoldImg>
          {lotteryObj?.isMemberLottery ? <img src={goldIcon} alt="" /> : <></>}
        </GoldImg>
        <Container>
          <CoinImg
            src={
              lotteryObj.rewardType === "G" && lotteryObj.rewardImages != null
                ? lotteryObj?.rewardImages[0]
                : LotteryCoin
            }
          />

          <LotteryDetail>
            <RewardTitle>Lottery Reward</RewardTitle>
            <Reward>
              {lotteryObj.rewardType === "M"
                ? `$${lotteryObj.rewardAmount.toLocaleString()}`
                : `${lotteryObj.rewardGiftName}`}
            </Reward>
            <StartsWrapper>
              <Label>
                Lottery Number: <b>{lotteryObj.lotteryGameNo}</b>{" "}
              </Label>
            </StartsWrapper>

            <StartsWrapper>
              <Label>Ends on: </Label>
              <Value>
                {transformDate(new Date(lotteryObj.lotteryGameEndDate))}
              </Value>
            </StartsWrapper>
            {/* <StartsWrapper>
            <Label>
              <b>{lotteryObj?.isMemberLottery ? "Member":"All"}</b>
            </Label>
          </StartsWrapper> */}
            <AuctionBtnSection>
              <Button
                disabled={false}
                appBtnType={AppButtonType.primaryBtn}
                fullWidth={dimention.width > 650 ? false : true}
                variant={ButtonVariant.contained}
                type={ButtonType.default}
                size={ButtonSizeVariant.small}
                clicked={() => onSelectBuyTicket(lotteryObj.lotteryGameId)}
              >
                BUY TICKETS
              </Button>
              {!isGoldMember && (
                <Button
                  disabled={false}
                  appBtnType={AppButtonType.secondary}
                  fullWidth={dimention.width > 650 ? false : true}
                  variant={ButtonVariant.contained}
                  type={ButtonType.default}
                  size={ButtonSizeVariant.small}
                  clicked={onSelectGoldMembership}
                >
                  BUY GOLD MEMBERSHIP
                </Button>
              )}
            </AuctionBtnSection>
          </LotteryDetail>
        </Container>
        
      </>
    );
  });

  let lotteries = sortedLiveLotteries.map((lotteryObj) => {
    return (
      !lotteryObj.isMemberLottery && (
        <Container>
          <CoinImg
            src={
              lotteryObj.rewardType === "G" && lotteryObj.rewardImages != null
                ? lotteryObj?.rewardImages[0]
                : LotteryCoin
            }
          />

          <LotteryDetail>
            <RewardTitle>Lottery Reward</RewardTitle>
            <Reward>
              {lotteryObj.rewardType === "M"
                ? `$${lotteryObj.rewardAmount.toLocaleString()}`
                : `${lotteryObj.rewardGiftName}`}
            </Reward>
            <StartsWrapper>
              <Label>
                Lottery Number: <b>{lotteryObj.lotteryGameNo}</b>{" "}
              </Label>
            </StartsWrapper>

            <StartsWrapper>
              <Label>Ends on: </Label>
              <Value>
                {transformDate(new Date(lotteryObj.lotteryGameEndDate))}
              </Value>
            </StartsWrapper>
            {/* <StartsWrapper>
            <Label>
              <b>{lotteryObj?.isMemberLottery ? "Member":"All"}</b>
            </Label>
          </StartsWrapper> */}
            <AuctionBtnSection>
              <Button
                disabled={false}
                appBtnType={AppButtonType.primaryBtn}
                fullWidth={dimention.width > 650 ? false : true}
                variant={ButtonVariant.contained}
                type={ButtonType.default}
                size={ButtonSizeVariant.small}
                clicked={() => onSelectBuyTicket(lotteryObj.lotteryGameId)}
              >
                BUY TICKETS
              </Button>
              {!isGoldMember && (
                <Button
                  disabled={false}
                  appBtnType={AppButtonType.secondary}
                  fullWidth={dimention.width > 650 ? false : true}
                  variant={ButtonVariant.contained}
                  type={ButtonType.default}
                  size={ButtonSizeVariant.small}
                  clicked={onSelectGoldMembership}
                >
                  BUY GOLD MEMBERSHIP
                </Button>
              )}
            </AuctionBtnSection>
          </LotteryDetail>
        </Container>
      )
    );
  });

  return (
    <Wrapper>
      {buyModal}
      <LotteryContainer>
        {liveLotteries.length > 0 ? (
          isGoldMember ? (
            memberLotteries
          ) : (
            lotteries
          )
        ) : (
          <EmptyLottery>No Live lotteries</EmptyLottery>
        )}
      </LotteryContainer>
    </Wrapper>
  );
};

export default LiveLotteries;
