import Styled from 'styled-components';

export const StyledBackdrop = Styled.div`
width: 100%;
height: 100%;
position: fixed;
top: 0;
left: 0;
z-index: 100;
background-color: rgba(0,0,0,0.6);
`;