import {FC, useEffect} from 'react';
import {Wrapper, ViewHeader, Container, Title, Subtitle, ActionSection, PurchaseSuccessWrapper, SuccessImage, } from './StyledPaymentSuccess';
import {ButtonSizeVariant, ButtonVariant, ButtonType, AppButtonType} from '../../../../Utility/InterFacesAndEnum';
import Button from '../../../../components/UI/Buttons/Button';

import successSrc from '../../../../assets/success.svg';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { RouterPath } from '../../../../routes/routes';
import { setPaymementStatus, storeWalletCreateAuctionPaymentResponse } from '../../../../features/auctionList';


const CreateAuctionPaymentComplete:FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const redirect=()=>{
        navigate(RouterPath.auctionList);
        dispatch(storeWalletCreateAuctionPaymentResponse({
            isWalletPaymentInitiated: false
            
        }))
        dispatch(setPaymementStatus({
            isPaymentComplete:false,
            isPaymentIncomplete:false
        }))
    }
  useEffect(()=>{
    dispatch(storeWalletCreateAuctionPaymentResponse({
        isWalletPaymentInitiated: false
        
    }))
    dispatch(setPaymementStatus({
        isPaymentComplete:false,
        isPaymentIncomplete:false
    }))
  },[])
    return(
        <Wrapper>
            <ViewHeader />
            <PurchaseSuccessWrapper>
            {/* <ContainerWrapper> */}
            <Container>
                <SuccessImage src={successSrc} />
                <Title>
                Auction Created Successfully.
                </Title>
                <Subtitle>
                Thank You.
                </Subtitle>
                <ActionSection>
                <Button 
                appBtnType={AppButtonType.primaryBtn}
                    disabled={false} 
                    fullWidth={false} 
                    size={ButtonSizeVariant.large} 
                    variant={ButtonVariant.contained} 
                    type={ButtonType.submit} 
                    clicked={redirect} >
                        Go Back
                </Button>
                </ActionSection>
            </Container>
            {/* </ContainerWrapper> */}
            </PurchaseSuccessWrapper>
        </Wrapper>
    )
};

export default CreateAuctionPaymentComplete