import { FC, useEffect, useState } from "react";
import * as style from "./styledSubscription";
import Button from "../../../components/UI/Buttons/Button";

import {
  ButtonSizeVariant,
  ButtonVariant,
  ButtonType,
  AppButtonType,
} from "../../../Utility/InterFacesAndEnum";

import { RootState } from "../../../app/store";
import {
  getSubscriptionDetailData,
  buyGoldMembership,
  suspendGoldmembership,
  reActivateMembership,
} from "../../../features/subScription";
import { useSelector, useDispatch } from "react-redux";
import { transformDate } from "../../../Utility/Utility";
import { getSubscriptiontAmount } from "../../../features/setting";

const Subscription: FC = () => {
  const dispatch = useDispatch();

  const { isSubscribed, subscription, links, isLoading } = useSelector(
    (state: RootState) => state.subscription
  );
  const subscriptionAmount = useSelector(
    (state: RootState) => state.setting.subscriptionAmount
  );
  const [showAll, setShowAll] = useState(true);
  const [showProceed, setShowProceed] = useState(false);
  // console.log("subscription",subscription)
  // console.log("links",links)
  // console.log(subscriptionAmount);

  useEffect(() => {
    dispatch(getSubscriptionDetailData());
    dispatch(getSubscriptiontAmount());
  }, []);

  const purchaseMembership = () => {
    dispatch(buyGoldMembership());
    setShowAll(false);
    setShowProceed(true);
  };

  const suspendMembership = () => {
    dispatch(
      suspendGoldmembership({
        payload: { reason: "Not intrested anymore" },
        id: subscription?.id,
      })
    );
  };

  const reActivateUserMembership = () => {
    dispatch(
      reActivateMembership({
        payload: { reason: "Re-activate" },
        id: subscription?.id,
      })
    );
  };

  const allButtons = (
    <>
      {!isSubscribed && (
        <style.BtnSection>
          <Button
            appBtnType={AppButtonType.secondary}
            disabled={
              subscription?.status === "ACTIVE"
                ? true
                : subscription?.status === "SUSPENDED"
                ? true
                : false
            }
            fullWidth={true}
            size={ButtonSizeVariant.medium}
            variant={ButtonVariant.contained}
            type={ButtonType.submit}
            clicked={purchaseMembership}
          >
            Buy
          </Button>
        </style.BtnSection>
      )}
      {isSubscribed && (
        <>
          {subscription?.status !== "SUSPENDED" && (
            <Button
              appBtnType={AppButtonType.danger}
              disabled={subscription?.status === "SUSPENDED" ? true : false}
              fullWidth={true}
              size={ButtonSizeVariant.medium}
              variant={ButtonVariant.contained}
              type={ButtonType.submit}
              clicked={suspendMembership}
            >
              Suspend
            </Button>
          )}
          {subscription?.status !== "ACTIVE" && (
            <Button
              appBtnType={AppButtonType.primaryBtn}
              disabled={subscription?.status === "ACTIVE" ? true : false}
              fullWidth={true}
              size={ButtonSizeVariant.medium}
              variant={ButtonVariant.contained}
              type={ButtonType.submit}
              clicked={reActivateUserMembership}
            >
              Activate
            </Button>
          )}
        </>
      )}
    </>
  );
  const proceedBtn = (
    <>
      {isLoading ? (
        <style.BtnSection>
          <Button
            appBtnType={AppButtonType.primaryBtn}
            disabled={false}
            fullWidth={true}
            size={ButtonSizeVariant.small}
            variant={ButtonVariant.contained}
            type={ButtonType.submit}
            clicked={() => {}}
          >
            Loading...
          </Button>
        </style.BtnSection>
      ) : (
        <a href={links.href} style={{ width: "100%" }}>
          <style.BtnSection>
            <Button
              appBtnType={AppButtonType.primaryBtn}
              disabled={false}
              fullWidth={true}
              size={ButtonSizeVariant.small}
              variant={ButtonVariant.contained}
              type={ButtonType.submit}
              clicked={() => {}}
            >
              Proceed to Payment
            </Button>
          </style.BtnSection>
        </a>
      )}
    </>
  );
  return (
    <style.Container>
      <style.Title>Gold Membership</style.Title>
      {!isSubscribed ? (
        <style.MembershipDetail>
          <ol>
            <li>
              Automatically participate in all lottery games with{" "}
              <b>higher chance for each</b> Lottery game
            </li>
            <li>lottery games participation</li>
            <li>Unlocked gold members lottery list</li>
            <li>Monthly Pass</li>
          </ol>
          <ul>
            <li>
              <b>
                Subscription Amount : ${subscriptionAmount?.subscriptionAmount}
              </b>
            </li>
            <li>
              <b>{subscriptionAmount?.subscriptionFrequency}</b>
            </li>
          </ul>
        </style.MembershipDetail>
      ) : (
        <style.MembershipDetail>
          <ul>
            <li>
              <div>
                Status : <b>{subscription?.status}</b>
              </div>
            </li>
            <li>
              <div>
                id : <b>{subscription?.id}</b>
              </div>
            </li>
            <li>
              <div>
                Create :{" "}
                <b>{transformDate(new Date(subscription?.create_time))}</b>
              </div>
            </li>
            <li>
              <div>
                Plan Id : <b>{subscription?.plan_id}</b>
              </div>
            </li>
            <li>
              <div>
                Shipping Amount : <b>${subscription?.shipping_amount?.value}</b>
              </div>
            </li>
            <li>
              <div>
                Quantity : <b>{subscription?.quantity}</b>
              </div>
            </li>
          </ul>
        </style.MembershipDetail>
      )}
      <style.ActionBtnContainer>
        {showAll && allButtons}
        {showProceed && proceedBtn}
      </style.ActionBtnContainer>
    </style.Container>
  );
};

export default Subscription;
