import React, { FC } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./AuctionStyle.css";

interface ResponsiveCarouselProp{
items:any
}

const AuctionResponsiveCarousel:FC<ResponsiveCarouselProp> = ({ items }) => {

    return (
        <Carousel width={"100%"} infiniteLoop autoPlay showArrows={false} showStatus={false}  showThumbs={true} showIndicators={false} key={items[0]} >
            {
                items?.map((item:any, i:any) => {
                    return (
                        <div key={i} >
                            <img src={item} alt={"img"} className='image' key={item}style={{backgroundColor:"white"}}/>
                        </div>
                    )
                })
            }

        </Carousel>

    )
}

export default AuctionResponsiveCarousel