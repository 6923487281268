import React from "react";
import { Auctions, EmptySection } from "./StyledAllAuction";
import AuctionItem from "../AuctionCards/AuctionCards";
import { purchaseAuction } from "../../features/auctionList";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";

interface AllAuctinProp {
  auctionList: any;
}
const AllAuctions: React.FC<AllAuctinProp> = ({ auctionList }) => {
  const isLoading = useSelector((state:RootState)=>state.loader.isLoading);
  const dispatch = useDispatch();
  const buyTickets = (id: number) => {
    dispatch(
      purchaseAuction({
        auctionId: id,
      })
    );
  };
  return (
    <>
      {auctionList.length > 0 ? (
        <>
          {auctionList.map((auctionItem: any) => {
            let auctionUrl = auctionItem?.auctionImageUrls
              ? auctionItem?.auctionImageUrls[0]
              : null;
            let totalUser =
              auctionItem?.totalUsers !== null ? auctionItem?.totalUsers : 100;
            let engagedUsersCount =
              auctionItem?.noOfUsersJoined !== null
                ? auctionItem?.noOfUsersJoined
                : 0;
            let usersParticipatedPercentage =
              auctionItem?.usersParticipatedPercentage !== null
                ? auctionItem?.usersParticipatedPercentage
                : 0;

            return (
              <Auctions key={auctionItem?.auctionId}>
                <AuctionItem
                  auctionObj={auctionItem}
                  auctionId={auctionItem?.auctionId}
                  imgUrl={auctionUrl}
                  title={auctionItem?.auctionTitle}
                  // totalUsers={auctionItem?.totalUsers}
                  totalUsers={100}
                  engagedUsers={usersParticipatedPercentage}
                  // engagedUsers={auctionItem?.noOfUsersJoined}
                  auctionProduct={auctionItem?.productType}
                  entryTicket={auctionItem?.bronzeTicketPrice}
                  drawDate={auctionItem?.auctionEndDate}
                  startDate={auctionItem?.auctionStartDate}
                  isLive={true}
                  onSelectBuy={buyTickets}
                />
              </Auctions>
            );
          })}
        </>
      ) : (
        <EmptySection>
          {isLoading?"Loading...":"No record found."}
        </EmptySection>
      )}
    </>
  );
};

export default AllAuctions;
