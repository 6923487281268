import * as localStorageActionTypes from './ActionTypes';

interface LoginResponse {
    accessToken: string,
    refreshToken: string
}

const setAccessToken = (obj: LoginResponse) => {
    if (typeof Storage !== "undefined") {
        localStorage.setItem("ACCESS_TOKEN", obj.accessToken);
        localStorage.setItem("REFRESH_TOKEN", obj.refreshToken);
    }
};


const setPublicUserId = (id: string) => {
    if (typeof Storage !== undefined) {
        localStorage.setItem("PUBLIC_USER_ID", id);
    }
}

const clearLoginDetails = () => {
    if (typeof Storage !== undefined) {
        localStorage.removeItem("ACCESS_TOKEN");
        localStorage.removeItem("REFRESH_TOKEN");
        localStorage.removeItem("PUBLIC_USER_ID");
        localStorage.removeItem("USER_DETAIL");
        localStorage.removeItem("IS_LOGGED_IN");
    }
};

const setUserDetail = (value: any) => {
    if (typeof Storage !== "undefined") {
        localStorage.setItem("USER_DETAIL", JSON.stringify(value));
    }
}

const setTwoFAStatus = (value: string) => {
    if (typeof Storage !== "undefined") {
        localStorage.setItem("IS_TWO_FA_ENABLED", value);
    }
}

const setSelectedLotteryObj = (value: any) => {
    if (typeof Storage !== "undefined") {
        localStorage.setItem("SELECTED_LOTTERY_OBJ", JSON.stringify(value));
    }
};

const setSelectedAuctionObj = (value: any) => {
    if (typeof Storage !== "undefined") {
        localStorage.setItem("SELECTED_AUCTION_OBJ", JSON.stringify(value));
    }
};

const setTicketDetailObj = (value: any) => {
    if (typeof Storage !== "undefined") {
        localStorage.setItem("TICKET_DETAIL_OBJ", JSON.stringify(value));
    }
};

const setPaymentToken = (value: any) => {
    if (typeof Storage !== "undefined") {
        localStorage.setItem("PAYMENT_TOKEN", value);
    }
};

const setIsLoggedIn = (value: any) => {
    if (typeof Storage !== "undefined") {
        localStorage.setItem("IS_LOGGED_IN", value);
    }
};
const setAccessInvalid = (value: any) => {
    if (typeof Storage !== "undefined") {
        localStorage.setItem("IS_ACCESS_INVALID", value);

    }
};

export const setLocalStorage = (type: string, value: any) => {
    switch (type) {
        case localStorageActionTypes.SET_ACCESS_REFRESH_TOKEN:
            setAccessToken(value);
            break;
        case localStorageActionTypes.SET_PUBLIC_USER_ID:
            setPublicUserId(value);
            break;
        case localStorageActionTypes.CLEAR_LOGIN_USER_DETAIL:
            clearLoginDetails();
            break;
        case localStorageActionTypes.SET_USER_DETAILS:
            setUserDetail(value);
            break;
        case localStorageActionTypes.SET_TWO_FA_STATUS:
            setTwoFAStatus(value);
            break;
        case localStorageActionTypes.SET_SELECTED_LOTTERY_OBJ:
            setSelectedLotteryObj(value);
            break;
        case localStorageActionTypes.SET_SELECTED_AUCTION_OBJ:
            setSelectedAuctionObj(value);
            break;
        case localStorageActionTypes.SET_TICKET_DETAIL_OBJ:
            setTicketDetailObj(value);
            break;
        case localStorageActionTypes.SET_PAYMENT_TOKEN:
            setPaymentToken(value);
            break;
        case localStorageActionTypes.SET_IS_LOGGED_IN:
            setIsLoggedIn(value);
            break;
        case localStorageActionTypes.SET_ACCESS_INVALID:
            setAccessInvalid(value)
            break;
        default:
            return null;
    }
}