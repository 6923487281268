import styled from 'styled-components';

export const Container = styled.div`
text-align:center;
max-width: 200px;
max-height: 200px;
`;

export const ProfileImage = styled.img`
object-fit: contain;
width: 100%;
max-height: 200px;
max-width: 200px;
height: 150px;
width: 150px;
border-radius: 50%;
`;

export const UpdateImageText = styled.div`
font-size: 14px;
color: #3A57E8;
cursor: pointer;
`;
