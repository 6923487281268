import React from 'react';
import styled from 'styled-components';
import Button from '../UI/Buttons/Button';
import { AppButtonType, ButtonSizeVariant, ButtonType, ButtonVariant } from '../../Utility/InterFacesAndEnum';
import * as UIConstants from "../../UIConstants";

interface CardProps {
  imageUrl: string;
  label: string;
  text: string;
  onClick1: () => void;
  onClick2: () => void;
}

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  // border: 1px solid black;

  // background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  @media (max-width:480px){
    width:100%;
  }
  `;

const CardImage = styled.img`
  background: #f0f0f0;
  /* margin-top: 11px; */
  width: 200px;
  height: 200px;
  object-fit: fill;
  @media (max-width:480px){
  width: 160px;
  height: 160px;
  }
`;

const CardLabel = styled.div`
   margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: black;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
  ${UIConstants.customMobileView}{
  width: 150px;

  }
`;

const CardText = styled.div`
  text-align:center;
  margin-top: 10px;
  font-size: 16px;
  color: black;
`;

const CardButtons = styled.div`
  padding-bottom: 5px;
  display: flex;
  gap:10px;
  justify-content: space-around;
  margin-top: 20px;
  width: 100%;
  Button{
    width:100px;
  }
`;

const DetailsButton = styled.button`
margin-left: 11px;
  padding: 11px;
  background-color: #E9EDF5;
  color: #000;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: #999;
  }
`;

const BuyButton = styled.button`
  margin-right: 11px;
  padding: 11px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  color: #ffffff;
  // background: linear-gradient(76.64deg, #3A57E8 5.43%, #647CF5 27.36%, #8395EF 46.41%, #4564FF 68.72%, #001994 90.4%);
  background: linear-gradient(76.64deg, #003459 5.43%, #326F9B 27.36%, #4B93C7 46.41%, #326F9B 68.72%, #003459 90.4%);
    padding: "16px";
    &:hover: {
      // backgroundColor: #3A57E8;
      backgroundColor: '#F54972',

  }
`;

const Card: React.FC<CardProps> = ({
  imageUrl,
  label,
  text,
  onClick1,
  onClick2
}) => {
  return (
    <>

    <CardContainer>
      <CardImage src={imageUrl} alt={label} key={imageUrl}/>
      <CardLabel>{label}</CardLabel>
      <CardText>{text}</CardText>
      <CardButtons>
        <DetailsButton onClick={onClick1}>MORE</DetailsButton>
        <BuyButton onClick={onClick2}>BUY</BuyButton>
      </CardButtons>
    </CardContainer>
    </>
  );
};

export default Card;