import styled from 'styled-components';
import * as UIConstants from '../../UIConstants';

export const Wrapper = styled.div`
width: 100%;
box-sizing: border-box;
padding: 50px;
@media(max-width:500px){
padding:30px 0 0 0;
}
`;

export const Container = styled.div`
width: 100%;
box-sizing: border-box;
padding: 50px;
background-color: #ffffff;
border-radius: 4px;
@media(max-width:500px){
    padding:5px;
}
`;

export const Section=styled.div`
background-color: rgb(247, 250, 245);
box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.2), 0 20px 20px 0 rgba(0, 0, 0, 0.19);
border-radius: 10px;
padding:0 40px 40px 40px;
@media(max-width:500px){
    padding:0px 20px 20px 20px;
box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.19);

}

`

export const Title = styled.div`
text-align:center;
color:red;
font-family: cursive;
font-size:100px;
font-weight:bold;
@media(max-width:500px){
font-size:90px;
    
}
`
export const Messege1= styled.div`
 color:black;
 text-align:center;
 font-size:60px;
 font-family: system-ui;

 padding-bottom:10px;
 @media(max-width:500px){
    font-size:50px;
 }
`
export const Messege2= styled.div`
 color:black;
 text-align:center;
 font-size:30px;
 font-weight:bold;
 @media(max-width:500px){
 font-size:20px;
    
}
`


export const SectionButtons=styled.div`
   margin-top:40px;
   Button {
    width: 140px;
    height: 40px;
    margin-right: 10px;
}
    display:flex;
   justify-content:center;
   gap:20px;
`
export const Button=styled.div`
    width:80px;
    text-align:center;
    cursor:pointer;
    border-radius:5px;
    color: #ffffff;
    background: linear-gradient(76.64deg, #3A57E8 5.43%, #647CF5 27.36%, #8395EF 46.41%, #4564FF 68.72%, #001994 90.4%);
    padding: 16px;
    &:hover{
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 10px 10px 0 rgba(0, 0, 0, 0.15);
        background-color:#3A57E8;

        
    }
   

`