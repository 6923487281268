import React, { FC } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./Style.css";
import useDimension from '../../CustomHooks/useDimention';

interface ResponsiveCarouselProp{
    items:any
    }

const ResponsiveCarousel:FC<ResponsiveCarouselProp> = ({ items }) => {

    
    return (
        <Carousel width={"100%"} infiniteLoop autoPlay showArrows={false} showStatus={false} showIndicators={false} >
            {
                items?.map((item:any, i:any) => {
                    return (
                        <div key={i}>
                            <img src={item} alt={"img"} className='image' key={item} style={{backgroundColor:"white"}}/>
                        </div>
                    )
                })
            }

        </Carousel>

    )
}

export default ResponsiveCarousel