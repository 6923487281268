import styled from "styled-components";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import * as UIConstants from "../../UIConstants";
import { NavLink } from "react-router-dom";

export const Container = styled.div`
  padding: 20px;
  @media (max-width: 650px) {
    padding: 5px;
  }
`;

export const Wrapper = styled.div`
  color: black;
  background-color: #ffffff;
`;

export const Title = styled.div`
  color: black;
  padding: 10px 0px 10px 0px;
  font-weight: 600;
  font-size: 20px;
`;
export const SectionWrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  @media (max-width: 650px) {
    padding: 30px 10px 30px 10px;
  }
`;
export const UserSection = styled.div`
  // height:50px;
  display: flex;
  flex-wrap: wrap;
  width: 550px;
  background-color: #fbfcfc;
  border-radius: 10px;
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
  @media (max-width: 650px) {
    justify-content: center;
  }
  @media (min-width: 1500px) {
    width: 650px;
  }
`;
export const Img = styled.div`
  padding: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
`;

export const Details = styled.div`
  padding: 20px 20px 10px 20px;
`;

export const WalletSection = styled.div`
  // height:50px;
  width: 550px;
  background-color: #fbfcfc;
  border-radius: 10px;
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
  @media (min-width: 1500px) {
    width: 650px;
  }
`;
export const DetailSection = styled.div`
  display: flex;
`;
export const DetailIcon = styled.span`
  margin-right: 5px;
  margin-top: 2px;
  font-size: 18px;
  opacity: 0.8;
`;
export const UserButtonSection = styled.div`
  margin-top: 70px;
`;
export const Heading = styled.div`
  font-weight: bold;
  font-size: 25px;
  margin: 10px 0 10px 0;
`;

export const BalanceSection = styled.div`
  margin-bottom: 5px;
  display: inline-flex;
`;
export const WalletIcon = styled(AccountBalanceWalletIcon)`
  margin-top: 15px;
  color: black;
  opacity: 0.7;
  margin-right: 10px;
`;

export const Balance = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: gray;
`;

export const AddMoneySection = styled.div`
  margin-top: 10px;
`;
export const AddMoneyAmount = styled.div`
  margin: 10px 0 20px 0px;
  width: 100%;
  box-sizing: border-box;
  flex-flow: row nowrap;
  background: linear-gradient(
    76.64deg,
    #efdfa3 5.43%,
    #ce8631 29.33%,
    #c1a053 47.47%,
    #f4c045 68.72%,
    #c88221 90.4%
  );
  border-radius: 6px;
  padding: 24px 56px;
`;
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
`;
export const Amount = styled.div`
  font-weight: 400;
  font-size: 30px;
`;
export const AddMoneyInvoice = styled.div`
  border: 2px dotted gray;
  border-radius: 5px;
  padding: 5px;
  font-style: italic;
  margin: 10px 0 15px 0px;
  color: gray;
  font-weight: bold;
  font-size: 20px;
`;
export const AddMoneySpan = styled.span`
  padding: 0 10px 0 20px;
`;
export const BtnSection = styled.div`
  margin-top: 105px;
`;

export const Remark = styled.div`
  margin-top: 5px;
  font-weight: 500;
  opacity: 0.7;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;
export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  /* Add other styles as needed */
`;
export const InputSection = styled.div`
  margin-top: 105px;
  display: flex;
  gap: 10px;
`;

export const LoaderSection = styled.div`
  background: linear-gradient(
    76.64deg,
    #3a57e8 5.43%,
    #647cf5 27.36%,
    #8395ef 46.41%,
    #4564ff 68.72%,
    #001994 90.4%
  );
`;
