import styled from 'styled-components';

export const Wrapper = styled.div`
width: 100%;
box-sizing: border-box;
`;

export const FooterWapper = styled.div`
width: 100%;
box-sizing: border-box;
margin-top: 30px;
`;