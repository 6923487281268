import { FC, useEffect, useState } from "react";
import {
  Wrapper,
  Container,
  CoinsSection,
  Coin,
  CoinLabel,
  MainLotterySection,
  MainLotteryImg,
  RewardDetail,
  LotteryDrawWrapper,
  DrawLabel,
  AuctionBtns,
  FooterSection,
  RewardDetailSkeleton,
  MainLotteryImgSkeleton,
} from "./StyledMainLottery";

import {
  ButtonSizeVariant,
  ButtonType,
  ButtonVariant,
  AppButtonType,
} from "../../Utility/InterFacesAndEnum";
import Button from "../UI/Buttons/Button";
import LotteryCoin from "../../assets/lotteryCoinremovedbg.png";
import EntryTicketIcon from "../../assets/ticket -icon.png";
// import UserIcon from "../../assets/User-icon.svg";
import lottery from "../../assets/order-lottery.svg";
import WinnerIcn from "../../assets/Winner-icon.svg";
import Coin1Src from "../../assets/coin1.png";
import Coin2Src from "../../assets/coin2.png";
import Coin3Src from "../../assets/coin3.png";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import { transformDate } from "../../Utility/Utility";

import { useNavigate } from "react-router-dom";
import { RouterPath } from "../../routes/routes";

import * as LiveLotteryStyle from "../LiveLottery/StyledLiveLottery";
import * as localStorageActionType from "../../localStorage/ActionTypes";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import BuyLotteryModal from "../../components/Modals/BuyTicketModal/BuyTicketModal";
import { getTopLotteryGame } from "../../features/lotteriesSlice";
import { setLocalStorage } from "../../localStorage/SetLocalStorage";
import { getUserProfileDetail } from "../../features/userProfileSlice";
import { Skeleton } from "@mui/material";
import useDimension from "../../CustomHooks/useDimention";
import * as UIConstant from "../../UIConstants";

const MainLottery: FC = () => {
  interface SelectedTicket {
    label: string;
    amount: number;
    selected: boolean;
    ticketType: string;
  }
  // const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  const dimention = useDimension();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isBuyTicketModalVisible, setModalVisibility] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<SelectedTicket[] | []>(
    []
  );
  const topLotteryGame = useSelector(
    (state: RootState) => state.lotteries.topLottery
  );
  // console.log(topLotteryGame);
  const isTopGame = useSelector(
    (state: RootState) => state.lotteries.isTopGame
  );

  useEffect(() => {
    dispatch(getTopLotteryGame());
    dispatch(getUserProfileDetail());
  }, []);

  let isLoggedIn = getLocalStorage(localStorageActionType.GET_IS_LOGGED_IN);
  let userDetails = getLocalStorage(localStorageActionType.GET_USER_DETAILS);
  let isLoading = useSelector((state: RootState) => state.loader.isLoading);
  // console.log(Object.keys(userDetails).length!==0 ? JSON.parse(userDetails) : "userDetails not found");
  let isGoldMember =
    Object.keys(userDetails).length !== 0
      ? JSON.parse(userDetails).isGoldMember
      : false;
  // console.log(isGoldMember)
  const buyTicketHandler = () => {
    // console.log("clicked")
    let ticketsArray = [
      {
        label: "Bronze Ticket",
        amount: topLotteryGame.bronzeTicketPrice,
        selected: false,
        ticketType: "bronze",
      },
      {
        label: "Silver Ticket",
        amount: topLotteryGame.silverTicketPrice,
        selected: false,
        ticketType: "silver",
      },
      {
        label: "Gold Ticket",
        amount: topLotteryGame.goldTicketPrice,
        selected: true,
        ticketType: "gold",
      },
      {
        label: "Platinum Ticket",
        amount: topLotteryGame.platinumTicketPrice,
        selected: false,
        ticketType: "Platinum",
      },
    ];
    setSelectedTicket(ticketsArray);
    let updatedObj = {
      ...topLotteryGame,
      lotteryGameEndDate: new Date(topLotteryGame.lotteryGameEndDate)
        .getTime()
        .toString(),
    };
    setLocalStorage(
      localStorageActionType.SET_SELECTED_LOTTERY_OBJ,
      updatedObj
    );

    isLoggedIn ? setModalVisibility(true) : navigate(RouterPath.signIn);
  };

  return (
    isTopGame && (
      <Wrapper>
        {selectedTicket.length > 0 ? (
          <BuyLotteryModal
            ticket={selectedTicket}
            label={"Buy tickets"}
            isVisible={isBuyTicketModalVisible}
            toggleModal={() => {
              setModalVisibility(!isBuyTicketModalVisible);
            }}
          />
        ) : (
          <div></div>
        )}

        {!isLoading ? (
          <>
            <Container>
              <MainLotterySection>
                <MainLotteryImg src={LotteryCoin} />
                <RewardDetail>&#36;{topLotteryGame?.rewardAmount.toLocaleString()}</RewardDetail>
                <LotteryDrawWrapper>
                  <DrawLabel>
                    Hurry Lottery Ends On :
                    <CountdownTimer
                      timestamp={
                        new Date(new Date(topLotteryGame?.lotteryGameEndDate))
                      }
                      isDay={false}
                    />
                  </DrawLabel>
                </LotteryDrawWrapper>
              </MainLotterySection>
              <MainLotterySection>
                <AuctionBtns>
                  <Button
                    disabled={false}
                    appBtnType={AppButtonType.primaryBtn}
                    fullWidth={true}
                    variant={ButtonVariant.contained}
                    type={ButtonType.default}
                    size={ButtonSizeVariant.small}
                    clicked={buyTicketHandler}
                  >
                    BUY TICKETS
                  </Button>
                  {!isGoldMember && (
                    <Button
                      disabled={false}
                      appBtnType={AppButtonType.secondary}
                      fullWidth={true}
                      variant={ButtonVariant.contained}
                      type={ButtonType.default}
                      size={ButtonSizeVariant.small}
                      clicked={() => {
                        navigate(
                          isLoggedIn ? RouterPath.membership : RouterPath.signIn
                        );
                      }}
                    >
                      BUY GOLD MEMBERSHIP
                    </Button>
                  )}
                </AuctionBtns>
              </MainLotterySection>
            </Container>
            <FooterSection>
              <LiveLotteryStyle.FooterOption>
                <LiveLotteryStyle.FooterImg src={EntryTicketIcon} />
                <LiveLotteryStyle.FooterTitle>
                  Entry Tickets :
                </LiveLotteryStyle.FooterTitle>
                <LiveLotteryStyle.FooterValue>
                  &#36;{topLotteryGame?.bronzeTicketPrice}
                </LiveLotteryStyle.FooterValue>
              </LiveLotteryStyle.FooterOption>
              <LiveLotteryStyle.FooterOption>
                <LiveLotteryStyle.FooterImg src={lottery} />
                <LiveLotteryStyle.FooterTitle>
                  Lottery Number :
                </LiveLotteryStyle.FooterTitle>
                <LiveLotteryStyle.FooterValue>
                  {topLotteryGame?.lotteryGameNo}{" "}
                </LiveLotteryStyle.FooterValue>
              </LiveLotteryStyle.FooterOption>
              <LiveLotteryStyle.FooterOption>
                <LiveLotteryStyle.FooterImg src={WinnerIcn} />
                <LiveLotteryStyle.FooterTitle>
                  Winner will be announced on :
                </LiveLotteryStyle.FooterTitle>
                <LiveLotteryStyle.WinnerValue>
                  {!isLoading &&
                    transformDate(new Date(topLotteryGame?.lotteryGameEndDate))}
                </LiveLotteryStyle.WinnerValue>
              </LiveLotteryStyle.FooterOption>
            </FooterSection>
          </>
        ) : (
          <>
            <Container>
              <MainLotterySection>
                <MainLotteryImgSkeleton>
                  <Skeleton
                    style={{ borderRadius: "5px" }}
                    sx={{ bgcolor: "#f5f5f5" }}
                    variant="rectangular"
                    width={
                      dimention.width <= UIConstant.customMobileWidth
                        ? 260
                        : 360
                    }
                    height={200}
                    animation="wave"
                  />
                </MainLotteryImgSkeleton>

                <RewardDetailSkeleton>
                  <Skeleton
                    style={{ borderRadius: "5px" }}
                    sx={{ bgcolor: "#f5f5f5" }}
                    variant="rectangular"
                    width={
                      dimention.width <= UIConstant.customMobileWidth
                        ? 160
                        : 260
                    }
                    // height={200}
                    animation="wave"
                  />
                </RewardDetailSkeleton>
                <LotteryDrawWrapper>
                  <DrawLabel>
                    <Skeleton
                      style={{ borderRadius: "5px" }}
                      sx={{ bgcolor: "#f5f5f5" }}
                      variant="rectangular"
                      width={300}
                      // height={200}
                      animation="wave"
                    />
                  </DrawLabel>
                </LotteryDrawWrapper>
              </MainLotterySection>
              <MainLotterySection>
                <AuctionBtns>
                  <Skeleton
                    style={{ borderRadius: "5px" }}
                    sx={{ bgcolor: "#f5f5f5" }}
                    variant="rectangular"
                    width={
                      dimention.width <= UIConstant.customMobileWidth
                        ? 160
                        : 200
                    }
                    height={50}
                    animation="wave"
                  />
                  <Skeleton
                    style={{ borderRadius: "5px" }}
                    sx={{ bgcolor: "#f5f5f5" }}
                    variant="rectangular"
                    width={
                      dimention.width <= UIConstant.customMobileWidth
                        ? 160
                        : 200
                    }
                    height={50}
                    animation="wave"
                  />
                </AuctionBtns>
              </MainLotterySection>
            </Container>
            <FooterSection>
              <LiveLotteryStyle.FooterOption>
                <Skeleton
                  style={{ borderRadius: "5px" }}
                  sx={{ bgcolor: "#f5f5f5" }}
                  variant="rectangular"
                  width={300}
                  // height={200}
                  animation="wave"
                />
              </LiveLotteryStyle.FooterOption>
              <LiveLotteryStyle.FooterOption>
                <Skeleton
                  style={{ borderRadius: "5px" }}
                  sx={{ bgcolor: "#f5f5f5" }}
                  variant="rectangular"
                  width={300}
                  // height={200}
                  animation="wave"
                />
              </LiveLotteryStyle.FooterOption>
              <LiveLotteryStyle.FooterOption>
                <Skeleton
                  style={{ borderRadius: "5px" }}
                  sx={{ bgcolor: "#f5f5f5" }}
                  variant="rectangular"
                  width={300}
                  // height={200}
                  animation="wave"
                />
              </LiveLotteryStyle.FooterOption>
            </FooterSection>
          </>
        )}
      </Wrapper>
    )
  );
};

export default MainLottery;
