import styled from "styled-components";

export const Wrapper = styled.div`
height:70vh;
color:gray;
display:flex;
justify-content:center;
align-item:center;
`
export const LoaderWrapper = styled.div`
margin-top:200px;
`