import styled from 'styled-components';
import * as UIConstants from "../../UIConstants";

export const Wrapper = styled.div`
width:100%;
box-sizing: border-box;
`;

export const Table = styled.table`
border: none;
border-collapse: collapse;
color: #200E32;
width: 100%;
box-sizing: border-box;
text-align: left;
thead tr td{
    // width: 30%;
    font-weight: 600;
    padding:20px 8px 20px 8px;
};
tbody tr td{
    padding :30px 10px 30px 10px;
    ${UIConstants.customMobileView}{
    padding :8px;
    
}
}
tr{
    border-bottom: 1px solid #E9EFFF;
}
td{
}

`;
export const TableWrapper = styled.div`
width: 100%;
box-sizing: border-box;
overflow-x: auto;
`

export const EmptyView = styled.div`
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 100px 0;
  color: #200e32;
  font-size: 18px;
`;