import {FC, useEffect} from 'react';
import {Wrapper, ViewHeader, Container, Title, Subtitle, ActionSection, PurchaseSuccessWrapper, SuccessImage} from '../LoadMoney/StyledLoadMoneyPayment';
import {ButtonSizeVariant, ButtonVariant, ButtonType, AppButtonType} from '../../Utility/InterFacesAndEnum';
import Button from '../../components/UI/Buttons/Button';

import successSrc from '../../assets/failure.svg';
import {RouterPath} from '../../routes/routes'
import {useNavigate} from 'react-router-dom';


const LotteryPaymentFail:FC = () => {
    const navigate = useNavigate();
   

    return(
        <Wrapper>
            <ViewHeader />
            <PurchaseSuccessWrapper>
            <Container>
                <SuccessImage src={successSrc} />
                <Title>
                Oh no, Payment Failed.
                </Title>
                {/* <Subtitle>
                </Subtitle> */}
                <ActionSection>
                <Button 
                appBtnType={AppButtonType.primaryBtn}
                    disabled={false} 
                    fullWidth={false} 
                    size={ButtonSizeVariant.large} 
                    variant={ButtonVariant.contained} 
                    type={ButtonType.submit} 
                    clicked={() => {
                        // resetRootState();
                        navigate(RouterPath.lotteries)

                    }} >
                        Go Back
                </Button>
                </ActionSection>
            </Container>
            </PurchaseSuccessWrapper>
        </Wrapper>
    )
};

export default LotteryPaymentFail