import styled from "styled-components";
import * as UIConstants from "../../UIConstants";

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 50px;
  ${UIConstants.customMobileView} {
    padding: 10px;
  }
`;

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 50px;
  background-color: #ffffff;
  border-radius: 4px;
  ${UIConstants.customMobileView} {
    padding: 10px;
  }
`;

export const Title = styled.title`
  display: flex;
  font-size: 30px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 50px;
  ${UIConstants.customMobileView} {
    margin-bottom: 20px;
    font-size: 25px;
  }
`;

export const List = styled.ul`
  padding-left: 60px;
  margin-bottom: 30px;
  li {
    font-size: 16px;
    color: #000000;
    line-height: 34px;
    text-align: justify;
  }
  ${UIConstants.customMobileView} {
    padding-left: 40px;
  }
`;

export const Details = styled.div`
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  text-align: justify;
  line-height: 34px;
  margin-bottom: 30px;
`;

export const B = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  color: #000000;
  font-weight: bold;
  font-size: 18px;
`;
export const P = styled.p`
  // margin-top:10px;
  font-size: 16px;
  line-height: 34px;
  color: #000000;
  text-align: justify;

  // font-weight:bold;
`;
