import { FC, useState, useEffect } from "react";
import Navigation from "../../components/Navigation/DesktopNavigation";
import {
  Wrapper,
  Viewheader,
  Container,
  ProfileContainer,
  TabMenuContainer,
  TabMenuElement,
  TabMenuBody,
  TabMenuContent,
} from "./StyledUserProfile";
import PersonalInfoForm from "./PersolanInfo/PersonalInfo";
import Address from "./Address/Address";
import ChangePassword from "./ChangePassword/ChangePassword";
import TwoFA from "./2FA/2FA";
import Subscription from "./Subscription/Subscription";
import { useLocation, useNavigate } from "react-router-dom";
import "./Style.css";
import { useDispatch } from "react-redux";
import { getUserDetailes } from "../../features/loadMoneySlice";
import { getUserProfileDetail } from "../../features/userProfileSlice";
import { Helmet } from "react-helmet";

const tabMenuOptions = [
  {
    label: "Personal Info",
    propsId: "PROFILE",
    id: 1,
    isSelected: false,
    route: "profile",
  },
  {
    label: "Address",
    propsId: "ADDRESS",
    id: 2,
    isSelected: false,
    route: "address",
  },
  {
    label: "Change Password",
    propsId: "CHANGE PASSWORD",
    id: 3,
    isSelected: false,
    route: "change-password",
  },
  {
    label: "2 Factor Authentication",
    propsId: "SETTINGS",
    id: 4,
    isSelected: false,
    route: "two-fa",
  },
  
];

interface ProfileProps {
  activeState: string;
}

const UserProfile: FC<ProfileProps> = ({ activeState }) => {
  const [tabMenu, setTabMenuHandler] = useState(tabMenuOptions);
  

  const location = useLocation();

  // console.log("location :", location.pathname);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getUserDetailes());
    dispatch(getUserProfileDetail());
  },[])

  // let tabMenuView = tabMenu.map((menuObj) => {
  //     return <TabMenuElement key={menuObj.id} isSelectdItem ={menuObj.isSelected} >
  //         {menuObj.label}
  //     </TabMenuElement>
  // });
  const [select, setSelect] = useState(false);
  const tabmenuHandler = (route: string, id: Number) => {
    navigate(`/account/${route}`);
    setSelect(!select);
  };

  let tabMenuView = tabMenu.map((menuObj) => {
    // console.log(menuObj)

    return (
      <TabMenuElement
        className={
          location.pathname === `/account/${menuObj.route}`
            ? "tabMenuSelect"
            : "tabMenu"
        }
        key={menuObj.id}
        isSelectdItem={select}
        onClick={() => tabmenuHandler(menuObj.route, menuObj.id)}
      >
        {menuObj.label}
      </TabMenuElement>
    );
  });

  useEffect(() => {
    let updatedMenu = tabMenu.map((menuObj) => {
      return {
        ...menuObj,
        isSelected: menuObj.propsId === activeState,
      };
    });
    setTabMenuHandler(updatedMenu);
  }, [location]);

  let detailView = <PersonalInfoForm />;

  for (let menuObj of tabMenu) {
    if (menuObj.isSelected === true) {
      if (menuObj.id === 1) {
        detailView = <PersonalInfoForm />;
      } else if (menuObj.id === 2) {
        detailView = <Address />;
      } else if (menuObj.id === 3) {
        detailView = <ChangePassword />;
      } else if (menuObj.id === 4) {
        detailView = <TwoFA />;
      }
      //   else if (menuObj.id === 5) {
      //     detailView = <Subscription />;
      //   }
    }
  }

  return (
    <>
      <Wrapper>
        <Helmet>
          <title>KingsRings - Profile</title>
        </Helmet>
        <Viewheader>{activeState}</Viewheader>
        <Container>
          <ProfileContainer>
            {activeState === "MEMBERSHIP" ? null : (
              <TabMenuContainer>{tabMenuView}</TabMenuContainer>
            )}
            <TabMenuBody>
              <TabMenuContent>
                {activeState === "MEMBERSHIP" ? <Subscription /> : detailView}
              </TabMenuContent>
            </TabMenuBody>
          </ProfileContainer>
        </Container>
      </Wrapper>
    </>
  );
};

export default UserProfile;
