import styled from 'styled-components';

export const Wrapper = styled.div`
width: 100%;
box-sizing: border-box;
display: flex;
flex-flow:row nowrap;
align-items: center;
justify-content: flex-start;
`;

export const DollarSymbol = styled.div`
padding-right: 10px;
`;