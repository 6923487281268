import { createAsyncThunk, PayloadAction, createSlice } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtilities/endpoints";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { toggleNotificationVisibility } from "./notificationSlice";
import { NotificationType } from "../Utility/InterFacesAndEnum";
import { getUserProfileDetail, setIsAuthorized } from "./userProfileSlice";
import { toggleLoader } from "./loaderSlice";
import { setLocalStorage } from "../localStorage/SetLocalStorage";

interface Loading {
  isLoading: boolean;
}

interface UserDetails {
  userData: any;
}

interface PurchaseProp {
  isPurchase: boolean;
}

interface loadMoneyState {
  checkout: [];
  isLoading: boolean;
  userData: any;
  isPurchase: boolean;
}

const initialState: loadMoneyState = {
  checkout: [],
  isLoading: false,
  userData: [],
  isPurchase: false,
};

interface getCheckout {
  checkoutList: [];
}
export const loadMoneyCheckout = createAsyncThunk(
  "load money checkout",
  async (payload: any, { dispatch }) => {
    dispatch(
      toggleLoading({
        isLoading: true,
      })
    );
    // console.log(payload);
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(endpoints.loadMoneyCheck, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        // Payload=data?.links;
        dispatch(toggleLoader({ isLoading: false }));
        dispatch(
          toggleLoading({
            isLoading: false,
          })
        );
        // console.log(data.links)
        data.links.filter((obj: any) => {
          if (obj.rel === "payer-action") {
            console.log(obj);
            dispatch(
              setLoadMoneyCheckout({
                checkoutList: obj,
              })
            );
          }
        });
        // dispatch(setLoadMoneyCheckout({
        //     checkoutList:data.links
        // }))
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        return err.json();
        console.log(err);
      });
  }
);

export const loadMoneyComplete = createAsyncThunk(
  "load money complete",
  async (payload: any, { dispatch }) => {
    const token = getLocalStorage(localStorageActionType.GET_PAYMENT_TOKEN);
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(`${endpoints.loadMoneyComplete}${token}`, {
      method: "POST",
      body: payload,
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));
        if (data.statusCode === 200) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.success,
              message: data.errorMsg,
            })
          );
          dispatch(
            togglePurchaseState({
              isPurchase: true,
            })
          );
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
        // console.log(data);
        dispatch(getUserProfileDetail());
        dispatch(getUserDetailes());
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        console.log(err);
      });
  }
);

export const getUserDetailes = createAsyncThunk(
  "get user details",
  async (payload, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(
      `${endpoints.viewProfile}${getLocalStorage(
        localStorageActionType.GET_PUBLIC_USER_ID
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 401) {
          setLocalStorage(localStorageActionType.CLEAR_LOGIN_USER_DETAIL, "");
          setLocalStorage(localStorageActionType.SET_ACCESS_INVALID, true);
          dispatch(
            setIsAuthorized({
              isAuthorized: false,
            })
          );
          // window.location.reload();
        }
        dispatch(toggleLoader({ isLoading: false }));
        if (data?.statusCode === 200) {
          dispatch(
            setUserDetails({
              userData: data?.result,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: true }));
        console.log(err);
      });
  }
);

const LoadMoneySlice = createSlice({
  name: "loadMoney Slice",
  initialState: initialState,
  reducers: {
    setLoadMoneyCheckout: (state, action: PayloadAction<getCheckout>) => {
      return {
        ...state,
        checkout: action.payload.checkoutList,
      };
    },
    toggleLoading: (state, action: PayloadAction<Loading>) => {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    },
    setUserDetails: (state, action: PayloadAction<UserDetails>) => {
      return {
        ...state,
        userData: action.payload.userData,
      };
    },
    togglePurchaseState: (state, action: PayloadAction<PurchaseProp>) => {
      return {
        ...state,
        isPurchase: action.payload.isPurchase,
      };
    },
    resetPurchaseState: (state, action: PayloadAction<void>) => {
      return {
        ...state,
        isPurchase: false,
      };
    },
  },
});

export const {
  setLoadMoneyCheckout,
  toggleLoading,
  setUserDetails,
  togglePurchaseState,
  resetPurchaseState,
} = LoadMoneySlice.actions;
export default LoadMoneySlice.reducer;
