import { FC, useState, useEffect, Fragment } from "react";
import Backdrop from "../Backdrop/Backdrop";
import headerLogo from "../../assets/headerLogo.svg";
import {
  ButtonSizeVariant,
  ButtonType,
  ButtonVariant,
  AppButtonType,
} from "../../Utility/InterFacesAndEnum";
import { RootState } from "../../app/store";
import { useSelector, useDispatch } from "react-redux";
import * as localStorageActionType from "../../localStorage/ActionTypes";
import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import { setLocalStorage } from "../../localStorage/SetLocalStorage";
import Button from "../UI/Buttons/Button";
import { setNavResponse } from "../../features/navigationSlice";
import { RouterPath } from "../../routes/routes";
import { useNavigate, useLocation } from "react-router-dom";

import {
  M_Wrapper,
  M_Container,
  NavBar,
  ToggleButton,
  LogoLabel,
  Action,
  CloseSection,
  Name,
  SelectedNavItem,
  NavItem,
  NavMenuSection,
  ProfileSection,
  ProfileItem,
  WalletContainer,
  Amount,
  LoginDiv,
} from "./StyledDesktopNavigation";
import { ToggleIcon, Close, WalletIcon } from "./StyledDesktopNavigation";

const ResponsiveMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  let navigationData = useSelector((state: RootState) => state.navigation.data);
  let isLoggedIn = getLocalStorage(localStorageActionType.GET_IS_LOGGED_IN);
  

  const [name, setName] = useState("");
  const [isMenuExpanded, setExpandStatus] = useState(false);
  const { userData } = useSelector((state: RootState) => state.loadmoney);
  const {userDetail} =useSelector((state:RootState)=>state.userProfile);
  // console.log(userDetail)
  // let userObj = JSON.parse(
  //   getLocalStorage(localStorageActionType.GET_USER_DETAILS)
  // );

  const toggleMenuExpantion = () => {
    setExpandStatus(!isMenuExpanded);
  };

  useEffect(() => {
    let updatedResponse = navigationData.map((navObj) => {
      return {
        ...navObj,
        isSelected: navObj.activeRoutes.indexOf(location.pathname) >= 0,
      };
    });
    dispatch(
      setNavResponse({
        data: updatedResponse,
      })
    );
  }, [location]);

  useEffect(() => {
    if (name === "") {
      let useerDetailObj = getLocalStorage(
        localStorageActionType.GET_USER_DETAILS
      );
      if (useerDetailObj !== null && Object.keys(useerDetailObj).length > 0) {
        let userObj = JSON.parse(
          getLocalStorage(localStorageActionType.GET_USER_DETAILS)
        );
        setName(userObj.firstName);
      }
    }
  }, []);
  
//  console.log(name.length,"lenght of name")
  const createLogout = () => {
    setLocalStorage(localStorageActionType.CLEAR_LOGIN_USER_DETAIL, "");
    navigate(RouterPath.tempRoot);
    navigate(RouterPath.signIn);
    window.location.reload();
  };

  const redirectToLogin = () => {
    navigate(RouterPath.signIn);
  };

  const redirectToView = (url: string) => {
    navigate(url);
    toggleMenuExpantion();
  };

  let walletandLoginView = <div>loading...</div>;

  if (!isLoggedIn) {
    walletandLoginView = (
      <Fragment>
        <LoginDiv
          onClick={() => {
            redirectToLogin();
          }}
        >
          login
        </LoginDiv>
      </Fragment>
    );
  } else {
    walletandLoginView = (
      <WalletContainer onClick={() => navigate(RouterPath.loadMoney)}>
        <WalletIcon />
        <Amount>&#x24;{userData?.amount}</Amount>
      </WalletContainer>
    );
  }

  let navView = navigationData.map((navObj): JSX.Element => {
    let Item = navObj.isSelected ? SelectedNavItem : NavItem;
    return (
      <Item
        key={navObj.key}
        onClick={() => {
          redirectToView(
            (navObj.label === "purchase" || navObj.label === "loadMoney") &&
              !isLoggedIn
              ? RouterPath.signIn
              : navObj.navRoute
          );
          // redirectToView(
          //   (navObj.label === "purchase" && !accessToken)
          //     ? RouterPath.signIn
          //     : navObj.navRoute

          // );
        }}
      >
        {navObj.label}
      </Item>
    );
  });

  return (
    <M_Wrapper>
      <Backdrop isVisible={isMenuExpanded} />
      <NavBar>
        <ToggleButton onClick={toggleMenuExpantion}>
          <ToggleIcon />
        </ToggleButton>
        <LogoLabel>Kings Rings</LogoLabel>
        <Action>{isLoggedIn && walletandLoginView}</Action>
      </NavBar>
      <M_Container isExpanded={isMenuExpanded}>
        <CloseSection onClick={toggleMenuExpantion}>
          {isLoggedIn ? (
            <Name>Hi, {userDetail?.firstName}</Name>
          ) : (
            <Name
              onClick={() => {
                redirectToLogin();
              }}
            >
              Login
            </Name>
          )}{" "}
          <Close />
        </CloseSection>
        <NavMenuSection>{navView}</NavMenuSection>
        {isLoggedIn && (
          <ProfileSection>
            <ProfileItem
              onClick={() => {
                redirectToView(RouterPath.profile);
              }}
            >
              Profile
            </ProfileItem>
            <ProfileItem
              onClick={() => {
                redirectToView(RouterPath.membership);
              }}
            >
              Membership
            </ProfileItem>
            {isLoggedIn && (
              <ProfileItem onClick={createLogout}>Logout</ProfileItem>
            )}
          </ProfileSection>
        )}
      </M_Container>
    </M_Wrapper>
  );
};

export default ResponsiveMenu;
