import { FC } from "react";
import {
  ButtonSizeVariant,
  ButtonType,
  ButtonVariant,
  AppButtonType,
} from "../../Utility/InterFacesAndEnum";
import Button from "../UI/Buttons/Button";
import { transformDate } from "../../Utility/Utility";

import LotteryCoin from "../../assets/lotteryCoin.png";
import EntryTicketIcon from "../../assets/ticket -icon.png";
import goldIcon from "../../assets/gold-icon.svg";
import UserIcon from "../../assets/User-icon.svg";
import WinnerIcon from "../../assets/Winner-icon.svg";
import CountdownTimer from "../CountdownTimer/CountdownTimer";

import {
  Wrapper,
  Container,
  ImgSection,
  LotteryDetail,
  CoinImg,
  RewardTitle,
  RewardDetail,
  CounterWrapper,
  CounterTitle,
  TicketDetails,
  IconImg,
  Detail,
  Actions,
  RewardName,
  ContainerWrapper,
  GoldImg,
} from "./StyledUpcomingLottery";
import { notifyMeLottery } from "../../features/notificationSlice";
import { useDispatch } from "react-redux";
import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import * as localStorageActionType from "../../localStorage/ActionTypes";
import { useNavigate } from "react-router-dom";
import { RouterPath } from "../../routes/routes";

interface UpcomingLotteryProps {
  lotteryId: number;
  reward: string | number;
  gameType: "M" | "G" | string;
  gameStartsOn: string;
  entryTicket: number;
  onClickNotifyMe(id: string | number): void;
  rewardImages: any;
  isMemberLottery: boolean;
}

const UpcomingLottery: FC<UpcomingLotteryProps> = (props) => {
  const {
    lotteryId,
    reward,
    gameType,
    gameStartsOn,
    entryTicket,
    onClickNotifyMe,
    rewardImages,
    isMemberLottery,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = getLocalStorage(localStorageActionType.GET_IS_LOGGED_IN);
  const publicUserId = getLocalStorage(
    localStorageActionType.GET_PUBLIC_USER_ID
  );

  const onSelectyNotifyMe = (id: number) => {
    isLoggedIn
      ? // alert(id)
        dispatch(
          notifyMeLottery({
            publicUserId: publicUserId,
            notifyType: "L",
            lotteryGameId: id,
          })
        )
      : navigate(RouterPath.signIn);
  };

  return (
    <Wrapper>
      <ContainerWrapper>
        <GoldImg>
          {isMemberLottery ? <IconImg src={goldIcon} /> : <div></div>}
        </GoldImg>
        <Container isMember={isMemberLottery}>
          <ImgSection>
            {rewardImages !== null && gameType === "G" ? (
              <CoinImg src={rewardImages[0]} />
            ) : (
              <CoinImg src={LotteryCoin} />
            )}
          </ImgSection>
          <LotteryDetail>
            <RewardTitle>Reward</RewardTitle>
            {gameType === "M" ? (
              <RewardDetail>
                <span>&#x24;</span>
                {reward.toLocaleString()}
              </RewardDetail>
            ) : (
              <RewardName>{reward}</RewardName>
            )}
            <CounterWrapper>
              <CounterTitle>Starts On:</CounterTitle>
              <CountdownTimer
                timestamp={new Date(gameStartsOn)}
                isDay={false}
              />
            </CounterWrapper>
            <TicketDetails>
              <IconImg src={EntryTicketIcon} />
              <Detail>Entry Ticket: ${entryTicket}</Detail>
            </TicketDetails>
            <TicketDetails>
              <IconImg src={WinnerIcon} />
              <Detail>
                Starts on: {transformDate(new Date(gameStartsOn))}
              </Detail>
            </TicketDetails>
            {/* <TicketDetails>
            <IconImg src={WinnerIcon} />
            <Detail>{isMemberLottery?"Gold Member":"All Users"}</Detail>
          </TicketDetails> */}
            <Actions>
              <Button
                disabled={false}
                appBtnType={AppButtonType.secondary}
                fullWidth={false}
                variant={ButtonVariant.contained}
                type={ButtonType.default}
                size={ButtonSizeVariant.small}
                clicked={() => {
                  // onClickNotifyMe(lotteryId);
                  onSelectyNotifyMe(lotteryId);
                }}
              >
                Notify Me
              </Button>
            </Actions>
          </LotteryDetail>
        </Container>
      </ContainerWrapper>
    </Wrapper>
  );
};

export default UpcomingLottery;
