import styled from "styled-components";
import * as UIConstants from '../../UIConstants';

export const AuctionList = styled.div`
width: 100%;
box-sizing: border-box;
display: flex;
flex-flow: row wrap;
align-items: flex-start;
justify-content: flex-start;
gap: 33px;
${UIConstants.mobileView}{
    // gap: 15px;
}
`;

export const AuctionListItem = styled.div`
margin:5px;
// display: flex;
// flex-basis: 23%;
${UIConstants.mobileView}{
    // flex-basis: 100%;
}
`;
