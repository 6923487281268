// https://www.npmjs.com/package/@ramonak/react-progress-bar
import {FC} from 'react';
import ProgressBar from "@ramonak/react-progress-bar";

import {Wrapper,CompletedColor, PercentageLabel} from './StyledProgressBart';

interface ProgressBarProps {
    total:number,
    completed: any
}


const ProgressBarView:FC<ProgressBarProps> = (props) => {
    const {total, completed} = props;
    return <Wrapper>
        <ProgressBar completed={completed} maxCompleted={total} height={"8px"} 
        // completedClassName={}
        baseBgColor={"#E3E3E3"}
        bgColor={completed===total ?'green':'#6a1b9a'}
        isLabelVisible={false}
        />
        <PercentageLabel>{Number.isNaN(Math.floor(completed/total*100))?"loading...": Math.floor(completed/total*100)}%</PercentageLabel>
        
    </Wrapper>
};

export default ProgressBarView