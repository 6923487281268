import React, { FC } from 'react'
import { AuctionList, AuctionListItem } from './StyledAuctionCarousel';
import AuctionItem from "../../components/AuctionCards/AuctionCards";
import { useDispatch } from 'react-redux';
import { purchaseAuction } from '../../features/auctionList';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useDimension from '../../CustomHooks/useDimention';



interface AuctionCarouselProps{
    auctionList:any
    isLive:boolean
}

const AuctionCarousel:FC<AuctionCarouselProps> = ({auctionList,isLive}) => {
    const dispatch = useDispatch();
    const buyTickets = (id: number) => {
        dispatch(
          purchaseAuction({
            auctionId: id,
          })
        );
      };
  const dimention =useDimension();
  // console.log(auctionList)
      const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 1270 },
          items:dimention.width>=3000? 10 : dimention.width>=2200? 8: dimention.width>=1700? 6 : 4,
        },
        desktop: {
          breakpoint: { max: 1270, min: 1070 },
          items: 4,
        },
        tablet: {
          breakpoint: { max: 1070, min: 495 },
          items: dimention.width>=890 ? 4 : dimention.width>=690 ? 3 :2,
        },
        mobile: {
          breakpoint: { max: 495, min: 0 },
          items: 2,
        },
      };
    return (
      <>
      <Carousel responsive ={responsive} >
        {/* <AuctionList> */}

          {auctionList.map((auctionItem:any) => {
            // console.log(auctionItem);
            let auctionUrl = auctionItem?.auctionImageUrls
            ? auctionItem?.auctionImageUrls[0]
            : null;
            // let totalUser= auctionItem?.totalUsers!==null?auctionItem?.totalUsers:100;
            // let engagedUsersCount = auctionItem?.noOfUsersJoined!==null
            // ? auctionItem?.noOfUsersJoined
            // : 0;
           let usersParticipatedPercentage=auctionItem?.usersParticipatedPercentage!==null ? auctionItem?.usersParticipatedPercentage:0;

            return (
              <>
              <AuctionListItem>
                <AuctionItem
                  auctionObj={auctionItem}
                  auctionId={auctionItem?.auctionId}
                  imgUrl={auctionUrl}
                  title={auctionItem?.auctionTitle}
                  // totalUsers={auctionItem?.totalUsers}
                  totalUsers={100}
                  engagedUsers={usersParticipatedPercentage}
                  // engagedUsers={auctionItem?.noOfUsersJoined}
                  auctionProduct={auctionItem?.productType}
                  entryTicket={auctionItem?.bronzeTicketPrice}
                  drawDate={auctionItem?.auctionEndDate}
                  startDate={auctionItem?.auctionStartDate}
                  isLive={isLive}
                  onSelectBuy={buyTickets}
                  isMemberAuction={auctionItem?.isMemberAuction}
                  />
                  
              </AuctionListItem>
                  </>
            );
          })}
          {/* </AuctionList> */}
          </Carousel>
      </>
  )
}

export default AuctionCarousel