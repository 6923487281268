import styled from 'styled-components';
import * as UIConstant from '../../UIConstants';

export const Wrapper = styled.div`
width: 100%;
box-sizing: border-box;
padding: 0 50px;
${UIConstant.customMobileView}{
padding:0 10px;

}
`;

export const Title = styled.div`
width: 100%;
box-sizing: border-box;
font-weight: 600;
font-size: 42px;
line-height: 51px;
text-transform: capitalize;
color: #ADB5BD;
margin-bottom: 30px;
${UIConstant.customMobileView}{
font-size: 32px;
font-weight: 500;

}
`;
export const ErrorMsg= styled.div`
text-align:center;
color: #000000;
opacity:0.7;
font-weight: 500;
font-size: 17px;
margin: 20px 0 10px 0;

`