import {FC} from 'react';
import {Wrapper,Container,Title,List,Details,P} from '../TAndC/StyledTandC';
import { useNavigate } from 'react-router-dom';
import { RouterPath } from '../../routes/routes';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
    const navigate= useNavigate();

    return<Wrapper>
        <Helmet>
            <title>KingsRings - Privacy Policy</title>
        </Helmet>
        <Container>
            <Title>
            Privacy Policy
            </Title>
            <Details>
            This privacy policy ("Policy") describes how King’s Rings collects, uses, and shares
             information about you when you use our website www.kingsrings.games and any
             other online products and services (collectively, the "Services").
            </Details>
            <Details>
            <b>Collection of Information</b>
            <br />
            We may collect information about you in a variety of ways. The information we may collect on the Services includes:
            </Details>
            <List>
                <li>
                Personal information you provide to us directly, such as your name, email address, postal address, and phone number.
                </li>
                <li>
                Information about your use of the Services, such as your search queries, 
                the pages you view, and the actions you take on the Services.
                </li>
                <li>
                Device information, such as your device type, device ID, and IP address.
                </li>
            </List>
            <Details>
            <b>Use of Information</b>
            <br />
            We may use the information we collect about you for a variety of purposes, including:
            </Details>
            <List>
                <li>
                To provide, maintain, and improve the Services.
                </li>
                <li>
                To personalize your experience on the Services.
                </li>
                <li>
                To communicate with you, such as to send you updates, 
                newsletters, marketing materials, and other information.
                </li>
                <li>
                To respond to your comments, questions, and requests.
                </li>
                <li>
                To enforce our policies and terms of use.
                </li>
            </List>
            <Details>
            <b>Sharing of Information</b>
            <br />
            We may share your information with third parties in the following circumstances:
            </Details>
            <List>
                <li>
                With service providers who assist us in operating the Services and conducting our business.
                </li>
                <li>
                With third parties as required by law, such as to comply with a subpoena or similar legal process.
                </li>
                <li>
                In the event of a merger, acquisition, or sale of all or a portion of our assets, 
                we may share your information with the party involved in the transaction.
                </li>
            </List>
            <Details>
            <b>Security of Information</b>
            <br />
            We take reasonable measures to protect the information we collect from loss, misuse, and unauthorized access, disclosure, alteration, and destruction. However, no internet or email transmission is ever fully secure or error free, so you should take special care in deciding what information you send to us.
            </Details>
            <Details>
            <b>Your Choices</b>
            <br />
            You have the following choices regarding the information we collect and how we use it:
            </Details>
            <List>
            	<li>You can opt out of receiving marketing emails from us by following the unsubscribe instructions in the emails we send you.</li>
            	<li>You can update your personal information by contacting us as described in the "Contact Us" section below.</li>

            </List>
            <Details>
            <b>Changes to This Policy</b>
            <br />
            We may update this Policy from time to time. We will post any changes on this page and encourage you to review the Policy periodically.
            </Details>
            <Details>
            <b>Contact Us</b>
            <br />
            If you have any questions about this Policy or the information we collect, you can contact us at:  <span style={{color:"blue", cursor:"pointer"}} onClick={()=>navigate(RouterPath.contactUs) }>info@kingsrings.games</span>.
            <br />
            Effective Date: 1st January 2023
            </Details>
        </Container>
    </Wrapper>
};

export default PrivacyPolicy