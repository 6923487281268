import styled from "styled-components";

import * as UIConstants from "../../UIConstants";

interface TabMenuElement {
  isSelectdItem: boolean;
}

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: 125vh;
  ${UIConstants.mobileView} {
    height: 115vh;
  }
  @media (max-width: 460px) {
    height: 100vh;
  }
`;

export const Viewheader = styled.div`
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  max-height: 160px;
  height: 160px;
  // background-color: #3A57E8;
  background-color: #003459;
  font-size: 16px;
  font-weight: 500;
  padding-top: 30px;
  ${UIConstants.mobileView} {
    height: 160px;
  }
`;

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 50px;
  position: absolute;
  top: 46px;
  ${UIConstants.mobileView} {
    padding: 0 10px;
    top: 116px;
  }
  @media (max-width: 600px) {
    top: 96px;
  }
`;

export const ProfileContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
`;

export const TabMenuContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  max-height: 170px;
  border-bottom: 1px solid #e8e8e8;
  // ${UIConstants.mobileView}{
  //     overflow-x: scroll;
  // }
  @media (max-width: 460px) {
    overflow-x: scroll;
  }
`;

export const TabMenuElement = styled.div<TabMenuElement>`
  font-size: 16px;
  padding: 20px 30px;
  /* background-color: #ffffff; */
  min-width: 140px;
  ${UIConstants.mobileView} {
    padding: 10px 15px;
    min-width: 70px;
  }
`;

export const TabMenuBody = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  ${UIConstants.mobileView} {
    padding: 15px;
  }
`;

export const TabMenuContent = styled.div`
  max-width: 674px;
`;
