import { FC, useState, useEffect, useRef } from "react";

import FormBuilder from "../../FormBuilder/FormBuilder";
import { updateFormInputState, validateForm } from "../../../Utility/Utility";
import {
  FormElementType,
  customValidationType,
  InputVariant,
  InputTypes,
  FormElement,
  ButtonSizeVariant,
  ButtonVariant,
  ButtonType,
  AppButtonType,
} from "../../../Utility/InterFacesAndEnum";
import Button from "../../../components/UI/Buttons/Button";
import ImageUploader from "../../../components/ImageUploader/CustomImageUploader";

// import ProfileAvtarUrl from '../../assets/profileavtar.webp';
import ProfileAvtarUrl from "../../../assets/profileavtar.webp";

import { Container, FormSection, ImageConteiner } from "./StyledPersonalInfo";

import * as localStorageActioinType from "../../../localStorage/ActionTypes";
import { getLocalStorage } from "../../../localStorage/GetLocalStorage";

import {
  getUserProfileDetail,
  updatePersonalDetails,
  uploadProfileImage,
} from "../../../features/userProfileSlice";
import { useSelector, useDispatch } from "react-redux";

import { validateUpdatePersonalInfo } from "../../../Utility/formValidation";

import { NotificationType } from "../../../Utility/InterFacesAndEnum";
import { toggleNotificationVisibility } from "../../../features/notificationSlice";
import { getUserDetailes } from "../../../features/loadMoneySlice";
import { RootState } from "../../../app/store";

interface PersonalInfo {
  form: FormElement[];
  isValidForm: boolean;
}

const PersonalInfoState: PersonalInfo = {
  form: [
    {
      elementType: FormElementType.input,
      value: "",
      id: "firstName",
      isRequired: true,
      fullWidth: true,
      isCustomValidationRequred: true,
      inputVariant: InputVariant.outlined,
      inputType: InputTypes.text,
      customValidationType: customValidationType.characterValidation,
      isValidInput: false,
      isTouched: false,
      errorMessage: "",
      label: "First Name",
      radioGroupValues: [],
      dropdownValues: [],
      slectedDate: null,
      isPasswordHidden: true,
    },
    {
      elementType: FormElementType.input,
      value: "",
      id: "secondName",
      isRequired: true,
      fullWidth: true,
      isCustomValidationRequred: true,
      inputVariant: InputVariant.outlined,
      inputType: InputTypes.text,
      customValidationType: customValidationType.characterValidation,
      isValidInput: false,
      isTouched: false,
      errorMessage: "",
      label: "Last Name",
      radioGroupValues: [],
      dropdownValues: [],
      slectedDate: null,
      isPasswordHidden: true,
    },
    {
      elementType: FormElementType.input,
      value: "",
      id: "mobileNumber",
      isRequired: true,
      fullWidth: true,
      isCustomValidationRequred: true,
      inputVariant: InputVariant.outlined,
      inputType: InputTypes.text,
      customValidationType: customValidationType.numberValidation,
      isValidInput: false,
      isTouched: false,
      errorMessage: "",
      label: "Mobile Number",
      radioGroupValues: [],
      dropdownValues: [],
      slectedDate: null,
      isPasswordHidden: true,
    },
  ],
  isValidForm: true,
};

const PersonalInfo: FC = () => {
  const dispatch = useDispatch();

  const [values, setValues] = useState<PersonalInfo>(PersonalInfoState);

  const [profileImage, setProfileImage] = useState(ProfileAvtarUrl);

  const userDetailRef = useRef({});

  const userDetail=useSelector((state:RootState)=>state.userProfile.userDetail);

  useEffect(()=>{
      console.log(userDetail)
      if(userDetail.imageUrl!==null){
        setProfileImage(userDetail.imageUrl)
      }
  },[userDetail])

  useEffect(() => {
    if (Object.keys(userDetailRef.current).length === 0) {
      let userObj = getLocalStorage(localStorageActioinType.GET_USER_DETAILS);
      if (Object.keys(userObj).length > 0) {
        userDetailRef.current = userObj;
      }
      updateLocalForm(userDetailRef.current);
    }
    return () => {
      userDetailRef.current = {};
    };
  }, []);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    let updatedStateArray = updateFormInputState(event, values.form);
    setValues({
      ...values,
      form: updatedStateArray,
    });
  };

  const updateLocalForm = (userObj: any) => {
    const { firstName, lastName, mobileNo, imageUrl } = JSON.parse(userObj);
    let updatedArry = [];
    for (let formObj of values.form) {
      if (formObj.id === "firstName") {
        formObj.value = firstName;
      }
      if (formObj.id === "secondName") {
        formObj.value = lastName;
      }
      if (formObj.id === "mobileNumber" && mobileNo) {
        formObj.value = mobileNo;
      }
      updatedArry.push(formObj);
    }
    console.log(imageUrl)
    if (imageUrl !== null) {
      setProfileImage(imageUrl);
    }
    setValues({
      ...values,
      form: updatedArry,
      isValidForm: values.isValidForm,
    });
  };

  const handleFormSubmision = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    interface updateProfile {
      firstName: string;
      lastName: string;
      mobileNo: string;
    }

    let updateProfileObj: updateProfile = {
      firstName: "",
      lastName: "",
      mobileNo: "",
    };
    for (let formObj of values.form) {
      if (formObj.id === "firstName") {
        updateProfileObj["firstName"] = formObj.value;
      }
      if (formObj.id === "secondName") {
        updateProfileObj["lastName"] = formObj.value;
      }
      if (formObj.id === "mobileNumber") {
        updateProfileObj["mobileNo"] = formObj.value;
      }
    }
    let validatedObj = validateUpdatePersonalInfo(updateProfileObj);
    if (validatedObj.status === true) {
      dispatch(updatePersonalDetails(updateProfileObj));
    } else {
      dispatch(
        toggleNotificationVisibility({
          isVisible: true,
          status: NotificationType.error,
          message: validatedObj.message,
        })
      );
    }
  };

  const onSelectProfileImage = (fileObj: any) => {
    dispatch(uploadProfileImage(fileObj));
  };

  return (
    <form
      name={"Customer Registration"}
      html-for={"customer resgistraion"}
      autoComplete="off"
    >
      <Container>
        <ImageConteiner>
          <ImageUploader
            // url={profileImage}
            onSelectImage={onSelectProfileImage}
          />
        </ImageConteiner>
        <FormSection>
          <FormBuilder
            formElements={values.form}
            onInputChange={handleInputChange}
            onChangeDate={() => {}}
            onSelectValueChange={() => {}}
            onDateSelect={() => {}}
          />
          <Button
            appBtnType={AppButtonType.primaryBtn}
            disabled={false}
            fullWidth={true}
            size={ButtonSizeVariant.medium}
            variant={ButtonVariant.contained}
            type={ButtonType.submit}
            clicked={handleFormSubmision}
          >
            Update
          </Button>
        </FormSection>
      </Container>
    </form>
  );
};

export default PersonalInfo;
