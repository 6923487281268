import coin1 from "../assets/coin1.png";
import coin2 from "../assets/coin2.png";
import coin3 from "../assets/coin3.png";
import lotteryCoin from "../assets/lotteryCoin.png";

// export const cardData = [
//   {
//     imageUrl: lotteryCoin,
//     label: "Gold Coin",
//     text: "Coin code: 02345",
//   },
//   {
//     imageUrl: lotteryCoin,
//     label: "Gold Coin",
//     text: "Coin code: 78545",
//   },
//   {
//     imageUrl: lotteryCoin,
//     label: "Gold Coin",
//     text: "Coin code: 185445",
//   },
//   {
//     imageUrl: lotteryCoin,
//     label: "Gold Coin",
//     text: "Coin code: 32145",
//   },
//   {
//     imageUrl: lotteryCoin,
//     label: "Gold Coin",
//     text: "Coin code: 88545",
//   },
//   {
//     imageUrl: lotteryCoin,
//     label: "Gold Coin",
//     text: "Coin code: 89645",
//   },
//   {
//     imageUrl: lotteryCoin,
//     label: "Gold Coin",
//     text: "Coin code: 19365",
//   },
//   {
//     imageUrl: lotteryCoin,
//     label: "Gold Coin",
//     text: "Coin code: 18665",
//   },
//   {
//     imageUrl: lotteryCoin,
//     label: "Gold Coin",
//     text: "Coin code: 88695",
//   },
//   {
//     imageUrl: lotteryCoin,
//     label: "Gold Coin",
//     text: "Coin code: 97967",
//   },
//   {
//     imageUrl: lotteryCoin,
//     label: "Gold Coin",
//     text: "Coin code: 25452",
//   },

// ];

// export const coin = [
//   {
//     imageUrl: coin1,
//     label: "coin"
//   },
//   {
//     imageUrl: coin2,
//     label: "coin"
//   }
//   ,
//   {
//     imageUrl: coin3,
//     label: "coin"
//   }
// ]

export const coinData = [
  {
    id: 1,
    imageUrl: lotteryCoin,
    label: "Lottery Coin",
    text: "Coin code: 02345",
    coin_code: "02345",
    coin_price: "$100",
    coin_subsheding: "Coin",

    coin_images: [
      {
        imageUrl: lotteryCoin,
        label: "coin",
      },
      {
        imageUrl: coin2,
        label: "coin",
      },
      {
        imageUrl: coin3,
        label: "coin",
      },
    ],
    coin_description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga! Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga!",
  },
  {
    id: 2,
    imageUrl: coin1,
    label: "Gold Coin 1",
    text: "Coin code: 02345",
    coin_code: "2554",
    coin_price: "$200",
    coin_subsheding: "Coin",

    coin_images: [
      {
        imageUrl: coin1,
        label: "coin",
      },
      {
        imageUrl: coin2,
        label: "coin",
      },
      {
        imageUrl: coin3,
        label: "coin",
      },
    ],
    coin_description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga! Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga!",
  },
  {
    id: 3,
    imageUrl: coin2,
    label: "Gold Coin 2",
    text: "Coin code: 02345",
    coin_code: "02345",
    coin_price: "$500",
    coin_subsheding: "coin",

    coin_images: [
      {
        imageUrl: coin2,
        label: "coin",
      },
      {
        imageUrl: coin1,
        label: "coin",
      },
      {
        imageUrl: coin3,
        label: "coin",
      },
    ],
    coin_description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga! orem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga!",
  },
  {
    id: 4,
    imageUrl: coin3,
    label: "Gold Coin 3",
    text: "Coin code: 02345",
    coin_code: "02345",
    coin_price: "$150",

    coin_subsheding: "coin",

    coin_images: [
      {
        imageUrl: coin3,
        label: "coin",
      },
      {
        imageUrl: coin2,
        label: "coin",
      },
      {
        imageUrl: coin1,
        label: "coin",
      },
    ],
    coin_description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga! Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga!",
  },
  {
    id: 5,
    imageUrl: lotteryCoin,
    label: "lottery coin",
    text: "Coin code: 02345",
    coin_code: "02345",
    coin_price: "$250",

    coin_subsheding: "coin",
    coin_images: [
      {
        imageUrl: lotteryCoin,
        label: "coin",
      },
      {
        imageUrl: coin2,
        label: "coin",
      },
      {
        imageUrl: coin3,
        label: "coin",
      },
    ],
    coin_description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga! Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga!",
  },
  {
    id: 6,
    imageUrl: coin1,
    label: "Gold Coin 1",
    text: "Coin code: 02345",
    coin_code: "02345",
    coin_price: "$100",
    coin_subsheding: "coin",

    coin_images: [
      {
        imageUrl: coin1,
        label: "coin",
      },
      {
        imageUrl: coin2,
        label: "coin",
      },
      {
        imageUrl: coin3,
        label: "coin",
      },
    ],
    coin_description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga!",
  },
  {
    id: 7,
    imageUrl: coin2,
    label: "Gold Coin 2",
    text: "Coin code: 02345",
    coin_code: "02345",
    coin_price: "$100",
    coin_subsheding: "coin",

    coin_images: [
      {
        imageUrl: coin2,
        label: "coin",
      },
      {
        imageUrl: coin1,
        label: "coin",
      },
      {
        imageUrl: coin3,
        label: "coin",
      },
    ],
    coin_description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga!",
  },

  {
    id: 8,
    imageUrl: lotteryCoin,
    label: "Lottery Coin",
    text: "Coin code: 02345",
    coin_code: "02345",
    coin_price: "$100",
    coin_subsheding: "Coin",

    coin_images: [
      {
        imageUrl: lotteryCoin,
        label: "coin",
      },
      {
        imageUrl: coin2,
        label: "coin",
      },
      {
        imageUrl: coin3,
        label: "coin",
      },
    ],
    coin_description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga! Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga!",
  },
  {
    id: 9,
    imageUrl: coin1,
    label: "Gold Coin 1",
    text: "Coin code: 02345",
    coin_code: "2554",
    coin_price: "$200",
    coin_subsheding: "Coin",

    coin_images: [
      {
        imageUrl: coin1,
        label: "coin",
      },
      {
        imageUrl: coin2,
        label: "coin",
      },
      {
        imageUrl: coin3,
        label: "coin",
      },
    ],
    coin_description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga! Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga!",
  },
  {
    id: 10,
    imageUrl: coin2,
    label: "Gold Coin 2",
    text: "Coin code: 02345",
    coin_code: "02345",
    coin_price: "$500",
    coin_subsheding: "coin",

    coin_images: [
      {
        imageUrl: coin2,
        label: "coin",
      },
      {
        imageUrl: coin1,
        label: "coin",
      },
      {
        imageUrl: coin3,
        label: "coin",
      },
    ],
    coin_description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga! orem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga!",
  },
  {
    id: 11,
    imageUrl: coin3,
    label: "Gold Coin 3",
    text: "Coin code: 02345",
    coin_code: "02345",
    coin_price: "$150",

    coin_subsheding: "coin",

    coin_images: [
      {
        imageUrl: coin3,
        label: "coin",
      },
      {
        imageUrl: coin2,
        label: "coin",
      },
      {
        imageUrl: coin1,
        label: "coin",
      },
    ],
    coin_description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga! Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga!",
  },
  {
    id: 12,
    imageUrl: lotteryCoin,
    label: "lottery coin",
    text: "Coin code: 02345",
    coin_code: "02345",
    coin_price: "$250",

    coin_subsheding: "coin",
    coin_images: [
      {
        imageUrl: lotteryCoin,
        label: "coin",
      },
      {
        imageUrl: coin2,
        label: "coin",
      },
      {
        imageUrl: coin3,
        label: "coin",
      },
    ],
    coin_description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga! Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga!",
  },
  {
    id: 13,
    imageUrl: coin1,
    label: "Gold Coin 1",
    text: "Coin code: 02345",
    coin_code: "02345",
    coin_price: "$100",
    coin_subsheding: "coin",

    coin_images: [
      {
        imageUrl: coin1,
        label: "coin",
      },
      {
        imageUrl: coin2,
        label: "coin",
      },
      {
        imageUrl: coin3,
        label: "coin",
      },
    ],
    coin_description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga!",
  },
  {
    id: 14,
    imageUrl: coin2,
    label: "Gold Coin 2",
    text: "Coin code: 02345",
    coin_code: "02345",
    coin_price: "$100",
    coin_subsheding: "coin",

    coin_images: [
      {
        imageUrl: coin2,
        label: "coin",
      },
      {
        imageUrl: coin1,
        label: "coin",
      },
      {
        imageUrl: coin3,
        label: "coin",
      },
    ],
    coin_description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure vel quia molestiae dolores, nobis incidunt modi. Dolorem cumque rerum architecto, temporibus non quasi sed nulla corporis. Atque officiis sequi fuga!",
  },
];
