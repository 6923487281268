import { FC } from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Swiper.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Img, SlidingElement } from "./StyledSwipper";
import useDimension from "../../CustomHooks/useDimention";

interface SlidingProps {
  sliderList: any;
}

const Sliders: FC<SlidingProps> = ({ sliderList }) => {
  // console.log(sliderList)
  const dimention = useDimension();
  return (
    <>
      {sliderList.length > 0 ? (
        <Swiper
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {sliderList.map((sliderObj: any, index: any) => {
            return (
              <SwiperSlide key={index}>
                {sliderObj?.promotionBannerUrl !== "" ? (
                  <a
                    href={`http://${sliderObj?.promotionBannerUrl}`}
                    target="_blank" // You can use "_self" for the same tab
                    rel="noopener noreferrer"
                  >
                    <SlidingElement>
                      <Img
                        srcSet={
                          dimention.width > 665
                            ? `${sliderObj?.promotionWebBannerImages[0]}`
                            : `${sliderObj?.promotionMobileBannerImages[0]}`
                        }
                        sizes=""
                        // src={sliderObj}
                        alt={`Slide ${index + 1}`}
                      />
                    </SlidingElement>
                  </a>
                ) : (
                  <SlidingElement>
                    <Img
                      srcSet={
                        dimention.width > 665
                          ? `${sliderObj?.promotionWebBannerImages[0]}`
                          : `${sliderObj?.promotionMobileBannerImages[0]}`
                      }
                      sizes=""
                      // src={sliderObj}
                      alt={`Slide ${index + 1}`}
                    />
                  </SlidingElement>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : null}
    </>
  );
};

export default Sliders;
