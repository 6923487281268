import styled from "styled-components";
import TwitterIcon from "@mui/icons-material/Twitter";
// import FacebookIcon from '@mui/icons-material/Facebook';
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { DiAndroid } from "react-icons/di";

import * as UIConstants from "../../UIConstants";

export const FooterWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #ffffff;
`;

export const FooterTop = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  /* flex-flow: row wrap; */
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding: 60px;
  ${UIConstants.mobileView} {
    flex-flow: column;
    padding: 15px;
    gap: 15px;
  }
`;

export const FooterLogoSection = styled.div`
  display: flex;
  flex-basis: 30%;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const LogoImg = styled.img`
  height: 100px;
  object-fit: contain;
`;

export const LogoDesc = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  padding: 15px 0;
`;

export const FooterItems = styled.div`
  display: flex;
  flex-basis: 15%;
  flex-flow: column;
  /* justify-content: space-around; */
  /* align-items: center; */
  /* border: 1px solid black; */
  ${UIConstants.mobileView} {
    align-items: start;
  }
`;

export const FooterItemTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #200e32;
  margin-bottom: 15px;
`;
export const FooterItmValue = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #200e32;
  margin-bottom: 15px;
  cursor: pointer;
  &:hover {
    color: black;
    font-weight: 500;
  }
`;

export const FooterBottom = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: #323232;
  height: 52px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0 30px;
  ${UIConstants.mobileView} {
    flex-flow: row;
    padding: 15px;
  }
`;

export const CopyRight = styled.div`
  display: flex;
  flex-basis: 60%;
  color: #ffffff;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 12px;
`;

export const FollowUsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-basis: 40%;
  align-items: center;
  justify-content: flex-end;
`;

export const FollowUsTitle = styled.div`
  font-size: 12px;
  color: #ffffff;
  margin-right: 15px;
`;
export const Version = styled.div`
  font-size: 12px;
  color: #ffffff;
`;

export const SocialIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
export const Android = styled(DiAndroid)`
  height: 18px;
  width: 18px;
  margin-right: 5px;
`;

export const Twitter = styled(TwitterIcon)`
  color: #ffffff;
  height: 8px;
  width: 8px;
  transition: color 0.3s ease;
  padding: 5px;

  &:hover {
    color: black;
    font-weight: 500;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
`;

export const Facebook = styled(FacebookOutlinedIcon)`
  color: #ffffff;
  height: 8px;
  width: 8px;
  transition: color 0.3s ease;
  padding: 5px;

  &:hover {
    color: black;
    font-weight: 500;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
`;

export const YouTube = styled(YouTubeIcon)`
  color: #ffffff;
  height: 8px;
  width: 8px;
  transition: color 0.3s ease;
  padding: 5px;

  &:hover {
    color: black;
    font-weight: 500;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
`;

export const LinkedIn = styled(LinkedInIcon)`
  color: #ffffff;
  height: 8px;
  width: 8px;
  transition: color 0.3s ease;
  padding: 5px;

  &:hover {
    color: black;
    font-weight: 500;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
`;
