import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as endpoints from "../networkUtilities/endpoints";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { toggleNotificationVisibility } from "./notificationSlice";
import { NotificationType } from "../Utility/InterFacesAndEnum";
import { toggleLoader } from "./loaderSlice";
import { getUserDetailes } from "./loadMoneySlice";

interface CoinObj {
  coinId: number;
  coinCode: string;
  coinTitle: string;
  coinPrice: number;
  coinDescription: string;
  coinImageUrls: [];
  coinThumbnailImageUrl: string;
}

interface CoinInitialStateProp {
  coins: [] | CoinObj[];
  singleCoin: any;
  isNavigate: boolean;
  checkout: [];
  isWalletPaymentInitiated: boolean;
  isPurchase: boolean;
}

const coinInitialState: CoinInitialStateProp = {
  coins: [],
  singleCoin: {},
  isNavigate: false,
  checkout: [],
  isWalletPaymentInitiated: false,
  isPurchase: false,
};

interface PurchaseProp {
  isPurchase: boolean;
}
interface SetCoinProps {
  result: CoinObj[] | [];
}
interface SetSingleCoinProps {
  result: any;
}
interface SetNavProp {
  result: boolean;
}

export const getCoins = createAsyncThunk(
  "get coins",
  async (payload, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(endpoints.getCoinList, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data)
        dispatch(toggleLoader({ isLoading: false }));
        if (data.statusCode === 200) {
          // dispatch(
          //   toggleNotificationVisibility({
          //     isVisible: false,
          //     status: NotificationType.success,
          //     message: data.errorMsg,
          //   })
          // );
          dispatch(
            setCoinList({
              result: data.result,
            })
          );
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
        if (data.statusCode === 504) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        console.log(err);
      });
  }
);

export const getCoinsById = createAsyncThunk(
  "get coins by id",
  async (payload: string, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));
    // console.log(payload);
    await fetch(`${endpoints.getCoinById}${payload}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data)
        dispatch(toggleLoader({ isLoading: false }));
        if (data.statusCode === 200) {
          dispatch(
            setSingleCoin({
              result: data.result,
            })
          );
        }

        if (data.statusCode === 504) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        console.log(err);
      });
  }
);

export const buyCoin = createAsyncThunk(
  "buy coin",
  async (payload: any, { dispatch }) => {
    // console.log(payload)
    dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    await fetch(endpoints.buyCoinCheckout, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        dispatch(
          setCoinCheckout({
            checkout: data?.links[1],
          })
        );
      })
      .catch((err) => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        return err;
      })
      .finally(() => {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
      });
  }
);

export const buyCoinPaymentComplete = createAsyncThunk(
  "payment complete",
  async (payload: any, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(`${endpoints.buyCoinComplete}${payload}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));
        if (data?.coinDeliveryStatus === "RECIEVED") {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.success,
              message: "Purchase Successful.",
            })
          );
          dispatch(
            togglePurchaseState({
              isPurchase: true,
            })
          );
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: "Something went wrong",
            })
          );
        }
        console.log(data);
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        dispatch(
          toggleNotificationVisibility({
            isVisible: true,
            status: NotificationType.error,
            message: "Something went wrong.",
          })
        );
        return err;
      });
  }
);

export const purchaseCoinWallet = createAsyncThunk(
  "purchase ticket",
  async (payload: any, { dispatch }) => {
    // payload["ticketType"] = "B";
    dispatch(toggleLoader({ isLoading: true }));

    console.log("payload", payload);
    await fetch(endpoints.coinPurchaseThroughWallet, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));
        if (data.statusCode === 500) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        } else if (data.statusCode === 200) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.success,
              message: "Coin Purchase Successfully.",
            })
          );
          dispatch(
            storeWalletPaymentResponse({
              isWalletPaymentInitiated: true,
            })
          );

          dispatch(getUserDetailes());
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.success,
              message: "Something went wrong",
            })
          );
        }
        console.log(data, "data from pyament");
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        return err;
      });
  }
);

const coinSlice = createSlice({
  name: "Coin Slice",
  initialState: coinInitialState,
  reducers: {
    setCoinList: (state, action: PayloadAction<SetCoinProps>) => {
      return {
        ...state,
        coins: action.payload.result,
      };
    },
    setSingleCoin: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        singleCoin: action.payload.result,
      };
    },
    setNavigate: (state, action: PayloadAction<SetNavProp>) => {
      return {
        ...state,
        isNavigate: action.payload.result,
      };
    },
    setCoinCheckout: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        checkout: action.payload.checkout,
      };
    },
    storeWalletPaymentResponse: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isWalletPaymentInitiated: action.payload.isWalletPaymentInitiated,
      };
    },
    togglePurchaseState: (state, action: PayloadAction<PurchaseProp>) => {
      return {
        ...state,
        isPurchase: action.payload.isPurchase,
      };
    },
    resetPurchaseState: (state, action: PayloadAction<void>) => {
      return {
        ...state,
        isPurchase: false,
      };
    },
  },
});

export const {
  setCoinList,
  setNavigate,
  setSingleCoin,
  setCoinCheckout,
  storeWalletPaymentResponse,
  togglePurchaseState,
  resetPurchaseState,
} = coinSlice.actions;
export default coinSlice.reducer;
