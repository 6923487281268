import styled from "styled-components";
import * as UIConstants from "../../UIConstants";
import bgImg from "../../assets/mainLotteryBgImg.jpg";

interface MainWrapperProp {
  homeMobileBackground: any;
  homeDesktopBackground: any;
  isActive: boolean;
}

export const MainPageWrapper = styled.div<MainWrapperProp>`
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  margin-top: 2px;
  border-bottom: 1px solid #d4d8e2;
  background-image: url(${(props) =>
    props.isActive ? props.homeDesktopBackground : null});
  /* background-size: cover; */
  background-size: 100% 100%;
  background-position: center;
  ${UIConstants.mobileView} {
    background-image: url(${(props) =>
      props.isActive ? props.homeMobileBackground : null});
  }
`;
export const BackdropImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
export const Section = styled.div`
  padding: 0px 20px 0px 20px;

  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-basis: 50%;
  flex-flow: column;
  ${UIConstants.mobileView} {
    margin: 15px;
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(255, 200, 57, 0.5);
  }
  @media (max-width: 480px) {
    padding: 0px;
    margin: 10px 10px 10px 10px;
  }
`;

export const LotterySection = styled.div`
  padding: 0px 20px 0px 20px;

  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-basis: 50%;
  flex-flow: column;
  ${UIConstants.mobileView} {
    /* margin:15px; */
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(255, 200, 57, 0.5);
  }
  @media (max-width: 480px) {
    padding: 0px;
    margin: 0 0 10px 0;
  }
`;

export const SectionTwo = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom: 1px solid #d4d8e2;
  ${Section}:first-child {
    border-right: 1px solid #d4d8e2;

    ${UIConstants.mobileView} {
      /* margin:15px; */
      /* border-right: none; */
      border-radius: 10px;
      overflow: hidden;
      padding: 0;
    }
  }
  ${UIConstants.mobileView} {
    flex-flow: column;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-transform: capitalize;
  color: #200e32;
  margin-bottom: 45px;
  text-align: left;
  ${UIConstants.mobileView} {
    border-bottom: none;
    padding: 20px 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: unset;
    background: rgba(255, 200, 57, 0.4);
  }
`;

export const AuctionSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  margin-top: 10px;
  padding: 20px 20px 20px 20px;
  border-bottom: 1px solid #d4d8e2;
  ${UIConstants.mobileView} {
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(255, 200, 57, 0.5);
    /* padding: 15px; */
  }
`;

export const TitelWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 0 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  ${UIConstants.mobileView} {
    flex-flow: column;
    /* padding: 0; */
    padding: 0 0 20px 16px;
  }
  ${Title} {
    margin-top: 30px;
    ${UIConstants.mobileView} {
      width: 100%;
      margin: 0;
    }
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  ${UIConstants.mobileView} {
    padding: 15px;
  }
  @media (max-width: 480px) {
    padding: 10px;
  }
`;

export const MoreAuction = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #200e32;
  cursor: pointer;
  &:hover {
    color: blue;
  }
  @media (max-width: 480px) {
    padding: 0 10px 10px 0;
  }
`;

export const CardContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`;

export const Card = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

export const AuctionWinnerSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  margin-top: 2px;
  padding: 40px 50px 70px 50px;
  border-bottom: 1px solid #d4d8e2;
  ${UIConstants.mobileView} {
    /* display: none; */
    padding: 15px;
    /* padding: 0; */
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(255, 200, 57, 0.5);
  }
`;

export const MoreLotteries = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #200e32;
  cursor: pointer;
  padding-top: 40px;
  &:hover {
    color: blue;
  }
  @media (max-width: 480px) {
    padding: 0 10px 10px 0;
  }
`;

export const AuctionList = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 33px;
  ${UIConstants.mobileView} {
    gap: 15px;
  }
`;

export const AuctionListItem = styled.div`
  display: flex;
  flex-basis: 23%;
  ${UIConstants.mobileView} {
    flex-basis: 100%;
  }
`;

export const EmptyList = styled.h3`
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #000000;
  padding: 30px;
`;
