import {FC} from 'react';
import LogoSrc from '../../assets/headerLogo.svg';
import {FooterWrapper,FooterTop, FooterLogoSection, LogoImg, LogoDesc, FooterItems, FooterItemTitle, 
    FooterItmValue, FooterBottom, CopyRight, FollowUsContainer, FollowUsTitle, Twitter, SocialIcon, Facebook, LinkedIn, YouTube, Version,
    Android} from './StyledFooter';
import {RouterPath} from '../../routes/routes';
import {Link, useNavigate} from 'react-router-dom';
import { getLocalStorage } from '../../localStorage/GetLocalStorage';
import * as localStorageActionType from "../../localStorage/ActionTypes";
import { useDispatch } from 'react-redux';
import { downloadApp } from '../../features/setting';


const Footer:FC = () => {
    const navigate = useNavigate();
    const iLoggedIn = getLocalStorage(localStorageActionType.GET_IS_LOGGED_IN);
    const dispatch = useDispatch();
    const redirectToView = (path:string) => {
        navigate(path);
    };
    const downloadApkHandler=()=>{
// dispatch(downloadApp());
window.location.href='https://kingsrings.games/kingsrings/apk/android/KingsringsAPK_v1.1.2.apk';
    }
    return <FooterWrapper>
        <FooterTop>
            <FooterLogoSection>
                <LogoImg src={LogoSrc} alt={"Kings ring"} />
                <LogoDesc>
                Stay Connected with Kings Rings LTD. Your source for all things lottery. Get updates, play, and win. ©2023 Kings Rings LTD. All rights reserved. 
                </LogoDesc>
            </FooterLogoSection>
            <FooterItems>
                <FooterItemTitle onClick={() => {redirectToView(RouterPath.root)}} >
                Home
                </FooterItemTitle>
                <FooterItmValue onClick={() => {redirectToView(RouterPath.lotteries)}} >
                Lottery
                </FooterItmValue>
                <FooterItmValue onClick={() => {redirectToView(RouterPath.auctionList)}} >

                Auction
                </FooterItmValue>
                <FooterItmValue onClick={() => {redirectToView(iLoggedIn? RouterPath.orders : RouterPath.signIn )}} >
                Purchases
                </FooterItmValue>
            </FooterItems>
            <FooterItems>
                <FooterItemTitle>
                Policy
                </FooterItemTitle>
                <FooterItmValue onClick={() => {redirectToView(RouterPath.privacyPolicy)}} >
                Privacy and Policy
                </FooterItmValue>
                <FooterItmValue onClick={() => {redirectToView(RouterPath.termsAndCondition)}} >
                Terms &amp; Conditions
                </FooterItmValue>
            </FooterItems>
            <FooterItems>
                <FooterItemTitle>
                Help
                </FooterItemTitle>
                <FooterItmValue onClick={()=>{redirectToView(RouterPath.contactUs)}}>
                Contact Us
                </FooterItmValue>
            </FooterItems>
            <FooterItems>
                <FooterItemTitle>
                Download App
                </FooterItemTitle>
                <FooterItmValue onClick={downloadApkHandler}>
                <Android />Android
                </FooterItmValue>
            </FooterItems>
        </FooterTop>
        <FooterBottom>
            <CopyRight>
            Copyright ©{new Date().getFullYear()} KIngs rings
            </CopyRight>
            <FollowUsContainer>
                {/* <FollowUsTitle>
                Follow us
                </FollowUsTitle> */}
                {/* <SocialIcon>
                    <Link target='_blank' to={""}><Facebook/></Link>
                    <Link target='_blank' to={""}><Twitter/></Link>
                    <Link target='_blank' to={""}><LinkedIn/></Link>
                    <Link target='_blank' to={""}><YouTube/></Link>
                </SocialIcon> */}
                <Version>V 2.1.1</Version>
            </FollowUsContainer>
        </FooterBottom>
    </FooterWrapper>
};

export default Footer