import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import * as endpoint from '../networkUtilities/endpoints';

import * as localStorageActionType from '../localStorage/ActionTypes';
import {getLocalStorage} from '../localStorage/GetLocalStorage';
import { toggleLoader } from './loaderSlice';
import { toggleNotificationVisibility } from './notificationSlice';
import { NotificationType } from '../Utility/InterFacesAndEnum';
// import { saveAs } from 'file-saver'; 


const ordersInitialState = {
    lotteryOrders:[],
    auctionOrders:[],
    coinOrders:[],
    page:1
}

export const getLotteryOrders = createAsyncThunk(
    'get users order list',
    async (payload:void, {dispatch}) => {
        dispatch(toggleLoader({isLoading:true}));
        let userObj = JSON.parse(getLocalStorage(localStorageActionType.GET_USER_DETAILS));

        await fetch(`${endpoint.getOrdersList}?userId=${userObj.userId}`,{
            method: 'GET',
            headers:{
                Authorization: `Bearer ${getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN)}`,
                "Content-type": "application/json; charset=UTF-8",
            }
        })
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            dispatch(toggleLoader({isLoading:false}));
            let dataList = response.result;
            let isExcutedLotteryFound = false;
            let isLiveLotteryFound = false;
            dataList = dataList.map((obj:any) => {
                let isExpanded = false;
                if (obj.lotteryGameStatus === "E" && isExcutedLotteryFound === false) {
                    isExpanded = true;
                    isExcutedLotteryFound = true;
                } else if (obj.lotteryGameStatus !== "E" && isLiveLotteryFound === false) {
                    isLiveLotteryFound = true;
                    isExpanded = true;
                }
                return {
                    ...obj,
                    isExpanded:isExpanded
                }
            })
            dispatch(setOrders({
                lotteryOrders:dataList
            }))
        }).catch((err)=>{
            dispatch(toggleLoader({isLoading:false}));
            return err;
        })
    }
)


export const getAuctionOrders = createAsyncThunk(
  'get users order list',
  async (payload:void, {dispatch}) => {
      dispatch(toggleLoader({isLoading:true}));
      let userObj = JSON.parse(getLocalStorage(localStorageActionType.GET_USER_DETAILS));

      await fetch(`${endpoint.getAuctionOrdersList}?userId=${userObj.userId}`,{
          method: 'GET',
          headers:{
              Authorization: `Bearer ${getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN)}`,
              "Content-type": "application/json; charset=UTF-8",
          }
      })
      .then((response) => {
          return response.json();
      })
      .then((response) => {
          dispatch(toggleLoader({isLoading:false}));
          // console.log(response)
          let dataList = response.result;
          let isExcutedAuctionFound = false;
          let isLiveAuctionFound = false;
          dataList = dataList.map((obj:any) => {
              let isExpanded = false;
              if (obj.auctionStatus === "E" && isExcutedAuctionFound === false) {
                  isExpanded = true;
                  isExcutedAuctionFound = true;
              } else if (obj.lotteryGameStatus !== "E" && isLiveAuctionFound === false) {
                  isLiveAuctionFound = true;
                  isExpanded = true;
              }
              return {
                  ...obj,
                  isExpanded:isExpanded
              }
          })
          dispatch(setAuctionOrders({
              auctionOrders:dataList
          }))
      }).catch((err)=>{
          dispatch(toggleLoader({isLoading:false}));
          return err;
      })
  }
)


export const getCoinOrders = createAsyncThunk(
    "purchase coin list",
    async (payload, { dispatch }) => {
      dispatch(
        toggleLoader({
          isLoading: true,
        })
      );
  
      await fetch(endpoint.purchaseCoinList, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          dispatch(
            toggleLoader({
              isLoading: false,
            })
          );
          if(data.statusCode===200){
            dispatch(setCoinOrders({
              coinOrders:data.result
            }))
          }
          // console.log(data);
        })
        .catch((err) => {
          dispatch(
            toggleLoader({
              isLoading: false,
            })
          );
          return err;
        })
        .finally(() => {
          dispatch(
            toggleLoader({
              isLoading: false,
            })
          );
        });
    }
  );
  
export const downloadPurchaseList = createAsyncThunk(
  'download purchase list',
  async (payload:any,{dispatch})=>{
    dispatch(toggleLoader({
      isLoading:true
    }))
    console.log(payload)
    await fetch(endpoint.purchaseListDownload,{
      method:"POST",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body:JSON.stringify(payload)
    }).then( async(response)=>{
      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is OK (200)
      if (response.status === 200) {
        // Get the filename from the 'Content-Disposition' header
        const contentDisposition = response.headers.get('Content-Disposition');
        const match = contentDisposition?.match(/filename="(.+)"/);
        const fileName = match ? match[1] : 'downloaded-file.xlsx';

        // Convert the response to a Blob
        const blob = await response.blob();

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        // Clean up the URL and remove the anchor element
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        dispatch(toggleNotificationVisibility({
          isVisible:true,
          status:NotificationType.success,
          message:"File downloaded successfully."
        }))
      } else {
        console.error('Failed to download the file.');
        dispatch(toggleNotificationVisibility({
          isVisible:true,
          status:NotificationType.error,
          message:"Failed to download the file."
        }))
      }
    }).catch((err)=>{
      dispatch(toggleLoader({isLoading:false}))
      dispatch(toggleNotificationVisibility({
          isVisible:true,
          status:NotificationType.error,
          message:"Failed to download the file."
        }))
      
      console.log(err)
    })
  }
)

export const downloadAuctionPurchaseList = createAsyncThunk(
  'download purchase list',
  async (payload:any,{dispatch})=>{
    dispatch(toggleLoader({
      isLoading:true
    }))
    console.log(payload)
    await fetch(endpoint.purchaseAuctionListDownload,{
      method:"POST",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body:JSON.stringify(payload)
    }).then( async(response)=>{
      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is OK (200)
      if (response.status === 200) {
        // Get the filename from the 'Content-Disposition' header
        const contentDisposition = response.headers.get('Content-Disposition');
        const match = contentDisposition?.match(/filename="(.+)"/);
        const fileName = match ? match[1] : 'downloaded-file.xlsx';

        // Convert the response to a Blob
        const blob = await response.blob();

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        // Clean up the URL and remove the anchor element
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        dispatch(toggleNotificationVisibility({
          isVisible:true,
          status:NotificationType.success,
          message:"File downloaded successfully."
        }))
      } else {
        console.error('Failed to download the file.');
        dispatch(toggleNotificationVisibility({
          isVisible:true,
          status:NotificationType.error,
          message:"Failed to download the file."
        }))
      }
    }).catch((err)=>{
      dispatch(toggleLoader({isLoading:false}))
      dispatch(toggleNotificationVisibility({
          isVisible:true,
          status:NotificationType.error,
          message:"Failed to download the file."
        }))
      
      console.log(err)
    })
  }
)

const ordersSlice = createSlice({
    name: 'Orders Slice',
    initialState: ordersInitialState,
    reducers: {
        setOrders: (state, action:PayloadAction<any>) => {
            return {
                ...state,
                lotteryOrders:action.payload.lotteryOrders
            }
        },
        setAuctionOrders: (state, action:PayloadAction<any>) => {
          return {
              ...state,
              auctionOrders:action.payload.auctionOrders
          }
      },
        setCoinOrders:(state,action:PayloadAction<any>)=>{
            return {
              ...state,
              coinOrders:action.payload.coinOrders
            }
          }
    }

});

export const {setOrders,setCoinOrders,setAuctionOrders} = ordersSlice.actions;
export default ordersSlice.reducer;