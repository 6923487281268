import { FC } from "react";
import { transformDate } from "../../Utility/Utility";
import {
  AppButtonType,
  ButtonSizeVariant,
  ButtonType,
  ButtonVariant,
} from "../../Utility/InterFacesAndEnum";
import Button from "../UI/Buttons/Button";
import AuctionBuyBtn from "../AuctionBuyButton/AuctionBuyButton";
import ProgressBar from "../ProgressBar/ProgressBar";
import {
  Wrapper,
  DetailWrapper,
  AuctionImage,
  Title,
  AuctinProductType,
  ProgressBarContainer,
  TicketDetailWrapper,
  Detail,
  DetailTitle,
  Value,
  Action,
  ImageWrapper,
  GoldImg,
  MemberImgWrapper,
} from "./StyledAuctionCards";

import { useNavigate } from "react-router-dom";
import { RouterPath } from "../../routes/routes";
import { useDispatch } from "react-redux";
import { notifyMe, notifyMeAuction } from "../../features/notificationSlice";
import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import * as localStorageActionType from "../../localStorage/ActionTypes";
import goldIcon from "../../assets/gold-icon.svg";

interface AuctionCardsProps {
  auctionObj: any;
  auctionId: number;
  imgUrl: string;
  title: string;
  auctionProduct: string;
  totalUsers: number;
  engagedUsers: number;
  entryTicket: number;
  drawDate: Date | string;
  startDate: Date | string;
  isLive: boolean;
  onSelectBuy(id: number): void;
  isMemberAuction:boolean
}

const AuctionCards: FC<AuctionCardsProps> = (props) => {
  const navigate = useNavigate();
  const {
    auctionId,
    imgUrl,
    title,
    auctionProduct,
    totalUsers,
    engagedUsers,
    entryTicket,
    drawDate,
    startDate,
    isLive,
    onSelectBuy,
    auctionObj,
    isMemberAuction
  } = props;
  const dispatch = useDispatch();
  const isLoggedIn = getLocalStorage(localStorageActionType.GET_IS_LOGGED_IN);
  const publicUserId = getLocalStorage(
    localStorageActionType.GET_PUBLIC_USER_ID
  );
  const redirectToMoreDetail = (auctionId: number) => {
    // navigate(`/auction/detail/${auctionId}`);
    navigate(`/auction/detail/${auctionId}`, { state: { id: auctionId } });
  };
  const onClickNotifyMe=(id:any)=>{
    if(isLoggedIn){

      dispatch(
        notifyMeAuction({
          publicUserId: publicUserId,
          notifyType: "A",
          auctionId: id,
        })
        );
      }else{
        navigate(RouterPath.signIn);
      }
  }
  return (
    <Wrapper>
      <ImageWrapper>

      <AuctionImage src={imgUrl} />
      </ImageWrapper>
      <MemberImgWrapper>
        {
        isMemberAuction!==undefined&&<GoldImg src={goldIcon}/>

        }
      </MemberImgWrapper>

      <DetailWrapper>
        <Title>{title}</Title>
        <AuctinProductType>{auctionProduct}</AuctinProductType>
        <ProgressBarContainer>
          <ProgressBar total={totalUsers} completed={engagedUsers} />
        </ProgressBarContainer>
        <TicketDetailWrapper>
          <Detail>
            <DetailTitle>Entry Ticket</DetailTitle>
            <Value>&#x24; {entryTicket === null ? 0 : entryTicket}</Value>
          </Detail>
          <Detail>
            <DetailTitle>{isLive ? "Draw Date" : "Start Date"}</DetailTitle>
            <Value>
              {isLive
                ? transformDate(new Date(drawDate)).toString()
                : transformDate(new Date(startDate)).toString()}
            </Value>
          </Detail>
        </TicketDetailWrapper>
        <Action>
          <Button
            disabled={false}
            appBtnType={AppButtonType.primaryBordered}
            fullWidth={false}
            variant={ButtonVariant.contained}
            type={ButtonType.default}
            size={ButtonSizeVariant.small}
            clicked={() => {
              redirectToMoreDetail(auctionId);
            }}
          >
            More Detail
          </Button>
          {isLive ? (
            <AuctionBuyBtn auctionObj={auctionObj} />
            
          ) : (
            <>
              <Button
                disabled={false}
                appBtnType={AppButtonType.secondary}
                fullWidth={false}
                variant={ButtonVariant.contained}
                type={ButtonType.default}
                size={ButtonSizeVariant.small}
                clicked={() => {
                  onClickNotifyMe(auctionId);
                }}
              >
                Notify Me
              </Button>
            </>
          )}
        </Action>
      </DetailWrapper>
    </Wrapper>
  );
};

export default AuctionCards;
