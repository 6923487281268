import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { RouterPath } from "../../routes/routes";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ResponsiveCarousel from "../Carousel/ResponsiveCarousel";

import {
  MainPageWrapper,
  SectionTwo,
  Section,
  Title,
  AuctionSection,
  TitelWrapper,
  MoreAuction,
  AuctionWinnerSection,
  MoreLotteries,
  SectionWrapper,
  CardContainer,
  Container,
  CoinImages,
  BigCoinImage,
  CoinSubheading,
  CoinDetailsText,
  BuyButton,
  SimilarCoinsSection,
  Heading,
  SmallCoinImage,
  CoinDiv,
  Text,
  Description,
} from "./StyledCoinCard";
import Card from "./CoinCard";
import { useLocation, useNavigate } from "react-router-dom";

import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import * as localStorageActionType from "../../localStorage/ActionTypes";

import {
  FormElementType,
  customValidationType,
  InputVariant,
  InputTypes,
  FormElement,
  ButtonSizeVariant,
  ButtonVariant,
  ButtonType,
  AppButtonType,
} from "../../Utility/InterFacesAndEnum";
import Button from "../../components/UI/Buttons/Button";

// import { cardData, coin } from '../../Dummy/Data';
import { coinData } from "../../Dummy/Data";
import useDimension from "../../CustomHooks/useDimention";
import { useDispatch, useSelector } from "react-redux";
import { getCoins, getCoinsById } from "../../features/coinSlice";
import { RootState } from "../../app/store";
import ScrollToTop from "../../routes/ScrollToTop";
import BuyCoinModal from "../Modals/BuyCoinModal/BuyCoinModal";
import { Skeleton } from "@mui/material";

interface Details {
  title: string;
  description: string;
  price: any;
  code: any;
}

const CoinDetails: React.FC = () => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const dimention = useDimension();
  const dispatch = useDispatch();
  const singleCoin = useSelector((state: RootState) => state.coin.singleCoin);
  const coinList = useSelector((state: RootState) => state.coin.coins);
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  const [openModal, setOpenModal] = useState(false);
  const [details, setDetails] = useState<Details>({
    title: "",
    description: "",
    price: "",
    code: "",
  });
  // console.log("single coin",singleCoin)
  const navigateToView = (url: string) => {
    navigate(url);
  };

  const [data, setdata] = useState({});

  useEffect(() => {
    dispatch(getCoinsById(state.id));
    dispatch(getCoins());
    coinData.map((item) => {
      if (item.id === state.id) {
        setdata(item);
      }
    });
  }, [state.id]);
  useEffect(() => {
    setDetails({
      title: singleCoin?.coinTitle,
      description: singleCoin?.coinDescription,
      price: singleCoin?.coinPrice,
      code: singleCoin?.coinCode,
    });
  }, [singleCoin]);
  //  console.log(data)
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1270 },
      items:
        dimention.width >= 3000
          ? 14
          : dimention.width >= 2200
          ? 10
          : dimention.width >= 1700
          ? 8
          : 6,
    },
    desktop: {
      breakpoint: { max: 1270, min: 1070 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1070, min: 495 },
      items: dimention.width >= 890 ? 4 : dimention.width >= 690 ? 3 : 2,
    },
    mobile: {
      breakpoint: { max: 495, min: 0 },
      items: 2,
    },
  };

  const coinDetailsHandler = (id: Number) => {
    // alert(id)
    navigate(RouterPath.coinDetails, { state: { id: id } });
  };
  const isLoggedIn = getLocalStorage(localStorageActionType.GET_IS_LOGGED_IN);

  const buyCoin = () => {
    if (isLoggedIn) {
      setOpenModal(true);
    } else {
      navigate(RouterPath.signIn);
    }
  };

  const paragraphs = details?.description
    ?.split("\n\n")
    .map((paragraph: any, index: any) => <p key={index}>{paragraph}</p>);
  console.log(paragraphs);
  return (
    <Container>
      <ScrollToTop />
      <SectionTwo>
        <Section>
          <CoinImages>
            {!isLoading ? (
              <ResponsiveCarousel items={singleCoin?.coinImageUrls} />
            ) : (
              <Skeleton
                width={500}
                height={600}
                style={{ borderRadius: "5px" }}
              />
            )}
          </CoinImages>
        </Section>
        <Section>
          {!isLoading ? (
            <Title>
              {details?.title !== undefined ? details.title : "loading..."}
            </Title>
          ) : (
            <Skeleton width={300} height={50} style={{ borderRadius: "5px" }} />
          )}

          <CoinDiv>
            {!isLoading ? (
              <Description>
                <Text>
                {details?.description !== undefined
                  ? details?.description
                  : "Loading"}
              </Text>
              </Description>
            ) : (
              <Skeleton
                width={300}
                height={50}
                style={{ borderRadius: "5px" }}
              />
            )}
          </CoinDiv>

          {!isLoading ? (
            // <BuyButton onClick={buyCoin}>BUY</BuyButton>
            <Button
              appBtnType={AppButtonType.primaryBtn}
              disabled={false}
              fullWidth={true}
              size={ButtonSizeVariant.large}
              variant={ButtonVariant.contained}
              type={ButtonType.submit}
              clicked={buyCoin}
            >
              Buy
            </Button>
          ) : (
            <Skeleton
              width={200}
              height={100}
              style={{ borderRadius: "5px" }}
            />
          )}
          <BuyCoinModal
            label="Buy"
            coinId={singleCoin?.coinId}
            isVisible={openModal}
            toggleModal={() => setOpenModal(false)}
          />
          <CoinDiv>
            {!isLoading ? (
              <>
                <CoinDetailsText>
                  Price:{" "}
                  <b>
                    {details.price !== undefined
                      ? `$${details.price}`
                      : "loading..."}
                  </b>
                </CoinDetailsText>
                <CoinDetailsText>
                  Code:{" "}
                  <b>
                    {details.price !== undefined
                      ? `${details.code}`
                      : "loading..."}
                  </b>
                </CoinDetailsText>
              </>
            ) : (
              <>
                <Skeleton
                  width={300}
                  height={50}
                  style={{ borderRadius: "5px" }}
                />
                <Skeleton
                  width={300}
                  height={50}
                  style={{ borderRadius: "5px" }}
                />
              </>
            )}
          </CoinDiv>
        </Section>
      </SectionTwo>

      <Heading>Similar</Heading>
      <SimilarCoinsSection>
        <SectionWrapper>
          <Carousel
            responsive={responsive}
            itemClass="carousel-item-padding-40-px"
          >
            {coinList?.map((item, i) => {
              return (
                <div key={i} style={{ padding: "5px" }}>
                  <Card
                    imageUrl={item.coinThumbnailImageUrl}
                    label={item.coinTitle}
                    text={`Code:${item.coinCode}`}
                    onClick1={() => coinDetailsHandler(item.coinId)}
                    onClick2={() => {
                      isLoggedIn
                        ? navigateToView("/")
                        : navigate(RouterPath.signIn);
                    }}
                  />
                </div>
              );
            })}
          </Carousel>
        </SectionWrapper>
      </SimilarCoinsSection>
    </Container>
  );
};

export default CoinDetails;
