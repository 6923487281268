import styled from 'styled-components';
import * as UIConstants from '../../../UIConstants';

const Width = `@media (max-width: 501px)`;

interface StyledAuthWrapper{
    bgImageUrl: string
}

export const StyledAuthContainer = styled.div`
width: 100%;
box-sizing: border-box;
display: flex;
flex-flow: row nowrap;
align-items: center;
justify-content: center;
height: 100%;
`;

export const StyledImgSection = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-basis: 60%;
/* background-color: #FFFDF7; */
background: rgba(255, 200, 57, 0.2);
mix-blend-mode: multiply;
height: 110%;
margin-top:70px;
border: 1px solid rgba(255, 179, 50, 0.3);
${Width}{
    border: unset;
    display: none;
}
${UIConstants.mobileView}{
    height: 100%;
    margin-top:0; 
}
`;

export const StyledImg = styled.img`
width: 500px;
max-width: 100%;
height: 100%;
max-height: 100%;
object-fit: contain;
mix-blend-mode: multiply;
${Width}{
    max-height: 300px;
    max-width: 300px;
}
`;

export const StyledFormSection = styled.div`
display: flex;
flex-flow: column;
align-items: center;
justify-content: center;
flex-basis: 40%;
${Width}{
    flex-basis: 100%;
}
`;