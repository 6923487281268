import { FC, useEffect, useMemo, useState } from "react";

import Collapse from "../../../components/LotteryCollapse/LotteryCollapse";

import { downloadAuctionPurchaseList, downloadPurchaseList, getAuctionOrders, getLotteryOrders, setAuctionOrders } from "../../../features/ordersSlice";
import { useSelector, useDispatch } from "react-redux";
import { setOrders } from "../../../features/ordersSlice";
import { RootState } from "../../../app/store";
import Button from "../../../components/UI/Buttons/Button";


import * as Style from "../Lottery/StyledLotteries";
import { getLocalStorage } from "../../../localStorage/GetLocalStorage";
import * as localStorageActionType from "../../../localStorage/ActionTypes";
import { AppButtonType, ButtonSizeVariant, ButtonType, ButtonVariant } from "../../../Utility/InterFacesAndEnum";

const lotteryTabMenu = [
  {
    label: "Live Auction",
    isActive: true,
    id: 1,
    filterParam: "",
  },
  {
    label: "Executed Auction",
    isActive: false,
    id: 2,
    filterParam: "",
  },
  {
    label: "Cancelled Auction",
    isActive: false,
    id: 3,
    filterParam: "",
  },
];
interface PaymentIdProp {
  paymentIds: [];
}

const AuctionPurchaseList: FC = () => {
  const dispatch = useDispatch();

  const [tabMenu, setTabMenu] = useState(lotteryTabMenu);

  const [liveAuction, setLiveAuction] = useState([]);
  const [executedAuction, setExecutedAuction] = useState([]);
  const [cancelAuction, setCancelAuction] = useState([]);
  const [activeTabMenuId, setMenuId] = useState(1);
  const [paymentId, setPaymentId] = useState<PaymentIdProp>();
  const publicUserId = getLocalStorage(
    localStorageActionType.GET_PUBLIC_USER_ID
  );

  // const lotteryList = useSelector(
  //   (state: RootState) => state.orders.lotteryOrders
  // );
  const auctionList = useSelector(
    (state: RootState) => state.orders.auctionOrders
  );
// console.log(executedAuction)
  useEffect(() => {
    // dispatch(getLotteryOrders());
    dispatch(getAuctionOrders());
  }, []);

  useEffect(() => {
    if (auctionList.length > 0) {
      let executedAuction = [];
      let currentAuction = [];
      let cancelAuction= [];

      executedAuction = auctionList.filter((obj: any) => {
        return obj.auctionStatus === "E";
      });

      currentAuction = auctionList.filter((obj: any) => {
        return obj.auctionStatus === "C";
      });
      cancelAuction = auctionList.filter((obj: any) => {
        return obj.auctionStatus === "CL";
      });
      setLiveAuction(currentAuction);
      setExecutedAuction(executedAuction);
      setCancelAuction(cancelAuction)
      console.log(currentAuction)
      
    }
  }, [auctionList]);

  const toggleActiveState = (id: number) => {
    setMenuId(id);
    setTabMenu((tabMenu) => {
      return tabMenu.map((menuObj) => {
        return {
          ...menuObj,
          isActive: menuObj.id === id,
        };
      });
    });
  };

  let tabMenuView = useMemo(() => {
    return tabMenu.map((menuObj, index) => {
      return (
        <Style.TabMenuItem
          onClick={() => {
            toggleActiveState(menuObj.id);
          }}
          key={`lotteryMenuItem_${menuObj.id}_${index}`}
          isActive={menuObj.isActive}
        >
          {menuObj.label}
        </Style.TabMenuItem>
      );
    });
  }, [tabMenu]);

  const toggleLotteryCollapse = (ticketNumber: any) => {
    let updateAuctionList = auctionList.map((auctionObj: any) => {
      console.log(auctionObj)
      return {
        ...auctionObj,
        isExpanded:
          auctionObj.ticketNo === ticketNumber
            ? !auctionObj.isExpanded
            : auctionObj.isExpanded,
      };
    });
    dispatch(
      // setOrders({
      //   lotteryOrders: updateAuctionList,
      // })
      setAuctionOrders({
        auctionOrders:updateAuctionList
      })
    );
  };

  useEffect(() => {
    // console.log(1)
    let arr: any = [];
    if (activeTabMenuId === 1) {
      liveAuction.map((lottery: any) => {
        // console.log(lottery.paymentId);
        arr.push(lottery.paymentId);
      });
    } else if(activeTabMenuId === 2){
      executedAuction.map((lottery: any) => {
        // console.log(lottery.paymentId);
        arr.push(lottery.paymentId);
      });
    }else{
       cancelAuction.map((lottery: any) => {
        // console.log(lottery.paymentId);
        arr.push(lottery.paymentId);
      });
    }
    setPaymentId({
      paymentIds: arr,
    });
  }, [liveAuction,executedAuction,cancelAuction, activeTabMenuId]);
  console.log(paymentId)
  const downloadListHandler = () => {
    dispatch(downloadAuctionPurchaseList({
      publicUserId: publicUserId,
      paymentIds: paymentId?.paymentIds,
    }))
  };
  return (
    <Style.Wrapper>
       <Style.TabMenuContainer>
        <Style.TabMenuWrapper>{tabMenuView}</Style.TabMenuWrapper>
        <Style.DownloadListBtn>
          <Button
            disabled={false}
            appBtnType={AppButtonType.primaryBtn}
            fullWidth={false}
            variant={ButtonVariant.text}
            type={ButtonType.default}
            size={ButtonSizeVariant.small}
            clicked={downloadListHandler}
          >
            Export
          </Button>
        </Style.DownloadListBtn>
      </Style.TabMenuContainer>
      <Style.TableOverflowX>
        <Style.Table>
          <Style.CollapseHeader>
            <Style.Label width={"30%"} textAlign={"center"}>
              Game Number
            </Style.Label>
            <Style.Label width={"20%"} textAlign={"center"}>
              Ticket number
            </Style.Label>
            <Style.Label width={"15%"} textAlign={"center"}>
              Reward
            </Style.Label>
            <Style.Label width={"25%"} textAlign={"center"}>
              Status
            </Style.Label>
            <Style.Label width={"30%"} textAlign={"right"}>
              Purchased Date
            </Style.Label>
          </Style.CollapseHeader>

          <Collapse
            onToggle={toggleLotteryCollapse}
            data={activeTabMenuId === 1 ? liveAuction : activeTabMenuId === 2? executedAuction :cancelAuction}
          />
        </Style.Table>
      </Style.TableOverflowX>
    </Style.Wrapper>
  );
};

export default AuctionPurchaseList;
