import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as endpoints from '../networkUtilities/endpoints'
import { toggleNotificationVisibility } from './notificationSlice';
import { NotificationType } from '../Utility/InterFacesAndEnum';

import * as localStorageActionType from '../localStorage/ActionTypes';
import { getLocalStorage } from '../localStorage/GetLocalStorage';
import { toggleLoader } from './loaderSlice';

interface PromotionState {
    home: any,
    lottery: any,
    auction: any,
    isLoading: boolean,
    lotterySlider: any,
    auctionSlider: any,
    static: any,
    staticLeftMobile: any,
    staticRightMobile: any,
    staticLeftWeb: any,
    staticRightWeb: any,
    staticLeftUrl: any,
    staticRightUrl: any,
    homeBanner: any
}

interface SetPromotionData {
    pageName: string,
    data: any
}

interface SetStaticPromotionData {
    pageName: string,
    data: any
}
interface SetSlidingPromotionData {
    pageName: string,
    data: any
}
interface SetStaticLeftRight {
    pageName: string,
    mobileBanner: any
    webBanner: any,
    url: any
}

interface SetHomeBanner {
    homeBanner: any
}

const initialState: PromotionState = {
    home: [],
    lottery: [],
    auction: [],
    isLoading: true,
    lotterySlider: [],
    auctionSlider: [],
    static: [],
    staticLeftMobile: {},
    staticRightMobile: {},
    staticLeftWeb: {},
    staticRightWeb: {},
    staticLeftUrl: {},
    staticRightUrl: {},
    homeBanner: {},
}


export const getPromotionData = createAsyncThunk(
    'get promotion data',
    async (payload: string, { dispatch }) => {
        dispatch(toggleLoader({ isLoading: true }));

        dispatch(toggleLoadingState({
            isLoading: true
        }))
        await fetch(`${endpoints.getPromotions}?promotionPage=${payload}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN)}`,
                "Content-type": "application/json; charset=UTF-8",
            }
        })
            .then((respose) => {
                return respose.json();
            })
            .then((response) => {

                dispatch(toggleLoader({ isLoading: false }));

                if (response?.statusCode === 200) {
                    // dispatch(toggleNotificationVisibility({
                    //     isVisible: false,
                    //     status: NotificationType.success,
                    //     message: response.errorMsg
                    // }));

                    dispatch(setPromotionResponse({
                        pageName: payload,
                        data: response.result
                    }));



                    let staticArr: any = [];
                    let slidingArr: any = [];
                    let homeBannerObj: any = {};
                    // let leftBanner:{}={};
                    // let RightBanner:{}={};

                    response?.result.map((item: any) => {
                        if (item?.promotionType === "STATIC") {
                            staticArr.push(item)
                            if (item?.promotionPosition === "LEFT") {
                                // leftBanner=item;
                                dispatch(setStaticLeftPromotion({
                                    pageName: payload,
                                    mobileBanner: item?.promotionMobileBannerImages[0],
                                    webBanner: item?.promotionWebBannerImages[0],
                                    url: item?.promotionBannerUrl
                                }))
                            }
                            if (item?.promotionPosition === "RIGHT") {
                                // RightBanner=item;
                                dispatch(setStaticRightPromotion({
                                    pageName: payload,
                                    mobileBanner: item?.promotionMobileBannerImages[0],
                                    webBanner: item?.promotionWebBannerImages[0],
                                    url: item?.promotionBannerUrl
                                }))
                            }

                            // console.log(item)
                        }
                        if (item?.promotionType === "SLIDER") {
                            slidingArr.push(item);
                        }

                        if (item.promotionPage === "HOME") {
                            homeBannerObj["bannerNo"] = item?.bannerNo;
                            homeBannerObj["isActive"] = item?.isActive;
                            homeBannerObj["promotionBannerUrl"] = item?.promotionBannerUrl;
                            homeBannerObj["promotionMobileBannerImage"] = item?.promotionMobileBannerImages[0];
                            homeBannerObj["promotionWebBannerImage"] = item?.promotionWebBannerImages[0];
                        }
                    })
                    dispatch(setHomeBanner({
                        homeBanner: homeBannerObj
                    }))
                    dispatch(setSlidingPromotion({
                        pageName: payload,
                        data: slidingArr
                    }))
                    // console.log(RightBanner)

                    dispatch(setStaticPromotion({
                        pageName: payload,
                        data: staticArr
                    }))
                } else {
                    // dispatch(toggleNotificationVisibility({
                    //     isVisible: false,
                    //     status: NotificationType.error,
                    //     message: response.errorMsg
                    // }));
                }
            })
            .catch((error) => {
                dispatch(toggleLoader({ isLoading: false }));

                dispatch(toggleNotificationVisibility({
                    isVisible: true,
                    status: NotificationType.error,
                    message: "Something went wrong!"
                }));
            })
            .finally(() => {
                dispatch(toggleLoader({ isLoading: false }));

                dispatch(toggleLoadingState({
                    isLoading: false
                }))
            })
    }
)

const promotionSlice = createSlice({
    name: 'Promotions',
    initialState: initialState,
    reducers: {
        setPromotionResponse: (state, action: PayloadAction<SetPromotionData>) => {
            return {
                ...state,
                home: action.payload.pageName === "HOME" ? action.payload.data : state.home,
                lottery: action.payload.pageName === "LOTTERY" ? action.payload.data : state.lottery,
                auction: action.payload.pageName === "AUCTION" ? action.payload.data : state.auction
            }
        },
        setSlidingPromotion: (state, action: PayloadAction<SetSlidingPromotionData>) => {
            return {
                ...state,
                lotterySlider: action.payload.pageName === "LOTTERY" ? action.payload.data : state.lotterySlider,
                auctionSlider: action.payload.pageName === "AUCTION" ? action.payload.data : state.auctionSlider


            }
        },
        setStaticPromotion: (state, action: PayloadAction<SetStaticPromotionData>) => {
            return {
                ...state,
                static: action.payload.data

            }
        },
        setStaticLeftPromotion: (state, action: PayloadAction<SetStaticLeftRight>) => {
            return {
                ...state,
                staticLeftMobile: action.payload.mobileBanner,
                staticLeftWeb: action.payload.webBanner,
                staticLeftUrl: action.payload.url

            }
        },
        setStaticRightPromotion: (state, action: PayloadAction<SetStaticLeftRight>) => {
            return {
                ...state,
                staticRightMobile: action.payload.mobileBanner,
                staticRightWeb: action.payload.webBanner,
                staticRightUrl: action.payload.url

            }
        },
        setHomeBanner: (state, action: PayloadAction<SetHomeBanner>) => {
            return {
                ...state,
                homeBanner: action.payload.homeBanner

            }
        },

        toggleLoadingState: (state, action: PayloadAction<{ isLoading: boolean }>) => {
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        }
    }
});

export const { setPromotionResponse, toggleLoadingState, setSlidingPromotion, setHomeBanner, setStaticPromotion, setStaticLeftPromotion, setStaticRightPromotion } = promotionSlice.actions;
export default promotionSlice.reducer