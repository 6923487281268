import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// import {RouterPath} from '../routes'

export const AppRoutePath = {
  profile: "/account/profile",
  address: "/account/address",
  changePassword: "/account/change-password",
  settings: "/account/two-fa",
  membership: "/account/membership",
  signUp: "/account/registration",
  signIn: "/account/signin",
  googleLogin: "/account/google-login",
  twoFA: "/account/2FA",
  updatePassword: "/account/password-update",
  resetPassword: "/auth/password-reset/:publicUserId",
  resendResetLink: "/account/resend-pwd-reset-link",
  forgotPassword: "/account/forgot-password",
  lotteries: "/lottery",
  lotteryPaymentView: "/lottery/payment",
  lotteryPaymentSuccess: "/lottery/payment-success",
  lotteryPaymentFail: "/lottery/payment-fail",
  userProfile: "/userProfile",
  auction: "/action",
  orders: "/orders",
  auctionList: "/auction",
  allAuction: "/auction/all-auction",
  auctionDetail: "/auction/detail/:auctionId",
  createAuction: "/auction/create",
  createAuctionPaymentSuccess: "/auction/create/payment-success",
  createAuctionPaymentFail: "/auction/create/payment-fail",
  createAuctionPaymentComplete: "/auction/create/payment-complete",

  auctionPaymentSuccess: "/auction/payment-success",
  auctionPayment: "/auction/payment",
  paypalSuccess: "/payment/success",
  paypalFail: "/payment/fail",
  auctionPaypalSuccess: "/auction/payment/success",
  auctionPaypalFail: "/auction/payment/fail",
  membershipSuccess: "/goldmembership/success",
  membershipFail: "/goldmembership/fail",
  tempRoot: "/",
  root: "/",
  notFound: "*",
  termsAndCondition: "/terms-conditions",
  privacyPolicy: "/privacy-policy",
  contactUs: "/contact-us",
  coinDetails: "/coin-details",
  coinPayment: "/coin/payment",
  coinPaymentSuccess: "/coin/payment-success",
  coinPaymentFail: "/coin/payment-fail",
  coinPaymentComplete: "/coin/payment-complete",
  buyTicket: "/buy-ticket",
  loadMoney: "/load-money",
  loadMoneyPaymentSucess: "/load-money/loadMoneyPayment-success",
  loadMoneyPaymentFail: "/load-money/loadMoneyPayment-fail",
  loadMoneyPaymentComplete: "/load-money/loadMoneyPayment-complete",
  successPayment: "/success-payment",
  auth2Redirect: "/oauth2/redirect",
};

interface NavigationState {
  data: {
    label: string;
    key: string;
    navRoute: string;
    isSelected: boolean;
    activeRoutes: string[];
  }[];
}

interface ToggleSelectionStatus {
  key: string;
}

let navigationState: NavigationState = {
  data: [
    {
      label: "Home",
      key: "home",
      activeRoutes: [AppRoutePath?.root, AppRoutePath?.userProfile],
      navRoute: AppRoutePath?.root,
      isSelected: false,
    },
    {
      label: "Lottery",
      key: "lottery",
      activeRoutes: [
        AppRoutePath?.lotteries,
        AppRoutePath?.lotteryPaymentView,
        AppRoutePath?.lotteryPaymentSuccess,
      ],
      navRoute: AppRoutePath?.lotteries,
      isSelected: true,
    },
    {
      label: "Auction",
      key: "auction",
      activeRoutes: [AppRoutePath?.auctionList, AppRoutePath.auctionDetail],
      navRoute: AppRoutePath?.auctionList,
      isSelected: false,
    },
    {
      label: "purchase",
      key: "auction",
      activeRoutes: [AppRoutePath?.orders],
      navRoute: AppRoutePath?.orders,
      isSelected: false,
    },
    {
      label: "loadMoney",
      key: "loadmoney",
      activeRoutes: [
        AppRoutePath?.loadMoney,
        AppRoutePath?.loadMoneyPaymentSucess,
        AppRoutePath?.loadMoneyPaymentFail,
      ],
      navRoute: AppRoutePath?.loadMoney,
      isSelected: false,
    },
    // {
    //   label: "version : 2.0",
    //   key: "version",
    //   activeRoutes: [],
    //   navRoute: "",
    //   isSelected: false,
    // },
  ],
};

interface SetNavDate {
  data: {
    label: string;
    key: string;
    navRoute: string;
    isSelected: boolean;
    activeRoutes: string[];
  }[];
}

const navigationSlice = createSlice({
  name: "navigation",
  initialState: navigationState,
  reducers: {
    toggleSelectionStatus: (
      state,
      action: PayloadAction<ToggleSelectionStatus>
    ) => {
      let selectedState = action.payload.key;
      return {
        ...state,
      };
    },
    setNavResponse: (state, action: PayloadAction<NavigationState>) => {
      return {
        ...state,
        data: action.payload.data,
      };
    },
  },
});

export const { toggleSelectionStatus, setNavResponse } =
  navigationSlice.actions;
export default navigationSlice.reducer;
