import { FC, useState, Fragment } from "react";
import {
  ButtonSizeVariant,
  ButtonType,
  ButtonVariant,
  AppButtonType,
} from "../../Utility/InterFacesAndEnum";
import Button from "../../components/UI/Buttons/Button";

import AuctionBuyTicketModal from "../Modals/AuctionBuyTicketModal/BuyTicketModal";

import * as localStorageActionType from '../../localStorage/ActionTypes';
import { setLocalStorage } from "../../localStorage/SetLocalStorage";
import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import { useNavigate } from "react-router-dom";
import { RouterPath } from "../../routes/routes";

interface AuctionBuyBtnProps {
  auctionObj: any;
}

const AuctionBuyBtn: FC<AuctionBuyBtnProps> = ({ auctionObj }) => {
  const [isVisible, setIsVisible] = useState(false);
  const isLoggedIn = getLocalStorage(localStorageActionType.GET_IS_LOGGED_IN);
  const navigate = useNavigate();

  const toggleIsModalVisible = () => {};

  const triggerTicketModal = () => {
    if(isLoggedIn){
      setIsVisible(!isVisible);
      console.log(auctionObj, "auctionObj, triggerTicketModal");
      setLocalStorage(localStorageActionType.SET_SELECTED_AUCTION_OBJ,auctionObj);
    }else{
      navigate(RouterPath.signIn);
    }
  };

  let ticketObj = [
    {
      label: "Bronze Ticket",
      amount: auctionObj?.bronzeTicketPrice,
      selected: false,
      ticketType: "bronze",
    },
    {
      label: "Silver Ticket",
      amount: auctionObj?.silverTicketPrice,
      selected: false,
      ticketType: "silver",
    },
    {
      label: "Gold Ticket",
      amount: auctionObj?.goldTicketPrice,
      selected: true,
      ticketType: "gold",
    },
    {
      label: "Platinum Ticket",
      amount: auctionObj?.platinumTicketPrice,
      selected: false,
      ticketType: "Platinum",
    },
  ];

  return (
    <Fragment>
      {isVisible && (
        <AuctionBuyTicketModal
          isVisible={isVisible}
          label={"Buy Auction Tickets"}
          ticket={ticketObj}
          toggleModal={triggerTicketModal}
        />
      )}
      <Button
        disabled={false}
        appBtnType={AppButtonType.primaryBtn}
        fullWidth={true}
        variant={ButtonVariant.contained}
        type={ButtonType.default}
        size={ButtonSizeVariant.small}
        clicked={triggerTicketModal}
      >
        BUY TICKETS
      </Button>
    </Fragment>
  );
};

export default AuctionBuyBtn;
