import React, { FC, useRef } from "react";
import {
  Container,
  ProfileImage,
  UpdateImageText,
} from "./StyledImageUploader";
import * as localStorageActioinType from "../../localStorage/ActionTypes";
import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import ProfileAvtarUrl from "../../assets/profileavtar.webp";


interface ImageUploaderProps {
  onSelectImage(obj: any): any;
}

const CustomImageUploader: FC<ImageUploaderProps> = (props) => {
  const { onSelectImage } = props;
  let fileInputRef = useRef<HTMLInputElement>(null);

  const userProfileImageUrl = useSelector((state: RootState) => state.userProfile.userDetail.imageUrl);
  //  console.log(userProfileImageUrl)
  const createUploadFileObj = (event: any) => {
    uploadFile(event.target.files[0]);
  };

  const clickOnInput = () => {
    fileInputRef.current?.click();
  };

  const uploadFile = (files: any) => {
    const formData = new FormData();
    formData.append("image", files);
    formData.append(
      "publicUserId",
      getLocalStorage(localStorageActioinType.GET_PUBLIC_USER_ID)
    );
    onSelectImage(formData);
  };

  // Append timestamp to force URL change
  const userProfileImageUrlWithTimestamp = `${userProfileImageUrl}?timestamp=${Date.now()}`;

  return (
    <Container>
      <ProfileImage src={userProfileImageUrl===null? ProfileAvtarUrl:userProfileImageUrlWithTimestamp} />
      <input
        style={{ display: "none" }}
        id={"imageFileInput"}
        ref={fileInputRef}
        type={"file"}
        onChange={(event) => {
          createUploadFileObj(event);
        }}
        accept="image/jpeg, image/png, image/jpg, image/tiff"
      />
      <UpdateImageText onClick={clickOnInput}>Update Image</UpdateImageText>
    </Container>
  );
};

export default CustomImageUploader;
