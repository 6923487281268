import { FC, useEffect } from "react";

import * as Styled from "../Success/StyledSuccess";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  ButtonSizeVariant,
  ButtonVariant,
  ButtonType,
  AppButtonType,
  NotificationType,
} from "../../../../Utility/InterFacesAndEnum";
import Button from "../../../../components/UI/Buttons/Button";
import { RouterPath } from "../../../../routes/routes";
import { toggleNotificationVisibility } from "../../../../features/notificationSlice";

const CoinPaypalFail: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
        toggleNotificationVisibility({
          isVisible: true,
          status: NotificationType.error,
          message: 'Payment Failed.',
        })
      );
  }, []);
  const resetRootState = () => {
    navigate(RouterPath.root)
  };
  return (
    <Styled.Wrapper>
      <Styled.SectionWrapper>
        <Styled.Title>Payment Failed!</Styled.Title>
        <Styled.Subtitle>
          Payment is failed due to following reason:
          <Styled.ImageSection>
            <ol>
              <li>Transaction cannot be authorized</li>
              <li>Incorrect OTP or Password</li>
              <li>Signature validation failed</li>
              <li>Insufficient balance in the account</li>
            </ol>
          </Styled.ImageSection>
          
        </Styled.Subtitle>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center", marginBottom:"20px"}}>
        <Button
          appBtnType={AppButtonType.primaryBtn}
          disabled={false}
          fullWidth={false}
          size={ButtonSizeVariant.large}
          variant={ButtonVariant.contained}
          type={ButtonType.submit}
          clicked={() => {
            resetRootState();
          }}
        >
          Go Back
        </Button>
        </div>
      
      </Styled.SectionWrapper>
      
    </Styled.Wrapper>
  );
};

export default CoinPaypalFail;
