import styled from 'styled-components';

export const StyledHeaderwrapper = styled.div`
width: 100%;
box-sizing: border-box;
display: flex;
flex-flow: row nowrap;
align-items: center;
justify-content: flex-start;
padding: 15px 30px;
`;

export const HeaderImg = styled.img`
width: 80px;
height: 60px;
`;