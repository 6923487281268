import styled from "styled-components";

export const ForgotPassword = styled.div`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  text-align: right;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 16px;
  cursor: pointer;
  color: #000000;
  margin-bottom: 50px;
`;

export const CreateAccountContainer = styled.div`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin: 16px 0;
  cursor: pointer;
  color: #adb5bd;
`;

export const CreateAccountOption = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  color: #3674bf;
  margin-left: 5px;
`;

export const GoogleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
