import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouterPath } from "../../routes/routes";
import MainLottery from "../../components/MainLottery/MainLottery";
import UpcomingLotteries from "../../components/HP-UpcomingLotteries/UpcomingLotterites";
import LotteryWinners from "../../components/HP-TopLotteriesWinners/TopLotteryWinners";

import SimilarProducts from "../AuctionSimilarProducts/SimilarProducts";
import Promotions from "../Promotions/Promotion";
import AuctionWinners from "../../components/HP-TopAuctionWinners/AuctionWinners";
import bgImg from "../../assets/mainLotteryBgImg.jpg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";
import {
  MainPageWrapper,
  SectionTwo,
  Section,
  Title,
  AuctionSection,
  TitelWrapper,
  MoreAuction,
  AuctionWinnerSection,
  MoreLotteries,
  SectionWrapper,
  CardContainer,
  EmptyList,
  LotterySection,
} from "./StyledHome";
import Card from "../../components/GoldCoins/CoinCard";
import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import * as localStorageActionType from "../../localStorage/ActionTypes";

import { coinData } from "../../Dummy/Data";
import useDimension from "../../CustomHooks/useDimention";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetailes } from "../../features/loadMoneySlice";
import LiveLotteries from "../../components/HP-LiveLotteries/LiveLotterites";
import { RootState } from "../../app/store";
import AuctionCarousel from "../../components/AuctionCarousel/AuctionCarousel";
import {
  getAuctionList,
  getLiveAuctionList,
  toggleAuctionPurchase,
} from "../../features/auctionList";
import { getCoins, storeWalletPaymentResponse } from "../../features/coinSlice";
import BuyCoinModal from "../../components/Modals/BuyCoinModal/BuyCoinModal";
import { getPromotionData } from "../../features/promotion";
import HomeLottery from "../../components/HomeLottery/HomeLottery";
import { customMobileWidth, mobileWidth } from "../../UIConstants";
import { addVisitorsCount } from "../../features/setting";
// import SkeletonMainLottery from "../../components/MainLottery/SkeletonMainLottery";

const Home: FC = () => {
  const dispatch = useDispatch();
  const dimention = useDimension();
  const coinList = useSelector((state: RootState) => state.coin.coins);
  const [openModal, setOpenModal] = useState(false);
  const [selectId, setSelectId] = useState("");

  // console.log(coinList)

  useEffect(() => {
    dispatch(getUserDetailes());
    dispatch(getCoins());
    dispatch(addVisitorsCount());
    dispatch(
      storeWalletPaymentResponse({
        isWalletPaymentInitiated: false,
      })
    );
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1270 },
      items:
        dimention.width >= 3000
          ? 14
          : dimention.width >= 2200
          ? 10
          : dimention.width >= 1700
          ? 8
          : 6,
    },
    desktop: {
      breakpoint: { max: 1270, min: 1070 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1070, min: 495 },
      items: dimention.width >= 890 ? 4 : dimention.width >= 690 ? 3 : 2,
    },
    mobile: {
      breakpoint: { max: 495, min: 0 },
      items: 2,
    },
  };

  const navigate = useNavigate();
  const navigateToView = (url: string) => {
    navigate(url);
  };
  const isLoggedIn = getLocalStorage(localStorageActionType.GET_IS_LOGGED_IN);
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);

  const coinDetailsHandler = (id: Number) => {
    // alert(id)
    navigate(RouterPath.coinDetails, { state: { id: id } });
  };

  //auction part
  const auctionList = useSelector(
    (state: RootState) => state.auction.auctionList
  );
  const liveAuctionList = useSelector(
    (state: RootState) => state.auction.liveAuctionList
  );
  const HomeBanner = useSelector(
    (state: RootState) => state.promotion.homeBanner
  );

  console.log(HomeBanner);
  useEffect(() => {
    dispatch(getAuctionList("auctionStatus=U"));
    dispatch(getLiveAuctionList("auctionStatus=C"));
    dispatch(getPromotionData("HOME"));
    return () => {
      dispatch(
        toggleAuctionPurchase({
          isPurchased: false,
        })
      );
    };
  }, []);

  const buyCoinHandler = (id: any) => {
    setSelectId(id);
    setOpenModal(true);
  };

  return (
    <div>
      <Helmet>
        <title>KingsRings - Home</title>
      </Helmet>

      <MainPageWrapper
        isActive={HomeBanner?.isActive}
        homeDesktopBackground={HomeBanner?.promotionWebBannerImage}
        homeMobileBackground={HomeBanner?.promotionMobileBannerImage}
      >
        {/* <SkeletonMainLottery />  */}
        {<MainLottery />}
      </MainPageWrapper>

      <Section>
        <BuyCoinModal
          isVisible={openModal}
          label="Buy"
          coinId={selectId}
          toggleModal={() => setOpenModal(false)}
        />
        <SectionWrapper>
          {coinList.length > 0 ? (
            <Carousel responsive={responsive}>
              {coinList?.map((item, i) => {
                return (
                  <div key={i} style={{ padding: "5px" }}>
                    <Card
                      imageUrl={item.coinThumbnailImageUrl}
                      label={item.coinTitle}
                      text={`Code:${item.coinCode}`}
                      onClick1={() => coinDetailsHandler(item.coinId)}
                      onClick2={() => {
                        isLoggedIn
                          ? buyCoinHandler(item.coinId)
                          : navigate(RouterPath.signIn);
                      }}
                    />
                  </div>
                );
              })}
            </Carousel>
          ) : (
            <EmptyList>No Coins Record Found.</EmptyList>
          )}
        </SectionWrapper>
      </Section>
      {dimention.width > mobileWidth ? (
        <SectionTwo>
          <Section>
            <Title>Top lottery contest winners</Title>
            <LotteryWinners />
          </Section>
          <Section>
            <Title>Live lotteries</Title>
            <LiveLotteries />
            {/* <Title>More Upcomming lotteries</Title>
          <UpcomingLotteries /> */}
            <MoreLotteries onClick={() => navigateToView(RouterPath.lotteries)}>
              More lotteries games
            </MoreLotteries>
          </Section>
        </SectionTwo>
      ) : (
        // <SectionTwo>
        <SectionWrapper>
          <LotterySection>
            <Title>Live lotteries</Title>
            <LiveLotteries />
            {/* <Title>More Upcomming lotteries</Title>
        <UpcomingLotteries /> */}
            <MoreLotteries onClick={() => navigateToView(RouterPath.lotteries)}>
              More lotteries games
            </MoreLotteries>
          </LotterySection>
          <LotterySection>
            <Title>Top lottery contest winners</Title>
            <LotteryWinners />
          </LotterySection>
        </SectionWrapper>
        // </SectionTwo>
      )}
      <SectionWrapper>
        <AuctionSection>
          <TitelWrapper>
            <Title>Live Auctions</Title>
          </TitelWrapper>
          {/* <SimilarProducts isTitleRequired={false} /> */}
          <AuctionCarousel auctionList={liveAuctionList} isLive={true} />
          <MoreAuction
            onClick={() => {
              navigateToView(RouterPath.auctionList);
            }}
          >
            More auctions
          </MoreAuction>
        </AuctionSection>
        <AuctionSection>
          <Title>Top Auction winners</Title>
          <AuctionWinners />
          {/* <MoreAuction
          onClick={() => {
            navigateToView(RouterPath.auctionList);
          }}
        >
          More auctions
        </MoreAuction> */}
        </AuctionSection>
      </SectionWrapper>
    </div>
  );
};

{
  /* Fix me  */
}

export default Home;
