import styled from 'styled-components';
import * as UIConstants from "../../../UIConstants";

interface TabMenuItemProps {
  isActive: boolean
}

interface ValueProps {
  width: number | string,
  textAlign: string,
  color?: string

}



export const Wrapper = styled.div`
width: 100%;
box-sizing: border-box;
padding: 40px;
${UIConstants.mobileView}{
    padding:20px 0px 20px 0px;
}
`;

export const TableWrapper = styled.div`
width: 100%;
box-sizing: border-box;
overflow-x: auto;
`;


export const TabMenuWrapper = styled.div`
width: 100%;
box-sizing: border-box;
display: flex;
flex-flow: row nowrap;
align-items: center;
justify-content: flex-start;
`;

export const TableOverflowX = styled.div`
  overflow-x:auto;
`
export const Table = styled.div`
${UIConstants.mobileView}{
    width:800px;
}
`

export const TabMenuItem = styled.div`
color: ${(props: TabMenuItemProps) => props.isActive ? '#ffffff' : '#ADB5BD'};
background-color: ${(props: TabMenuItemProps) => props.isActive ? '#003459' : '#F1F3FF'};
padding: 16px;
cursor: pointer;
`;

export const CollapseHeader = styled.div`
width: 100%;
box-sizing: border-box;
display: flex;
flex-flow: row nowrap;
border: 1px solid #E8E8E8;
border-bottom: none;
padding: 15px 10px;
background-color: #F9F9F9;
${UIConstants.mobileView}{
// width: 1000px;

}
`;

export const Label = styled.div`
color: #000000;
width: ${(props: ValueProps) => props.width};
text-align: ${(props: ValueProps) => props.textAlign};
font-weight: 600;
font-size: 14px;
@media (max-width: 500px) {
// font-size: 10px;
    
  }
`;

export const CoinTable = styled.div`
width: 100%;
box-sizing: border-box;
display: flex;
padding: 10px 20px;
border: 1px solid #E8E8E8;
border-bottom: none;

// cursor:pointer;
`;

export const Values = styled.div`
width: 100%;
box-sizing: border-box;
max-height: 500px;
overflow-y: auto;
${Wrapper}:nth-child(2n){
    background-color: #F9F9F9;
};
${UIConstants.mobileView}{
    //  width:1000px;
    //   overflow-x:auto;
}
`;


export const Value = styled.div`
color: #000000;
width: ${(props: ValueProps) => props.width};
text-align: ${(props: ValueProps) => props.textAlign};
color: ${(props: ValueProps) => props.color !== undefined ? props.color : '#000000'};
font-weight: ${(props: ValueProps) => props.color !== undefined ? 600 : "unset"};
`;