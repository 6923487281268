import styled from "styled-components";
import * as UIConstants from "../../UIConstants";

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const SlidingElement = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 24px;
  // color: #3A57E8;
  color: #f54972;
`;

export const Img = styled.img`
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  ${UIConstants.mobileView} {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  ${UIConstants.customMobileView} {
  }
`;
