import {FC} from 'react';
import {Wrapper, ViewHeader, Container, Title, Subtitle, ActionSection, PurchaseSuccessWrapper, SuccessImage, } from './StyledPaymentSuccess';
import {ButtonSizeVariant, ButtonVariant, ButtonType, AppButtonType} from '../../Utility/InterFacesAndEnum';
import Button from '../../components/UI/Buttons/Button';

import successSrc from '../../assets/success.svg';
import {RouterPath} from '../../routes/routes'
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { RootState } from '../../app/store';
import { resetAuctionPurchaseState } from '../../features/purchaseAuctionSlice';


const PaymentSuccess:FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let totalNoOfTickets = useSelector((state:RootState) => state.purchaseAuction.totalNoOfTickets);
    const redirect=()=>{
        navigate(RouterPath.auctionList)
        dispatch(resetAuctionPurchaseState());
    }
    return(
        <Wrapper>
            <ViewHeader />
            <PurchaseSuccessWrapper>
            {/* <ContainerWrapper> */}
            <Container>
                <SuccessImage src={successSrc} />
                <Title>
                Ticket  purchased Successfully
                </Title>
                <Subtitle>
                Total {totalNoOfTickets} entries created to the Auction, 
                your ticket will be sent to your registered email address
                </Subtitle>
                <ActionSection>
                <Button 
                appBtnType={AppButtonType.primaryBtn}
                    disabled={false} 
                    fullWidth={false} 
                    size={ButtonSizeVariant.large} 
                    variant={ButtonVariant.contained} 
                    type={ButtonType.submit} 
                    clicked={redirect} >
                        Go Back
                </Button>
                </ActionSection>
            </Container>
            {/* </ContainerWrapper> */}
            </PurchaseSuccessWrapper>
        </Wrapper>
    )
};

export default PaymentSuccess