import styled from "styled-components";
import * as UIConstant from "../../UIConstants";

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  // display: flex;
  // flex-flow: column wrap;
  // align-items: flex-start;
  // justify-content: flex-start;
  // text-align: left;
  border: 1px solid #adb5bd;
  border-radius: 8px;
  overflow: hidden;
`;

export const DetailWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  ${UIConstant.customMobileView} {
    padding: 5px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  position: relative;
  /* img{
  background: #f0f0f0;
border-radius:5px;
padding: 5px;
width: 100%;
height: 290px;
object-position: 50% 50%;
object-fit:scale-down;
${UIConstant.mobileView}{
  height:250px;
}
${UIConstant.customMobileView}{
  height:160px;
}

} */
`;
export const MemberImgWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
`;
export const GoldImg = styled.img`
  width: 25px;
  position: absolute;
  z-index: 2;
`;

export const AuctionImage = styled.img`
  background: #f0f0f0;
  border-radius: 5px;
  /* padding: 5px; */
  width: 100%;
  height: 290px;
  object-position: 50% 50%;
  object-fit: fill;
  ${UIConstant.mobileView} {
    height: 250px;
    /* width: 250px; */
  }
  ${UIConstant.customMobileView} {
    height: 160px;
    /* height: 160px; */
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #200e32;
  margin-bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AuctinProductType = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #adb5bd;
  margin-bottom: 16px;
  ${UIConstant.customMobileView} {
    font-size: 12px;
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
`;

export const TicketDetailWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 16px;
`;

export const Detail = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-flow: column;
  text-align: left;
`;

export const DetailTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: #200e32;
`;

export const Value = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  text-transform: capitalize;
  color: #000000;
`;

export const Action = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  Button {
    width: 100%;
    height: 41px;
  }
  Button:first-child {
    margin-right: 10px;
    ${UIConstant.customMobileView} {
      margin-right: 5px;
    }
  }
`;
