import { FC, useState, useEffect } from "react";
import Navigation from "../../components/Navigation/DesktopNavigation";
import CoinImgSrc from "../../assets/lotteryCoin.png";
import {
  FormElementType,
  customValidationType,
  InputVariant,
  InputTypes,
  FormElement,
  ButtonSizeVariant,
  ButtonVariant,
  ButtonType,
  AppButtonType,
  NotificationType,
} from "../../Utility/InterFacesAndEnum";
import {
  transformDate,
  updateFormInputState,
  validateForm,
} from "../../Utility/Utility";
import Button from "../../components/UI/Buttons/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RouterPath } from "../../routes/routes";

import CountDownTimer from "../../components/CountdownTimer/CountdownTimer";
import * as localStorageActionType from "../../localStorage/ActionTypes";
import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import { useSelector, useDispatch } from "react-redux";
import { purchaseLottery } from "../../features/purchaseLotterySlice";

import {
  ViewHeader,
  ContentSection,
  Content,
  SectionTitle,
  SectionContainer,
  PurchaseSectionDetails,
  PurchaseSectionImg,
  PurchaseDetails,
  LotteryDetail,
  Label,
  Value,
  GoldTicketView,
  GoldMemberDetail,
  GoldMemberCheckbox,
  GoldMemberDetails,
  GoldMemberAmount,
  GoldMemberTitle,
  GoldMemberFeatureList,
  Icon,
  Feature,
  PaymentDetailSection,
  PaymentListItem,
  PaymentLabel,
  Amount,
  Line,
  TotalAmount,
  Wrapper,
  BtnWrapper,
} from "./StyledCoinPayment";
import { RootState } from "../../app/store";
import { buyCoin, getCoinsById, purchaseCoinWallet } from "../../features/coinSlice";
import { getUserProfileDetail } from "../../features/userProfileSlice";
import { getSubscriptiontAmount } from "../../features/setting";
import { coinThumbnailbaseUrl } from "../../networkUtilities/endpoints";



const CoinPayment: FC = () => {
  const {state}:any = useLocation();
  // console.log(state.id)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ticketDetailObj, setTicketDetail] = useState<any>(null);
  const [show, setShow] = useState(false);
  const coin = useSelector((state:RootState)=>state.coin.singleCoin);
  const {userId}  = useSelector((state:RootState)=>state.userProfile.userDetail);
  const checkout = useSelector((state: RootState) => state.coin.checkout);
  const isWalletPaymentInitiated = useSelector((state:RootState)=>state.coin.isWalletPaymentInitiated);
  let isLoading = useSelector((state: RootState) => state.loader.isLoading);
  const subscriptionAmount = useSelector((state:RootState)=>state.setting.subscriptionAmount);


console.log(coin)
 

 
  useEffect(()=>{
    if(isWalletPaymentInitiated===true){
      navigate(RouterPath.coinPaymentComplete)
    }
  },[isWalletPaymentInitiated])

  useEffect(()=>{
    dispatch(getSubscriptiontAmount());
    dispatch(getCoinsById(state.id));
    dispatch(getUserProfileDetail());
  },[state.id])
  
 
const redirectToPaypalPayment=()=>{
  window.open(checkout?.href, "_self");
}


const proceedToPayment= () => {
 
  setShow(true);
  dispatch(buyCoin({
    coinId: state.id,
    userId: userId,
  }))
    
  };

  const redirectToWalletPayment = () => {
    dispatch(purchaseCoinWallet({
      coinId: state.id,
    userId: userId,
    }))    
  };

 

  const paymentBtn = (
    <>
      {isLoading ? (
        <>
          <Button
            appBtnType={AppButtonType.primaryBtn}
            disabled={false}
            fullWidth={true}
            size={ButtonSizeVariant.medium}
            variant={ButtonVariant.contained}
            type={ButtonType.submit}
            clicked={() => {}}
          >
            loading...
          </Button>
        </>
      ) : (
        <>
          {/* <a href={checkout.href} style={{ width: "100%" }}> */}
            <Button
              appBtnType={AppButtonType.primaryBtn}
              disabled={false}
              fullWidth={true}
              size={ButtonSizeVariant.medium}
              variant={ButtonVariant.contained}
              type={ButtonType.submit}
              clicked={redirectToPaypalPayment}
            >
              Pay with Debit Card
            </Button>
          {/* </a> */}

          <Button
            appBtnType={AppButtonType.primaryBtn}
            disabled={false}
            fullWidth={true}
            size={ButtonSizeVariant.medium}
            variant={ButtonVariant.contained}
            type={ButtonType.submit}
            clicked={redirectToWalletPayment}
          >
            Pay With Wallet{" "}
          </Button>
        </>
      )}
    </>
  );

  const proceedBtn = (
    <>
      <Button
        appBtnType={AppButtonType.primaryBtn}
        disabled={false}
        fullWidth={true}
        size={ButtonSizeVariant.medium}
        variant={ButtonVariant.contained}
        type={ButtonType.submit}
        clicked={proceedToPayment}
      >
        Procced To Payment
      </Button>
    </>
  );

  return (
    <Wrapper>
      <ViewHeader />
      <ContentSection>
        <Content>
          <SectionContainer>
            <SectionTitle>Purchase details</SectionTitle>
            <PurchaseSectionDetails>
              <PurchaseSectionImg>
                <img src={coin?.coinThumbnailImage!==null?`${coinThumbnailbaseUrl}${coin?.coinId}/${coin.coinThumbnailImage}`:CoinImgSrc} />
              </PurchaseSectionImg>
              <PurchaseDetails>
                <LotteryDetail>
                  <Label>Id</Label>
                  <Value>{coin?.coinId}</Value>
                </LotteryDetail>
                <LotteryDetail>
                  <Label>Code</Label>
                  <Value>{coin?.coinCode}</Value>
                </LotteryDetail>
                <LotteryDetail>
                  <Label>Title</Label>
                  <Value>{coin?.coinTitle}</Value>
                </LotteryDetail>
                <LotteryDetail>
                  <Label>Price</Label>
                  <Value>&#x24; {coin?.coinPrice}</Value>
                </LotteryDetail>
              </PurchaseDetails>
            </PurchaseSectionDetails>
          </SectionContainer>
          <SectionContainer>
            <GoldMemberDetail>
              <GoldMemberCheckbox></GoldMemberCheckbox>
              <GoldMemberDetails>
                <GoldMemberTitle>Gold Membership</GoldMemberTitle>
                <GoldMemberFeatureList>
                  <Icon>&#10003;</Icon>
                  <Feature>
                    Automatically participate in all lottery games with 5
                    entries in each Lottery game
                  </Feature>
                </GoldMemberFeatureList>
                <GoldMemberFeatureList>
                  <Icon>&#10003;</Icon>
                  <Feature>Unlimited lottery games pariticipation</Feature>
                </GoldMemberFeatureList>
                <GoldMemberFeatureList>
                  <Icon>&#10003;</Icon>
                  <Feature>Unlocked gold members lottery list</Feature>
                </GoldMemberFeatureList>
                <GoldMemberFeatureList>
                  <Icon>&#10003;</Icon>
                  <Feature>Monthly Pass</Feature>
                </GoldMemberFeatureList>
              </GoldMemberDetails>
              <GoldMemberAmount>&#x24;{subscriptionAmount?.subscriptionAmount}</GoldMemberAmount>
            </GoldMemberDetail>
          </SectionContainer>
        </Content>
        <Content>
          <SectionContainer>
            <SectionTitle>Payment details</SectionTitle>
            <PaymentDetailSection>
              <PaymentListItem>
                <PaymentLabel>Price</PaymentLabel>
                <Amount>&#x24; {coin?.coinPrice}</Amount>
              </PaymentListItem>
              <Line />
              <TotalAmount>
                <PaymentLabel>Total amount</PaymentLabel>
                <Amount>&#x24; {coin?.coinPrice}</Amount>
              </TotalAmount>
              <Line />
              <BtnWrapper>
                {!show && proceedBtn}
                {show && paymentBtn}
              </BtnWrapper>
            </PaymentDetailSection>
          </SectionContainer>
          <SectionContainer>
            <GoldMemberDetail>
              <GoldMemberCheckbox></GoldMemberCheckbox>
              <GoldMemberDetails>
                <GoldMemberTitle>Note</GoldMemberTitle>
                <GoldMemberFeatureList>
                  <Icon>&#10003;</Icon>
                  <Feature>
                    If you want to pay with crypto,{" "}
                    <Link to={RouterPath.contactUs}>
                      contact customer service
                    </Link>
                    .
                  </Feature>
                </GoldMemberFeatureList>
              </GoldMemberDetails>
            </GoldMemberDetail>
          </SectionContainer>
        </Content>
      </ContentSection>
    </Wrapper>
  );
};

export default CoinPayment;
