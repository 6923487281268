import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import FormBuilder from "../../../containers/FormBuilder/FormBuilder";

import { updateFormInputState } from "../../../Utility/Utility";
import {
  FormElementType,
  customValidationType,
  InputVariant,
  InputTypes,
  FormElement,
  ButtonSizeVariant,
  ButtonVariant,
  ButtonType,
  AppButtonType,
} from "../../../Utility/InterFacesAndEnum";
import Button from "../../../components/UI/Buttons/Button";

import * as localStorageActionType from "../../../localStorage/ActionTypes";
import { setLocalStorage } from "../../../localStorage/SetLocalStorage";

import {
  ModalBox,
  ModalHeader,
  CloseIcon,
  Title,
  ModalBody,
  SectionTitle,
  SummarySection,
  ActionSection,
  CoinDetails,
  CoinImage,
  CoinTitle,
  Img,
  ModalWrapper,
  BtnWrapper,
  EmptyImg,
} from "./StyledBuyCoinModal";

import { RouterPath } from "../../../routes/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  buyCoin,
  getCoinsById,
  purchaseCoinWallet,
} from "../../../features/coinSlice";
import { RootState } from "../../../app/store";
import { getLocalStorage } from "../../../localStorage/GetLocalStorage";
import { getUserProfileDetail } from "../../../features/userProfileSlice";

interface BuyCoinModalProps {
  label: string;
  isVisible: boolean;
  coinId: string;
  toggleModal(): void;
}

const BuyCoinModal: FC<BuyCoinModalProps> = (props) => {
  const { isVisible, label, coinId, toggleModal } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const coin = useSelector((state: RootState) => state.coin.singleCoin);
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  // const {userId}  = useSelector((state:RootState)=>state.userProfile.userDetail);
  // console.log(userId)
  // JSON.parse(
  //   getLocalStorage(localStorageActionType.GET_USER_DETAILS)
  // ).userId;
  // const checkout = useSelector((state: RootState) => state.coin.checkout);
  // const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  // const isWalletPaymentInitiated = useSelector((state:RootState)=>state.coin.isWalletPaymentInitiated);
  // const [show, setShow] = useState(false);
  // console.log(checkout);

  // useEffect(()=>{
  //   if(isWalletPaymentInitiated===true){
  //     navigate(RouterPath.coinPaymentSuccess)
  //   }
  // },[isWalletPaymentInitiated])

  useEffect(() => {
    dispatch(getCoinsById(coinId));
  }, [coinId]);

  const buyCoinHandler = () => {
    // dispatch(
    //   buyCoin({
    //     coinId: coinId,
    //     userId: userId,
    //   })
    // );
    // setShow(true);
    navigate(RouterPath.coinPayment, { state: { id: coinId } });
  };

  const actionBtn = (
    <>
      <Button
        appBtnType={AppButtonType.secondaryBordered}
        disabled={false}
        fullWidth={false}
        size={ButtonSizeVariant.large}
        variant={ButtonVariant.contained}
        type={ButtonType.submit}
        clicked={toggleModal}
      >
        close
      </Button>
      <Button
        appBtnType={AppButtonType.primaryBtn}
        disabled={false}
        fullWidth={false}
        size={ButtonSizeVariant.large}
        variant={ButtonVariant.contained}
        type={ButtonType.submit}
        clicked={buyCoinHandler}
      >
        Buy
      </Button>
    </>
  );

  // const walletPayment = () => {
  //   // console.log('cliced')
  //   // dispatch(
  //   //   purchaseCoinWallet({
  //   //     coinId: coinId,
  //   //     userId: userId,
  //   //   })
  //   // );
  // };

  // const redirectToPaypalPayment=()=>{
  //   window.open(checkout?.href, "_self")
  // }

  // const proceedBtn = (
  //   <>
  //     {isLoading ? (
  //       <Button
  //         appBtnType={AppButtonType.primaryBtn}
  //         disabled={false}
  //         fullWidth={false}
  //         size={ButtonSizeVariant.large}
  //         variant={ButtonVariant.contained}
  //         type={ButtonType.submit}
  //         clicked={() => {}}
  //       >
  //         Loading...
  //       </Button>
  //     ) : (
  //       <BtnWrapper>
  //         {/* <a href={checkout?.href}> */}
  //           <Button
  //             appBtnType={AppButtonType.primaryBtn}
  //             disabled={false}
  //             fullWidth={false}
  //             size={ButtonSizeVariant.large}
  //             variant={ButtonVariant.contained}
  //             type={ButtonType.submit}
  //             clicked={redirectToPaypalPayment}
  //           >
  //             Pay with Debit Card
  //           </Button>
  //         {/* </a> */}
  //         <Button
  //           appBtnType={AppButtonType.primaryBtn}
  //           disabled={false}
  //           fullWidth={false}
  //           size={ButtonSizeVariant.large}
  //           variant={ButtonVariant.contained}
  //           type={ButtonType.submit}
  //           clicked={walletPayment}
  //         >
  //           Pay with wallet
  //         </Button>
  //       </BtnWrapper>
  //     )}
  //   </>
  // );
  return (
    <Modal
      open={isVisible}
      onClose={toggleModal}
      aria-labelledby={label}
      aria-describedby={label}
    >
      <ModalWrapper>
        <ModalBox>
          <ModalHeader>
            <Title>{label}</Title>
            <CloseIcon onClick={toggleModal} />
          </ModalHeader>
          <ModalBody>
            <SectionTitle>Details</SectionTitle>
            <CoinDetails>
              <CoinTitle>
                Code : {isLoading ? "loading..." : coin?.coinCode}
              </CoinTitle>
              <CoinImage>
                {!isLoading ? (
                  <Img
                    src={coin?.coinThumbnailImageUrl}
                    alt="coinimg"
                    key={coin?.coinThumbnailImageUrl}
                  />
                ) : (
                  <EmptyImg >Loading Image...</EmptyImg>
                )}
              </CoinImage>
              <CoinTitle>
                Title : {isLoading ? "loading..." : coin?.coinTitle}
              </CoinTitle>
              <CoinTitle>
                Price : {isLoading ? "loading..." : `$${coin?.coinPrice}`}
              </CoinTitle>
            </CoinDetails>

            <SummarySection></SummarySection>
            <ActionSection>
              {actionBtn}
              {/* {!show && actionBtn} */}
              {/* {show && proceedBtn} */}
            </ActionSection>
          </ModalBody>
        </ModalBox>
      </ModalWrapper>
    </Modal>
  );
};

export default BuyCoinModal;
