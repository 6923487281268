import React, { Fragment, useEffect, useState } from "react";
import {
  getCountryWiseLiveAuctions,
  getLiveAuctionList,
  purchaseAuction,
  setCountrywiseLiveAuctionList,
} from "../../../features/auctionList";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  AuctionContainer,
  BreadCrumb,
  BreadCrumbItem,
  FormView,
  SectionTitle,
  Wrapper,
  FilterViewSection,
  Section,
  TotalView,
} from "./StyledSeeAll";
import AuctionItem from "../../../components/AuctionCards/AuctionCards";
import { useNavigate } from "react-router-dom";
import { RouterPath } from "../../../routes/routes";
import { countryNames } from "../../../assets/DropdownValues/DropdownValues";
import FormBuilder from "../../FormBuilder/FormBuilder";
import { updateFormSelectState } from "../../../Utility/Utility";
import {
  AppButtonType,
  ButtonSizeVariant,
  ButtonType,
  ButtonVariant,
  FormElement,
  FormElementType,
  InputTypes,
  InputVariant,
  customValidationType,
} from "../../../Utility/InterFacesAndEnum";
import Button from "../../../components/UI/Buttons/Button";
import AllAuctions from "../../../components/AllAuctions/AllAuction";

interface FormState {
  form: FormElement[];
  isValidForm: boolean;
}

const FileterForm: FormState = {
  form: [
    {
      elementType: FormElementType.autocomplete,
      value: "",
      id: "country",
      isRequired: true,
      fullWidth: true,
      isCustomValidationRequred: false,
      inputVariant: InputVariant.outlined,
      inputType: InputTypes.text,
      customValidationType: customValidationType.null,
      isValidInput: false,
      isTouched: false,
      errorMessage: "",
      label: "Search",
      radioGroupValues: [],
      dropdownValues: [...countryNames],
      slectedDate: null,
      isPasswordHidden: true,
    },
  ],
  isValidForm: true,
};

const SeeAllAuction = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showFiterData, setShowFilterData] = useState(false);

  const [fileterState, setFilterState] = useState(FileterForm);

  const { liveAuctionList, countrywiseLiveAuctionList } = useSelector(
    (state: RootState) => state.auction
  );

  const dispatch = useDispatch();
  const buyTickets = (id: number) => {
    dispatch(
      purchaseAuction({
        auctionId: id,
      })
    );
  };
  useEffect(() => {
    dispatch(getLiveAuctionList("auctionStatus=C"));
  }, []);

  const redirectToView = (path: string) => {
    navigate(path);
  };

  const handleCountryDropdownValueChange = (value: string, name: string) => {
    let updatedArray = updateFormSelectState(value, name, fileterState.form);
    setFilterState({
      ...fileterState,
      form: updatedArray,
    });
    console.log(value.length);
    if (value === "All" || value.length === 0) {
      setShow(false);
      setShowFilterData(false);
    } else {
      setShow(true);
    }
  };

  const filterView = (
    <FormBuilder
      formElements={fileterState.form}
      onChangeDate={() => {}}
      onInputChange={() => {}}
      onSelectValueChange={handleCountryDropdownValueChange}
    />
  );

  const handleSearchData = () => {
    dispatch(
      setCountrywiseLiveAuctionList({
        data: [],
      })
    );

    dispatch(
      getCountryWiseLiveAuctions({
        auctionStatus: "C",
        countries: [fileterState.form[0].value],
      })
    );

    setShowFilterData(true);
  };
  return (
    <Fragment>
      <BreadCrumb>
        <BreadCrumbItem
          onClick={() => {
            redirectToView(RouterPath.root);
          }}
        >
          Home
        </BreadCrumbItem>{" "}
        /
        <BreadCrumbItem
          onClick={() => {
            redirectToView(RouterPath.auctionList);
          }}
        >
          Auction
        </BreadCrumbItem>{" "}
        /
        <BreadCrumbItem
          onClick={() => {
            redirectToView(RouterPath.allAuction);
          }}
        >
          All Live Auction
        </BreadCrumbItem>
      </BreadCrumb>
      <Wrapper>
        {liveAuctionList.length > 0 ? (
          <>
            <SectionTitle>Live Auctions</SectionTitle>
          </>
        ) : (
          <SectionTitle>No Auctions</SectionTitle>
        )}
        <Section>
          <FilterViewSection>
            <FormView>{filterView}</FormView>
            {show && (
              <Button
                disabled={false}
                appBtnType={AppButtonType.primaryBordered}
                fullWidth={false}
                variant={ButtonVariant.contained}
                type={ButtonType.default}
                size={ButtonSizeVariant.small}
                clicked={handleSearchData}
              >
                Search
              </Button>
            )}
          </FilterViewSection>
          {(liveAuctionList.length !== 0 && !showFiterData) ||
          (showFiterData && countrywiseLiveAuctionList.length !== 0) ? (
            <TotalView>
              Showing{" "}
              {showFiterData
                ? countrywiseLiveAuctionList.length
                : liveAuctionList.length}{" "}
              Auctions.
            </TotalView>
          ) : (
            <></>
          )}
          <AuctionContainer>
            {showFiterData ? (
              <AllAuctions auctionList={countrywiseLiveAuctionList} />
            ) : (
              <AllAuctions auctionList={liveAuctionList} />
            )}
          </AuctionContainer>
        </Section>
      </Wrapper>
    </Fragment>
  );
};

export default SeeAllAuction;
