import {FC, useEffect} from 'react';
import {Wrapper, ViewHeader, Container, Title, Subtitle, ActionSection, PurchaseSuccessWrapper, SuccessImage} from './StyledCoinPaymentComplete';
import {ButtonSizeVariant, ButtonVariant, ButtonType, AppButtonType} from '../../../../Utility/InterFacesAndEnum';
import Button from '../../../../components/UI/Buttons/Button';

import successSrc from '../../../../assets/success.svg';
import {RouterPath} from '../../../../routes/routes'
import {useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetPurchaseState, storeWalletPaymentResponse } from '../../../../features/coinSlice';
// import { resetPurchaseState } from '../../../../features/loadMoneySlice';



const CoinPaymentComplete:FC = () => {
    const navigate = useNavigate();
    const dispatch=useDispatch();

    useEffect(()=>{
       dispatch( resetPurchaseState());
    },[])
 
    const resetRootState = () => {
        dispatch(storeWalletPaymentResponse({
            isWalletPaymentInitiated:false
        }))
        navigate(RouterPath.root)
    };
    return(
        <Wrapper>
            <ViewHeader />
            <PurchaseSuccessWrapper>
            <Container>
                <SuccessImage src={successSrc} />
                <Title>
                Payment Successful.
                </Title>
                {/* <Subtitle>
                </Subtitle> */}
                <ActionSection>
                <Button 
                appBtnType={AppButtonType.primaryBtn}
                    disabled={false} 
                    fullWidth={false} 
                    size={ButtonSizeVariant.large} 
                    variant={ButtonVariant.contained} 
                    type={ButtonType.submit} 
                    clicked={() => {
                        resetRootState();
                       
                    }} >
                        Go Back
                </Button>
                </ActionSection>
            </Container>
            </PurchaseSuccessWrapper>
        </Wrapper>
    )
};

export default CoinPaymentComplete