import {FC, useEffect, useState} from 'react';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

import * as UIConstants from '../../UIConstants';


const Navigation:FC = () => {
    
    const [ dimention , setDimention] =useState({
        width:window.innerWidth
    })
    
    useEffect(()=>{
        function handleResise(){
            setDimention({
                width:window.innerWidth
            })
        }
        window.addEventListener('resize',handleResise)
    },[])

    return dimention.width <= UIConstants.mobileWidth ? <MobileNavigation /> : <DesktopNavigation />;
}

export default Navigation