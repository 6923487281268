import styled from "styled-components";
import * as UIConstants from "../../UIConstants";

export const CardContainer = styled.div`
  border: 1px solid #adb5bd;
  overflow: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  flex-basis: 50%;
  border-radius: 8px;
  // display:flex;
  // align-items: center;
  // justify-content:center;

  ${UIConstants.mobileView} {
    flex-flow: column;
    gap: 15px;
    flex-basis: 100%;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // height:65%;
  color: black;

  img {
    background: #f0f0f0;
    margin-top: 5px;
    /* padding: 0 10px 0 10px; */
    border-radius: 5px;
    width: 98%;
    height: 450px;
    object-fit: fill;
    @media (min-width: 1000px) {
      height: 280px;
    }
    @media (min-width: 1150px) {
      height: 380px;
    }
    @media (min-width: 1300px) {
      height: 450px;
    }
    @media (min-width: 1400px) {
      height: 510px;
    }
    @media (min-width: 1600px) {
      height: 610px;
    }
    @media (min-width: 1800px) {
      height: 670px;
    }
    @media (min-width: 2000px) {
      height: 790px;
    }
    @media (min-width: 2200px) {
      height: 1150px;
    }
    // margin-bottom:40px;
  }
`;

export const EmptyImg = styled.div`
  background: #f0f0f0;
  margin-top: 5px;
  padding: 0 10px 0 10px;
  border-radius: 5px;
  width: 95%;
  height: 450px;
  object-fit: scale-down;

  @media (min-width: 1400px) {
    height: 510px;
  }
  @media (min-width: 1600px) {
    height: 610px;
  }
  @media (min-width: 1800px) {
    height: 670px;
  }
  @media (min-width: 2000px) {
    height: 770px;
  }
  @media (min-width: 2200px) {
    height: 1150px;
  }
  // margin-bottom:40px;
`;

export const CardFooter = styled.div`
  background-color: #ffffff;
  padding: 10px;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Description = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const CardDescription = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
`;

export const CardAction = styled.div`
  // width:100%;
  margin: 20px;
  display: flex;
  justify-content: space-evenly;
  // flex-basis: 30%;
  gap: 10px;
`;

export const CardTitle = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #200e32;
  text-align: left;
  margin-bottom: 10px;
`;

export const CardProduct = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #adb5bd;
  margin-bottom: 10px;
`;
export const ProgressBarContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  // margin-bottom: 16px;
`;

export const TicketDetails = styled.div`
  // padding:10px;
  display: flex;
  justify-content: center;
  align-item: center;
  width: 100%;
`;

export const TicketDetail = styled.div`
  padding-left: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  // flex-flow: row nowrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #000000;
  margin-right: 10px;
`;

export const Value = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #000000;
`;
