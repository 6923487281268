import styled from "styled-components";
import * as UIConstant from "../../UIConstants";

import * as UIConstants from "../../UIConstants";
export const BannerWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  img {
    width: 100%;
    object-fit: contain;
  }
  ${UIConstants.mobileView} {
    img {
      width: 100%;
      object-fit: contain;
    }
    padding: 0px;
  }
`;

export const AuctionContainer = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  background-color: white;
  border-radius: 10px;

  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  ${UIConstants.mobileView} {
    padding: 15px;
  }
`;

export const StaticWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  ${UIConstants.mobileView} {
    img {
      width: 100%;
      object-fit: contain;
    }
  }
`;

export const BannerSection = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  // align-items: center;
  justify-content: center;
  gap: 20px;
  ${UIConstants.mobileView} {
    flex-flow: column;
    gap: 15px;
  }
`;

export const BannerContainer = styled.div`
  max-height: 100%;
  flex-basis: 25%;
  img {
    // max-height:100%;
    object-fit: contain;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);

    overflow: hidden;
  }
  ${UIConstants.mobileView} {
  }
`;

export const BannerContainerSkeleton = styled.div`
  max-height: 750px;
  //background-color: rgba(58, 87, 232, 0.15);
  background: #f0f0f0;

  flex-basis: 25%;
  object-fit: contain;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);

  overflow: hidden;

  ${UIConstants.mobileView} {
    max-height: 350px;
  }
`;
export const TopAuctionSkeleton = styled.div`
  height: 750px;
  /* background-color: rgba(58, 87, 232, 0.15); */
  background: #f0f0f0;

  flex-basis: 50%;
  object-fit: contain;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);

  overflow: hidden;

  ${UIConstants.mobileView} {
  }
`;

export const DefaultBannerImg = styled.div`
  max-height: 750px;
  object-fit: contain;
  width: 350px;

  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);

  overflow: hidden;
`;

export const BannerCardContainer = styled.div`
  display: flex;
  flex-basis: 52%;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 8px;
  overflow: hidden;
  ${UIConstants.mobileView} {
    flex-flow: column;
    gap: 15px;
    flex-basis: 100%;
  }
`;

export const CardContainer = styled.div`
  border: 1px solid #adb5bd;
  overflow: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  flex-basis: 50%;
  border-radius: 8px;
  // display:flex;
  // align-items: center;
  // justify-content:center;

  ${UIConstants.mobileView} {
    flex-flow: column;
    gap: 15px;
    flex-basis: 100%;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // height:65%;
  color: black;

  img {
    // width:100%;
    height: 450px;
    // margin-bottom:40px;
  }
`;

export const CardFooter = styled.div`
  background-color: #ffffff;
  padding: 15px;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Description = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const CardDescription = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
`;

export const CardAction = styled.div`
  // width:100%;
  margin: 20px;
  display: flex;
  justify-content: space-evenly;
  // flex-basis: 30%;
  gap: 10px;
`;

export const CardTitle = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #200e32;
  text-align: left;
  margin-bottom: 10px;
`;

export const CardProduct = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #adb5bd;
  margin-bottom: 10px;
`;

export const AuctionList = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 33px;
  ${UIConstants.mobileView} {
    gap: 15px;
  }
`;

export const AuctionListItem = styled.div`
  display: flex;
  flex-basis: 23%;
  ${UIConstants.mobileView} {
    flex-basis: 100%;
  }
`;

export const ListTitle = styled.div`
  width: 100%;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
  margin: 50px 0 30px 0;
  text-align: left;
`;

export const ActionSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

export const FilterViewSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
`;

export const FormView = styled.div`
  width: 200px;
`;

export const DispayAuction = styled.div``;
export const AuctionAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  Button {
    width: 100px;
    max-height: 20px;
  }
`;
export const AuctionTitle = styled.div`
  width: 100%;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
  margin: 10px 0 20px 0;
  text-align: left;
`;
export const Category = styled.div`
  color: #000000;
  font-weight: 500;
  font-size: 17px;
  margin: 20px 0 10px 0;
`;
export const ErrorMsg = styled.div`
  text-align: center;
  color: #000000;
  opacity: 0.7;
  font-weight: 500;
  font-size: 17px;
  margin: 20px 0 10px 0;
`;
export const UpcommingAuction = styled.div`
  /* img{
width: 290px;
height: 290px;
object-fit:contain;
${UIConstant.mobileView}{
    width: 250px;
  height:250px;
}
${UIConstant.customMobileView}{
    width: 160px;
  height:160px;
}
} */
  margin-bottom: 40px;
`;
export const LiveAuction = styled.div`
  /* img{
width: 290px;
height: 290px;
object-fit: contain;
${UIConstant.mobileView}{
  height:250px;
  width: 250px;
}
${UIConstant.customMobileView}{
  height:160px;
  height: 160px;
}
} */
`;

export const AuctionCategory = styled.div`
  color: black;
  font-weight: bold;
  font-size: 20px;
`;
export const ProgressBarContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  // margin-bottom: 16px;
`;

export const TicketDetails = styled.div`
  // padding:10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const EntryTicket = styled.div`
  color: black;
`;
export const StartDate = styled.div`
  color: black;
`;

export const TicketDetail = styled.div`
  padding-left: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  // flex-flow: row nowrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #000000;
  margin-right: 10px;
`;

export const Value = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #000000;
`;
