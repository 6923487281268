import {FC, useEffect} from 'react';
import {Wrapper, ViewHeader, Container, Title, Subtitle, ActionSection, PurchaseSuccessWrapper, SuccessImage} from './StyledFail';
import {ButtonSizeVariant, ButtonVariant, ButtonType, AppButtonType} from '../../../Utility/InterFacesAndEnum';
import Button from '../../../components/UI/Buttons/Button';

import successSrc from '../../../assets/failure.svg';
import {RouterPath} from '../../../routes/routes'
import {useNavigate} from 'react-router-dom';

import { setLocalStorage } from '../../../localStorage/SetLocalStorage';
import * as localStorageActionType from '../../../localStorage/ActionTypes';
import { useDispatch } from 'react-redux';
// import { loadMoneyComplete } from '../../../features/loadMoneySlice';

const MembershipFail:FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const resetRootState = () => {
        
    //     navigate(RouterPath.root, { replace: true });
    //     window.location.replace(RouterPath.loadMoney);
    // };
    useEffect(()=>{
        const currentUrl = window.location.href;
        // console.log("current url :",currentUrl)
        const url = new URL(currentUrl);
        const token = url.searchParams.get('token');
        setLocalStorage(localStorageActionType.SET_PAYMENT_TOKEN,token);
        const PayerID = url.searchParams.get('PayerID');
        
        console.log("token :",token,PayerID)
        // dispatch(loadMoneyComplete({
        //     PayerID : PayerID
        // }))
    },[])

    return(
        <Wrapper>
            <ViewHeader />
            <PurchaseSuccessWrapper>
            <Container>
                <SuccessImage src={successSrc} />
                <Title>
                Oh no, Payment Failed.
                </Title>
                {/* <Subtitle>
                </Subtitle> */}
                <ActionSection>
                <Button 
                appBtnType={AppButtonType.primaryBtn}
                    disabled={false} 
                    fullWidth={false} 
                    size={ButtonSizeVariant.large} 
                    variant={ButtonVariant.contained} 
                    type={ButtonType.submit} 
                    clicked={() => {
                        // resetRootState();
                        navigate(RouterPath.membership)

                    }} >
                        Go Back
                </Button>
                </ActionSection>
            </Container>
            </PurchaseSuccessWrapper>
        </Wrapper>
    )
};

export default MembershipFail