import { FC, useEffect, useMemo, useState } from "react";

import Collapse from "../../../components/LotteryCollapse/LotteryCollapse";

import { downloadPurchaseList, getLotteryOrders } from "../../../features/ordersSlice";
import { useSelector, useDispatch } from "react-redux";
import { setOrders } from "../../../features/ordersSlice";
import { RootState } from "../../../app/store";

import * as Style from "./StyledLotteries";
import Button from "../../../components/UI/Buttons/Button";
import {
  AppButtonType,
  ButtonSizeVariant,
  ButtonType,
  ButtonVariant,
} from "../../../Utility/InterFacesAndEnum";
import { getLocalStorage } from "../../../localStorage/GetLocalStorage";
import * as localStorageActionType from "../../../localStorage/ActionTypes";

const lotteryTabMenu = [
  {
    label: "Live lottery",
    isActive: true,
    id: 1,
    filterParam: "",
  },
  {
    label: "Executed lottery",
    isActive: false,
    id: 2,
    filterParam: "",
  },
  {
    label: "Cancelled lottery",
    isActive: false,
    id: 3,
    filterParam: "",
  },
];

interface PaymentIdProp {
  paymentIds: [];
}

const LotteryPurchaseList: FC = () => {
  const dispatch = useDispatch();

  const [tabMenu, setTabMenu] = useState(lotteryTabMenu);

  const [liveLottery, setLiveLottery] = useState([]);
  const [executedLottery, setExecutedLottery] = useState([]);
  const [cancelLottery, setCancelLottery] = useState([]);
  const [activeTabMenuId, setMenuId] = useState(1);
  const [paymentId, setPaymentId] = useState<PaymentIdProp>();
  const publicUserId = getLocalStorage(
    localStorageActionType.GET_PUBLIC_USER_ID
  );

  const lotteryList = useSelector(
    (state: RootState) => state.orders.lotteryOrders
  );
  useEffect(() => {
    dispatch(getLotteryOrders());
  }, []);

  useEffect(() => {
    if (lotteryList.length > 0) {
      let executedLottery = [];
      let currentLottery = [];
      let cancelledLottery = [];

      executedLottery = lotteryList.filter((obj: any) => {
        return obj.lotteryGameStatus === "E";
      });

      currentLottery = lotteryList.filter((obj: any) => {
        return obj.lotteryGameStatus === "C";
      });
      cancelledLottery = lotteryList.filter((obj: any) => {
        return obj.lotteryGameStatus === "D";
      });
      setLiveLottery(currentLottery);
      setExecutedLottery(executedLottery);
      setCancelLottery(cancelLottery);
      
      // console.log(currentLottery);
    }
  }, [lotteryList]);

  const toggleActiveState = (id: number) => {
    setMenuId(id);
    setTabMenu((tabMenu) => {
      return tabMenu.map((menuObj) => {
        return {
          ...menuObj,
          isActive: menuObj.id === id,
        };
      });
    });
  };

  let tabMenuView = useMemo(() => {
    return tabMenu.map((menuObj, index) => {
      return (
        <Style.TabMenuItem
          onClick={() => {
            toggleActiveState(menuObj.id);
          }}
          key={`lotteryMenuItem_${menuObj.id}_${index}`}
          isActive={menuObj.isActive}
        >
          {menuObj.label}
        </Style.TabMenuItem>
      );
    });
  }, [tabMenu]);

  const toggleLotteryCollapse = (ticketNumber: any) => {
    let updateLotteryList = lotteryList.map((lotteryObj: any) => {
      // console.log(lotteryObj)
      return {
        ...lotteryObj,
        isExpanded:
          lotteryObj.ticketNo === ticketNumber
            ? !lotteryObj.isExpanded
            : lotteryObj.isExpanded,
      };
    });
    dispatch(
      setOrders({
        lotteryOrders: updateLotteryList,
      })
    );
  };

  useEffect(() => {
    // console.log(1)
    let arr: any = [];
    if (activeTabMenuId === 1) {
      liveLottery.map((lottery: any) => {
        console.log(lottery);
        arr.push(lottery.paymentId);
      });
    } else if(activeTabMenuId === 2){
      executedLottery.map((lottery: any) => {
        console.log(lottery);
        arr.push(lottery.paymentId);
      });
    }else{
      cancelLottery.map((lottery: any) => {
        console.log(lottery);
        arr.push(lottery.paymentId);
      });
    }
    setPaymentId({
      paymentIds: arr,
    });
  }, [liveLottery,executedLottery,cancelLottery, activeTabMenuId]);
  // console.log(lotteryList)
  const downloadListHandler = () => {
    // console.log({
    //   publicUserId: publicUserId,
    //   paymentIds: paymentId?.paymentIds,
    // });
    dispatch(downloadPurchaseList({
      publicUserId: publicUserId,
      paymentIds: paymentId?.paymentIds,
    }))
  };

  return (
    <Style.Wrapper>
      <Style.TabMenuContainer>
        <Style.TabMenuWrapper>{tabMenuView}</Style.TabMenuWrapper>
        <Style.DownloadListBtn>
          <Button
            disabled={false}
            appBtnType={AppButtonType.primaryBtn}
            fullWidth={false}
            variant={ButtonVariant.text}
            type={ButtonType.default}
            size={ButtonSizeVariant.small}
            clicked={downloadListHandler}
          >
            Export
          </Button>
        </Style.DownloadListBtn>
      </Style.TabMenuContainer>

      <Style.TableOverflowX>
        <Style.Table>
          <Style.CollapseHeader>
            <Style.Label width={"30%"} textAlign={"center"}>
              Game Number
            </Style.Label>
            <Style.Label width={"20%"} textAlign={"center"}>
              Ticket number
            </Style.Label>
            <Style.Label width={"15%"} textAlign={"center"}>
              Reward
            </Style.Label>
            <Style.Label width={"25%"} textAlign={"center"}>
              Status
            </Style.Label>
            <Style.Label width={"30%"} textAlign={"right"}>
              Purchased Date
            </Style.Label>
          </Style.CollapseHeader>

          <Collapse
            onToggle={toggleLotteryCollapse}
            data={activeTabMenuId === 1 ? liveLottery :activeTabMenuId === 2? executedLottery:cancelLottery}
          />
        </Style.Table>
      </Style.TableOverflowX>
    </Style.Wrapper>
  );
};

export default LotteryPurchaseList;
