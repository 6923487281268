import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtilities/endpoints";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { setLocalStorage } from "../localStorage/SetLocalStorage";
import { toggleNotificationVisibility } from "./notificationSlice";
import { NotificationType } from "../Utility/InterFacesAndEnum";
import { toggleLoader } from "./loaderSlice";

interface UserDetaileItem {
  address: string | null;
  amount: number;
  city: string | null;
  country: string | null;
  dateOfBirth: any;
  emailId: string;
  firstName: string;
  firstTimeLogin: any;
  gender: any;
  imageUrl: any;
  isActive: boolean;
  isGoldMember: boolean;
  lastLogin: any;
  lastName: string;
  mobileNo: any;
  pinCode: any;
  preferedLang: any;
  publicUserId: string;
  roleName: string;
  state: string;
  userId: number | any;
  using2FA: boolean;
}

interface userProfile {
  userDetail: any;
  isTwoCodeVerified: boolean;
  isAuthorized: boolean;
}

interface SetUserProfileProp {
  userDetails: any;
}

const profileState: userProfile = {
  userDetail: [],
  isTwoCodeVerified: false,
  isAuthorized: true,
};

interface UpdateAddressForm {
  address: string;
  country: string;
  state: string;
  pinCode: string;
}

export const uploadProfileImage = createAsyncThunk(
  "update profile image",
  async (payload: any, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));

    await fetch(endpoints.updateProfileImgae, {
      method: "POST",
      body: payload,
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
      },
    })
      .then((response) => {
        // console.log(response.ok);
        dispatch(toggleLoader({ isLoading: false }));
        if (response?.ok) {
          dispatch(getUserProfileDetail());
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        return err;
      });
  }
);

export const getUserProfileDetail = createAsyncThunk(
  "get user detail",
  async (payload, { dispatch }) => {
    // console.log("I am here")
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(
      `${endpoints.viewProfile}${getLocalStorage(
        localStorageActionType.GET_PUBLIC_USER_ID
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        if (data.status === 401) {
          setLocalStorage(localStorageActionType.CLEAR_LOGIN_USER_DETAIL, "");
          setLocalStorage(localStorageActionType.SET_ACCESS_INVALID, true);
          dispatch(
            setIsAuthorized({
              isAuthorized: false,
            })
          );
          // window.location.reload();
        }

        dispatch(toggleLoader({ isLoading: false }));
        let userObj = data?.result;
        // console.log(userObj,"user detail 1111");
        let localStorageObj = {
          firstName: userObj.firstName,
          lastName: userObj.lastName,
          amount: userObj.amount,
          emailId: userObj.emailId,
          mobileNo: userObj.mobileNo,
          address: userObj.address,
          city: userObj.city,
          state: userObj.state,
          country: userObj.country,
          pincCde: userObj.pinCode,
          using2FA: userObj.using2FA,
          userId: userObj.userId,
          imageUrl: userObj.imageUrl,
          isGoldMember: userObj.isGoldMember,
        };
        // console.log("userData",data)
        setLocalStorage(
          localStorageActionType.SET_TWO_FA_STATUS,
          userObj.using2FA.toString()
        );
        if (data.statusCode === 200) {
          dispatch(
            setIsAuthorized({
              isAuthorized: true,
            })
          );
          dispatch(
            setUserDetail({
              userDetails: data?.result,
            })
          );
          // dispatch(toggleNotificationVisibility({
          //     isVisible: false,
          //     status: NotificationType.success,
          //     message: "User data fetch successfully."
          // }));
        }

        setLocalStorage(
          localStorageActionType.SET_USER_DETAILS,
          JSON.stringify(localStorageObj)
        );
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        return err;
      });
  }
);

interface updateProfile {
  firstName: string;
  lastName: string;
  mobileNo: string;
}

export const updatePersonalDetails = createAsyncThunk(
  "update personal info",
  async (payloadObj: updateProfile, { dispatch }) => {
    let publicUrserId = getLocalStorage(
      localStorageActionType.GET_PUBLIC_USER_ID
    );
    dispatch(toggleLoader({ isLoading: true }));

    await fetch(`${endpoints.updateProfile}${publicUrserId}`, {
      method: "PUT",
      body: JSON.stringify(payloadObj),
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));
        let userObj = data?.result;
        let localStorageObj = {
          firstName: userObj.firstName,
          lastName: userObj.lastName,
          emailId: userObj.emailId,
          mobileNo: userObj.mobileNo,
          address: userObj.address,
          city: userObj.city,
          state: userObj.state,
          country: userObj.country,
          pincCde: userObj.pinCode,
          using2FA: userObj.using2FA,
          userId: userObj.userId,
          imageUrl: userObj.imageUrl,
        };
        setLocalStorage(
          localStorageActionType.SET_USER_DETAILS,
          JSON.stringify(localStorageObj)
        );
        setLocalStorage(
          localStorageActionType.SET_TWO_FA_STATUS,
          userObj.using2FA
        );
        dispatch(
          toggleNotificationVisibility({
            isVisible: true,
            status: NotificationType.success,
            message: data.errorMsg,
          })
        );
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        return err;
      });
  }
);

export const updateAddress = createAsyncThunk(
  "update user address/add new address",
  async (payload: UpdateAddressForm, { dispatch }) => {
    let publicUrserId = getLocalStorage(
      localStorageActionType.GET_PUBLIC_USER_ID
    );
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(`${endpoints.updateAddress}${publicUrserId}`, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));
        let userObj = data?.result;
        let localStorageObj = {
          firstName: userObj.firstName,
          lastName: userObj.lastName,
          emailId: userObj.emailId,
          mobileNo: userObj.mobileNo,
          address: userObj.address,
          city: userObj.city,
          state: userObj.state,
          country: userObj.country,
          pincCde: userObj.pinCode,
          using2FA: userObj.using2FA,
          userId: userObj.userId,
          imageUrl: userObj.imageUrl,
        };
        setLocalStorage(
          localStorageActionType.SET_USER_DETAILS,
          JSON.stringify(localStorageObj)
        );
        setLocalStorage(
          localStorageActionType.SET_TWO_FA_STATUS,
          userObj.using2FA
        );
        dispatch(
          toggleNotificationVisibility({
            isVisible: true,
            status: NotificationType.success,
            message: data.errorMsg,
          })
        );
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        return err;
      });
  }
);

export const toggleTwoFA = createAsyncThunk(
  "enable/disable 2FA",
  async (payload, { dispatch }) => {
    let publicUrserId = getLocalStorage(
      localStorageActionType.GET_PUBLIC_USER_ID
    );
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(`${endpoints.toggleTwoFA}${publicUrserId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));
        dispatch(
          toggleNotificationVisibility({
            isVisible: true,
            status: NotificationType.success,
            message: data.errorMsg,
          })
        );

        let isEnabled = getLocalStorage(
          localStorageActionType.GET_TWO_FA_STATUS
        );

        let updatedStatus = false;

        if (isEnabled === "true") {
          updatedStatus = false;
        } else if (isEnabled === "false") {
          updatedStatus = true;
        }

        setLocalStorage(
          localStorageActionType.SET_TWO_FA_STATUS,
          updatedStatus
        );
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        return err;
      });
  }
);

export const verify2FAVerificationCode = createAsyncThunk(
  "resend 2FA verification code",
  async (payload: string, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(endpoints.verifyTwoFACode, {
      method: "POST",
      body: payload,
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));
        if (data.statusCode === 200) {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.success,
              message: "Code verified successfully",
            })
          );
          dispatch(
            toggleTwoFAVerified({
              isVerified: true,
            })
          );
          let userObj = JSON.parse(
            getLocalStorage(localStorageActionType.GET_USER_DETAILS)
          );
          setLocalStorage(localStorageActionType.GET_TWO_FA_STATUS, true);
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        return err;
      });
  }
);

interface TwoFAVerified {
  isVerified: boolean;
}
interface SetIsAuthorized {
  isAuthorized: boolean;
}

const profileSlice = createSlice({
  name: "userProfile",
  initialState: profileState,
  reducers: {
    setUserDetail: (state, action: PayloadAction<SetUserProfileProp>) => {
      return {
        ...state,
        userDetail: action.payload.userDetails,
      };
    },
    toggleTwoFAVerified: (state, action: PayloadAction<TwoFAVerified>) => {
      return {
        ...state,
        isTwoCodeVerified: action.payload.isVerified,
      };
    },
    setIsAuthorized: (state, action: PayloadAction<SetIsAuthorized>) => {
      return {
        ...state,
        isAuthorized: action.payload.isAuthorized,
      };
    },
  },
});

export const { toggleTwoFAVerified, setUserDetail, setIsAuthorized } =
  profileSlice.actions;
export default profileSlice.reducer;
