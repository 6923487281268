import { FC, useEffect } from "react";
import Navigation from "../../components/Navigation/DesktopNavigation";
import { transformDate } from "../../Utility/Utility";
import ImageComponent from "../../components/AuctionImage/AuctionImage";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import {
  ButtonSizeVariant,
  ButtonType,
  ButtonVariant,
  AppButtonType,
} from "../../Utility/InterFacesAndEnum";
import Button from "../../components/UI/Buttons/Button";
import AuctionBuyBtn from "../../components/AuctionBuyButton/AuctionBuyButton";
import SimilarProducts from "../AuctionSimilarProducts/SimilarProducts";

import { useSelector, useDispatch } from "react-redux";
import {
  getAuctionDetail,
  purchaseAuction,
  setSearchAuction,
  toggleAuctionPurchase,
} from "../../features/auctionList";
import { RootState } from "../../app/store";
import { RouterPath } from "../../routes/routes";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import {
  Wrapper,
  Container,
  ImageSection,
  DetailSection,
  Title,
  ProductType,
  Description,
  ProgressBarContainer,
  ProgressBarDetail,
  EngagedUrsers,
  TotalUsers,
  ActionDetail,
  TicketDetail,
  Label,
  Value,
  Line,
  SellerSectionTitle,
  SellerDetailWrapper,
  ProfileImg,
  SellerDetail,
  SellerName,
  ItemDetails,
  ItemDetailLabel,
  SimilarProductContainer,
  BreadCrumb,
  BreadCrumbItem,
  Section,
  Text,
  DetailSection2,
  DetailContainer,
  ContainerWrapper,
  TitleWrapper,
} from "./StyledAuctionDetail";
import ResponsiveCarousel from "../../components/Carousel/ResponsiveCarousel";
import AuctionResponsiveCarousel from "../../components/Carousel/AuctionResponsiveCarousel";
import AuctionImagesCarousel from "../../components/AuctionCarousel/Responsive/AuctionResponsiveCarousel";
import { notifyMe, notifyMeAuction } from "../../features/notificationSlice";
import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import * as localStorageActionType from "../../localStorage/ActionTypes";
import AuctionDetailSkeleton from "./AuctionDetailSkeleton";

const AuctionDetail: FC = () => {
  const { auctionId }: any = useParams();
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let auctionDetail = useSelector(
    (state: RootState) => state.auction.auctionDetail
  );
  // console.log(auctionDetail);

  let isAuctionPurchased = useSelector(
    (state: RootState) => state.auction.isAuctionPurchased
  );
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  let detailState = useSelector(
    (state: RootState) => state.auction.detailState
  );
  const publicUserId = getLocalStorage(
    localStorageActionType.GET_PUBLIC_USER_ID
  );
  const IsLoggedIn = getLocalStorage(localStorageActionType.GET_IS_LOGGED_IN);
  const totalUserCount =
    auctionDetail?.totalUsers !== null ? auctionDetail?.totalUsers : 100;
  // const noOfUserToJoin=0;
  const engagedUsersCount =
    auctionDetail?.noOfUsersJoined !== null
      ? auctionDetail?.noOfUsersJoined
      : 0;

  let usersParticipatedPercentage =
    auctionDetail?.usersParticipatedPercentage !== null
      ? auctionDetail?.usersParticipatedPercentage
      : 0;

  const paragraphs = auctionDetail?.auctionDesc
    ?.split("\n")
    .map((paragraph: any, index: any) => <p key={index}>{paragraph}</p>);

  useEffect(() => {
    dispatch(getAuctionDetail(state.id.toString()));
  }, [state.id]);

  useEffect(() => {
    if (auctionId !== null && auctionId !== undefined) {
      dispatch(getAuctionDetail(auctionId.toString()));
      dispatch(setSearchAuction({
        value:auctionDetail.country
      }))
    }
    return () => {
      dispatch(
        toggleAuctionPurchase({
          isPurchased: false,
        })
      );
    };
  }, []);

  useEffect(() => {
    if (isAuctionPurchased === true) {
      redirectToView(RouterPath.lotteryPaymentSuccess);
    }
    return () => {
      dispatch(
        toggleAuctionPurchase({
          isPurchased: false,
        })
      );
    };
  }, [isAuctionPurchased]);

  const redirectToView = (path: string) => {
    navigate(path);
  };

  const purchaseTicket = (id: number) => {
    dispatch(
      purchaseAuction({
        auctionId: auctionId,
      })
    );
  };

  const onClickNotifyMe = (id: any) => {
    if (IsLoggedIn) {
      dispatch(
        notifyMeAuction({
          publicUserId: publicUserId,
          notifyType: "L",
          lotteryGameId: id,
        })
      );
    } else {
      navigate(RouterPath.signIn);
    }
  };

  const notifyMeButton = (
    <>
      <Button
        disabled={false}
        appBtnType={AppButtonType.secondary}
        fullWidth={false}
        variant={ButtonVariant.contained}
        type={ButtonType.default}
        size={ButtonSizeVariant.small}
        clicked={() => {
          onClickNotifyMe(auctionId);
        }}
      >
        Notify Me
      </Button>
    </>
  );

  return (
    <Wrapper>
      {/* <Navigation /> */}
      <BreadCrumb>
        <BreadCrumbItem
          onClick={() => {
            redirectToView(RouterPath.root);
          }}
        >
          Home
        </BreadCrumbItem>{" "}
        /
        <BreadCrumbItem
          onClick={() => {
            redirectToView(RouterPath.auctionList);
          }}
        >
          Auction
        </BreadCrumbItem>{" "}
        /
        <BreadCrumbItem
          onClick={() => {
            redirectToView(RouterPath.createAuction);
          }}
        >
          {isLoading ? "Loading..." : auctionDetail.auctionTitle}
        </BreadCrumbItem>
      </BreadCrumb>
      <Section>
        {!isLoading ? (
          <>
            <ContainerWrapper>
            <TitleWrapper>
            <Title key={auctionDetail.auctionTitle}>
                  {!isLoading ? auctionDetail.auctionTitle : "loading..."}
                </Title>
              </TitleWrapper>
            <Container>
              <ImageSection>
                {detailState && (
                  <div style={{ marginTop: "20px" }}>
                    <AuctionResponsiveCarousel
                      items={auctionDetail?.auctionImageUrls}
                    />
                  </div>
                )}
              </ImageSection>
              <DetailSection>
                
                {/* <ProductType>{auctionDetail.auctionType!==undefined?auctionDetail.auctionType==="A"?"Admin Auction":"User Auction":"loading..."}</ProductType> */}
                <Description key={auctionDetail?.auctionDesc}>
                  {!isLoading ? (
                    <Text>{auctionDetail?.auctionDesc}</Text>
                  ) : (
                    "loading..."
                  )}
                </Description>
              </DetailSection>
            </Container>
            </ContainerWrapper>
            <Container>
              <DetailSection2>
                <ProgressBarContainer>
                  {/* <ProgressBar completed={auctionDetail.engagedUsers} total={auctionDetail.totalUsers} /> */}
                  <ProgressBar
                    completed={usersParticipatedPercentage}
                    total={100}
                    key={usersParticipatedPercentage}
                  />
                  <ProgressBarDetail>
                    <EngagedUrsers key={usersParticipatedPercentage}>
                      {/* {10 - auctionDetail.engagedUsers} users to join */}
                      {Number.isNaN(
                        Math.floor((usersParticipatedPercentage / 100) * 100)
                      ) || isLoading
                        ? 0
                        : Math.floor((usersParticipatedPercentage / 100) * 100)}
                      % users joined.
                    </EngagedUrsers>
                    <TotalUsers>{/* {100} users */}</TotalUsers>
                  </ProgressBarDetail>
                </ProgressBarContainer>
                <ActionDetail key={auctionDetail?.auctionStatus}>
                  {auctionDetail?.auctionStatus === "C" ? (
                    <AuctionBuyBtn auctionObj={auctionDetail} />
                  ) : (
                    notifyMeButton
                  )}
                </ActionDetail>
                <TicketDetail>
                  <Label>Entry Ticket:</Label>
                  <Value key={auctionDetail?.bronzeTicketPrice}>
                    {!isLoading
                      ? `$${auctionDetail?.bronzeTicketPrice}`
                      : "loading..."}
                  </Value>
                </TicketDetail>
                <TicketDetail>
                  {auctionDetail?.auctionStatus === "C" ? (
                    <>
                      <Label>Draw Date:</Label>
                      <Value key={auctionDetail.auctionEndDate}>
                        {!isLoading
                          ? transformDate(auctionDetail.auctionEndDate)
                          : "loading..."}
                      </Value>
                    </>
                  ) : (
                    <>
                      <Label>Start Date:</Label>
                      <Value key={auctionDetail?.auctionStartDate}>
                        {!isLoading
                          ? transformDate(auctionDetail?.auctionStartDate)
                          : "loading..."}
                      </Value>
                    </>
                  )}
                </TicketDetail>
              </DetailSection2>
              {/* <Line /> */}

              <DetailContainer>
                <SellerSectionTitle>Seller Details:</SellerSectionTitle>
                <SellerDetailWrapper>
                  {/* <ProfileImg src={"https://picsum.photos/450/520"} /> */}
                  <SellerDetail>
                    <SellerName>Name </SellerName>
                    <Value key={auctionDetail.userName}>
                      {auctionDetail.userName}
                    </Value>
                  </SellerDetail>
                </SellerDetailWrapper>
                <SellerSectionTitle>Item location:</SellerSectionTitle>
                <ItemDetails>
                  <ItemDetailLabel>State</ItemDetailLabel>
                  <Value>{auctionDetail.state}</Value>
                </ItemDetails>
                <ItemDetails>
                  <ItemDetailLabel>City</ItemDetailLabel>
                  <Value>{auctionDetail.city}</Value>
                </ItemDetails>
                <ItemDetails>
                  <ItemDetailLabel>Country</ItemDetailLabel>
                  <Value>{auctionDetail.country}</Value>
                </ItemDetails>
                <ItemDetails>
                  <ItemDetailLabel>Zip Code</ItemDetailLabel>
                  <Value>{auctionDetail.pincode}</Value>
                </ItemDetails>
              </DetailContainer>
            </Container>
          </>
        ) : (
          <AuctionDetailSkeleton />
        )}
      </Section>
      <Section>
        <SimilarProductContainer>
          <SimilarProducts isTitleRequired={true} />
        </SimilarProductContainer>
      </Section>
    </Wrapper>
  );
};

export default AuctionDetail;
