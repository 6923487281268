import {FC, useEffect} from 'react';
import {Wrapper, ViewHeader, Container, Title, Subtitle, ActionSection, PurchaseSuccessWrapper, SuccessImage} from './StyledSuccess';
import {ButtonSizeVariant, ButtonVariant, ButtonType, AppButtonType} from '../../../Utility/InterFacesAndEnum';
import Button from '../../../components/UI/Buttons/Button';

import successSrc from '../../../assets/success.svg';
import {RouterPath} from '../../../routes/routes'
import {useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLocalStorage } from '../../../localStorage/SetLocalStorage';
import * as localStorageActionType from '../../../localStorage/ActionTypes';
import { subscriptionComplete } from '../../../features/subScription';

const PaymentSuccess:FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const resetRootState = () => {
        
    //     navigate(RouterPath.root, { replace: true });
    //     window.location.replace(RouterPath.loadMoney);
    // };

    useEffect(()=>{
        const currentUrl = window.location.href;
        console.log("current url :",currentUrl)
        const url = new URL(currentUrl);
        const token = url.searchParams.get('token');
        setLocalStorage(localStorageActionType.SET_PAYMENT_TOKEN,token);
        const subscription_id = url.searchParams.get('subscription_id');
        
        console.log("token :",token,subscription_id)
        dispatch(subscriptionComplete({
            subscription_id:subscription_id
        }))
    },[])

    return(
        <Wrapper>
            <ViewHeader />
            <PurchaseSuccessWrapper>
            <Container>
                <SuccessImage src={successSrc} />
                <Title>
                Gold membership Purchased successfully
                </Title>
                <Subtitle>
                Now your account is activated to Goldmembership profile
                </Subtitle>
                <ActionSection>
                <Button 
                appBtnType={AppButtonType.primaryBtn}
                    disabled={false} 
                    fullWidth={false} 
                    size={ButtonSizeVariant.large} 
                    variant={ButtonVariant.contained} 
                    type={ButtonType.submit} 
                    clicked={() => {
                        // resetRootState();
                        navigate(RouterPath.membership)
                    }} >
                        Go Back
                </Button>
                </ActionSection>
            </Container>
            </PurchaseSuccessWrapper>
        </Wrapper>
    )
};

export default PaymentSuccess