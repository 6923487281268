import * as localStorageActionTypes from "./ActionTypes";

const getAccessToken = () => {
    if (typeof Storage !== "undefined") {
        return localStorage.getItem("ACCESS_TOKEN");
    }
}

const getRefreshToken = () => {
    if (typeof Storage !== "undefined") {
        return localStorage.getItem("REFRESH_TOKEN");
    }
};
const getAccessInvalid = () => {
    if (typeof Storage !== "undefined") {
        return localStorage.getItem("GET_ACCESS_INVALID");
    }
}
const getPublicUserId = () => {
    if (typeof Storage !== "undefined") {
        return localStorage.getItem("PUBLIC_USER_ID");
    }
}

const getIsLoggedIn = () => {
    if (typeof Storage !== "undefined") {
        return localStorage.getItem("IS_LOGGED_IN");
    }
}

const getUserDetail = () => {
    if (typeof Storage !== "undefined") {
        let userDetailObj = localStorage.getItem("USER_DETAIL");
        return JSON.parse(userDetailObj ? userDetailObj : "{}");
    }
};

const getTwoFaStatus = () => {
    if (typeof Storage !== "undefined") {
        return localStorage.getItem("IS_TWO_FA_ENABLED");
    }
};

const getSelectedLotteryObj = () => {
    if (typeof Storage !== "undefined") {
        return localStorage.getItem("SELECTED_LOTTERY_OBJ");
    }
};

const getSelectedAuctionObj = () => {
    if (typeof Storage !== "undefined") {
        return localStorage.getItem("SELECTED_AUCTION_OBJ");
    }
};

const getTicketDtail = () => {
    if (typeof Storage !== "undefined") {
        return localStorage.getItem("TICKET_DETAIL_OBJ");
    }
};

const getPaymentToken = () => {
    if (typeof Storage !== "undefined") {
        return localStorage.getItem("PAYMENT_TOKEN");
    }
}


export const getLocalStorage = (type: string) => {
    switch (type) {
        case localStorageActionTypes.GET_ACCESS_TOKEN:
            return getAccessToken();
        case localStorageActionTypes.GET_REFRESH_TOKEN:
            return getRefreshToken();
        case localStorageActionTypes.GET_PUBLIC_USER_ID:
            return getPublicUserId();
        case localStorageActionTypes.GET_USER_DETAILS:
            return getUserDetail();
        case localStorageActionTypes.GET_TWO_FA_STATUS:
            return getTwoFaStatus();
        case localStorageActionTypes.GET_SELECTED_LOTTERY_OBJ:
            return getSelectedLotteryObj();
        case localStorageActionTypes.GET_SELECTED_AUCTION_OBJ:
            return getSelectedAuctionObj();
        case localStorageActionTypes.GET_TICKET_DETAIL_OBJ:
            return getTicketDtail();
        case localStorageActionTypes.GET_PAYMENT_TOKEN:
            return getPaymentToken();
        case localStorageActionTypes.GET_IS_LOGGED_IN:
            return getIsLoggedIn();
        case localStorageActionTypes.GET_ACCESS_INVALID:
            return getAccessInvalid();
        default:
            return null;
    }
}