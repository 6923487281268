import * as endPoint from "../networkUtilities/endpoints";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { NotificationType } from "../Utility/InterFacesAndEnum";
import { toggleNotificationVisibility } from "./notificationSlice";
import { getLocalStorage } from "../localStorage/GetLocalStorage";

import * as localStorageActionType from "../localStorage/ActionTypes";
import { setLocalStorage } from "../localStorage/SetLocalStorage";
import { toggleLoader } from "./loaderSlice";
import { getUserProfileDetail } from "./userProfileSlice";

interface LoginState {
  isLoading: boolean;
  isLoggedin: boolean;
  isAuthenticated: boolean;
}

interface Loading {
  isLoading: boolean;
}

interface Login {
  isLoggedin: boolean;
  isAuthenticated: boolean;
}

const loginInitialState: LoginState = {
  isLoading: false,
  isLoggedin: false,
  isAuthenticated: false,
};

interface SigninAccount {
  emailId: string;
  password: string;
}

export const createLogin = createAsyncThunk(
  "auth/login",
  async (loginObj: SigninAccount, { dispatch }) => {
    dispatch(
      toggleLoading({
        isLoading: true,
      })
    );
    dispatch(toggleLoader({ isLoading: true }));
    // dispatch(toggleLogin({
    //     isLoggedin:true,
    //     isAuthenticated:true
    // }))
    const response = await fetch(endPoint.login, {
      method: "post",
      body: JSON.stringify(loginObj),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));
        if (data.statusCode === 200) {
          console.log(data);
          let responseObj = data.result;
          let publicUserId = responseObj.publicUserId;
          let tokenObj = {
            accessToken: responseObj.accessToken,
            refreshToken: responseObj.refreshToken,
          };

          setLocalStorage(
            localStorageActionType.SET_ACCESS_REFRESH_TOKEN,
            tokenObj
          );
          localStorage.removeItem("IS_ACCESS_INVALID");

          if (data.result.authenticated) {
            setLocalStorage(localStorageActionType.SET_IS_LOGGED_IN, true);
            setLocalStorage(
              localStorageActionType.SET_PUBLIC_USER_ID,
              publicUserId
            );
          }

          dispatch(
            toggleLogin({
              isLoggedin: true,
              isAuthenticated: responseObj.authenticated,
            })
          );

          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.success,
              message: data.errorMsg,
            })
          );
          // dispatch(getUserProfileDetail());
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(toggleLoader({ isLoading: false }));
        console.log(error, "error");
      })
      .finally(() => {
        dispatch(toggleLoader({ isLoading: false }));
        dispatch(
          toggleLoading({
            isLoading: false,
          })
        );
      });
  }
);

export const verify2FACode = createAsyncThunk(
  "verify 2FA code",
  async (payload: string, { dispatch }) => {
    console.log(payload, "payload");
    dispatch(toggleLoader({ isLoading: true }));

    await fetch(endPoint.verifySignin2FA, {
      method: "POST",
      body: payload,
      headers: {
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));
        if (data.statusCode === 200) {
          let responseObj = data.result;
          let publicUserId = responseObj.publicUserId;

          let refreshToken = getLocalStorage(
            localStorageActionType.GET_REFRESH_TOKEN
          );

          let tokenObj = {
            accessToken: responseObj.accessToken,
            refreshToken: refreshToken,
          };

          setLocalStorage(
            localStorageActionType.SET_ACCESS_REFRESH_TOKEN,
            tokenObj
          );
          localStorage.removeItem("IS_ACCESS_INVALID");

          setLocalStorage(localStorageActionType.SET_IS_LOGGED_IN, true);

          setLocalStorage(
            localStorageActionType.SET_PUBLIC_USER_ID,
            publicUserId
          );

          dispatch(
            toggleLogin({
              isAuthenticated: true,
              isLoggedin: true,
            })
          );
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.success,
              message: data.errorMsg,
            })
          );
        } else {
          dispatch(
            toggleNotificationVisibility({
              isVisible: true,
              status: NotificationType.error,
              message: data.errorMsg,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(toggleLoader({ isLoading: false }));
        console.log(error, "error");
      });
  }
);

// login with google part
export const getLoginwithGoogleUserdata = createAsyncThunk(
  "get user data",
  async (payload: string, { dispatch }) => {
    dispatch(
      toggleLoading({
        isLoading: true,
      })
    );
    dispatch(toggleLoader({ isLoading: true }));
    await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));
        // console.log(data)
        // create login or dispatch login here
        dispatch(
          createLoginWithGoogle({
            emailId: data.email,
          })
        );
        // let tokenObj = {
        //   accessToken: payload,
        //   refreshToken: payload,
        // };
        // setLocalStorage(
        //   localStorageActionType.SET_ACCESS_REFRESH_TOKEN,
        //   tokenObj
        // );
      })
      .catch((err) => {
        dispatch(toggleLoader({ isLoading: false }));
        console.log(err);
        dispatch(
          toggleNotificationVisibility({
            isVisible: true,
            status: NotificationType.error,
            message: "Something went wrong, try Mannually.",
          })
        );
      });
  }
);

export const createLoginWithGoogle = createAsyncThunk(
  "auth/login",
  async (payload: any, { dispatch }) => {
    console.log(payload);
    const token = getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN);
    console.log(token);
    // dispatch(toggleNotificationVisibility({
    //     isVisible: true,
    //     status: NotificationType.error,
    //     message: "Something went wrong, try Mannually."
    // }));

    dispatch(
      toggleLoading({
        isLoading: true,
      })
    );

    const response = await fetch(endPoint.socialLogin, {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        //     if (data.statusCode === 200) {

        //     let responseObj = data.result;
        //     let publicUserId = responseObj.publicUserId;
        //     let tokenObj = {
        //         accessToken: responseObj.accessToken,
        //         refreshToken: responseObj.refreshToken
        //     }

        //      setLocalStorage(localStorageActionType.SET_ACCESS_REFRESH_TOKEN, tokenObj);
        //      setLocalStorage(localStorageActionType.SET_PUBLIC_USER_ID, publicUserId);

        //     dispatch(toggleLogin({
        //         isLoggedin:true,
        //         isAuthenticated: responseObj.authenticated
        //     }));

        //     dispatch(toggleNotificationVisibility({
        //         isVisible: true,
        //         status: NotificationType.success,
        //         message: data.errorMsg
        //     }));
        // } else {
        //     dispatch(toggleNotificationVisibility({
        //         isVisible: true,
        //         status: NotificationType.error,
        //         message: data.errorMsg
        //     }));
        // }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    // .finally(() => {
    //     dispatch(toggleLoading({
    //         isLoading: false
    //     }));
    // })
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: loginInitialState,
  reducers: {
    resetLoginState: () => {
      return loginInitialState;
    },
    toggleLoading: (state, action: PayloadAction<Loading>) => {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    },
    toggleLogin: (state, action: PayloadAction<Login>) => {
      return {
        ...state,
        isLoggedin: action.payload.isLoggedin,
        isAuthenticated: action.payload.isAuthenticated,
      };
    },
  },
});

export const { resetLoginState, toggleLoading, toggleLogin } =
  loginSlice.actions;
export default loginSlice.reducer;
